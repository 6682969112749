import React, { useState, useEffect, useRef } from 'react';
import { FiPlus, FiX } from 'react-icons/fi';

interface SearchableSelectProps {
    label: string;
    name: string;
    value: string;
    options: { value: string; label: string }[];
    onChange: (value: string) => void;
    onButtonClick: () => void;
    buttonLabel: string;
}

const SearchableSelect: React.FC<SearchableSelectProps> = ({
   label,
   name,
   value,
   options,
   onChange,
   onButtonClick,
   buttonLabel
}) => {
    // Trouver le label correspondant à la valeur actuelle
    const selectedOption = options.find(option => option.value === value);
    const selectedLabel = selectedOption ? selectedOption.label : '';

    const [query, setQuery] = useState(selectedLabel);
    const [isOpen, setIsOpen] = useState(false);
    const inputRef = useRef<HTMLDivElement>(null);

    // Mettre à jour query lorsque value change (important pour la sélection automatique)
    useEffect(() => {
        const option = options.find(opt => opt.value === value);
        if (option) {
            setQuery(option.label);
        } else if (!value) {
            // Si la valeur est vide/nulle, effacer aussi le champ de recherche
            setQuery('');
        }
    }, [value, options]);

    // Assurer la mise à jour du champ quand les options changent mais que la valeur reste la même
    useEffect(() => {
        // Si nous avons une valeur sélectionnée
        if (value) {
            // Chercher dans les nouvelles options
            const option = options.find(opt => opt.value === value);
            if (option) {
                // Mettre à jour le champ de recherche avec le libellé correspondant
                setQuery(option.label);
            }
        }
    }, [options, value]); // Dépend à la fois des options et de la valeur

    // Ferme les propositions si l'on clique en dehors de l'input
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [inputRef]);

    // Fonction pour effacer la sélection
    const handleClear = () => {
        setQuery('');
        onChange(''); // Appeler onChange avec une chaîne vide pour indiquer qu'aucun client n'est sélectionné
        setIsOpen(false);
    };

    // Si l'input est vide, affiche tous les clients, sinon filtre
    const filteredOptions = query === ''
        ? options
        : options.filter(option =>
            option.label.toLowerCase().includes(query.toLowerCase())
        );

    return (
        <div className="mb-4 relative" ref={inputRef}>
            <div className="flex justify-between items-center mb-[5px]">
                <label className="block text-primary-theme text-xs font-normal">{label}</label>
            </div>
            <div className="flex items-center relative">
                <input
                    type="text"
                    name={name}
                    value={query}
                    onChange={(e) => {
                        setQuery(e.target.value);
                        setIsOpen(true);
                        // Si l'utilisateur efface complètement le champ, réinitialiser la valeur
                        if (e.target.value === '') {
                            onChange('');
                        }
                    }}
                    onFocus={() => setIsOpen(true)}
                    className="appearance-none text-sm block w-full border border-theme bg-theme text-primary-theme rounded-lg px-5 py-3 leading-tight focus:outline-none focus:ring-1 focus:ring-primary"
                    placeholder="Rechercher un client"
                    style={{paddingRight: '120px'}} // Augmenter l'espace pour accommoder les deux boutons
                />
                {selectedOption && (
                    <button
                        type="button"
                        onClick={handleClear}
                        className="absolute right-[80px] top-1/2 transform -translate-y-1/2 p-1 text-secondary-theme hover:text-primary-theme"
                        title="Effacer la sélection"
                    >
                        <FiX />
                    </button>
                )}
                <button
                    type="button"
                    onClick={onButtonClick}
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 px-3 py-1 bg-primary text-white text-xs rounded-lg flex items-center space-x-1"
                    style={{whiteSpace: 'nowrap'}}
                >
                    <FiPlus className="text-theme"/>
                    <span className="text-theme">{buttonLabel}</span>
                </button>
            </div>

            {isOpen && (
                <ul className="absolute left-0 right-0 bg-theme border border-theme rounded-b-lg mt-0 max-h-48 w-full overflow-y-auto z-10">
                    {filteredOptions.length > 0 ? (
                        filteredOptions.map((option, index) => (
                            <li
                                key={index}
                                className="px-4 py-2 text-primary-theme hover:bg-theme-hover cursor-pointer"
                                onClick={() => {
                                    setQuery(option.label);
                                    setIsOpen(false);
                                    onChange(option.value);
                                }}
                            >
                                {option.label}
                            </li>
                        ))
                    ) : (
                        <li className="px-4 py-2 text-primary-theme">Aucune option disponible</li>
                    )}
                </ul>
            )}
        </div>
    );
};

export default SearchableSelect;