import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {FiArrowLeft} from 'react-icons/fi';
import React from "react";

const TermsOfServicePage: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="prose max-w-4xl mx-auto p-6">
            <div className="flex items-center justify-between mb-8">
                <button
                    onClick={() => navigate(-1)}
                    className="flex items-center gap-2 text-sm text-primary-theme py-2 rounded-lg transition"
                >
                    <FiArrowLeft className="text-lg" />
                    Retour
                </button>
                <h1 className="text-center text-lg sm:text-2xl font-semibold mx-auto w-full left-0 right-0 pointer-events-none">
                    Conditions Générales d’Utilisation (CGU) de Bricksly
                </h1>
            </div>

            <div className="mb-6">
                <p>Version en vigueur au : 1 avril 2025</p>
            </div>

            <div id="objet" className="mb-6">
                <h2 className="text-lg sm:text-xl font-semibold mb-2">1. Objet</h2>
                <p>Les présentes Conditions Générales d’Utilisation (ci-après "CGU") ont pour objet de définir les conditions d’accès et d’utilisation de la plateforme web Bricksly.fr (ci-après "la Plateforme"), une application en ligne de gestion de projet et de collaboration entre entreprises et clients.</p>
            </div>

            <div id="acceptation" className="mb-6">
                <h2 className="text-lg sm:text-xl font-semibold mb-2">2. Acceptation des CGU</h2>
                <p>L’accès et l’utilisation de la Plateforme impliquent l’acceptation sans réserve des présentes CGU. En accédant à Bricksly, l’utilisateur reconnaît avoir pris connaissance des CGU et s’engage à les respecter.</p>
            </div>

            <div id="acces" className="mb-6">
                <h2 className="text-lg sm:text-xl font-semibold mb-2">3. Accès au service</h2>
                <p>L’accès à certaines fonctionnalités de la Plateforme peut nécessiter la création d’un compte utilisateur. L'utilisateur s'engage à fournir des informations exactes et à jour.</p>
            </div>

            <div id="restrictions" className="mb-6">
                <h2 className="text-lg sm:text-xl font-semibold mb-2">4. Restrictions d’utilisation</h2>
                <p>a) Utiliser la Plateforme à des fins illégales ou non autorisées</p>
                <p>b) Violer toute loi en vigueur ou règlementation applicable</p>
                <p>c) Porter atteinte aux droits de tiers, notamment les droits de propriété intellectuelle</p>
                <p>d) Tenter d'accéder sans autorisation à des parties restreintes de la Plateforme</p>
            </div>

            <div id="propriete" className="mb-6">
                <h2 className="text-lg sm:text-xl font-semibold mb-2">5. Propriété intellectuelle</h2>
                <p>Tous les contenus présents sur la Plateforme (textes, images, logos, codes, fonctionnalités) sont protégés par les lois françaises relatives à la propriété intellectuelle. Toute reproduction, distribution ou exploitation sans autorisation est interdite.</p>
            </div>

            <div id="disponibilite" className="mb-6">
                <h2 className="text-lg sm:text-xl font-semibold mb-2">6. Disponibilité du service</h2>
                <p>La Plateforme est proposée en version bêta. Son accès est gratuit et peut faire l’objet d’interruptions pour maintenance ou améliorations. Aucun engagement de disponibilité ou de performance n’est garanti.</p>
            </div>

            <div id="responsabilite" className="mb-6">
                <h2 className="text-lg sm:text-xl font-semibold mb-2">7. Limitation de responsabilité</h2>
                <p>a) D’un usage non conforme par les utilisateurs</p>
                <p>b) D’une perte de données</p>
                <p>c) D’une interruption ou d’un dysfonctionnement du service</p>

            </div>

            <div id="resiliation" className="mb-6">
                <h2 className="text-lg sm:text-xl font-semibold mb-2">8. Résiliation</h2>
                <p>Nous nous réservons le droit de suspendre ou supprimer un compte utilisateur en cas de non-respect des présentes CGU. L’utilisateur peut également clôturer son compte à tout moment.</p>
            </div>

            <div id="loi" className="mb-6">
                <h2 className="text-lg sm:text-xl font-semibold mb-2">9. Loi applicable</h2>
                <p>Les présentes CGU sont régies par la loi française. En cas de litige, une tentative de résolution amiable sera privilégiée avant tout recours judiciaire.</p>
            </div>
            <div>
                <p className="text-primary text-xs text-center cursor-pointer"><a className="mr-2" onClick={() => navigate('/terms-of-service')}> Conditions Générales d’Utilisation</a><a className="mr-2" onClick={() => navigate('/privacy-policy')}>Politique de Confidentialité</a><a onClick={() => navigate('/legal-notice')}>Mentions légales</a></p>
            </div>
        </div>
    );
};

export default TermsOfServicePage;