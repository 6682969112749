import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import {
    fetchMessages,
    fetchThreadDetail,
    markAsRead,
    removeMessage,
    sendNewMessage,
    updateMessageStatus,
    addTempMessage
} from './messagingSlice';
import { fetchUserInfo, selectUser } from '../auth/authSlice';
import { fetchProjectById, selectProjectById } from '../project/projectSlice';
import { useMercure } from '../../hooks/useMercure';
import ThreadMessagesList from './components/ThreadMessagesList';
import ThreadMessageInput from './components/ThreadMessageInput';
import DropOverlay from './components/DropOverlay';
import ThreadHeader from "./components/ThreadHeader";

interface ThreadContainerProps {
    threadId: string;
    onMoreClick: () => void;
    onBackClick: () => void;
    showBackButton: boolean;
}

const ThreadContainer: React.FC<ThreadContainerProps> = ({
     threadId,
     onMoreClick,
     onBackClick,
     showBackButton
 }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [file, setFile] = useState<File | null>(null);
    const [newMessageAdded, setNewMessageAdded] = useState(false);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const user = useSelector(selectUser);
    const thread = useSelector((state: RootState) => state.messaging.threads.find((t) => t.uuid === threadId));
    const messages = useSelector((state: RootState) => threadId ? state.messaging.messages[threadId] || [] : []);
    const loadingState = useSelector((state: RootState) => state.messaging.loading || state.auth.loading);
    const project = useSelector((state: RootState) => thread?.project?.uuid ? selectProjectById(state, thread.project.uuid) : null);

    useMercure(threadId);

    useEffect(() => {
        const loadThreadData = async () => {
            if (threadId) {
                await Promise.all([
                    dispatch(fetchThreadDetail(threadId)),
                    dispatch(fetchMessages(threadId))
                ]);
                dispatch(markAsRead({ threadId }));
            }

            dispatch(fetchUserInfo());

            if (thread?.project?.uuid) {
                dispatch(fetchProjectById(thread.project.uuid));
            }
        };

        loadThreadData();
    }, [dispatch, threadId, thread?.project?.uuid]);

    const handleSendMessage = async (message: string) => {
        if (!threadId) return;

        const nonce = generateNonce();
        const tempMessage = {
            content: message.trim(),
            sender: user,
            createdAt: new Date().toISOString(),
            status: 'pending',
            nonce,
        };

        dispatch(addTempMessage({ threadId, message: tempMessage }));
        setNewMessageAdded(true);

        try {
            const response = await dispatch(sendNewMessage({ threadId, content: message, nonce })).unwrap();
            dispatch(updateMessageStatus({
                threadId,
                nonce: response.message.nonce,
                status: 'sent',
                updatedMessage: { ...response.message }
            }));
        } catch (error) {
            dispatch(updateMessageStatus({ threadId, nonce, status: 'failed' }));
        }
    };

    const handleSendDocument = async (documentUuid: string) => {
        if (!threadId) return;

        const tempMessage = {
            content: '',
            sender: user,
            createdAt: new Date().toISOString(),
            status: 'pending',
            nonce: generateNonce(),
            isDocument: true,
            documents: [documentUuid],
        };

        dispatch(addTempMessage({ threadId, message: tempMessage }));
    };

    const handleDeleteMessage = (messageId: string) => {
        if (threadId && messageId) {
            dispatch(removeMessage({ threadId, messageId }));
        }
    };

    return (
        <div className="relative flex flex-col h-full">
            <ThreadHeader
                project={project}
                thread={thread}
                loading={loadingState}
                onMoreClick={onMoreClick}
                onBackClick={onBackClick}
                showBackButton={showBackButton}
            />
            <DropOverlay setFile={setFile}/>
            <ThreadMessagesList
                messages={messages}
                user={user}
                handleDeleteMessage={handleDeleteMessage}
                threadId={threadId}
                newMessageAdded={newMessageAdded}
                setNewMessageAdded={setNewMessageAdded}
            />
            <ThreadMessageInput
                onSendMessage={handleSendMessage}
                onSendDocument={handleSendDocument}
                file={file}
                textareaRef={textareaRef}
                fileInputRef={fileInputRef}
                setFile={setFile}
                customer={project?.customer}
                lengthMessages={messages.length}
                projectUuid={project?.uuid || ''}
            />
        </div>
    );
};

export default ThreadContainer;

const generateNonce = () => {
    return (Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2)).substring(0, 20);
};