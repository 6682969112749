import { useEffect, useState } from 'react';
import logoLight from './../assets/images/logo_bricksly.svg';
import logoDark from './../assets/images/logo_dark_bricksly.svg';

export const useLogo = (theme: string) => {
    const [logo, setLogo] = useState(theme === 'dark' ? logoDark : logoLight);

    useEffect(() => {
        const updateLogo = () => {
            if (theme === 'system') {
                const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
                setLogo(systemTheme === 'dark' ? logoDark : logoLight);
            } else {
                setLogo(theme === 'dark' ? logoDark : logoLight);
            }
        };

        updateLogo();

        if (theme === 'system') {
            const systemThemeListener = window.matchMedia('(prefers-color-scheme: dark)');
            systemThemeListener.addEventListener('change', updateLogo);

            return () => {
                systemThemeListener.removeEventListener('change', updateLogo);
            };
        }
    }, [theme]);

    return logo;
};