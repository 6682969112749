import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import companyReducer from '../features/company/companySlice';
import customerReducer from '../features/customer/customerSlice';
import projectReducer from '../features/project/projectSlice';
import messagingReducer from '../features/messaging/messagingSlice';
import documentReducer from '../features/document/documentSlice';
import missionReducer from '../features/project/missionSlice';
import imageReducer from '../features/image/imageSlice';
import { RESET_STORE } from './resetActions';

const appReducer = combineReducers({
    auth: authReducer,
    company: companyReducer,
    customers: customerReducer,
    projects: projectReducer,
    missions: missionReducer,
    messaging: messagingReducer,
    documents: documentReducer,
    images: imageReducer,
});

export type AppState = ReturnType<typeof appReducer>;

const rootReducer = (state: AppState | undefined, action: any) => {

    if (action.type === RESET_STORE) {
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;