import React from 'react';

interface Option {
    value: string;
    label: string;
}

interface RadioButtonGroupProps {
    options: Option[];
    value: string;
    onChange: (value: string) => void;
    name: string;
    label?: string;
    className?: string;
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
   options,
   value,
   onChange,
   name,
   label,
   className = ''
}) => {
    return (
        <div className="flex flex-col gap-2 w-full">
            {label && (
                <div className="flex justify-between items-center mb-[5px]">
                    <label className="block text-primary-theme text-xs font-normal">
                        {label}
                    </label>
                </div>
            )}
            <div className="flex flex-col md:flex-row gap-2">
                {options.map((option: Option) => (
                    <button
                        key={option.value}
                        type="button"
                        className={`
                            w-full md:flex-1
                            appearance-none 
                            text-sm 
                            block 
                            border 
                            border-theme 
                            text-primary-theme 
                            rounded-lg 
                            px-5 
                            py-3 
                            leading-tight 
                            focus:outline-none 
                            focus:ring-1 
                            focus:ring-primary
                            ${value === option.value
                            ? 'bg-primary text-white'
                            : 'bg-theme text-primary-theme hover:bg-theme-hover'
                        }
                            ${className}
                        `}
                        onClick={() => onChange(option.value)}
                    >
                        {option.label}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default RadioButtonGroup;