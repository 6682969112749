// src/components/company/FormNavigation.tsx
import React from 'react';
import { t } from 'i18next';
import Button from "../../components/UI/Button";

interface FormNavigationProps {
    step: number;
    isLoading: boolean;
    isNextDisabled?: boolean;
    onNext: () => void;
    onBack: () => void;
    onSubmit: () => void;
}

const FormNavigation: React.FC<FormNavigationProps> = ({
   step,
   isLoading,
   isNextDisabled = false,
   onNext,
   onBack,
   onSubmit
}) => {
    return (
        <div>
            <div className="w-full flex justify-center">
                <div className="w-full max-w-md py-4 bg-surface-theme">
                    {step === 1 && (
                        <div className="flex justify-end">
                            <Button
                                size="medium"
                                type="button"
                                onClick={onNext}
                                disabled={isNextDisabled}
                            >
                                {t('company.next')}
                            </Button>
                        </div>
                    )}
                    {step === 2 && (
                        <div className="flex justify-between bg-surface-theme space-x-8">
                            <Button
                                size="medium"
                                type="button"
                                background={false}
                                onClick={onBack}
                            >
                                {t('company.back')}
                            </Button>
                            <Button
                                size="medium"
                                type="button"
                                isLoading={isLoading}
                                onClick={onSubmit}
                            >
                                {t('company.submit')}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FormNavigation;