import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getThreads, getMessages, getThreadDetail, deleteMessage, sendMessage } from '../../api/messagingService';

// Définition des types pour une meilleure typage
interface Message {
    uuid?: string;
    content?: string;
    body?: string;
    sender?: { firstName: string, lastName: string };
    nonce?: string;
    createdAt: string;
    isDocument?: boolean;
    status?: string;
    threadId?: string;
    documents?: string[];
}

interface LastMessage {
    body: string | undefined;
    sender?: { firstName: string, lastName: string };
    createdAt: string;
}

interface Thread {
    uuid: string;
    title: string;
    project?: any;
    lastMessage?: LastMessage;
}

interface MessagingState {
    threads: Thread[];
    messages: Record<string, Message[]>;
    loading: boolean;
    error: string | null;
    unreadMessages: Record<string, number>;
    hasFetchedThreads: boolean;
}

const initialState: MessagingState = {
    threads: [],
    messages: {},
    loading: false,
    error: null,
    unreadMessages: {},
    hasFetchedThreads: false,
};

// Thunks pour les opérations asynchrones
export const fetchThreads = createAsyncThunk('messaging/fetchThreads', async () => {
    const response = await getThreads();
    return response.items;
});

export const fetchMessages = createAsyncThunk('messaging/fetchMessages', async (threadId: string) => {
    const response = await getMessages(threadId);
    return { threadId, messages: response.items };
});

export const fetchMessagesWithPagination = createAsyncThunk(
    'messaging/fetchMessagesWithPagination',
    async ({ threadId, limit = 20, skip = 0 }: { threadId: string, limit?: number, skip?: number }) => {
        const response = await getMessages(threadId, limit, skip);
        return { threadId, messages: response.items, skip };
    }
);

export const fetchThreadDetail = createAsyncThunk('messaging/fetchThreadDetail', async (threadId: string) => {
    const response = await getThreadDetail(threadId);
    return response;
});

export const removeMessage = createAsyncThunk('messaging/removeMessage', async ({ threadId, messageId }: { threadId: string, messageId: string }) => {
    await deleteMessage(threadId, messageId);
    return { threadId, messageId };
});

export const sendNewMessage = createAsyncThunk('messaging/sendNewMessage', async ({ threadId, content, nonce }: { threadId: string, content: string, nonce: string }) => {
    const response = await sendMessage(threadId, content, nonce);
    return { threadId, message: response };
});

export const markAsRead = createAsyncThunk('messaging/markAsRead', async ({ threadId }: { threadId: string }) => {
    return threadId;
});

export const fetchThreadsWithLastMessage = createAsyncThunk(
    'messaging/fetchThreadsWithLastMessage',
    async () => {
        const response = await getThreads();

        // Pour chaque thread, récupérer le dernier message
        const threadsWithLastMessage = await Promise.all(
            response.items.map(async (thread: Thread) => {
                const lastMessageResponse = await getMessages(thread.uuid, 1);
                const lastMessage = lastMessageResponse.items[0];

                if (lastMessage) {
                    return {
                        ...thread,
                        lastMessage: {
                            body: lastMessage.content || lastMessage.body,
                            sender: lastMessage.sender,
                            createdAt: lastMessage.createdAt
                        }
                    };
                }

                return thread;
            })
        );

        return threadsWithLastMessage;
    }
);

const updateThreadLastMessage = (state: MessagingState, threadId: string, message: Message) => {
    const threadIndex = state.threads.findIndex(t => t.uuid === threadId);
    if (threadIndex !== -1) {
        state.threads[threadIndex] = {
            ...state.threads[threadIndex],
            lastMessage: {
                body: message.content || message.body,
                sender: message.sender,
                createdAt: message.createdAt
            }
        };
    }
};


// Fonction utilitaire pour vérifier l'existence d'un message par nonce
const messageExists = (messages: Message[], nonce: string): boolean => {
    return messages.some(message => message.nonce === nonce);
};

const messagingSlice = createSlice({
    name: 'messaging',
    initialState,
    reducers: {
        addTempMessage: (state, action: PayloadAction<{ threadId: string, message: Message }>) => {
            const { threadId, message } = action.payload;
            if (!state.messages[threadId]) {
                state.messages[threadId] = [];
            }
            state.messages[threadId].push(message);
            updateThreadLastMessage(state, threadId, message);
        },
        updateMessageStatus: (state, action: PayloadAction<{ threadId: string, nonce: string, status: string, updatedMessage?: Message }>) => {
            const { threadId, nonce, status, updatedMessage } = action.payload;
            const threadMessages = state.messages[threadId];

            if (!threadMessages) return;

            const messageIndex = threadMessages.findIndex(message => message.nonce === nonce);
            if (messageIndex !== -1) {
                threadMessages[messageIndex].status = status;
                if (updatedMessage) {
                    threadMessages[messageIndex] = { ...threadMessages[messageIndex], ...updatedMessage };
                }
            }
        },
        receiveMercureMessage: (state, action: PayloadAction<{ threadId: string, message: Message }>) => {
            const { threadId, message } = action.payload;

            // S'assurer que le tableau de messages existe
            if (!state.messages[threadId]) {
                state.messages[threadId] = [];
            }

            // Ajouter le message s'il n'existe pas déjà
            if (!state.messages[threadId].some(m => m.uuid === message.uuid)) {
                // Ajouter le message au thread
                state.messages[threadId].push(message);

                // Mettre à jour le lastMessage
                const threadIndex = state.threads.findIndex(t => t.uuid === threadId);
                if (threadIndex !== -1) {
                    // Conserver toutes les propriétés existantes du thread
                    const updatedThread = {
                        ...state.threads[threadIndex],
                        lastMessage: {
                            body: message.content,
                            sender: message.sender,
                            createdAt: message.createdAt
                        }
                    };

                    // Supprimer le thread de sa position actuelle
                    state.threads.splice(threadIndex, 1);
                    // L'ajouter au début de la liste
                    state.threads.unshift(updatedThread);
                }
            }
        },
        updateUnreadMessages: (state, action: PayloadAction<{ threadId: string, count: number }>) => {
            const { threadId, count } = action.payload;
            state.unreadMessages[threadId] = count;
        },
        addUnreadMessage: (state, action: PayloadAction<string>) => {
            const threadId = action.payload;
            if (!state.unreadMessages[threadId]) {
                state.unreadMessages[threadId] = 0;
            }
            state.unreadMessages[threadId] += 1;

            // Mise à jour du lastMessage pour le thread concerné
            const threadIndex = state.threads.findIndex(t => t.uuid === threadId);
            if (threadIndex !== -1) {
                // Récupérer le dernier message du thread depuis state.messages
                const threadMessages = state.messages[threadId] || [];
                const lastMessage = threadMessages[threadMessages.length - 1];

                if (lastMessage) {
                    state.threads[threadIndex] = {
                        ...state.threads[threadIndex],
                        lastMessage: {
                            body: lastMessage.content || lastMessage.body,
                            sender: lastMessage.sender,
                            createdAt: lastMessage.createdAt
                        }
                    };

                    // Réorganiser les threads
                    const thread = state.threads[threadIndex];
                    state.threads.splice(threadIndex, 1);
                    state.threads.unshift(thread);
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchThreadsWithLastMessage.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchThreadsWithLastMessage.fulfilled, (state, action) => {
                state.threads = action.payload;
                state.loading = false;
                state.hasFetchedThreads = true;
            })
            .addCase(fetchThreadsWithLastMessage.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch threads with last message';
            })
            .addCase(fetchThreads.pending, (state) => {
                state.loading = true;
                state.error = null; // Reset error state on new request
            })
            .addCase(fetchThreads.fulfilled, (state, action) => {
                state.threads = action.payload;
                state.loading = false;
            })
            .addCase(fetchThreads.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch threads';
            })
            .addCase(fetchMessages.pending, (state) => {
                state.loading = true;
                state.error = null; // Reset error state on new request
            })
            .addCase(fetchMessages.fulfilled, (state, action) => {
                state.messages[action.payload.threadId] = action.payload.messages;
                state.loading = false;
            })
            .addCase(fetchMessagesWithPagination.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchMessagesWithPagination.fulfilled, (state, action) => {
                const { threadId, messages, skip } = action.payload;

                if (skip === 0) {
                    // Initial load or reload, replace the messages
                    state.messages[threadId] = messages;
                } else {
                    // Append older messages at the beginning for infinite scroll
                    state.messages[threadId] = [...messages, ...(state.messages[threadId] || [])];
                }
                state.loading = false;
            })
            .addCase(fetchMessagesWithPagination.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch messages';
            })
            .addCase(fetchThreadDetail.pending, (state) => {
                state.loading = true;
                state.error = null; // Reset error state on new request
            })
            .addCase(fetchThreadDetail.fulfilled, (state, action) => {
                const thread = action.payload;
                const existingThreadIndex = state.threads.findIndex(t => t.uuid === thread.uuid);

                if (existingThreadIndex !== -1) {
                    const existingLastMessage = state.threads[existingThreadIndex].lastMessage;
                    state.threads[existingThreadIndex] = {
                        ...thread,
                        lastMessage: existingLastMessage
                    };
                } else {
                    state.threads.push(thread);
                }
                state.loading = false;
            })
            .addCase(fetchThreadDetail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch thread detail';
            })
            .addCase(sendNewMessage.fulfilled, (state, action) => {
                const { threadId, message } = action.payload;
                const threadMessages = state.messages[threadId] || [];

                const messageIndex = threadMessages.findIndex(msg => msg.nonce === message.nonce);

                if (messageIndex !== -1) {
                    threadMessages[messageIndex] = { ...threadMessages[messageIndex], ...message, status: 'sent' };
                } else {
                    state.messages[threadId] = [...threadMessages, message];
                }
                // Mettre à jour le lastMessage du thread
                updateThreadLastMessage(state, threadId, message);
            })
            .addCase(sendNewMessage.rejected, (state, action) => {
                state.error = action.error.message || 'Failed to send message';
            })
            .addCase(removeMessage.fulfilled, (state, action) => {
                state.messages[action.payload.threadId] = state.messages[action.payload.threadId].filter(
                    (message) => message.uuid !== action.payload.messageId
                );
            })
            .addCase(markAsRead.fulfilled, (state, action) => {
                delete state.unreadMessages[action.payload];
            });
    },
});

export const { addTempMessage, updateMessageStatus, receiveMercureMessage, updateUnreadMessages, addUnreadMessage } = messagingSlice.actions;

export default messagingSlice.reducer;