import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { FiChevronRight } from "react-icons/fi";

type BreadcrumbProps = {
    dynamicNames?: { [key: string]: string };
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ dynamicNames = {} }) => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    const routeNames: { [key: string]: string } = {
        projects: 'projets',
        customers: 'clients',
        documents: 'documents',
        settings: 'paramètres',
    };

    return (
        <nav aria-label="breadcrumb" className="flex items-center text-sm text-gray-600">
            <ol className="flex space-x-2">
                {pathnames.map((value, index) => {
                    const isLast = index === pathnames.length - 1;
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                    // Priorité : Noms dynamiques > Noms mappés statiques > Segment brut
                    const name = dynamicNames[value] || routeNames[value] || decodeURIComponent(value);

                    return (
                        <li key={to} className="flex items-center space-x-2">
                            {index > 0 && <span className="text-secondary-theme"><FiChevronRight /></span>}
                            {isLast ? (
                                <span className="text-primary">{name}</span>
                            ) : (
                                <Link to={to} className="text-secondary-theme">
                                    {name}
                                </Link>
                            )}
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
};

export default Breadcrumb;