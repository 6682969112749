import {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../store/hooks';
import {
    fetchCustomers,
    fetchCustomerById,
    createCustomer,
    updateCustomer,
    deleteCustomer,
    selectCustomers,
    selectCustomersLoading,
    selectCustomersError,
    selectCustomerById,
    selectHasFetchedAll,
} from '../features/customer/customerSlice';
import { RootState } from '../store/store';
import { Customer } from '../features/customer/customerSlice';

export const useCustomers = (customerUuid?: string) => {
    const dispatch = useAppDispatch();

    // Sélection des données et des états depuis Redux
    const customers = useSelector(selectCustomers);
    const customersLoading = useSelector(selectCustomersLoading);
    const customersError = useSelector(selectCustomersError);
    const hasFetchedAll = useSelector(selectHasFetchedAll);

    const [localSelectedCustomer, setLocalSelectedCustomer] = useState<Customer | null>(null);

    const reduxSelectedCustomer = useSelector((state: RootState) =>
        customerUuid ? selectCustomerById(state, customerUuid) : null
    );

    const selectedCustomer = customerUuid ? reduxSelectedCustomer : localSelectedCustomer;

    // Récupération des clients ou d'un client spécifique
    useEffect(() => {
        if (customerUuid) {
            if (!selectedCustomer) {
                dispatch(fetchCustomerById(customerUuid));
            }
        } else if (!hasFetchedAll) {
            dispatch(fetchCustomers());
        }
    }, [customerUuid, selectedCustomer, hasFetchedAll, dispatch]);

    // Méthode pour ajouter un client
    const addCustomer = async (customerData: { companyName: string; firstName: string; lastName: string; email: string; phone: string }) => {
        try {
            await dispatch(createCustomer(customerData)).unwrap();
        } catch (err) {
            console.error('Failed to create customer:', err);
        }
    };

    // Méthode pour mettre à jour un client
    const modifyCustomer = async (
        uuid: string,
        customerData: Partial<Omit<Customer, 'uuid'>>
    ) => {
        // Construire un objet valide pour updateCustomer
        const completeCustomerData: Omit<Customer, 'uuid'> = {
            companyName: customerData.companyName || '',
            firstName: customerData.firstName || '',
            lastName: customerData.lastName || '',
            email: customerData.email || '',
            phone: customerData.phone || '',
        };

        try {
            await dispatch(updateCustomer({ uuid, customerData: completeCustomerData })).unwrap();
        } catch (err) {
            console.error('Failed to update customer:', err);
        }
    };


    // Méthode pour supprimer un client
    const removeCustomer = async (uuid: string) => {
        try {
            await dispatch(deleteCustomer(uuid)).unwrap();
        } catch (err) {
            console.error('Failed to delete customer:', err);
        }
    };

    return {
        customers,
        selectedCustomer,
        setSelectedCustomer: setLocalSelectedCustomer,
        loading: customersLoading,
        error: customersError,
        addCustomer,
        modifyCustomer,
        removeCustomer,
    };
};