import React from 'react';
import { HiOutlineSparkles } from "react-icons/hi2";
import { useCountries } from "../hooks/useCountries";
import {t} from "i18next";

interface CountrySelectProps {
    label: string;
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    required?: boolean;
    className?: string;
    error?: string;
    autoFilled?: boolean;
}

const CountrySelect: React.FC<CountrySelectProps> = ({
     label,
     name,
     value,
     onChange,
     required = false,
     className = '',
     error,
     autoFilled = false
 }) => {
    const { countries, isLoading } = useCountries();

    return (
        <div>
            <div className="flex justify-between items-center mb-[5px]">
                <label className="block text-primary-theme text-xs font-normal">
                    {label}
                </label>
                {autoFilled && (
                    <div className="flex items-center gap-1 text-primary">
                        <HiOutlineSparkles className="w-3 h-3" />
                        <span className="text-[10px]">Renseignée automatiquement</span>
                    </div>
                )}
            </div>
            <select
                name={name}
                value={value}
                onChange={onChange}
                required={required}
                disabled={isLoading}
                className={`appearance-none text-sm block w-full border border-theme bg-theme text-primary-theme rounded-lg px-5 py-3 leading-tight focus:outline-none focus:ring-1 focus:ring-primary ${className}`}
            >
                <option value="">{t('company.address.country.placeholder')}</option>
                {countries.map((country) => (
                    <option key={country.value} value={country.value}>
                        {country.label}
                    </option>
                ))}
            </select>
            {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>
    );
};

export default CountrySelect;