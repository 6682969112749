import React from 'react';
import {FiPlus, FiSmile} from 'react-icons/fi';

interface ThreadFileUploaderProps {
    projectUuid: string; // Nouveau prop pour le projet lié à la messagerie
    file: File | null;
    setFile: (file: File | null) => void;
    fileInputRef: React.RefObject<HTMLInputElement>;
}

const ThreadFileUploader: React.FC<ThreadFileUploaderProps> = ({ file, setFile, fileInputRef }) => {
    // Gestionnaire de changement de fichier
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        setFile(selectedFile || null); // Met à jour l'état du fichier sélectionné
    };

    // Gestionnaire pour simuler le clic sur l'input file
    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <div className="relative flex items-center justify-center">
            <input
                type="file"
                onChange={handleFileChange}
                ref={fileInputRef}
                className="hidden"
            />
            <button
                type="button"
                onClick={handleClick}
                className="w-10 h-10 flex items-center justify-center rounded-full cursor-pointer hover:bg-theme-hover"
            >
                <FiPlus className="text-xl text-primary-theme"/>
            </button>
        </div>
    );
};

export default ThreadFileUploader;