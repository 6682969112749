import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FiGrid, FiLayers, FiUsers, FiMessageSquare, FiSettings } from 'react-icons/fi';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { useUserPermissions } from '../hooks/useUserPermissions';
import { useIsPWA } from '../hooks/useIsPWA';
import {usePageContext} from "../contexts/PageContext";

type NavbarMobileProps = {
    theme: string;
};

const NavbarMobile: React.FC<NavbarMobileProps> = ({ theme }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { isCompanyProfile } = useUserPermissions();
    const { threadId } = useParams<{ threadId: string }>();
    const unreadMessages = useSelector((state: RootState) => state.messaging.unreadMessages);
    const hasUnreadMessages = Object.keys(unreadMessages).some(id => id !== threadId && unreadMessages[id] > 0);
    const isPWA = useIsPWA();
    const { dynamicData, showList } = usePageContext();

    return (
        <nav className={`fixed bottom-0 left-0 right-0 bg-surface-theme border-t border-theme z-50 block md:hidden ${isPWA ? 'pb-3' : 'pb-0'} ${location.pathname.startsWith('/messaging') ?
            `${!showList ? 'hidden md:flex' : ''}`
            : ''}`}>
            <ul className="flex justify-around items-center h-16">
                <li
                    className="flex-1 h-full"
                    onClick={() => navigate('/dashboard')}
                >
                    <button className="w-full h-full flex flex-col items-center justify-center">
                        <FiGrid
                            className={`text-xl p-full ${location.pathname === '/dashboard' ? 'text-primary' : 'text-primary-theme'}`}
                        />
                    </button>
                </li>

                <li
                    className="flex-1 h-full"
                    onClick={() => navigate('/projects')}
                >
                    <button className="w-full h-full flex flex-col items-center justify-center">
                        <FiLayers
                            className={`text-xl ${location.pathname.startsWith('/projects') ? 'text-primary' : 'text-primary-theme'}`}
                        />
                    </button>
                </li>

                {isCompanyProfile && (
                    <li
                        className="flex-1 h-full"
                        onClick={() => navigate('/customers')}
                    >
                        <button className="w-full h-full flex flex-col items-center justify-center">
                            <FiUsers
                                className={`text-xl ${location.pathname.startsWith('/customers') ? 'text-primary' : 'text-primary-theme'}`}
                            />
                        </button>
                    </li>
                )}

                <li
                    className="flex-1 h-full"
                    onClick={() => navigate('/messaging')}
                >
                    <button className="w-full h-full flex flex-col items-center justify-center">
                        <div className="relative">
                            <FiMessageSquare
                                className={`text-xl ${location.pathname.startsWith('/messaging') ? 'text-primary' : 'text-primary-theme'}`}
                            />
                            {hasUnreadMessages && (
                                <span className="absolute -top-1 -right-1 bg-red-500 w-2 h-2 rounded-full"></span>
                            )}
                        </div>
                    </button>
                </li>

                <li
                    className="flex-1 h-full"
                    onClick={() => navigate('/settings')}
                >
                    <button className="w-full h-full flex flex-col items-center justify-center">
                        <FiSettings
                            className={`text-xl ${location.pathname === '/settings' ? 'text-primary' : 'text-primary-theme'}`}
                        />
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default NavbarMobile;