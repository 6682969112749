import React from 'react';
import Skeleton from '../../../components/Skeleton';
import {FiMoreHorizontal, FiPhone, FiUsers, FiArrowLeft} from "react-icons/fi";
import {HiOutlineVideoCamera} from "react-icons/hi2";
import {useUserPermissions} from "../../../hooks/useUserPermissions";
import {Link} from "react-router-dom";

interface ThreadHeaderProps {
    loading: boolean;
    thread: any;
    project: any;
    onMoreClick: () => void;
    onBackClick?: () => void;
    showBackButton?: boolean;
}

const ThreadHeader: React.FC<ThreadHeaderProps> = ({
   loading,
   thread,
   project,
   onMoreClick,
   onBackClick,
   showBackButton
}) => {
    const { isCompanyProfile } = useUserPermissions();

    if (loading && !thread) {
        return (
            <div className="p-4">
                <Skeleton width="50%" height="30px" />
                <Skeleton width="100%" height="20px" />
                <Skeleton width="100%" height="20px" />
            </div>
        );
    }

    if (!thread) {
        return <div>Thread non trouvé.</div>;
    }

    return (
        <div className="w-full relative">
            <div
                className="flex items-center justify-between border-b border-theme md:px-4 py-4 fixed md:relative bg-surface-theme z-20 top-0 left-0 right-0 mx-[-16px] md:mx-0 px-6 md:pt-0">
                <div className="flex items-center">
                    {showBackButton && (
                        <button
                            onClick={onBackClick}
                            className="w-10 h-10 flex items-center justify-center rounded-full cursor-pointer hover:bg-theme-hover mr-2"
                        >
                            <FiArrowLeft className="text-[18px] text-primary-theme"/>
                        </button>
                    )}
                    <div>
                        <h1 className="text-base md:text-xl text-primary-theme font-medium">
                            <Link
                                to={`/projects/${project?.uuid}`}
                                className="text-primary-theme hover:text-primary"
                            >
                                {project?.title.slice(0, 30)}
                                {project?.title.length > 30 && "..."}
                            </Link>
                        </h1>
                        {isCompanyProfile ? (
                            <p className="text-primary-theme text-xs md:text-base">
                                <Link
                                    to={`/customers/${project?.customer.uuid}`}
                                    className="text-primary-theme hover:text-primary"
                                >
                                    {project?.customer.firstName} {project?.customer.lastName}
                                </Link>
                            </p>
                        ) : (
                            <p className="text-primary-theme">
                                <Link
                                    to={`/customers/${project?.createdBy.uuid}`}
                                    className="text-primary-theme hover:text-primary"
                                >
                                    {project?.createdBy.firstName} {project?.createdBy.lastName}
                                </Link>
                            </p>
                        )}
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="relative group">
                        <button
                            type="button"
                            className="w-10 h-10 flex items-center justify-center rounded-full cursor-pointer hover:bg-theme-hover"
                        >
                            <HiOutlineVideoCamera className="text-xl text-primary-theme" />
                        </button>
                        <div
                            className="absolute top-full mt-2 left-1/2 -translate-x-1/2 scale-0 group-hover:scale-100 transition-transform duration-200
                    bg-black text-white text-xs rounded px-3 py-1 z-50 whitespace-nowrap max-w-[200px] overflow-hidden text-ellipsis text-center"
                        >
                            Appel vidéo bientôt disponible
                        </div>
                    </div>

                    {/* Bouton audio */}
                    <div className="relative group">
                        <button
                            type="button"
                            className="w-10 h-10 flex items-center justify-center rounded-full cursor-pointer hover:bg-theme-hover"
                        >
                            <FiPhone className="text-[18px] text-primary-theme" />
                        </button>
                        <div
                            className="absolute top-full mt-2 left-1/2 -translate-x-1/2 scale-0 group-hover:scale-100 transition-transform duration-200
                    bg-black text-white text-xs rounded px-3 py-1 z-50 whitespace-nowrap max-w-[200px] overflow-hidden text-ellipsis text-center"
                        >
                            Appel audio bientôt disponible
                        </div>
                    </div>
                    <button
                        type="button"
                        onClick={onMoreClick}
                        className="w-10 h-10 flex items-center justify-center rounded-full cursor-pointer hover:bg-theme-hover"
                    >
                        <FiMoreHorizontal className="text-xl text-primary-theme"/>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ThreadHeader;