// features/project/projectSlice.ts

import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { Project, NewProject, ProjectsState, Mission } from './types';
import {
    createProject as createProjectAPI,
    deleteProject as apiDeleteProject,
    getAllProjects,
    getProjectInfo as apiGetProjectInfo,
    updateProject as apiUpdateProject,
    createMission as apiCreateMission,
    validateProject as apiValidateProject,
    cancelProject as apiCancelProject,
    apiCreateQuote,
    apiInviteCustomer,
} from '../../api/projectService';
import { addProjectDocument } from '../document/documentSlice';
import { addMissionDocument } from '../document/documentSlice';

const initialState: ProjectsState = {
    projects: [],
    loading: false,
    error: null,
    hasFetchedAll: false,
    selectedProject: null,
};

const handleRejected = (state: ProjectsState, action: PayloadAction<unknown, string, any, SerializedError>) => {
    state.loading = false;
    state.error = action.error.message ?? 'Une erreur est survenue';
};

export const fetchProjects = createAsyncThunk(
    'projects/fetchProjects',
    async (_, { rejectWithValue }) => {
        try {
            const { items } = await getAllProjects();
            return items;
        } catch {
            return rejectWithValue('Échec de la récupération des projets');
        }
    }
);

export const fetchProjectById = createAsyncThunk(
    'projects/fetchProjectById',
    async (uuid: string, { rejectWithValue }) => {
        try {
            return await apiGetProjectInfo(uuid);
        } catch {
            return rejectWithValue('Échec de la récupération du projet');
        }
    }
);

export const createProject = createAsyncThunk(
    'projects/createProject',
    async (projectData: NewProject, { rejectWithValue }) => {
        try {
            return await createProjectAPI(projectData);
        } catch {
            return rejectWithValue('Erreur lors de la création du projet');
        }
    }
);

export const createMission = createAsyncThunk(
    'projects/createMission',
    async ({ projectUuid, missionData }: {
        projectUuid: string;
        missionData: Omit<Mission, 'uuid' | 'status'>
    }, { rejectWithValue }) => {
        try {
            const response = await apiCreateMission(projectUuid, missionData);
            return { projectUuid, mission: response };
        } catch {
            return rejectWithValue('Erreur lors de la création de la mission');
        }
    }
);

export const updateProject = createAsyncThunk(
    'projects/updateProject',
    async ({ uuid, projectData }: {
        uuid: string;
        projectData: Partial<Omit<Project, 'uuid'>>
    }) => {
        return await apiUpdateProject(uuid, projectData);
    }
);

export const validateProject = createAsyncThunk(
    'projects/validateProject',
    async (uuid: string, { rejectWithValue }) => {
        try {
            const updatedProject = await apiValidateProject(uuid);
            return updatedProject;
        } catch {
            return rejectWithValue('Erreur lors de la validation du projet');
        }
    }
);

export const cancelProject = createAsyncThunk(
    'projects/cancelProject',
    async (uuid: string, { rejectWithValue }) => {
        try {
            const updatedProject = await apiCancelProject(uuid);
            return updatedProject;
        } catch {
            return rejectWithValue('Erreur lors de l\'annulation du projet');
        }
    }
);

export const createQuote = createAsyncThunk(
    'projects/createQuote',
    async (projectUuid: string, { rejectWithValue }) => {
        try {
            const quote = await apiCreateQuote(projectUuid);
            return { projectUuid, quote };
        } catch (error: any) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const inviteCustomer = createAsyncThunk(
    'projects/inviteCustomer',
    async (projectUuid: string, { rejectWithValue }) => {
        try {
            const response = await apiInviteCustomer(projectUuid);
            return response;
        } catch {
            return rejectWithValue('Erreur lors de l\'invitation du client');
        }
    }
);

export const deleteProject = createAsyncThunk(
    'projects/deleteProject',
    async (uuid: string, { rejectWithValue }) => {
        try {
            await apiDeleteProject(uuid);
            return uuid;
        } catch {
            return rejectWithValue('Erreur lors de la suppression du projet');
        }
    }
);

const projectSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        setSelectedProject: (state, action: PayloadAction<Project | null>) => {
            state.selectedProject = action.payload;
        },
        updateProjectInList: (state, action: PayloadAction<Partial<Project> & { uuid: string }>) => {
            const index = state.projects.findIndex(p => p.uuid === action.payload.uuid);
            if (index !== -1) {
                state.projects[index] = {
                    ...state.projects[index],
                    ...action.payload,
                };
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjects.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProjects.fulfilled, (state, action: PayloadAction<Project[]>) => {
                state.projects = action.payload;
                state.loading = false;
                state.hasFetchedAll = true;
            })
            .addCase(fetchProjects.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? 'Une erreur est survenue';
                state.hasFetchedAll = true;
            })
            .addCase(fetchProjectById.fulfilled, (state, action: PayloadAction<Project>) => {
                const index = state.projects.findIndex(project => project.uuid === action.payload.uuid);
                if (index >= 0) {
                    state.projects[index] = action.payload;
                } else {
                    state.projects.push(action.payload);
                }
                state.loading = false;
            })
            .addCase(fetchProjectById.rejected, handleRejected)
            .addCase(createProject.fulfilled, (state, action: PayloadAction<Project>) => {
                state.projects.push(action.payload);
            })
            .addCase(createProject.rejected, handleRejected)
            .addCase(createMission.fulfilled, (state, action: PayloadAction<{
                projectUuid: string;
                mission: Mission
            }>) => {
                const { projectUuid, mission } = action.payload;
                const project = state.projects.find(p => p.uuid === projectUuid);
                if (project) {
                    if (!project.missions) {
                        project.missions = [];
                    }
                    project.missions.push(mission);
                }
            })
            .addCase(updateProject.fulfilled, (state, action: PayloadAction<Project>) => {
                const updatedProject = action.payload;
                const index = state.projects.findIndex(project => project.uuid === updatedProject.uuid);

                if (index !== -1) {
                    // Important: Fusionner correctement les données du projet
                    state.projects[index] = {
                        ...state.projects[index],  // Garde les données existantes
                        ...updatedProject,         // Ajoute/écrase avec les nouvelles données

                        // Gestion spéciale pour les missions:
                        missions: updatedProject.missions && updatedProject.missions.length > 0
                            ? updatedProject.missions  // Utilise les nouvelles missions si présentes
                            : state.projects[index].missions || []  // Sinon garde les existantes
                    };
                }
            })
            .addCase(deleteProject.fulfilled, (state, action: PayloadAction<string>) => {
                state.projects = state.projects.filter(project => project.uuid !== action.payload);
            })
            .addCase(validateProject.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(validateProject.fulfilled, (state, action: PayloadAction<Project>) => {
                const index = state.projects.findIndex(project => project.uuid === action.payload.uuid);
                if (index !== -1) {
                    state.projects[index] = action.payload;
                }
                state.loading = false;
            })
            .addCase(validateProject.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? 'Erreur lors de la validation du projet';
            })
            .addCase(cancelProject.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(cancelProject.fulfilled, (state, action: PayloadAction<Project>) => {
                const index = state.projects.findIndex(project => project.uuid === action.payload.uuid);
                if (index !== -1) {
                    state.projects[index] = action.payload;
                }
                state.loading = false;
            })
            .addCase(cancelProject.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? 'Erreur lors de l\'annulation du projet';
            })
            .addCase(createQuote.fulfilled, (state, action) => {
                const { projectUuid, quote } = action.payload;

                const project = state.projects.find(p => p.uuid === projectUuid);
                if (project) {
                    project.quotes = [...(project.quotes || []), quote];
                }

                if (state.selectedProject?.uuid === projectUuid) {
                    state.selectedProject.quotes = [...(state.selectedProject.quotes || []), quote];
                }
            })
            .addCase(inviteCustomer.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(inviteCustomer.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(inviteCustomer.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? 'Erreur lors de l\'invitation du client';
            })
            .addCase(addProjectDocument.fulfilled, (state, action) => {
                const { document, projectUuid } = action.payload;
                const project = state.projects.find(p => p.uuid === projectUuid);
                if (project) {
                    project.documents = [...(project.documents || []), document];
                }

                if (state.selectedProject?.uuid === projectUuid) {
                    state.selectedProject.documents = [...(state.selectedProject.documents || []), document];
                }
            })
            .addCase(addMissionDocument.fulfilled, (state, action) => {
                const { document, projectUuid, missionUuid } = action.payload;

                const updateMission = (mission: any) => {
                    mission.documents = [...(mission.documents || []), document];
                };

                const project = state.projects.find(p => p.uuid === projectUuid);
                if (project?.missions) {
                    const mission = project.missions.find(m => m.uuid === missionUuid);
                    if (mission) updateMission(mission);
                }

                if (state.selectedProject?.uuid === projectUuid) {
                    const mission = state.selectedProject.missions?.find(m => m.uuid === missionUuid);
                    if (mission) updateMission(mission);
                }
            });
    }
});

// Selectors
export const selectProjects = (state: RootState) => state.projects.projects;
export const selectProjectsLoading = (state: RootState) => state.projects.loading;
export const selectProjectsError = (state: RootState) => state.projects.error;
export const selectHasFetchedAll = (state: RootState) => state.projects.hasFetchedAll;
export const selectProjectById = (state: RootState, uuid: string) =>
    state.projects.projects.find((project: Project) => project.uuid === uuid);
export const { setSelectedProject, updateProjectInList } = projectSlice.actions;
export default projectSlice.reducer;