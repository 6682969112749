import React, { useEffect, useState, ReactNode, Children, isValidElement } from 'react';
import { FiChevronRight, FiArrowLeft } from 'react-icons/fi';

interface SidePanelProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
    showOverlay?: boolean;
    animate?: boolean;
    currentPageIndex?: number;
    setCurrentPageIndex?: (index: number) => void;
    onValidate?: () => void; // Fonction de validation en fonction de la page
    isLoading?: boolean; // Ajout de isLoading pour gérer l'état de chargement
}

const SidePanel: React.FC<SidePanelProps> = ({ isOpen, onClose, children, showOverlay = true, animate = true, currentPageIndex = 0, setCurrentPageIndex, onValidate, isLoading = false }) => {
    const [isFullyClosed, setIsFullyClosed] = useState(true);
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setIsFullyClosed(false);
            if (animate) {
                setTimeout(() => {
                    setIsAnimating(true);
                }, 10);
            } else {
                setIsAnimating(true);
            }
        } else {
            setIsAnimating(false);
            const timer = setTimeout(() => {
                setIsFullyClosed(true);
            }, animate ? 300 : 0);
            return () => clearTimeout(timer);
        }
    }, [isOpen, animate]);

    if (isFullyClosed && !isOpen) return null;

    const pages = Children.toArray(children).filter(
        (child) => isValidElement(child) && (child.props as { [key: string]: any })['data-page'] !== undefined
    );

    const handleNextPage = () => {
        if (setCurrentPageIndex && currentPageIndex < pages.length - 1) {
            setCurrentPageIndex(currentPageIndex + 1);
        }
    };

    const handlePreviousPage = () => {
        if (setCurrentPageIndex && currentPageIndex > 0) {
            setCurrentPageIndex(currentPageIndex - 1);
        }
    };

    const currentChild = pages[currentPageIndex];
    const hasCustomNavigation = isValidElement(currentChild) && (currentChild.props as { [key: string]: any })['data-has-navigation'];

    // Déterminer le texte du bouton de validation à partir de l'attribut data-validation-text
    const validationButtonText = isLoading ? 'En cours...' : (isValidElement(currentChild)
        ? (currentChild.props as { [key: string]: any })['data-validation-text'] || 'Valider'
        : 'Valider');

    return (
        <>
            {showOverlay && (
                <div
                    className={`fixed inset-0 bg-theme/10 backdrop-blur-sm z-40 transition-opacity duration-300 ${
                        isOpen ? 'opacity-100' : 'opacity-0'
                    }`}
                    onClick={onClose}
                ></div>
            )}

            <div
                className={`fixed z-50 bg-surface-theme border-theme border-2 rounded-lg 
                ${animate ? 'transform transition-transform duration-300 ease-in-out' : ''}
                ${isAnimating ? 'translate-x-0' : 'translate-x-full'}
                
                // Version mobile (plein écran)
                inset-0 md:inset-auto
                
                // Positionnement et taille pour desktop
                md:right-4 md:top-4 md:h-[calc(100%-2rem)] 
                w-full md:w-2/5
                
                // Gestion des bordures
                rounded-none md:rounded-lg`}
                style={!animate ? {right: 0, transform: 'none'} : {}}
            >
                {/* Header */}
                <div className="flex justify-between items-center p-4 border-b border-theme md:border-none">
                    {currentPageIndex > 0 ? (
                        <button
                            onClick={handlePreviousPage}
                            className="text-base text-primary hover:text-indigo-800 focus:outline-none"
                        >
                            <div className="flex items-center">
                                <FiArrowLeft className="mr-2" size={16}/>
                                <span className="text-base leading-none">Retour</span>
                            </div>
                        </button>
                    ) : (
                        <div></div>
                    )}

                    <button
                        onClick={onClose}
                        className="w-8 h-8 flex items-center justify-center rounded-lg border border-theme text-primary-theme focus:outline-none"
                    >
                        <FiChevronRight size={12}/>
                    </button>
                </div>

                {/* Contenu */}
                <div className="p-4 md:p-6 overflow-y-auto h-[calc(100%-8rem)]">
                    {currentChild}

                    {setCurrentPageIndex && pages.length > 1 && (
                        <div className="mt-6 flex justify-between">
                            {!hasCustomNavigation && currentPageIndex < pages.length - 1 && (
                                <button
                                    onClick={handleNextPage}
                                    className="ml-auto px-4 py-2 bg-indigo-600 text-white rounded"
                                >
                                    Suivant
                                </button>
                            )}
                        </div>
                    )}
                </div>

                {/* Footer */}
                <div className="fixed bottom-0 left-0 right-0 md:relative md:px-4 md:py-[8px] border-t-2 border-theme bg-surface-theme p-6">
                    <div className="flex justify-end">
                        <button
                            onClick={onValidate}
                            disabled={isLoading}
                            className={`w-full md:w-auto py-2 px-4 text-white rounded focus:outline-none ${
                                isLoading ? 'bg-gray-400' : 'bg-primary'
                            }`}
                        >
                            {validationButtonText}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SidePanel;