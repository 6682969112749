import React from 'react';
import RegisterForm from '../features/auth/RegisterForm';

type RegisterPageProps = {
    theme: string;
};

const RegisterPage: React.FC<RegisterPageProps> = ({theme}) => {
    return (
        <div>
            <RegisterForm theme={theme}/>
        </div>
    );
};

export default RegisterPage;
