import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { store } from './store/store';
import App from './App';
import './assets/styles/index.css';

const queryClient = new QueryClient();

const container = document.getElementById('root');

if (!container) {
    throw new Error("Impossible de trouver l'élément avec l'id 'root'");
}

const root = ReactDOM.createRoot(container);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </Provider>
    </React.StrictMode>
);
