import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { selectAuthChecked } from '../../features/auth/authSlice';

const PrivateRoute: React.FC = () => {
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const authChecked = useSelector(selectAuthChecked);
    if (!authChecked) {
        return null;
    }

    if (!isAuthenticated) {
        sessionStorage.removeItem('wasAuthenticated');
        return <Navigate to="/login" />;
    }

    sessionStorage.setItem('wasAuthenticated', 'true');
    return <Outlet />;
};

export default PrivateRoute;