import { useEffect } from 'react';

function usePreventInputZoom() {
    useEffect(() => {
        const preventZoom = (e: FocusEvent) => {
            const viewport = document.querySelector('meta[name="viewport"]');
            if (!viewport) return;

            if (e.type === 'focus') {
                viewport.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
            } else if (e.type === 'blur') {
                viewport.setAttribute('content', 'width=device-width, initial-scale=1');
            }
        };

        const inputs = document.querySelectorAll('input');

        inputs.forEach(input => {
            input.addEventListener('focus', preventZoom as EventListener);
            input.addEventListener('blur', preventZoom as EventListener);
        });

        return () => {
            inputs.forEach(input => {
                input.removeEventListener('focus', preventZoom as EventListener);
                input.removeEventListener('blur', preventZoom as EventListener);
            });
        };
    }, []);
}

export default usePreventInputZoom;