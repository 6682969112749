import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { getUserInfo, requestPasswordReset, logout as logoutAPI } from '../../api/authService';

interface AuthState {
    user: any | null;
    isAuthenticated: boolean;
    hasCompany: boolean;
    loading: boolean;
    authChecked: boolean;
}

const initialState: AuthState = {
    user: null,
    isAuthenticated: false,
    hasCompany: false,
    loading: false,
    authChecked: false, // Initialement false jusqu'à la première vérification
};

export const logoutUser = createAsyncThunk(
    'auth/logoutUser',
    async (_, { rejectWithValue }) => {
        try {
            await logoutAPI();
            return true;
        } catch (error) {
            return rejectWithValue('Erreur lors de la déconnexion');
        }
    }
);

// Thunk pour vérifier l'état d'authentification initial
export const checkAuthStatus = createAsyncThunk(
    'auth/checkAuthStatus',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getUserInfo();
            return response.data;
        } catch (error: any) {
            if (error.response?.status === 401) {
                return rejectWithValue('Non authentifié');
            }
            throw error;
        }
    }
);

export const fetchUserInfo = createAsyncThunk(
    'auth/fetchUserInfo',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getUserInfo();
            return response.data;
        } catch (error: any) {
            if (error.response?.status === 401) {
                return rejectWithValue('Non authentifié');
            }
            throw error;
        }
    }
);

export const requestPasswordResetThunk = createAsyncThunk(
    'auth/requestPasswordReset',
    async (email: string, { rejectWithValue }) => {
        try {
            const response = await requestPasswordReset(email);
            return response.data;
        } catch (error) {
            if (typeof error === 'object' && error !== null && 'response' in error) {
                const err = error as { response?: { data: any } };
                return rejectWithValue(err.response?.data || 'Erreur lors de la demande de réinitialisation');
            }
            return rejectWithValue('Une erreur inattendue s\'est produite');
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<any>) => {
            state.user = action.payload;
            state.isAuthenticated = !!action.payload;
            state.hasCompany = !!action.payload?.company;
            state.loading = false;
            state.authChecked = !!action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setHasCompany: (state, action: PayloadAction<boolean>) => {
            state.hasCompany = action.payload;
        },
        logout: (state) => {
            state.user = null;
            state.isAuthenticated = false;
            state.hasCompany = false;
            state.loading = false;
            state.authChecked = true;  // On peut aussi mettre ça à true pour être sûr
            sessionStorage.removeItem('wasAuthenticated');
        },
    },
    extraReducers: (builder) => {
        builder
            // Gestion de checkAuthStatus
            .addCase(checkAuthStatus.pending, (state) => {
                state.loading = true;
            })
            .addCase(checkAuthStatus.fulfilled, (state, action) => {
                state.user = action.payload;
                state.isAuthenticated = true;
                state.hasCompany = !!action.payload.company;
                state.loading = false;
                state.authChecked = true;
            })
            .addCase(checkAuthStatus.rejected, (state) => {
                state.user = null;
                state.isAuthenticated = false;
                state.hasCompany = false;
                state.loading = false;
                state.authChecked = true;
            })
            // Gestion de fetchUserInfo
            .addCase(fetchUserInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchUserInfo.fulfilled, (state, action) => {
                state.user = action.payload;
                state.isAuthenticated = true;
                state.hasCompany = !!action.payload.company;
                state.loading = false;
                state.authChecked = true;
            })
            .addCase(fetchUserInfo.rejected, (state) => {
                state.loading = false;
                state.isAuthenticated = false;
                state.hasCompany = false;
                state.authChecked = true;
                sessionStorage.removeItem('wasAuthenticated');
            })
            .addCase(logoutUser.fulfilled, (state) => {
                state.user = null;
                state.isAuthenticated = false;
                state.hasCompany = false;
                state.loading = false;
                state.authChecked = true;
            });
    },
});

export const { setUser, setLoading, setHasCompany, logout } = authSlice.actions;

export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectHasCompany = (state: RootState) => state.auth.hasCompany;
export const selectUser = (state: RootState) => state.auth.user;
export const selectLoading = (state: RootState) => state.auth.loading;
export const selectAuthChecked = (state: RootState) => state.auth.authChecked;

export default authSlice.reducer;