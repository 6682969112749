import React from 'react';
import { Document } from '../types';
import { FiUpload, FiX } from "react-icons/fi";

interface DocumentSectionProps {
    documents?: Document[];
    onUpload: (files: FileList) => void;
    onRemove: (documentUuid: string) => void;
    isLoading?: boolean;
}

const DocumentSection: React.FC<DocumentSectionProps> = ({
                                                             documents,
                                                             onUpload,
                                                             onRemove,
                                                             isLoading
                                                         }) => {
    return (
        <div className="mt-4 border-t pt-4">
            <h4 className="text-sm font-medium mb-2">Documents</h4>
            <div className="space-y-2">
                {documents?.map((doc) => (
                    <div key={doc.uuid} className="flex items-center justify-between bg-gray-50 p-2 rounded">
                        <a href={doc.downloadUrl} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                            {doc.originalName}
                        </a>
                        <button
                            type="button"
                            onClick={() => onRemove(doc.uuid)}
                            className="text-red-500 hover:text-red-700"
                        >
                            <FiX />
                        </button>
                    </div>
                ))}
                <div className="relative">
                    <input
                        type="file"
                        multiple
                        onChange={(e) => e.target.files && onUpload(e.target.files)}
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                    />
                    <button
                        type="button"
                        className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                        disabled={isLoading}
                    >
                        <FiUpload className="mr-2" />
                        {isLoading ? 'Chargement...' : 'Ajouter des documents'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DocumentSection;