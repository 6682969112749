import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

interface Permissions {
    canAccessCustomers: boolean;
    canAccessProjects: boolean;
    canEditProjects: boolean;
    canDeleteProjects: boolean;
    canAccessSettings: boolean;
    canManageUsers: boolean;
    // Ajoutez d'autres permissions selon vos besoins
}

export const useUserPermissions = () => {
    const user = useSelector((state: RootState) => state.auth.user);
    const isCompanyProfile = user?.profileSelected === 'company';

    return {
        isCompanyProfile,
        isCustomerProfile: !isCompanyProfile
    };
};