// src/hooks/useBackground.ts
import { useState, useEffect } from 'react';
import backgroundLight from '../assets/images/background.png';
import backgroundDark from '../assets/images/backgroundDark.png';

export const useBackground = (theme: string) => {
    const [background, setBackground] = useState(theme === 'dark' ? backgroundDark : backgroundLight);

    useEffect(() => {
        const updateBackground = () => {
            if (theme === 'system') {
                const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
                setBackground(systemTheme === 'dark' ? backgroundDark : backgroundLight);
            } else {
                setBackground(theme === 'dark' ? backgroundDark : backgroundLight);
            }
        };

        updateBackground();

        if (theme === 'system') {
            const systemThemeListener = window.matchMedia('(prefers-color-scheme: dark)');
            systemThemeListener.addEventListener('change', updateBackground);

            return () => {
                systemThemeListener.removeEventListener('change', updateBackground);
            };
        }
    }, [theme]);

    return background;
};