import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProjectList from '../features/project/ProjectList';
import ProjectDetail from '../features/project/ProjectDetail';

const ProjectsPage: React.FC = () => {
    return (
        <div className="md:pl-6 md:pr-4 h-full p-4 mb-8 md:m-0">
            <Routes>
                <Route path="/" element={<ProjectList />} />
                <Route path=":uuid" element={<ProjectDetail />} />
            </Routes>
        </div>
    );
};

export default ProjectsPage;