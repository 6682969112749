import React from 'react';

interface DateInputProps {
    label: string;
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DateInput: React.FC<DateInputProps> = ({ label, name, value, onChange }) => {
    return (
        <div className="mb-4">
            <div className="flex justify-between items-center mb-[5px]">
                <label className="block text-primary-theme text-xs font-normal">
                    {label}
                </label>
            </div>
            <input
                type="date"
                name={name}
                value={value}
                onChange={onChange}
                className="appearance-none text-sm block w-full border border-theme bg-theme text-primary-theme rounded-lg px-5 py-3 leading-tight focus:outline-none focus:ring-1 focus:ring-primary"
            />
        </div>
    );
};

export default DateInput;