import React, {useState, useEffect, useRef} from 'react';
import {FiArrowUp, FiX, FiFile, FiImage, FiSmile} from 'react-icons/fi';
import ThreadFileUploader from './ThreadFileUploader';
import { useDocuments } from '../../../hooks/useDocuments';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import {useIsPWA} from "../../../hooks/useIsPWA";
import {t} from "i18next";
import CustomIcon from "../../../components/CustomIcon";
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;

interface ThreadMessageInputProps {
    onSendMessage: (message: string) => void;
    onSendDocument: (documentUuid: string) => void;
    file: File | null;
    textareaRef: React.RefObject<HTMLTextAreaElement>;
    fileInputRef: React.RefObject<HTMLInputElement>;
    setFile: (file: File | null) => void;
    projectUuid: string;
    missionUuid?: string;
    lengthMessages: number;
    customer?: any;
}

const ThreadMessageInput: React.FC<ThreadMessageInputProps> = ({
   onSendMessage,
   onSendDocument,
   file,
   textareaRef,
   fileInputRef,
   setFile,
   customer,
   lengthMessages,
   projectUuid,
   missionUuid,
}) => {
    const [newMessage, setNewMessage] = useState('');
    const [uploading, setUploading] = useState(false);
    const { uploadDocument, loading: documentLoading } = useDocuments({ projectUuid, missionUuid });
    const emojiButtonRef = useRef<HTMLButtonElement>(null);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const pickerRef = useRef<HTMLDivElement>(null);
    const [showSuggestion, setShowSuggestion] = useState(false);
    const [animateSuggestion, setAnimateSuggestion] = useState(false);
    const suggestionMessage = `Bonjour ${customer?.firstName} ! 👋 Maintenant que vous faites partie du projet, nous pouvons avancer ensemble. Cet espace est dédié à nos échanges et à la collaboration afin de garantir une communication fluide et efficace. N'hésitez pas à poser vos questions, partager vos idées ou nous faire part de vos attentes. Nous sommes là pour avancer dans la même direction et faire de ce projet une réussite. Hâte de construire ensemble ! 🚀`;

    const onEmojiSelect = (emoji: any) => {
        if (textareaRef.current) {
            const start = textareaRef.current.selectionStart;
            const end = textareaRef.current.selectionEnd;
            const text = newMessage;
            const newText = text.substring(0, start) + emoji.native + text.substring(end);
            setNewMessage(newText);

            setTimeout(() => {
                adjustTextareaHeight();
                textareaRef.current?.setSelectionRange(start + emoji.native.length, start + emoji.native.length);
                textareaRef.current?.focus();
            }, 0);
        }
    };

    useEffect(() => {
        adjustTextareaHeight();
    }, []);

    useEffect(() => {
        if (lengthMessages === 0 && customer) {
            setShowSuggestion(true);
        } else {
            setShowSuggestion(false);
        }
    }, [lengthMessages, customer]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (showEmojiPicker &&
                pickerRef.current &&
                !pickerRef.current.contains(event.target as Node) &&
                emojiButtonRef.current &&
                !emojiButtonRef.current.contains(event.target as Node)) {
                setShowEmojiPicker(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showEmojiPicker]);

    const adjustTextareaHeight = () => {
        const lineHeight = 20;
        const maxHeight = 120;

        if (textareaRef.current) {
            textareaRef.current.style.height = `${lineHeight}px`;
            const scrollHeight = textareaRef.current.scrollHeight;

            if (scrollHeight > lineHeight) {
                textareaRef.current.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
                textareaRef.current.style.overflowY = scrollHeight > maxHeight ? 'auto' : 'hidden';
            } else {
                textareaRef.current.style.height = `${lineHeight}px`;
                textareaRef.current.style.overflowY = 'hidden';
            }
        }
    };

    const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNewMessage(e.target.value);
        adjustTextareaHeight();
    };

    const handleKeyDown = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter') {
            if (e.shiftKey) {
                // Permettre les retours à la ligne avec Shift+Enter
                return;
            }
            e.preventDefault();
            await handleSubmit();
        }
    };

    const handleSubmit = async (e?: React.FormEvent) => {
        if (e) e.preventDefault();
        setUploading(true);

        try {
            const messageWithLineBreaks = newMessage.replace(/\n/g, '\n');

            if (file && !messageWithLineBreaks.trim()) {
                const documentUuid = await uploadDocument(file);
                onSendDocument(documentUuid);
                setFile(null);
            }

            if (!file && messageWithLineBreaks.trim()) {
                onSendMessage(messageWithLineBreaks);
                setNewMessage('');
            }

            if (file && messageWithLineBreaks.trim()) {
                const documentUuid = await uploadDocument(file);
                onSendMessage(messageWithLineBreaks);
                onSendDocument(documentUuid);
                setFile(null);
                setNewMessage('');
            }

            setShowSuggestion(false);
        } catch (error) {
            console.error('Erreur lors de l\'envoi du message ou du document : ', error);
        } finally {
            setUploading(false);
            if (textareaRef.current) {
                textareaRef.current.style.height = '40px';
            }
        }
    };

    const handleRemoveFile = () => {
        setFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const isButtonDisabled = (!newMessage.trim() && !file) || uploading || documentLoading;

    const isPWA = useIsPWA();

    const useSuggestion = () => {
        setNewMessage(suggestionMessage);
        setShowSuggestion(false);

        setTimeout(() => {
            if (textareaRef.current) {
                textareaRef.current.focus();
                adjustTextareaHeight();
            }
        }, 0);
    };

    const handleCloseSuggestion = () => {
        setAnimateSuggestion(true);

        setTimeout(() => {
            setShowSuggestion(false);
        }, 100);
    };

    return (
        <form
            onSubmit={handleSubmit}
            className={`px-4 pt-4 flex flex-col space-y-2 w-full md:sticky fixed left-0 right-0 ${isPWA ? 'pb-7' : 'pb-4'}`}
            style={{
                flexShrink: 0,
                bottom: 0,
                zIndex: 10,
            }}
        >
            {file && (
                <div className="relative inline-flex items-center p-2 mb-2 bg-theme border border-theme rounded max-w-max">
                    <div className="flex items-center justify-center w-12 h-12 mr-2 bg-surface-theme rounded">
                        {file.type.startsWith('image/') ? (
                            <FiImage className="text-2xl text-primary-theme"/>
                        ) : (
                            <FiFile className="text-2xl text-primary-theme"/>
                        )}
                    </div>
                    <span className="text-sm truncate w-40 text-primary-theme">
                        {file.name}
                    </span>
                    <button
                        type="button"
                        className="ml-2 p-1 bg-red-500 rounded-full text-white"
                        onClick={handleRemoveFile}
                    >
                        <FiX/>
                    </button>
                </div>
            )}

            <div className={`${!animateSuggestion && "bg-theme rounded-[26px]"}`}>
                {showSuggestion && (
                    <div
                        className={`py-2.5 px-4 transition-all duration-100 ${
                            animateSuggestion ? 'opacity-0 transform translate-y-4' : 'opacity-100'
                        }`}
                    >
                        <div className="flex items-center justify-between mb-2">
                            <div className="flex items-center">
                                <CustomIcon className="w-4 h-4 mr-1"/>
                                <p className="text-primary-theme text-sm">Suggestion</p>
                            </div>
                            <div>
                                <FiX
                                    className="w-4 h-4 text-primary-theme cursor-pointer"
                                    onClick={handleCloseSuggestion}
                                />
                            </div>
                        </div>
                        <div
                            className="bg-[#F6EBFD] hover:bg-[#F6EBFD]/70 py-2.5 px-4 rounded-[8px] cursor-pointer"
                            onClick={useSuggestion}
                        >
                            <p className="text-[#3D056C] text-sm leading-6">
                                {suggestionMessage}
                            </p>
                        </div>
                    </div>
                )}
                <div className="flex items-end w-full bg-theme rounded-[26px] p-1">
                    <ThreadFileUploader
                        file={file}
                        setFile={setFile}
                        fileInputRef={fileInputRef}
                        projectUuid={projectUuid}
                    />
                    <div className="flex-1 flex items-end relative">
                    <textarea
                        ref={textareaRef}
                        value={newMessage}
                        onChange={handleTextareaChange}
                        onKeyDown={handleKeyDown}
                        className="flex-1 bg-theme text-primary-theme resize-none border-none focus:ring-0 focus:border-none rounded-none text-sm"
                        placeholder={t('project.write')}
                        style={{
                            padding: '8px',
                            boxSizing: 'border-box',
                            height: '37px',
                            maxHeight: '120px',
                            minHeight: '37px',
                            overflowY: 'hidden',
                            outline: 'none',
                        }}
                    />
                        <button
                            type="button"
                            ref={emojiButtonRef}
                            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                            className="w-10 h-10 flex items-center justify-center rounded-full cursor-pointer hover:bg-theme-hover mr-2"
                        >
                            <FiSmile className="text-xl text-primary-theme"/>
                        </button>

                        {showEmojiPicker && (
                            <div ref={pickerRef} className="absolute bottom-12 right-0">
                                <Picker
                                    data={data}
                                    onEmojiSelect={onEmojiSelect}
                                    theme="light"
                                    previewPosition="none"
                                    skinTonePosition="none"
                                />
                            </div>
                        )}
                    </div>

                    <button
                        type="submit"
                        className={`w-10 h-10 flex items-center justify-center rounded-full cursor-pointer bg-color-primary ${
                            isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                        disabled={isButtonDisabled}
                    >
                        <FiArrowUp className="text-white text-xl"/>
                    </button>
                </div>
            </div>
        </form>
    );
};

export default ThreadMessageInput;