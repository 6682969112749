import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProjects } from '../../hooks/useProjects';
import Skeleton from '../../components/Skeleton';
import Modal from '../../components/Modal';
import SidePanel from '../../components/SidePanel';
import ProjectForm from './form/ProjectForm';
import { FiMoreVertical } from 'react-icons/fi';
import { formatPrice } from '../../utils/formatPrice';
import { formatDate } from '../../utils/dateUtils';
import CustomerForm from '../customer/CustomerForm';
import {useUserPermissions} from "../../hooks/useUserPermissions";
import Button from "../../components/UI/Button";
import { clearFormState } from '../../helpers/formPersistence';
import {t} from "i18next";

const ProjectList: React.FC = () => {
    const navigate = useNavigate();

    // Utiliser le hook personnalisé
    const {
        projects,
        loading,
        addProject,
        modifyProject,
        removeProject,
    } = useProjects();

    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState<any | null>(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [projectToDelete, setProjectToDelete] = useState<any | null>(null);
    const [deleting, setDeleting] = useState(false);
    const [hoveredProject, setHoveredProject] = useState<string | null>(null);
    const { isCompanyProfile } = useUserPermissions();

    const projectFormRef = useRef<any>(null);
    const customerFormRef = useRef<any>(null);

    const PROJECT_FORM_KEY = 'projectFormData';
    const MISSIONS_FORM_KEY = 'missionsFormData';

    useEffect(() => {
        // L'appel à `useProjects` gère la récupération initiale des projets
    }, []);

    const handleCreateProject = () => {
        setIsEditMode(false);
        setSelectedProject(null);
        setCurrentPageIndex(0);
        setIsSidePanelOpen(true);
    };

    const handleEditProject = (project: any) => {
        setIsEditMode(true);
        setSelectedProject(project);
        setCurrentPageIndex(0);
        setIsSidePanelOpen(true);
    };

    const handleViewDetails = (uuid: string) => {
        navigate(`/projects/${uuid}`);
    };

    const handleCloseSidePanel = (): void => {
        // On ferme simplement le panneau
        setIsSidePanelOpen(false);

        // Si on est en mode édition, on nettoie les données
        if (isEditMode) {
            clearFormState(PROJECT_FORM_KEY);
            clearFormState(MISSIONS_FORM_KEY);
        }
        // En mode création, on garde les données pour pouvoir reprendre
    };

    const handleValidate = async () => {
        setIsLoading(true);

        if (currentPageIndex === 0 && projectFormRef.current) {
            const newProject = await projectFormRef.current.submitForm();

            if (newProject?.uuid && !isEditMode) {
                setIsSidePanelOpen(false);
                navigate(`/projects/${newProject.uuid}`);
            }
        } else if (currentPageIndex === 1 && customerFormRef.current) {
            await customerFormRef.current.submitForm();
        }

        setIsLoading(false);
    };

    const handleDeleteClick = (project: any) => {
        setProjectToDelete(project);
        setShowConfirmModal(true);
    };

    const confirmDelete = async () => {
        if (projectToDelete) {
            setDeleting(true);
            await removeProject(projectToDelete.uuid);
            setDeleting(false);
            setShowConfirmModal(false);
            setProjectToDelete(null);
        }
    };

    const cancelDelete = () => {
        setShowConfirmModal(false);
        setProjectToDelete(null);
    };

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        customer: '',
    });

    const handleCustomerCreated = (customerUuid?: string) => {

        setTimeout(() => {
            setCurrentPageIndex(0);
        }, 300);
    };

    return (
        <div>
            {isCompanyProfile && (
                <div className="flex justify-end mb-4">
                    <Button
                        size="medium"
                        onClick={handleCreateProject}
                    >
                        {t('project.new')}
                    </Button>
                </div>
            )}

            {loading ? (
                <div className="space-y-4">
                    {[...Array(3)].map((_, i) => (
                        <div key={i} className="bg-theme p-4 rounded-lg border-theme border-2 cursor-pointer">
                            <Skeleton height="15px" width="70%"/>
                            <Skeleton height="15px" width="50%" />
                        </div>
                    ))}
                </div>
            ) : projects.length === 0 ? (
                <p className="text-primary-theme">{t('project.noProjects')}</p>
            ) : (
                <div className="space-y-4">
                    {projects.map((project: any) => (
                        <div
                            key={project.uuid}
                            className="bg-theme p-4 rounded-lg border-theme border-2 flex justify-between items-center cursor-pointer"
                            onClick={() => handleViewDetails(project.uuid)}
                        >
                            <div className="w-[20rem]">
                                <p className="text-base text-primary-theme font-medium">{project.title}</p>
                                <p className="text-xs text-primary-theme">
                                    {project.updatedAt
                                        ? t('project.updatedAt') + " " + formatDate(project.updatedAt)
                                        : t('project.createdAt') + " " + formatDate(project.createdAt)}
                                </p>
                            </div>

                            <div className="flex-grow text-center">
                                <p className="text-sm text-gray-600">{project.status.label}</p>
                            </div>

                            {isCompanyProfile && (
                                <div className="flex items-center w-[10rem] justify-end">
                                    <div className="relative hover-menu">
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setHoveredProject(hoveredProject === project.uuid ? null : project.uuid);
                                            }}
                                            className="w-8 h-8 flex justify-center items-center rounded-full bg-surface-theme text-primary-theme hover:bg-theme-hover border-2 border-theme"
                                        >
                                            <FiMoreVertical/>
                                        </button>

                                        {hoveredProject === project.uuid && (
                                            <div
                                                className="absolute right-0 mt-2 w-40 bg-surface-theme border-2 border-theme rounded shadow-md z-10 hover-menu">
                                                <button
                                                    className="block w-full text-left px-4 py-2 hover:bg-theme-hover text-primary-theme"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleEditProject(project);
                                                        setHoveredProject(null);
                                                    }}
                                                >
                                                    {t('project.edit')}
                                                </button>
                                                <button
                                                    className="block w-full text-left px-4 py-2 hover:bg-theme-hover text-primary-theme"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDeleteClick(project);
                                                        setHoveredProject(null);
                                                    }}
                                                >
                                                    {t('project.delete')}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}

            <Modal show={showConfirmModal} onClose={cancelDelete} title="Confirmer la suppression">
                <p className="text-primary-theme">Êtes-vous sûr de vouloir supprimer le projet {projectToDelete?.title} ?</p>
                <div className="flex justify-end space-x-2 mt-4">
                    <button className="px-4 py-2 bg-gray-500 text-white rounded" onClick={cancelDelete}
                            disabled={deleting}>
                        Annuler
                    </button>
                    <button
                        className="px-4 py-2 bg-red-500 text-white rounded flex items-center"
                        onClick={confirmDelete}
                        disabled={deleting}
                    >
                        {deleting ? 'En cours...' : 'Supprimer'}
                    </button>
                </div>
            </Modal>

            <SidePanel
                isOpen={isSidePanelOpen}
                onClose={handleCloseSidePanel}
                currentPageIndex={currentPageIndex}
                setCurrentPageIndex={setCurrentPageIndex}
                onValidate={handleValidate}
                isLoading={isLoading}
            >
                <div data-page="1" data-validation-text={isEditMode ? 'Modifier' : 'Créer'} data-has-navigation="true">
                    <ProjectForm
                        ref={projectFormRef}
                        onSuccess={handleCloseSidePanel}
                        projectData={selectedProject}
                        isEditMode={isEditMode}
                        setFormData={setFormData}
                        formData={formData}
                        isLoading={isLoading}
                        onNext={() => {
                            setCurrentPageIndex(1);
                        }}
                    />
                </div>

                <div data-page="2" data-validation-text="Créer un client" data-has-navigation="true">
                    <CustomerForm
                        ref={customerFormRef}
                        onSuccess={handleCustomerCreated}
                        isLoading={isLoading}
                        projectFormKey={PROJECT_FORM_KEY}
                    />
                </div>
            </SidePanel>
        </div>
    );
};

export default ProjectList;