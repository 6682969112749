import React, { useState, useRef, useEffect } from 'react';
import { FiSearch, FiUser, FiFolder, FiList, FiPlus, FiCommand } from 'react-icons/fi';

export type SearchResultType = 'customer' | 'project' | 'mission' | 'action';
export type ActionType = 'new_customer' | 'new_project';

export interface SearchResult {
    type: SearchResultType;
    title: string;
    subtitle?: string;
    uuid?: string;
    projectUuid?: string;
    actionType?: ActionType;
}

type SearchBarProps = {
    placeholder?: string;
    onSearch: (query: string) => SearchResult[];
    onResultSelect: (result: SearchResult) => void;
};

const SearchBar: React.FC<SearchBarProps> = ({
     placeholder = "Find...",
     onSearch,
     onResultSelect
 }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [query, setQuery] = useState("");
    const [results, setResults] = useState<SearchResult[]>([]);
    const [isFullyClosed, setIsFullyClosed] = useState(true);
    const [isAnimating, setIsAnimating] = useState(false);
    const [isMac] = useState(() => {
        return typeof window !== 'undefined' && /Mac|iPod|iPhone|iPad/.test(navigator.platform);
    });

    const inputRef = useRef<HTMLInputElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);

    const getIconForType = (type: SearchResultType) => {
        switch (type) {
            case 'customer':
                return <FiUser className="w-4 h-4" />;
            case 'project':
                return <FiFolder className="w-4 h-4" />;
            case 'mission':
                return <FiList className="w-4 h-4" />;
            case 'action':
                return <FiPlus className="w-4 h-4" />;
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
        if (e.target.value.trim()) {
            setResults(onSearch(e.target.value));
        } else {
            setResults([]);
        }
    };

    const handleSearch = () => {
        if (query.trim()) {
            setResults(onSearch(query));
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && results.length > 0) {
            onResultSelect(results[0]);
            closePopup();
        } else if (e.key === "Escape") {
            closePopup();
        }
    };

    const handleResultClick = (result: SearchResult) => {
        onResultSelect(result);
        closePopup();
    };

    const openPopup = () => {
        setIsOpen(true);
    };

    const closePopup = () => {
        setIsOpen(false);
        setQuery("");
        setResults([]);
    };

    const handleGlobalKeyDown = (e: KeyboardEvent) => {
        if ((isMac ? e.metaKey : e.ctrlKey) && e.key === "k") {
            e.preventDefault();
            openPopup();
        } else if (e.key === "Escape" && isOpen) {
            closePopup();
        }
    };

    useEffect(() => {
        if (isAnimating && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isAnimating]);

    useEffect(() => {
        document.addEventListener("keydown", handleGlobalKeyDown);
        return () => {
            document.removeEventListener("keydown", handleGlobalKeyDown);
        };
    }, [isOpen, isMac]);

    const handleClickOutside = (e: MouseEvent) => {
        if (
            isOpen &&
            popupRef.current &&
            !popupRef.current.contains(e.target as Node)
        ) {
            closePopup();
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            setIsFullyClosed(false);
            setTimeout(() => {
                setIsAnimating(true);
            }, 10);
        } else {
            setIsAnimating(false);
            const timer = setTimeout(() => {
                setIsFullyClosed(true);
            }, 300);
            return () => clearTimeout(timer);
        }
    }, [isOpen]);

    const ShortcutDisplay = () => (
        <span className="text-xs text-primary-theme bg-surface-theme px-2 py-1 rounded flex items-center gap-1 hidden md:block">
            {isMac ? (
                <>
                    <div className="flex items-center">
                        <FiCommand className="w-3 h-3"/>
                        <span>&#8203; + K</span>
                    </div>
                </>
            ) : (
                'Ctrl + K'
            )}
        </span>
    );

    return (
        <>
            {/* Button to open the popup */}
            <button
                onClick={openPopup}
                className="w-full max-w-xl px-4 py-2 flex items-center justify-between text-secondary-theme bg-theme rounded-lg hover:bg-theme-hover focus:outline-none"
            >
                <div className="flex items-center gap-3">
                    <FiSearch className="w-4 h-4" />
                    <span className="text-sm">{placeholder}</span>
                </div>
                <ShortcutDisplay />
            </button>

            {/* Popup with the search bar */}
            {!isFullyClosed && (
                <div className={`fixed inset-0 flex items-start pt-32 justify-center z-50 bg-theme/10 backdrop-blur-md transition-opacity duration-300 ${isAnimating ? 'opacity-100' : 'opacity-0'}`}>
                    <div ref={popupRef} className={`w-full max-w-xl transition-all duration-300 ${isAnimating ? 'scale-100 opacity-100' : 'scale-95 opacity-0'}`}>
                        {/* Input section */}
                        <div className="p-4">
                            <div className="flex items-center justify-between w-full bg-theme rounded-lg px-4 py-2 shadow-lg">
                                <div className="flex items-center gap-3 flex-1">
                                    <FiSearch className="w-4 h-4 text-secondary-theme" />
                                    <input
                                        type="text"
                                        value={query}
                                        onChange={handleInputChange}
                                        onKeyDown={handleKeyDown}
                                        placeholder={placeholder}
                                        ref={inputRef}
                                        className="w-full bg-transparent border-none outline-none text-sm text-primary-theme placeholder-secondary-theme"
                                    />
                                </div>
                                <ShortcutDisplay />
                            </div>
                        </div>

                        {/* Results section */}
                        {results.length > 0 && (
                            <div className="px-4 pb-4">
                                <div className="text-sm text-gray-500 px-2 mb-2">Results</div>
                                <div className="bg-theme rounded-lg overflow-hidden shadow-lg">
                                    {results.map((result, index) => (
                                        <div
                                            key={index}
                                            onClick={() => handleResultClick(result)}
                                            className="flex items-center gap-3 px-4 py-2 hover:bg-theme-hover cursor-pointer text-secondary-theme"
                                        >
                                            {getIconForType(result.type)}
                                            <div className="flex flex-col">
                                                <span className="text-sm text-primary-theme">{result.title}</span>
                                                {result.subtitle && (
                                                    <span className="text-xs text-secondary-theme">{result.subtitle}</span>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default SearchBar;