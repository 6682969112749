import React, { useState } from 'react';
import {t} from "i18next";

interface InputEmailProps {
    label: string;
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    required?: boolean;
}

const InputEmail: React.FC<InputEmailProps> = ({
   label,
   name,
   value,
   onChange,
   placeholder,
   required = false
}) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [wasBlurred, setWasBlurred] = useState(false);

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleBlur = () => {
        if (value) {
            setWasBlurred(true);
            if (!validateEmail(value)) {
                setErrorMessage( t('inputError.email.format'));
            } else {
                setErrorMessage(null);
            }
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;

        if (!newValue) {
            setErrorMessage(null);
            setWasBlurred(false);
        } else if (wasBlurred) {
            if (!validateEmail(newValue)) {
                setErrorMessage( t('inputError.email.format'));
            } else {
                setErrorMessage(null);
            }
        }

        onChange(e);
    };

    return (
        <div>
            <div className="flex justify-between items-center mb-[5px]">
                <label className="block text-primary-theme text-xs font-normal">
                    {label}
                </label>
                {errorMessage && (
                    <p className="text-red-500 text-xs">{errorMessage}</p>
                )}
            </div>
            <input
                type="email"
                name={name}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={placeholder}
                className={`appearance-none text-sm block w-full border border-theme bg-theme text-primary-theme rounded-lg px-5 py-3 leading-tight focus:outline-none ${
                    errorMessage ? 'border-red-500 focus:border-red-500' : 'focus:ring-1 focus:ring-primary'
                }`}
                required={required}
            />
        </div>
    );
};

export default InputEmail;