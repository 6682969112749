import React from "react";

interface CustomIconProps {
    className?: string; // Permet d'ajouter des classes Tailwind pour personnaliser le style
}

const CustomIcon: React.FC<CustomIconProps> = ({ className = "" }) => {
    return (
        <svg
            width="11"
            height="9"
            viewBox="0 0 11 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`inline-block ${className}`} // Permet d'ajouter des classes Tailwind
        >
            <path
                d="M3.25 1.10491C3.39146 0.64016 4.04943 0.640159 4.19089 1.10491L4.79156 3.07842C4.83929 3.23523 4.96201 3.35795 5.11882 3.40568L7.09233 4.00635C7.55708 4.14781 7.55708 4.80578 7.09233 4.94724L5.11882 5.54791C4.96201 5.59564 4.83929 5.71836 4.79156 5.87517L4.19089 7.84868C4.04943 8.31343 3.39146 8.31343 3.25 7.84868L2.64933 5.87517C2.6016 5.71836 2.47888 5.59564 2.32207 5.54791L0.348564 4.94724C-0.116188 4.80578 -0.116188 4.14781 0.348564 4.00635L2.32207 3.40568C2.47888 3.35795 2.6016 3.23523 2.64933 3.07842L3.25 1.10491Z"
                fill="url(#paint0_linear_911_56)"
            />
            <path
                d="M8.66135 0.123447C8.71145 -0.0411488 8.94447 -0.0411489 8.99457 0.123446L9.22356 0.875786C9.24046 0.931321 9.28392 0.974783 9.33946 0.991686L10.0918 1.22067C10.2564 1.27077 10.2564 1.5038 10.0918 1.5539L9.33946 1.78288C9.28392 1.79979 9.24046 1.84325 9.22356 1.89878L8.99457 2.65112C8.94447 2.81572 8.71145 2.81572 8.66135 2.65112L8.43236 1.89878C8.41546 1.84325 8.37199 1.79979 8.31646 1.78288L7.56412 1.5539C7.39953 1.5038 7.39952 1.27077 7.56412 1.22067L8.31646 0.991686C8.37199 0.974783 8.41546 0.931321 8.43236 0.875786L8.66135 0.123447Z"
                fill="url(#paint1_linear_911_56)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_911_56"
                    x1="2.54375"
                    y1="3.26498"
                    x2="4.93226"
                    y2="5.65349"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#BEA9FF" />
                    <stop offset="1" stopColor="#846EE8" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_911_56"
                    x1="8.40998"
                    y1="0.934476"
                    x2="9.28077"
                    y2="1.82268"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#BEA9FF" />
                    <stop offset="1" stopColor="#846EE8" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default CustomIcon;