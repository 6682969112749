import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../../api/authService';
import Button from '../../components/UI/Button';
import { t } from "i18next";
import InputPassword from "../../components/InputPassword";
import Logo from "../../components/Logo";

type ResetPasswordProps = {
    theme: string;
};

const ResetPasswordForm: React.FC<ResetPasswordProps> = ({ theme }) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const token = new URLSearchParams(location.search).get('token');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        if (name === "newPassword") {
            setNewPassword(value);
        } else if (name === "confirmPassword") {
            setConfirmPassword(value);
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setIsLoading(true);
        setErrorMessage('');

        try {
            await resetPassword(token as string, { newPassword, confirmPassword });
            navigate('/login');
        } catch (error) {
            setErrorMessage("Erreur lors de la réinitialisation du mot de passe. Veuillez réessayer.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center p-4">
            <div className="max-w-md w-full">
                <div className="mb:space-y-[5rem] space-y-0">
                    <div
                        className="absolute top-[3.5rem] md:top-[2.125rem] left-1/2 -translate-x-1/2 md:left-auto md:right-[2.125rem] md:transform-none">
                        <Logo theme={theme}/>
                    </div>
                    <div className="space-y-[.313rem]">
                        <h2 className="text-center text-[2rem] leading-9 font-medium text-primary-theme">{t('resetPassword.title')}</h2>
                        <p className="text-center text-xs leading-normal font-normal text-primary-theme">{t('resetPassword.label')}</p>
                    </div>
                </div>
                <form className="mt-10 space-y-[1.25rem]" onSubmit={handleSubmit}>
                    <div className="space-y-[1.25rem]">
                        <div>
                            <InputPassword
                                label={t('resetPassword.newPassword.label')}
                                name="newPassword"
                                value={newPassword}
                                onChange={handleChange}
                                placeholder={t('resetPassword.newPassword.placeholder')}
                                required={true}
                            />
                        </div>
                        <div>
                            <InputPassword
                                label={t('resetPassword.confirmPassword.label')}
                                name="confirmPassword"
                                value={confirmPassword}
                                onChange={handleChange}
                                placeholder={t('resetPassword.confirmPassword.placeholder')}
                                required={true}
                                isConfirmation={true}
                                passwordToMatch={newPassword}
                            />
                        </div>
                    </div>

                    {errorMessage && (
                        <div className="text-center text-red-500 text-xs">
                            {errorMessage}
                        </div>
                    )}

                    <div className="flex items-center justify-between">
                        <Button
                            type="submit"
                            isLoading={isLoading}
                            fullWidth
                            size={"medium"}
                        >
                            {t('resetPassword.submit')}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ResetPasswordForm;