import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FiGrid, FiLayers, FiUsers, FiMessageSquare, FiFile, FiSettings, FiUser, FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {logout, logoutUser, setUser} from '../features/auth/authSlice';
import { updateUserInfo } from '../api/authService';
import {AppDispatch, RootState} from '../store/store';
import Logo from './Logo';
import logoIcon from './../assets/images/icon_bricksly.svg';
import ProfileCard from "./ProfileCard";
import {ImageDisplay} from "./ImageDisplay";
import {useCompany} from "../hooks/useCompany";
import { useUserPermissions } from '../hooks/useUserPermissions';

type NavbarSideProps = {
    theme: string;
    toggleTheme: (newTheme: string) => void;
};

const NavbarSide: React.FC<NavbarSideProps> = ({ theme, toggleTheme }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(() => {
        const savedState = localStorage.getItem('navbar-collapsed');
        return savedState === 'true';
    });
    const [isLoading, setIsLoading] = useState(false);
    const { isCompanyProfile } = useUserPermissions();
    const [isHovered, setIsHovered] = useState(false); // Ajout de l'état pour détecter le hover
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { threadId } = useParams<{ threadId: string }>(); // Récupérer l'ID du thread actuel à partir de l'URL
    const user = useSelector((state: RootState) => state.auth.user);
    const { company, loading: companyLoading, error: companyError } = useCompany(user?.company?.uuid);
    const unreadMessages = useSelector((state: RootState) => state.messaging.unreadMessages);
    const hasUnreadMessages = Object.keys(unreadMessages).some(id => id !== threadId && unreadMessages[id] > 0);

    const toggleNavbar = () => {
        const newState: boolean = !collapsed;
        setCollapsed(newState);
        localStorage.setItem('navbar-collapsed', newState.toString());
    };

    const handleLogout = async () => {
        setIsLoading(true);

        try {
            await dispatch(logoutUser()).unwrap();
            navigate('/login');
        } catch (error) {
            console.error("Erreur lors de la déconnexion:", error);
        } finally {
            setIsLoading(false);
            setIsModalOpen(false);
        }
    };

    const handleProfileChange = async (profile: 'company' | 'customer') => {
        if (user) {
            try {
                const updatedUser = {
                    ...user,
                    profileSelected: profile,
                };
                const response = await updateUserInfo(updatedUser);

                // D'abord, on remet tout le store à zéro
                dispatch({ type: 'RESET_STORE' });
                window.location.reload();

                // Ensuite, on remet à jour les infos utilisateur dans le store
                dispatch(setUser(response.data));

                setIsModalOpen(false);
            } catch (error) {
                console.error("Erreur lors de la mise à jour du profil:", error);
            }
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node) &&
                buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
                setIsModalOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isModalOpen]);

    return (
        <div
            className={`relative text-black h-full transition-all duration-300 ${collapsed ? 'w-[5.25rem]' : 'w-64'} hidden md:block`}
            onMouseEnter={() => setIsHovered(true)} // Détecter quand la souris entre
            onMouseLeave={() => setIsHovered(false)} // Détecter quand la souris sort
        >
            <div className="flex items-center mt-8 pl-8 relative">
                <div className={`absolute transition-opacity duration-300 ${
                    collapsed ? 'opacity-100' : 'opacity-100'
                }`}>
                    <img src={logoIcon} alt="Logo Icon" className="h-8 whitespace-nowrap leading-none"/>
                </div>

                <div className={`transition-opacity duration-300 ${
                    collapsed ? 'opacity-0' : 'opacity-100'
                }`}>
                    <Logo theme={theme}/>
                </div>
            </div>

            <button
                onClick={toggleNavbar}
                className={`absolute p-2 focus:outline-none text-primary-theme bg-surface-theme border-2 border-theme rounded-full
            transition-opacity transform duration-200 ease-in-out
            ${isHovered ? 'opacity-100' : 'opacity-0'}`} // Applique les animations ici
                style={{
                    right: '-17px',
                    top: '2rem',
                }}
            >
                {collapsed ? <FiChevronRight/> : <FiChevronLeft/>}
            </button>

            <ul className="mt-16 pl-4 pr-4 text-primary-theme">
                <li className={`flex items-center pl-4 p-3 hover:bg-theme-hover rounded cursor-pointer ${location.pathname === '/dashboard' ? 'bg-theme-focus' : ''}`}
                    onClick={() => navigate('/dashboard')}>
                    <span className="transition-none"><FiGrid
                        className={`text-[1.3rem] leading-none ${location.pathname === '/dashboard' ? 'text-primary' : 'text-secondaryColor'}`}/></span>
                    {!collapsed && <span
                        className={`ml-4 min-w-[50px] whitespace-nowrap leading-none text-sm  ${location.pathname === '/dashboard' ? 'text-primary' : 'text-theme'}`}>{t('navbar.dashboard')}</span>}
                </li>
                <li className={`flex items-center pl-4 p-3 hover:bg-theme-hover rounded cursor-pointer ${location.pathname.startsWith('/projects') ? 'bg-theme-focus' : ''}`}
                    onClick={() => navigate('/projects')}>
                    <span className="transition-none"><FiLayers
                        className={`text-[1.3rem] leading-none ${location.pathname.startsWith('/projects') ? 'text-primary' : 'text-secondaryColor'}`}/></span>
                    {!collapsed && <span
                        className={`ml-4 min-w-[50px] whitespace-nowrap leading-none text-sm  ${location.pathname.startsWith('/projects') ? 'text-primary' : 'text-theme'}`}>{t('navbar.projects')}</span>}
                </li>
                {isCompanyProfile && (
                    <li className={`flex items-center pl-4 p-3 hover:bg-theme-hover rounded cursor-pointer ${location.pathname.startsWith('/customers') ? 'bg-theme-focus' : ''}`}
                        onClick={() => navigate('/customers')}>
                    <span className="transition-none"><FiUsers
                        className={`text-[1.3rem] leading-none ${location.pathname.startsWith('/customers') ? 'text-primary' : 'text-secondaryColor'}`}/></span>
                        {!collapsed && <span
                            className={`ml-4 min-w-[50px] whitespace-nowrap leading-none text-sm  ${location.pathname.startsWith('/customers') ? 'text-primary' : 'text-theme'}`}>{t('navbar.customers')}</span>}
                    </li>
                )}
                <li className={`flex relative items-center pl-4 p-3 hover:bg-theme-hover rounded cursor-pointer ${location.pathname.startsWith('/messaging') ? 'bg-theme-focus' : ''}`}
                    onClick={() => navigate('/messaging')}>
                    <span className="transition-none"><FiMessageSquare
                        className={`text-[1.3rem] leading-none ${location.pathname.startsWith('/messaging') ? 'text-primary' : 'text-secondaryColor'}`}/></span>
                    {!collapsed && <span
                        className={`ml-4 min-w-[50px] whitespace-nowrap leading-none text-sm  ${location.pathname.startsWith('/messaging') ? 'text-primary' : 'text-theme'}`}>{t('navbar.messaging')}</span>}
                    {hasUnreadMessages && (
                        <span
                            className="absolute top-2 right-2 bg-red-500 text-white text-xs rounded-full w-3 h-3"></span>
                    )}
                </li>
                <li className={`flex items-center pl-4 p-3 hover:bg-theme-hover rounded cursor-pointer ${location.pathname === '/settings' ? 'bg-theme-focus' : ''}`}
                    onClick={() => navigate('/settings')}>
                    <span className="transition-none"><FiSettings
                        className={`text-[1.3rem] leading-none ${location.pathname.startsWith('/settings') ? 'text-primary' : 'text-secondaryColor'}`}/></span>
                    {!collapsed && <span
                        className={`ml-4 min-w-[50px] whitespace-nowrap leading-none text-sm  ${location.pathname.startsWith('/settings') ? 'text-primary' : 'text-theme'}`}>{t('navbar.settings')}</span>}
                </li>
            </ul>
            <div className="absolute bottom-0 p-4 w-full">
                {isModalOpen && (
                    <div className="relative w-56 pr-4">
                        <div ref={modalRef}
                             className="w-full bg-surface-theme p-1 rounded-lg absolute bottom-0 left-0 drop-shadow-[0px_5px_10px_rgba(22,_19,_35,_0.05)]">
                            <button
                                onClick={() => handleProfileChange('company')}
                                className={`block w-full text-left p-2 hover:bg-theme-hover rounded-md ${user?.profileSelected === 'company' && 'bg-theme-focus'}`}
                            >
                                <ProfileCard
                                    imageUrl={company?.logo && (company.logo.uuid)}
                                    firstName={t('profil.company')}
                                    lastName=""
                                    label="Profil"
                                    size="large"
                                />
                            </button>
                            <button
                                onClick={() => handleProfileChange('customer')}
                                className={`block w-full text-left p-2 hover:bg-theme-hover rounded-md ${user?.profileSelected === 'customer' && 'bg-theme-focus'}`}
                            >
                                <ProfileCard
                                    imageUrl={company?.logo && (company.logo.uuid)}
                                    firstName={t('profil.customer')}
                                    lastName=""
                                    label="Profil"
                                    size="large"
                                />
                            </button>
                            <hr className="my-2"/>
                            <button onClick={handleLogout}
                                    className={`block w-full text-left p-2 hover:bg-theme-hover text-primary-theme rounded-md text-sm ${isLoading && 'flex justify-center items-center'}`}>
                                {isLoading ? (
                                    <svg
                                        className="animate-spin h-5 w-5 text-theme"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                ) : (
                                    <>
                                        {t('navbar.logout')}
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                )}
                <button
                    ref={buttonRef}
                    onClick={() => setIsModalOpen(!isModalOpen)}
                    className={`flex ${!collapsed ? 'px-4 justify-start ' : 'px-1 justify-center'} whitespace-nowrap py-2 w-full text-left hover:bg-theme-hover bg-surface-theme rounded-md drop-shadow-[0px_5px_10px_rgba(22,_19,_35,_0.02)]`}
                >
                    <ProfileCard
                        imageUrl=""
                        // imageUrl={company?.logo && (company?.logo.uuid)}
                        firstName={user?.firstName || ''}
                        lastName={user?.lastName || ''}
                        label={user?.profileSelected === 'company' ? t('profil.company') : user?.profileSelected === 'customer' ? t('profil.customer') : ''}
                        size="large"
                        hideText={collapsed}
                    />
                </button>
            </div>
        </div>
    );
};

export default NavbarSide;