import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store';
import { updateUserInfo } from '../../../api/authService';
import { setUser } from '../../auth/authSlice';
import Skeleton from '../../../components/Skeleton';
import { useTranslation } from 'react-i18next';
import InputText from '../../../components/InputText';
import InputEmail from "../../../components/InputEmail";
import Button from "../../../components/UI/Button"; // Importation de votre composant personnalisé

const UserInformationForm: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.auth);

    const [formData, setFormData] = React.useState({
        email: '',
        firstName: '',
        lastName: '',
    });

    const [loading, setLoading] = React.useState<boolean>(true);
    const [message, setMessage] = React.useState<string>('');

    React.useEffect(() => {
        if (user) {
            setFormData({
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
            });
            setLoading(false);
        }
    }, [user]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const response = await updateUserInfo(formData);
            dispatch(setUser(response.data));
            setMessage(t('settings.updateSuccess'));
        } catch (error) {
            console.error(t('settings.updateError'), error);
            setMessage(t('settings.updateError'));
        }
    };

    return (
        <form onSubmit={handleSubmit} className="max-w-sm">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {t('settings.userInformation')}
            </label>
            <div className="space-y-6">
                {loading ? (
                    <>
                        <Skeleton height="40px"/>
                        <Skeleton height="40px"/>
                        <Skeleton height="40px"/>
                    </>
                ) : (
                    <>
                        <InputEmail
                            label={t('user.email')}
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder={t('user.email')}
                            required={true}
                        />
                        <InputText
                            label={t('user.firstName')}
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            placeholder={t('user.firstName')}
                            required={true}
                        />
                        <InputText
                            label={t('user.lastName')}
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            placeholder={t('user.lastName')}
                            required={true}
                        />
                    </>
                )}
                <Button
                    type="submit"
                    // isLoading={isLoading}
                    fullWidth
                    size="medium"
                >
                    {t('settings.update')}
                </Button>
                {message && <div className="text-center mt-4 text-green-500">{message}</div>}
            </div>
        </form>
    );
};

export default UserInformationForm;