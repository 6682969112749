// features/project/missionSlice.ts

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import {
    Mission,
    MissionsState,
    CreateMissionDTO,
    UpdatePositionDTO, Project
} from './types';
import {
    getMissionsByProject,
    updateMissionStatus,
    apiUpdateMission,
    apiDeleteMission,
    createMission,
    updateMissionsPositions,
    startMission,
    finishMission
} from '../../api/projectService';
import { updateProject } from './projectSlice';

const initialState: MissionsState = {
    missions: {},
    loading: false,
    error: null,
    fetchedProjects: {},
};

export const createMissionThunk = createAsyncThunk(
    'missions/createMission',
    async ({ projectUuid, missionData }: {
        projectUuid: string;
        missionData: CreateMissionDTO
    }, { rejectWithValue }) => {
        try {
            const response = await createMission(projectUuid, missionData);
            return { projectUuid, mission: response };
        } catch (error) {
            return rejectWithValue('Erreur lors de la création de la mission');
        }
    }
);

export const fetchMissionsByProjectId = createAsyncThunk(
    'missions/fetchMissionsByProjectId',
    async (projectUuid: string, { rejectWithValue }) => {
        try {
            const response = await getMissionsByProject(projectUuid);
            return { projectUuid, missions: response.items };
        } catch {
            return rejectWithValue('Erreur lors de la récupération des missions');
        }
    }
);

export const updateMissionStatusThunk = createAsyncThunk(
    'missions/updateMissionStatusThunk',
    async ({ projectUuid, missionUuid, status }: {
        projectUuid: string;
        missionUuid: string;
        status: string
    }, { rejectWithValue }) => {
        try {
            await updateMissionStatus(projectUuid, missionUuid, status);
            return { projectUuid, missionUuid, status };
        } catch {
            return rejectWithValue('Erreur lors de la mise à jour du statut de la mission');
        }
    }
);

export const updateMission = createAsyncThunk(
    'missions/updateMission',
    async ({ projectUuid, missionUuid, missionData }: {
        projectUuid: string;
        missionUuid: string;
        missionData: Partial<Mission>
    }, { rejectWithValue, getState }) => {
        try {

            // Appel API
            const response = await apiUpdateMission(projectUuid, missionUuid, missionData);

            // Si la réponse ne contient pas l'UUID, ajoutons-le manuellement
            // C'est crucial pour que le reducer puisse trouver la mission à mettre à jour
            const enrichedResponse = {
                ...response,
                uuid: missionUuid
            };

            return enrichedResponse;
        } catch (error) {
            console.error("Erreur dans updateMission:", error);
            return rejectWithValue('Erreur lors de la mise à jour de la mission');
        }
    }
);

export const deleteMission = createAsyncThunk(
    'missions/deleteMission',
    async ({ projectUuid, missionUuid }: {
        projectUuid: string;
        missionUuid: string
    }, { rejectWithValue }) => {
        try {
            await apiDeleteMission(projectUuid, missionUuid);
            return { projectUuid, missionUuid };
        } catch {
            return rejectWithValue('Erreur lors de la suppression de la mission');
        }
    }
);

export const updateMissionsPositionsThunk = createAsyncThunk(
    'missions/updatePositions',
    async ({ projectUuid, positions }: {
        projectUuid: string;
        positions: UpdatePositionDTO[]
    }, { rejectWithValue }) => {
        try {
            await updateMissionsPositions(projectUuid, positions);
            return { projectUuid, positions };
        } catch (error) {
            return rejectWithValue('Erreur lors de la mise à jour des positions');
        }
    }
);

export const startMissionThunk = createAsyncThunk(
    'missions/startMission',
    async ({ projectUuid, missionUuid }: {
        projectUuid: string;
        missionUuid: string;
    }, { rejectWithValue }) => {
        try {
            await startMission(projectUuid, missionUuid);
            return { projectUuid, missionUuid, status: 'in_progress' };
        } catch {
            return rejectWithValue('Erreur lors du démarrage de la mission');
        }
    }
);

export const finishMissionThunk = createAsyncThunk(
    'missions/finishMission',
    async ({ projectUuid, missionUuid }: {
        projectUuid: string;
        missionUuid: string;
    }, { rejectWithValue }) => {
        try {
            await finishMission(projectUuid, missionUuid);
            return { projectUuid, missionUuid, status: 'completed' };
        } catch {
            return rejectWithValue('Erreur lors de la finalisation de la mission');
        }
    }
);

const missionSlice = createSlice({
    name: 'missions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createMissionThunk.fulfilled, (state, action: PayloadAction<{
                projectUuid: string;
                mission: Mission
            }>) => {
                const { projectUuid, mission } = action.payload;
                if (!state.missions[projectUuid]) {
                    state.missions[projectUuid] = [];
                }
                state.missions[projectUuid].push(mission);
            })
            .addCase(fetchMissionsByProjectId.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMissionsByProjectId.fulfilled, (state, action: PayloadAction<{
                projectUuid: string;
                missions: Mission[]
            }>) => {
                const { projectUuid, missions } = action.payload;
                state.missions[projectUuid] = missions;
                state.loading = false;
                state.fetchedProjects[projectUuid] = true;
            })
            .addCase(fetchMissionsByProjectId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? 'Une erreur est survenue';
                state.fetchedProjects[action.meta.arg] = true;
            })
            .addCase(updateMissionStatusThunk.fulfilled, (state, action: PayloadAction<{
                projectUuid: string;
                missionUuid: string;
                status: string
            }>) => {
                const { projectUuid, missionUuid, status } = action.payload;
                const missions = state.missions[projectUuid];
                const missionIndex = missions.findIndex((mission) => mission.uuid === missionUuid);
                if (missionIndex !== -1) {
                    state.missions[projectUuid][missionIndex].status = status;
                }
            })
            .addCase(updateMissionStatusThunk.rejected, (state, action) => {
                state.error = action.error.message ?? 'Erreur lors de la mise à jour du statut';
            })
            .addCase(updateMission.fulfilled, (state, action) => {
                const updatedMission = action.payload;

                if (!updatedMission || !updatedMission.uuid) {
                    console.error("Erreur: Mission mise à jour sans UUID");
                    return;
                }

                // Trouver la mission dans tous les projets
                let found = false;

                for (const projectUuid in state.missions) {
                    const missions = state.missions[projectUuid];
                    const missionIndex = missions.findIndex(m => m.uuid === updatedMission.uuid);

                    if (missionIndex !== -1) {

                        // Fusion de la mission existante avec les modifications
                        state.missions[projectUuid][missionIndex] = {
                            ...state.missions[projectUuid][missionIndex],
                            ...updatedMission
                        };

                        found = true;
                        break;
                    }
                }

                if (!found) {
                    console.error(`Mission ${updatedMission.uuid} non trouvée dans le store`);
                }
            })
            .addCase(deleteMission.fulfilled, (state, action: PayloadAction<{
                projectUuid: string;
                missionUuid: string
            }>) => {
                const { projectUuid, missionUuid } = action.payload;
                if (state.missions[projectUuid]) {
                    state.missions[projectUuid] = state.missions[projectUuid].filter(
                        mission => mission.uuid !== missionUuid
                    );
                }
            })
            .addCase(updateMissionsPositionsThunk.fulfilled, (state, action: PayloadAction<{
                projectUuid: string;
                positions: UpdatePositionDTO[]
            }>) => {
                const { projectUuid, positions } = action.payload;
                if (state.missions[projectUuid]) {
                    const sortedMissions = [...state.missions[projectUuid]].sort((a, b) => {
                        const posA = positions.find(p => p.uuid === a.uuid)?.position ?? Number.MAX_SAFE_INTEGER;
                        const posB = positions.find(p => p.uuid === b.uuid)?.position ?? Number.MAX_SAFE_INTEGER;
                        return posA - posB;
                    });
                    state.missions[projectUuid] = sortedMissions;
                }
            })
            .addCase(updateProject.fulfilled, (state, action: PayloadAction<Project>) => {
                const updatedProject = action.payload;

                // Si le projet mis à jour contient des missions, mettre à jour le state des missions
                if (updatedProject && updatedProject.missions && updatedProject.missions.length > 0) {
                    state.missions[updatedProject.uuid] = updatedProject.missions;
                    state.fetchedProjects[updatedProject.uuid] = true;
                }
            })
            .addCase(startMissionThunk.fulfilled, (state, action: PayloadAction<{
                projectUuid: string;
                missionUuid: string;
                status: string
            }>) => {
                const { projectUuid, missionUuid, status } = action.payload;
                const missions = state.missions[projectUuid];
                const missionIndex = missions.findIndex((mission) => mission.uuid === missionUuid);
                if (missionIndex !== -1) {
                    state.missions[projectUuid][missionIndex].status = status;
                }
            })
            .addCase(startMissionThunk.rejected, (state, action) => {
                state.error = action.error.message ?? 'Erreur lors du démarrage de la mission';
            })
            .addCase(finishMissionThunk.fulfilled, (state, action: PayloadAction<{
                projectUuid: string;
                missionUuid: string;
                status: string
            }>) => {
                const { projectUuid, missionUuid, status } = action.payload;
                const missions = state.missions[projectUuid];
                const missionIndex = missions.findIndex((mission) => mission.uuid === missionUuid);
                if (missionIndex !== -1) {
                    state.missions[projectUuid][missionIndex].status = status;
                }
            })
            .addCase(finishMissionThunk.rejected, (state, action) => {
                state.error = action.error.message ?? 'Erreur lors de la finalisation de la mission';
            });
    },
});

// Selectors
export const selectMissionsByProjectId = (state: RootState, projectUuid: string) =>
    state.missions.missions[projectUuid] || [];
export const selectMissionsLoading = (state: RootState) => state.missions.loading;
export const selectMissionsError = (state: RootState) => state.missions.error;
export const selectHasFetchedMissionsForProject = (state: RootState, projectUuid: string) =>
    !!state.missions.fetchedProjects[projectUuid];

export default missionSlice.reducer;