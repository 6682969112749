import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import {
    uploadImage as apiUploadImage,
    deleteImage as apiDeleteImage,
    getImage as apiGetImage,
    getAllImages as apiGetAllImages,
    ImageUploadResponse
} from '../../api/imageService';

export interface Image {
    uuid: string;
    url: string;
    // downloadUrl: string;
    createdAt: string;
}

interface ImagesState {
    images: Image[];
    loading: boolean;
    error: string | null;
    hasFetchedAll: boolean;
    currentImage: Blob | null;
}

const initialState: ImagesState = {
    images: [],
    loading: false,
    error: null,
    hasFetchedAll: false,
    currentImage: null,
};

export const fetchImages = createAsyncThunk(
    'images/fetchImages',
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiGetAllImages();
            return response.items;
        } catch (error) {
            return rejectWithValue('Échec de la récupération des images');
        }
    }
);

export const fetchImage = createAsyncThunk(
    'images/fetchImage',
    async (uuid: string, { rejectWithValue }) => {
        try {
            const response = await apiGetImage(uuid);
            return response;
        } catch (error) {
            return rejectWithValue('Échec de la récupération de l\'image');
        }
    }
);

export const uploadImage = createAsyncThunk(
    'images/uploadImage',
    async (file: File, { rejectWithValue }) => {
        try {
            return await apiUploadImage(file);
        } catch (error) {
            return rejectWithValue('Échec du téléchargement de l\'image');
        }
    }
);

export const deleteImage = createAsyncThunk(
    'images/deleteImage',
    async (uuid: string, { rejectWithValue }) => {
        try {
            await apiDeleteImage(uuid);
            return uuid;
        } catch (error) {
            return rejectWithValue('Échec de la suppression de l\'image');
        }
    }
);

const imagesSlice = createSlice({
    name: 'images',
    initialState,
    reducers: {
        clearCurrentImage: (state) => {
            state.currentImage = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchImages.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchImages.fulfilled, (state, action: PayloadAction<Image[]>) => {
                state.images = action.payload;
                state.loading = false;
                state.hasFetchedAll = true;
            })
            .addCase(fetchImages.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? 'Une erreur est survenue';
                state.hasFetchedAll = true;
            })
            .addCase(fetchImage.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchImage.fulfilled, (state, action: PayloadAction<Blob>) => {
                state.currentImage = action.payload;
                state.loading = false;
            })
            .addCase(fetchImage.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? 'Une erreur est survenue';
            })
            .addCase(uploadImage.fulfilled, (state, action: PayloadAction<ImageUploadResponse>) => {
                state.images.push(action.payload);
            })
            .addCase(deleteImage.fulfilled, (state, action: PayloadAction<string>) => {
                state.images = state.images.filter(image => image.uuid !== action.payload);
            });
    }
});

export const { clearCurrentImage } = imagesSlice.actions;

export const selectImages = (state: RootState) => state.images.images;
export const selectImagesLoading = (state: RootState) => state.images.loading;
export const selectImagesError = (state: RootState) => state.images.error;
export const selectCurrentImage = (state: RootState) => state.images.currentImage;
export const selectImageById = (state: RootState, uuid: string) =>
    state.images.images.find(image => image.uuid === uuid);

export default imagesSlice.reducer;