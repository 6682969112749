import { ProjectFormData } from '../features/project/form/types';
import { Mission } from '../features/project/types';

export const saveFormState = <T>(formKey: string, formData: T): void => {
    localStorage.setItem(formKey, JSON.stringify(formData));
};

export const getFormState = <T>(formKey: string): T | null => {
    const savedState = localStorage.getItem(formKey);
    return savedState ? JSON.parse(savedState) as T : null;
};

export const clearFormState = (formKey: string): void => {
    localStorage.removeItem(formKey);
};