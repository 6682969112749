// src/components/company/CreateCompanyForm.tsx
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { createCompany } from './companySlice';
import { setHasCompany, setLoading } from '../auth/authSlice';
import { useCompany } from '../../hooks/useCompany';
import { useImages } from '../../hooks/useImages';
import { useCountries } from '../../hooks/useCountries';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import FormNavigation from './FormNavigation';
import { CompanySearchResult, CompanyFormData } from './types';
import { t } from 'i18next';
import Logo from "../../components/Logo";

const initialFormData: CompanyFormData = {
    legalName: '',
    commercialName: '',
    companyNumber: '',
    companyNumberSearch: '',
    vatNumber: '',
    mainColor: '',
    secondaryColor: '',
    logo: '',
    address: {
        street: '',
        streetComplement: '',
        zipCode: '',
        city: '',
        country: ''
    }
};

const isStepOneValid = (formData: CompanyFormData): boolean => {
    return !!(
        formData.companyNumber && formData.companyNumber.length === 14 &&
        formData.legalName &&
        formData.address.street &&
        formData.address.city &&
        formData.address.zipCode && formData.address.zipCode.length === 5 &&
        formData.address.country
    );
};

type CreateCompanyFormProps = {
    theme: string;
};

const CreateCompanyForm: React.FC<CreateCompanyFormProps> = ({ theme }) => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState<CompanyFormData>(initialFormData);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [message, setMessage] = useState('');
    const [uploadError, setUploadError] = useState<string | null>(null);
    const [showVatInput, setShowVatInput] = useState(false);
    const [autoFilledFields, setAutoFilledFields] = useState<Record<string, boolean>>({});

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { uploadImage } = useImages();
    const { company, loading } = useCompany();
    const { getCountryCodeByName } = useCountries();
    const vatInputRef = useRef<HTMLInputElement>(null);
    const [isNextDisabled, setIsNextDisabled] = useState(true);

    useEffect(() => {
        if (company) {
            navigate('/dashboard');
        }
    }, [company, navigate]);

    useEffect(() => {
        setIsNextDisabled(!isStepOneValid(formData));
    }, [formData]);

    const handleCompanySelect = (company: CompanySearchResult) => {
        const countryCode = getCountryCodeByName(company.country);

        setFormData(prev => ({
            ...prev,
            companyNumberSearch: company.companyNumber,
            companyNumber: company.companyNumber,
            vatNumber: company.vatNumber || '',
            legalName: company.name || '',
            address: {
                ...prev.address,
                street: company.address || '',
                streetComplement: '',
                zipCode: company.postcode || '',
                city: company.city || '',
                country: countryCode || ''
            }
        }));

        setAutoFilledFields({
            legalName: !!company.name,
            companyNumber: true,
            vatNumber: !!company.vatNumber,
            'address.street': !!company.address,
            'address.zipCode': !!company.postcode,
            'address.city': !!company.city,
            'address.country': !!countryCode
        });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;

        setAutoFilledFields(prev => ({
            ...prev,
            [name]: false
        }));

        if (name.startsWith('address.')) {
            const addressField = name.split('.')[1];
            setFormData(prev => ({
                ...prev,
                address: {
                    ...prev.address,
                    [addressField]: value
                }
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleColorChange = (name: string, newColor: string) => {
        setFormData(prev => ({
            ...prev,
            [name]: newColor
        }));
    };

    const handleVatCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        setShowVatInput(isChecked);
        if (!isChecked) {
            setFormData(prev => ({ ...prev, vatNumber: '' }));
        } else {
            setTimeout(() => {
                const inputElement = vatInputRef.current?.querySelector('input');
                inputElement?.focus();
            }, 0);
        }
    };

    const handleImageCropped = (croppedImage: string) => {
        setFormData(prev => ({
            ...prev,
            logo: croppedImage
        }));

        fetch(croppedImage)
            .then(res => res.blob())
            .then(blob => {
                const file = new File([blob], 'logo.png', { type: 'image/png' });
                setSelectedFile(file);
                setUploadError(null);
            });
    };

    const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
        if (e) {
            e.preventDefault();
        }
        setIsLoading(true);

        try {
            let updatedFormData = { ...formData };

            // Supprimer la propriété "companyNumberSearch" avant la requête
            const { companyNumberSearch, ...dataToSubmit } = updatedFormData;

            if (selectedFile) {
                try {
                    const uploadedImage = await uploadImage(selectedFile);
                    dataToSubmit.logo = uploadedImage.uuid;
                } catch (error) {
                    console.error('Erreur lors du téléchargement de l\'image:', error);
                    setMessage('Erreur lors du téléchargement de l\'image');
                    setIsLoading(false);
                    return;
                }
            }

            // Envoi uniquement de dataToSubmit (sans companyNumberSearch)
            const createdCompany = await dispatch(createCompany(dataToSubmit)).unwrap();

            if (createdCompany?.uuid) {
                dispatch(setHasCompany(true));
                setMessage('Compagnie créée avec succès !');
                navigate('/dashboard');
            } else {
                throw new Error('UUID de la société est absent de la réponse API');
            }
        } catch (error) {
            console.error('Erreur lors de la création de la compagnie:', error);
            setMessage('Erreur lors de la création de la compagnie');
        } finally {
            setIsLoading(false);
            dispatch(setLoading(false));
        }
    };


    if (loading) {
        return <div>Chargement...</div>;
    }

    return (
        <>
            <div
                className="absolute top-[3.5rem] md:top-[2.125rem] left-1/2 -translate-x-1/2 md:left-auto md:right-[2.125rem] md:transform-none">
                <Logo theme={theme}/>
            </div>

            <div className="container mx-auto px-4 pb-12">
                <div className="max-w-md mx-auto mt-24">
                    {step === 1 ? (
                        <div className="space-y-[.313rem]">
                            <h2 className="text-center text-[2rem] leading-9 font-medium text-primary-theme">{t('company.step1.title')}</h2>
                            <p className="text-center text-xs leading-normal font-normal text-primary-theme">{t('company.step1.label')}</p>
                        </div>
                    ) : (
                        <div className="space-y-[.313rem]">
                            <h2 className="text-center text-[2rem] leading-9 font-medium text-primary-theme">{t('company.step2.title')}</h2>
                            <p className="text-center text-xs leading-normal font-normal text-primary-theme">{t('company.step2.label')}</p>
                        </div>
                    )}
                    <form onSubmit={handleSubmit} className="mt-10">
                        {step === 1 && (
                            <StepOne
                                formData={formData}
                                autoFilledFields={autoFilledFields}
                                onCompanySelect={handleCompanySelect}
                                onChange={handleChange}
                            />
                        )}
                        {step === 2 && (
                            <StepTwo
                                formData={formData}
                                showVatInput={showVatInput}
                                uploadError={uploadError}
                                autoFilledFields={autoFilledFields}
                                onVatCheckboxChange={handleVatCheckboxChange}
                                onChange={handleChange}
                                onColorChange={handleColorChange}
                                onImageCropped={handleImageCropped}
                            />
                        )}

                        {message && (
                            <div className="text-center mt-4 text-red-500">{message}</div>
                        )}

                        <div className="mt-6">
                            <FormNavigation
                                step={step}
                                isLoading={isLoading}
                                isNextDisabled={isNextDisabled}
                                onNext={() => setStep(2)}
                                onBack={() => setStep(1)}
                                onSubmit={handleSubmit}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default CreateCompanyForm;