// src/components/company/StepTwo.tsx
import React, {useEffect} from 'react';
import { t } from 'i18next';
import { CompanyFormData } from './types';
import ImageCropper from "../../components/ImageCropper";
import InputCheckbox from "../../components/InputCheckbox";
import InputText from "../../components/InputText";
import InputColor from "../../components/InputColor";

interface StepTwoProps {
    formData: CompanyFormData;
    showVatInput: boolean;
    uploadError: string | null;
    onVatCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onColorChange: (name: string, color: string) => void;
    onImageCropped: (croppedImage: string) => void;
    autoFilledFields: Record<string, boolean>;
}

const StepTwo: React.FC<StepTwoProps> = ({
     formData,
     autoFilledFields,
     showVatInput,
     uploadError,
     onVatCheckboxChange,
     onChange,
     onColorChange,
     onImageCropped
 }) => {

    return (
        <div className="space-y-[1.25rem]">
            <div className="mb-4">
                <ImageCropper
                    label={t('company.logo')}
                    name="logo"
                    onImageCropped={onImageCropped}
                    required={false}
                    initialImage={formData.logo || null} // Ajout de la prop ici
                />
                {uploadError && (
                    <p className="mt-1 text-sm text-red-500">{uploadError}</p>
                )}
            </div>
            <InputColor
                label={t('company.mainColor')}
                name="mainColor"
                value={formData.mainColor}
                onChange={(newColor) => onColorChange('mainColor', newColor)}
            />
            <InputColor
                label={t('company.secondaryColor')}
                name="secondaryColor"
                value={formData.secondaryColor}
                onChange={(newColor) => onColorChange('secondaryColor', newColor)}
            />
        </div>
    );
};

export default StepTwo;