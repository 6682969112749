import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import {
    createCompany as createCompanyAPI,
    getCompanyInfo as apiGetCompanyInfo,
    updateCompanyInfo as apiUpdateCompany,
} from '../../api/companyService';

export interface Company {
    uuid: string;
    legalName: string;
    commercialName: string;
    companyNumber: string;
    vatNumber?: string;
    mainColor?: string;
    secondaryColor?: string;
    logo?: Logo;
    address: {
        street: string;
        streetComplement: string;
        zipCode: string;
        city: string;
        country: string;
    };
    createdAt: string;
    updatedAt: string;
}

interface NewCompany {
    legalName: string;
    commercialName: string;
    companyNumber: string;
    vatNumber?: string;
    mainColor?: string;
    secondaryColor?: string;
    logo?: string;
    address: {
        street: string;
        streetComplement: string;
        zipCode: string;
        city: string;
        country: string;
    };
}

interface CompanyState {
    company: Company | null;
    loading: boolean;
    error: string | null;
}

interface Logo {
    uuid: string;
}

const initialState: CompanyState = {
    company: null,
    loading: false,
    error: null,
};

// Helper function to handle errors
const handleRejected = <T>(
    state: CompanyState,
    action: PayloadAction<unknown, string, T, SerializedError>
) => {
    state.loading = false;
    state.error = action.error.message ?? 'Une erreur est survenue';
};

// Thunks
export const fetchCompanyById = createAsyncThunk(
    'company/fetchCompanyById',
    async (uuid: string, { rejectWithValue }) => {
        try {
            const response = await apiGetCompanyInfo(uuid);
            return response.data; // Assurez-vous de renvoyer uniquement les données nécessaires
        } catch {
            return rejectWithValue('Échec de la récupération des informations de l’entreprise');
        }
    }
);

export const createCompany = createAsyncThunk<Company, NewCompany>(
    'company/createCompany',
    async (companyData, { rejectWithValue }) => {
        try {
            const response = await createCompanyAPI(companyData);
            return response.data; // Type explicite : renvoyez un objet de type `Company`
        } catch (error: any) {
            console.error('Erreur lors de la création de la compagnie:', error);
            return rejectWithValue('Erreur lors de la création de l’entreprise');
        }
    }
);

export const updateCompany = createAsyncThunk(
    'company/updateCompany',
    async ({ uuid, companyData }: { uuid: string; companyData: Partial<Omit<Company, 'uuid'>> }) => {
        const response = await apiUpdateCompany(uuid, companyData);
        return response.data; // Assurez-vous que la réponse contient les données mises à jour
    }
);

const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        // Ajout de l'action setCompany
        setCompany: (state, action: PayloadAction<Company | null>) => {
            state.company = action.payload;
        },
        setCompanyLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompanyById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCompanyById.fulfilled, (state, action: PayloadAction<Company>) => {
                state.company = action.payload;
                state.loading = false;
            })
            .addCase(fetchCompanyById.rejected, handleRejected)
            .addCase(createCompany.fulfilled, (state, action: PayloadAction<Company>) => {
                state.company = action.payload;
                state.loading = false;
            })
            .addCase(createCompany.rejected, handleRejected)
            .addCase(updateCompany.fulfilled, (state, action: PayloadAction<Company>) => {
                if (state.company && state.company.uuid === action.payload.uuid) {
                    state.company = action.payload;
                }
            });
    }
});

// Export des actions
export const { setCompany, setCompanyLoading } = companySlice.actions;

// Selectors
export const selectCompany = (state: RootState) => state.company.company;
export const selectCompanyLoading = (state: RootState) => state.company.loading;
export const selectCompanyError = (state: RootState) => state.company.error;

export default companySlice.reducer;