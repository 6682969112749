import React from 'react';
import PdfViewer from './PdfViewer'; // Ton composant actuel

interface DocumentPreviewerProps {
    url: string; // publicUrl pour affichage
    mimeType: string;
}

const DocumentPreviewer: React.FC<DocumentPreviewerProps> = ({ url, mimeType }) => {
    if (mimeType.startsWith('image/')) {
        return (
            <img src={url} alt="Aperçu du document" className="max-w-full max-h-[80vh] rounded mx-auto" />
        );
    }

    if (mimeType === 'application/pdf') {
        return <PdfViewer pdfUrl={url} />;
    }

    return (
        <div className="text-center py-10 text-sm text-secondary-theme">
            Aucun aperçu disponible pour ce type de fichier.
        </div>
    );
};

export default DocumentPreviewer;
