import React, {useRef, useLayoutEffect, useState, useEffect} from 'react';
import ThreadMessageItem from './ThreadMessageItem';
import ThreadDateSeparator from './ThreadDateSeparator';
import { useDispatch } from 'react-redux';
import { fetchMessagesWithPagination } from '../messagingSlice';
import { useInView } from 'react-intersection-observer';

interface Message {
    uuid?: string;
    content?: string;
    body?: string;
    sender?: { firstName: string, lastName: string };
    nonce?: string;
    createdAt: string;
    isDocument?: boolean;
    status?: string;
    threadId?: string;
    documents?: string[];
}

interface ThreadMessagesListProps {
    messages: Message[];
    user: any;
    handleDeleteMessage: (messageId: string) => void;
    threadId: string;
    newMessageAdded: boolean;
    setNewMessageAdded: (value: boolean) => void;
}

const ThreadMessagesList: React.FC<ThreadMessagesListProps> = ({ messages, user, handleDeleteMessage, threadId }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [loadingMore, setLoadingMore] = useState(false);
    const [allMessagesLoaded, setAllMessagesLoaded] = useState(false);
    const [skip, setSkip] = useState(0);
    const [initialLoad, setInitialLoad] = useState(true);
    const dispatch = useDispatch();

    const { ref: loaderRef, inView } = useInView({
        triggerOnce: false,
        threshold: 1.0,
    });

    const fetchMessages = async () => {
        if (loadingMore || allMessagesLoaded) return;

        const container = containerRef.current;
        if (!container) return;

        // Capture l'élément visible actuel pour référence
        const visibleElement = document.elementFromPoint(
            container.getBoundingClientRect().x + container.clientWidth / 2,
            container.getBoundingClientRect().y + container.clientHeight / 2
        );

        setLoadingMore(true);

        try {
            const response = await dispatch(fetchMessagesWithPagination({ threadId, limit: 20, skip }) as any);
            if (response.payload.messages.length === 0) {
                setAllMessagesLoaded(true);
            }
            setSkip(prevSkip => prevSkip + 20);

            // Maintenir la position en se basant sur l'élément visible
            setTimeout(() => {
                if (visibleElement) {
                    visibleElement.scrollIntoView({ block: 'center' });
                }
            }, 0);
        } finally {
            setLoadingMore(false);
        }
    };

    useLayoutEffect(() => {
        if (inView && initialLoad) {
            setInitialLoad(false);
            fetchMessages();
        }
    }, [inView, initialLoad]);

    useLayoutEffect(() => {
        if (inView && !initialLoad) {
            fetchMessages();
        }
    }, [inView, initialLoad]);

    const formatDate = (date: string) => {
        const messageDate = new Date(date);
        const today = new Date();

        const isToday = messageDate.toDateString() === today.toDateString();
        const isYesterday = new Date(today.setDate(today.getDate() - 1)).toDateString() === messageDate.toDateString();

        if (isToday) return "Aujourd'hui";
        if (isYesterday) return "Hier";
        if (messageDate > new Date(today.setDate(today.getDate() - 5))) {
            return messageDate.toLocaleDateString('fr-FR', { weekday: 'long' });
        }
        return messageDate.toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' });
    };

    const sortedAndReversedMessages = [...messages]
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        .reverse();

    let groupedMessages = sortedAndReversedMessages.reduce((acc: { [key: string]: Message[] }, message) => {
        const dateKey = formatDate(message.createdAt);
        if (!acc[dateKey]) {
            acc[dateKey] = [];
        }
        acc[dateKey].push(message);
        return acc;
    }, {});

    const groupedMessagesArray = Object.entries(groupedMessages).reverse();

    const [newMessageAdded, setNewMessageAdded] = useState(false);

    useEffect(() => {
        if (messages.length > 0 && newMessageAdded) {
            const container = containerRef.current;
            if (container) {
                container.scrollTo({
                    top: container.scrollHeight,
                    behavior: 'smooth'
                });
            }
            setNewMessageAdded(false);
        }
    }, [messages, newMessageAdded]);


    return (
        <div
            ref={containerRef}
            className="flex-1 md:overflow-y-auto md:p-4 flex flex-col-reverse py-20 overflow-y-auto h-[calc(100vh-200px)]"
            style={{WebkitOverflowScrolling: 'touch'}}
        >
            {groupedMessagesArray.map(([date, groupedMessages], index) => (
                <ThreadDateSeparator key={date} date={date}>
                    {groupedMessages.map((message, msgIndex) => (
                        <ThreadMessageItem
                            key={message.uuid || msgIndex}
                            message={message}
                            user={user}
                            previousMessage={msgIndex > 0 ? groupedMessages[msgIndex - 1] : null}
                            handleDeleteMessage={handleDeleteMessage}
                            isLastMessage={index === groupedMessagesArray.length - 1 && msgIndex === groupedMessages.length - 1}
                        />
                    ))}
                </ThreadDateSeparator>
            ))}
            <div
                ref={loaderRef}
                className="text-center mt-4 text-theme text-sm"
            >
                {loadingMore ? '' : (allMessagesLoaded ? 'Vous avez atteint la fin des messages.' : '')}
            </div>
        </div>
    );
};

export default ThreadMessagesList;