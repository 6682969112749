import React from 'react';
import ContactCard from '../../../components/ContactCard';
import documents from "../../project/components/Documents";
import {FiArrowLeft} from "react-icons/fi";

interface Document {
    uuid: string;
    originalName?: string;
    mimeType?: string;
    createdAt?: string;
    size?: string;
}

interface CustomerSidebarProps {
    isOpen: boolean;
    onClose: () => void; // Ajout de la prop onClose
    customer?: {
        firstName?: string;
        lastName?: string;
        companyName?: string;
        email?: string;
        phone?: string;
        imageUrl?: string;
        projects?: Array<{ uuid: string; title: string; }>;
        documents?: Document[];
    };
}

const CustomerSidebar: React.FC<CustomerSidebarProps> = ({ isOpen, onClose, customer }) => {
    if (!isOpen || !customer) return null;

    return (
        <div className={`
            ${isOpen ? 'block' : 'hidden'}
            w-full md:w-[400px] xl:w-[450px]
            absolute md:relative 
            inset-0 md:inset-auto 
            bg-surface-theme
            h-full
            overflow-hidden
            md:border-l border-theme
        `}>
            {/* Header avec bouton retour pour mobile */}
            <div className="flex items-center border-b border-theme md:hidden">
                <button
                    onClick={onClose}
                    className="md:hidden p-4 flex items-center text-primary"
                >
                    <FiArrowLeft className="mr-2"/> Retour sur la conversation
                </button>
            </div>

            {/* Contenu */}
            <div className="px-4 overflow-y-auto h-[calc(100%-60px)] md:h-full">
                <ContactCard
                    firstName={customer.firstName}
                    lastName={customer.lastName}
                    companyName={customer.companyName}
                    email={customer.email}
                    phone={customer.phone}
                    imageUrl={customer.imageUrl}
                    customerProjects={customer.projects}
                    documents={customer.documents}
                />
            </div>
        </div>
    );
};


export default CustomerSidebar;