import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Tabs from '../components/Tabs';
import UserInformationForm from '../features/settings/components/UserInformationForm';
import CompanyInformationForm from '../features/settings/components/CompanyInformationForm';
import ThemeSelector from '../features/settings/components/ThemeSelector';
import LanguageSelector from '../features/settings/components/LanguageSelector';
import SecurityForm from '../features/settings/components/SecurityForm';
import { useTranslation } from "react-i18next";

const SettingsPage: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const initialTab = queryParams.get('tab') || 'profile';

    const [activeTab, setActiveTab] = useState<string>(initialTab);

    useEffect(() => {
        navigate(`/settings?tab=${activeTab}`, { replace: true });
    }, [activeTab, navigate]);

    const tabs = [
        { label: t('settings.profile'), value: 'profile' },
        { label: t('settings.security'), value: 'security' },
        { label: t('settings.company'), value: 'company' },
        { label: t('settings.appearance'), value: 'appearance' },
        { label: t('settings.language'), value: 'language' },
    ];

    const renderContent = () => {
        switch (activeTab) {
            case 'profile':
                return <UserInformationForm />;
            case 'security':
                return <SecurityForm />;
            case 'company':
                return <CompanyInformationForm />;
            case 'appearance':
                return <ThemeSelector />;
            case 'language':
                return <LanguageSelector />;
            default:
                return null;
        }
    };

    return (
        <div className="md:pl-6 md:pr-4 h-full p-4 mb-8 md:m-0">
            <Tabs tabs={tabs} activeTab={activeTab} onSelectTab={setActiveTab} />
            <div className="mt-8">{renderContent()}</div>
        </div>
    );
};

export default SettingsPage;