import axiosInstance from './apiService';

interface DocumentData {
    title: string;
    content: string;
}

// Fonction pour récupérer un document d'un projet
export const getProjectDocument = (projectUuid: string, documentUuid: string) => {
    return axiosInstance.get(`/v1/projects/${projectUuid}/documents/${documentUuid}`)
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la récupération du document:', error.response?.data || error.message);
            throw error;
        });
};

// Fonction pour récupérer un document d'une mission
export const getMissionDocument = (projectUuid: string, missionUuid: string, documentUuid: string) => {
    return axiosInstance.get(`/v1/projects/${projectUuid}/missions/${missionUuid}/documents/${documentUuid}`)
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la récupération du document:', error.response?.data || error.message);
            throw error;
        });
};

// Fonction pour supprimer un document d'un projet
export const deleteProjectDocument = (projectUuid: string, documentUuid: string) => {
    return axiosInstance.delete(`/v1/projects/${projectUuid}/documents/${documentUuid}`)
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la suppression du document:', error.response?.data || error.message);
            throw error;
        });
};

// Fonction pour supprimer un document d'une mission
export const deleteMissionDocument = (projectUuid: string, missionUuid: string, documentUuid: string) => {
    return axiosInstance.delete(`/v1/projects/${projectUuid}/missions/${missionUuid}/documents/${documentUuid}`)
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la suppression du document:', error.response?.data || error.message);
            throw error;
        });
};

// Fonction pour uploader un document dans un projet
export const uploadProjectDocument = async (formData: FormData, projectUuid: string) => {
    try {
        const response = await axiosInstance.post(`/v1/projects/${projectUuid}/documents`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error('Erreur lors de l\'upload du document:', error);
        throw error;
    }
};

// Fonction pour uploader un document dans une mission
export const uploadMissionDocument = async (formData: FormData, projectUuid: string, missionUuid: string) => {
    try {
        const response = await axiosInstance.post(
            `/v1/projects/${projectUuid}/missions/${missionUuid}/documents`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        return response;
    } catch (error) {
        console.error('Erreur lors de l\'upload du document:', error);
        throw error;
    }
};