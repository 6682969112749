import React from 'react';

// Types pour les différentes variantes
type SkeletonVariant = 'text' | 'circular' | 'rectangular' | 'button' | 'card';

interface SkeletonProps {
    variant?: SkeletonVariant;
    width?: string | number;
    height?: string | number;
    className?: string;
    count?: number;
    // Pour les variantes qui nécessitent un arrondi
    rounded?: boolean;
}

const Skeleton: React.FC<SkeletonProps> = ({
   variant = 'text',
   width,
   height,
   className = '',
   count = 1,
   rounded = false,
}) => {
    // Fonction pour générer les styles de base selon la variante
    const getVariantStyles = (variant: SkeletonVariant): string => {
        switch (variant) {
            case 'text':
                return 'h-4 rounded-sm';
            case 'circular':
                return 'rounded-full';
            case 'button':
                return 'h-10 rounded-md';
            case 'card':
                return 'rounded-lg';
            default:
                return rounded ? 'rounded-md' : '';
        }
    };

    // Fonction pour générer les dimensions par défaut selon la variante
    const getDefaultDimensions = (variant: SkeletonVariant) => {
        switch (variant) {
            case 'text':
                return { width: '100%', height: '1rem' };
            case 'circular':
                return { width: '40px', height: '40px' };
            case 'button':
                return { width: '100px', height: '40px' };
            case 'card':
                return { width: '300px', height: '200px' };
            default:
                return { width: width || '100%', height: height || '20px' };
        }
    };

    const dimensions = getDefaultDimensions(variant);
    const baseStyles = getVariantStyles(variant);

    // Fonction pour générer un skeleton unique
    const SingleSkeleton = () => (
        <div
            className={`bg-gray-200 animate-pulse ${baseStyles} ${className}`}
            style={{
                width: width || dimensions.width,
                height: height || dimensions.height,
            }}
        />
    );

    // Si count > 1, on génère plusieurs skeletons (utile pour les listes de texte)
    if (count > 1) {
        return (
            <div className="space-y-2">
                {Array.from({ length: count }).map((_, index) => (
                    <SingleSkeleton key={index} />
                ))}
            </div>
        );
    }

    return <SingleSkeleton />;
};

// Exemple d'utilisation
const SkeletonDemo = () => (
    <div className="space-y-4 p-4">
        <div>
            <h3 className="text-sm font-medium mb-2">Text Skeleton</h3>
            <Skeleton variant="text" count={3} />
        </div>

        <div>
            <h3 className="text-sm font-medium mb-2">Button Skeleton</h3>
            <Skeleton variant="button" />
        </div>

        <div>
            <h3 className="text-sm font-medium mb-2">Circular Skeleton</h3>
            <Skeleton variant="circular" />
        </div>

        <div>
            <h3 className="text-sm font-medium mb-2">Card Skeleton</h3>
            <Skeleton variant="card" />
        </div>

        <div>
            <h3 className="text-sm font-medium mb-2">Custom Skeleton</h3>
            <Skeleton width={200} height={100} rounded />
        </div>
    </div>
);

export default Skeleton;