import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useBackground } from '../hooks/useBackground';

interface HeroSliderProps {
    theme: string;
    children?: React.ReactNode;
}

const HeroSlider: React.FC<HeroSliderProps> = ({ theme }) => {
    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const timerRef = useRef<NodeJS.Timeout>();
    const background = useBackground(theme);

    const slides = [
        {
            title: "Collaborez avec vos clients,\nréalisez leurs projets",
            subtitle: "Facilitez le suivis de projet",
            image: background
        },
        {
            title: "Simplifiez votre gestion de projet,\nboostez votre productivité",
            subtitle: "Un outil conçu pour les freelances et leurs clients",
            image: background
        },
        {
            title: "Organisez, collaborez,\nlivrez : tout en un seul endroit",
            subtitle: "Pilotez vos projets freelances en toute simplicité",
            image: background
        }
    ];

    const startTimer = useCallback(() => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }
        timerRef.current = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % slides.length);
        }, 4000);
    }, [slides.length]);

    useEffect(() => {
        startTimer();
        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [startTimer]);

    const handleDotClick = (index: number): void => {
        setCurrentSlide(index);
        startTimer();
    };

    return (
        <div className="w-3/5 p-4 relative h-screen hidden md:block">
            {slides.map((slide, index) => (
                <div
                    key={index}
                    className="absolute inset-0">
                    <div className="text-center relative h-full flex flex-col justify-start">
                        <div
                            key={index}
                            className={`transition-opacity duration-500 ease-in-out
                            ${currentSlide === index ? 'opacity-100' : 'opacity-0'}`}
                        >
                            <div className="mt-32 relative z-10 space-y-[.625rem]">
                                <p className="text-primary-theme text-[1.25rem]">{slide.subtitle}</p>
                                <h1 className="text-primary-theme text-[2.75rem] leading-[3.4375rem] font-medium mb-4 whitespace-pre-line">
                                    {slide.title}
                                </h1>
                            </div>
                        </div>
                        <div
                            className="absolute inset-0 w-full h-full flex items-end justify-center">
                            <img
                                src={background}
                                alt={`Slide ${index + 1}`}
                                className="w-full md:w-[80%] lg:w-full max-w-md md:max-w-xl lg:max-w-2xl object-contain"
                            />
                        </div>
                    </div>
                </div>
            ))}

            <div className="absolute bottom-24 left-1/2 transform -translate-x-1/2 flex gap-3 z-10">
                {slides.map((_, index) => (
                    <button
                        key={index}
                        onClick={() => handleDotClick(index)}
                        className={`transition-all duration-300 ease-out transform
                        ${currentSlide === index
                            ? 'w-[2.625rem] h-[.625rem] bg-theme'
                            : 'w-[.625rem] dark:bg-[rgba(35,_35,_35,_0.50)] bg-[rgba(255,_255,_255,_0.50)] hover:bg-white/30'}
                        h-[.625rem] rounded-full`}
                        aria-label={`Go to slide ${index + 1}`}
                    />
                ))}
            </div>
        </div>
    );
};

export default HeroSlider;