import React, { useState, useEffect, useRef } from 'react';
import {FiCheck, FiChevronDown, FiChevronUp} from 'react-icons/fi';  // Import des icônes Feather

export interface SelectOption {
    id: string;
    label: string;
    description?: string;
    icon?: React.ReactNode;
    disabled?: boolean;
}

interface InputSelectProps {
    options: SelectOption[];
    onSelect: (option: SelectOption) => void;
    selectedOption?: SelectOption;
    placeholder?: string;
    disabled?: boolean;
    error?: string;
}

const InputSelect: React.FC<InputSelectProps> = ({
     options,
     onSelect,
     selectedOption,
     placeholder = "Sélectionner",
     disabled = false,
     error,
 }) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    };

    const handleSelect = (option: SelectOption) => {
        if (!option.disabled) {
            onSelect(option);
            setIsOpen(false);
        }
    };

    return (
        <div ref={selectRef} className="relative w-full min-w-60">
            <button
                onClick={toggleDropdown}
                disabled={disabled}
                className={`w-full px-4 py-2 bg-theme rounded-lg border ${
                    error ? 'border-red-500' : 'border-theme'
                } flex items-center justify-between disabled:opacity-50 disabled:cursor-not-allowed focus:border-primary`}
                type="button"
            >
                <span className="text-primary-theme">
                    {selectedOption ? selectedOption.label : placeholder}
                </span>
                {isOpen ? (
                    <FiChevronUp className="w-5 h-5 text-primary-theme" />
                ) : (
                    <FiChevronDown className="w-5 h-5 text-primary-theme" />
                )}
            </button>

            {error && <p className="mt-1 text-sm text-red-500">{error}</p>}

            {isOpen && (
                <div className="absolute w-full mt-2 bg-theme rounded-lg border border-theme z-50 ">
                    {options.map((option) => (
                        <button
                            key={option.id}
                            onClick={() => handleSelect(option)}
                            disabled={option.disabled}
                            className={`w-full px-4 py-3 text-left hover:bg-theme-hover disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2 rounded-lg ${selectedOption?.id === option.id && 'bg-theme-focus'}`}
                            type="button"
                        >
                            {option.icon}
                            <div className="flex-1">
                                <div className="text-primary-theme">{option.label}</div>
                                {option.description && (
                                    <span className="text-sm text-primary-theme">
                                        {option.description}
                                    </span>
                                )}
                            </div>
                            {selectedOption?.id === option.id && (
                                <FiCheck />
                            )}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default InputSelect;