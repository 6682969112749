import { useEffect, useState } from 'react';
import { discoverMercure, connectToMercure } from '../api/mercureService';
import { useDispatch, useSelector } from 'react-redux';
import { receiveMercureMessage, addUnreadMessage } from '../features/messaging/messagingSlice';
import { selectUser } from '../features/auth/authSlice';

let globalEventSource: EventSource | null = null;
let isInitializing = false;

export const useMercure = (threadUuid?: string) => {
    const [topics, setTopics] = useState<string[] | null>(null);
    const dispatch = useDispatch();
    const currentUser = useSelector(selectUser);

    useEffect(() => {
        const initializeMercure = async () => {
            if (!currentUser || isInitializing || globalEventSource) return;

            isInitializing = true;
            try {
                const { topics } = await discoverMercure();
                setTopics(topics);

                globalEventSource = connectToMercure(topics, (parsedData) => {
                    if (!parsedData.thread || !parsedData.thread.uuid) return;

                    // Vérifier si c'est notre propre message
                    const isOwnMessage = parsedData.sender?.uuid === currentUser?.uuid;

                    // Vérifier si on est dans le thread du message
                    const isInCurrentThread = threadUuid === parsedData.thread.uuid;

                    // Ne traiter que les messages des autres
                    if (!isOwnMessage) {
                        // Toujours mettre à jour l'interface
                        dispatch(receiveMercureMessage({
                            threadId: parsedData.thread.uuid,
                            message: {
                                uuid: parsedData.uuid,
                                content: parsedData.body,
                                sender: {
                                    firstName: parsedData.sender.firstName,
                                    lastName: parsedData.sender.lastName
                                },
                                createdAt: parsedData.createdAt,
                                status: 'sent',
                                threadId: parsedData.thread.uuid,
                                nonce: parsedData.nonce,
                            }
                        }));

                        // N'ajouter de notification que si on n'est pas dans le thread
                        if (!isInCurrentThread) {
                            dispatch(addUnreadMessage(parsedData.thread.uuid));
                        }
                    }
                });

            } catch (error) {
                console.error('Erreur lors de la connexion à Mercure:', error);
            } finally {
                isInitializing = false;
            }
        };

        initializeMercure();

        return () => {
            // Ne pas fermer la connexion globale ici
        };
    }, [dispatch, threadUuid, currentUser]);

    return { topics, isConnected: !!globalEventSource };
};

export const closeMercureConnection = () => {
    if (globalEventSource) {
        globalEventSource.close();
        globalEventSource = null;
    }
};