import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FiMaximize2 } from 'react-icons/fi';
import { AppDispatch, RootState } from '../store/store';
import {
    fetchMessages,
    fetchThreadDetail,
    removeMessage,
    sendNewMessage,
    addTempMessage,
    updateMessageStatus
} from '../features/messaging/messagingSlice';
import { useMercure } from '../hooks/useMercure';
import { selectUser } from '../features/auth/authSlice';
import ThreadMessagesList from '../features/messaging/components/ThreadMessagesList';
import ThreadMessageInput from '../features/messaging/components/ThreadMessageInput';
import DropOverlay from '../features/messaging/components/DropOverlay';
import Button from "./UI/Button";
import {t} from "i18next";

interface MessageWidgetProps {
    threadId?: string;
    maxHeight?: string;
    projectUuid?: string;
    showInviteOverlay?: boolean;
    onInviteClick?: () => void;
    customer?: any;
}

const MessageWidget: React.FC<MessageWidgetProps> = ({
     threadId,
     maxHeight = "300px",
     projectUuid = "",
     customer,
     showInviteOverlay = false,
     onInviteClick
 }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [newMessageAdded, setNewMessageAdded] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const user = useSelector(selectUser);
    const messages = useSelector((state: RootState) =>
        threadId ? state.messaging.messages[threadId] || [] : []
    );

    useMercure(threadId);

    useEffect(() => {
        if (threadId) {
            dispatch(fetchThreadDetail(threadId));
            dispatch(fetchMessages(threadId));
        }
    }, [dispatch, threadId]);

    const handleNavigateToMessages = () => {
        if (threadId) {
            navigate(`/messaging/${threadId}`);
        }
    };

    const handleDeleteMessage = (messageId: string) => {
        if (threadId && messageId) {
            dispatch(removeMessage({ threadId, messageId }));
        }
    };

    const handleSendMessage = async (message: string) => {
        if (!message.trim() || !threadId) return;

        const nonce = (Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2)).substring(0, 20);

        const tempMessage = {
            content: message.trim(),
            sender: user,
            createdAt: new Date().toISOString(),
            status: 'pending',
            nonce,
        };

        dispatch(addTempMessage({ threadId, message: tempMessage }));
        setNewMessageAdded(true);

        try {
            const response = await dispatch(sendNewMessage({
                threadId,
                content: message.trim(),
                nonce
            })).unwrap();

            dispatch(updateMessageStatus({
                threadId,
                nonce: response.message.nonce,
                status: 'sent',
                updatedMessage: { ...response.message }
            }));
        } catch (error) {
            dispatch(updateMessageStatus({ threadId, nonce, status: 'failed' }));
        }
    };

    const handleSendDocument = async (documentUuid: string) => {
        if (!threadId) return;

        const nonce = (Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2)).substring(0, 20);

        const tempMessage = {
            content: '',
            sender: user,
            createdAt: new Date().toISOString(),
            status: 'pending',
            nonce,
            isDocument: true,
            documents: [documentUuid],
        };

        dispatch(addTempMessage({ threadId, message: tempMessage }));
    };

    return (
        <div className="bg-surface-theme rounded-lg flex flex-col h-full relative">
            <DropOverlay setFile={setFile} />

            {showInviteOverlay && (
                <>
                    <div className="absolute inset-0 bg-surface-theme z-20 flex flex-col items-center justify-center gap-4 rounded-lg">
                        <p className="text-center bg-surface-theme">
                            Votre client ne fait toujours pas partie du projet. Pour partager et échanger avec lui, vous devez l'inviter.
                        </p>
                        <div className="flex flex-col items-center justify-center gap-4 rounded-lg">
                            <Button
                                size="medium"
                                onClick={onInviteClick}
                            >
                                {t('project.invite')}
                            </Button>
                        </div>
                    </div>
                </>
            )}

            <div className="flex items-center justify-between p-4">
                <p className="text-primary-theme text-sm font-medium">{t('project.messaging')}</p>
                {threadId && (
                    <button
                        onClick={handleNavigateToMessages}
                        className="text-primary-theme hover:text-color-primary-hover text-sm font-bold cursor-pointer transition-colors"
                    >
                        <FiMaximize2 className="w-4 h-4" />
                    </button>
                )}
            </div>

            <div
                className="flex-1 overflow-y-auto flex flex-col-reverse"
                style={{ maxHeight }}
            >
                {threadId ? (
                    <ThreadMessagesList
                        messages={messages}
                        user={user}
                        handleDeleteMessage={handleDeleteMessage}
                        threadId={threadId}
                        newMessageAdded={newMessageAdded}
                        setNewMessageAdded={setNewMessageAdded}
                    />
                ) : (
                    <div className="flex-1 flex items-center justify-center text-gray-500">
                        Aucune conversation active
                    </div>
                )}
            </div>

            {threadId && !showInviteOverlay && (
                <div>
                    <ThreadMessageInput
                        onSendMessage={handleSendMessage}
                        onSendDocument={handleSendDocument}
                        file={file}
                        textareaRef={textareaRef}
                        fileInputRef={fileInputRef}
                        setFile={setFile}
                        projectUuid={projectUuid}
                        customer={customer}
                        lengthMessages={messages.length}
                    />
                </div>
            )}
        </div>
    );
};

export default MessageWidget;