import React, { useRef, useState } from 'react';
import {FiCalendar, FiFile, FiImage, FiLoader, FiMaximize2, FiPlus, FiUsers, FiX} from 'react-icons/fi';
import { formatDate } from '../../../utils/dateUtils';
import { formatFileSize } from '../../../utils/fileUtils';
import DragScrollContainer from "../../../components/DragScrollContainer";
import {useUserPermissions} from "../../../hooks/useUserPermissions";
import {useNavigate} from "react-router-dom";
import {t} from "i18next";
import { useDocuments } from "../../../hooks/useDocuments";
import Button from "../../../components/UI/Button";
import DocumentPreviewer from "../../../components/DocumentPreviewer";
import Modal from "../../../components/Modal";

const Documents: React.FC<{ project: any; }> = ({ project }) => {
    const { isCompanyProfile } = useUserPermissions();
    const navigate = useNavigate();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<File | null>(null);
    const [uploading, setUploading] = useState(false);
    const { uploadDocument, loading: documentLoading } = useDocuments({ projectUuid: project.uuid });

    const [selectedDocument, setSelectedDocument] = useState<{
        publicUrl: string;
        downloadUrl: string;
        mimeType: string;
        originalName: string;
    } | null>(null);

    const handleQuoteClick = (quoteUuid: string) => {
        navigate(`/projects/${project.uuid}/${quoteUuid}`);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        setFile(selectedFile || null);
    };

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleRemoveFile = () => {
        setFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleUploadDocument = async () => {
        if (!file) return;

        setUploading(true);
        try {
            await uploadDocument(file);
            setFile(null);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            // Ici, vous pourriez ajouter une logique pour rafraîchir la liste des documents
            // Par exemple, en appelant une fonction qui recharge les documents du projet
        } catch (error) {
            console.error('Erreur lors du téléchargement du document :', error);
        } finally {
            setUploading(false);
        }
    };

    const handleDocumentClick = (publicUrl: string, downloadUrl: string, mimeType: string, originalName: string) => {
        setSelectedDocument({ publicUrl, downloadUrl, mimeType, originalName });
    };

    const closePreview = () => {
        setSelectedDocument(null);
    };

    return (
        <div>
            <div className="bg-theme rounded-xl p-1 mb-5 drop-shadow-[0px_5px_10px_rgba(22,_19,_35,_0.02)]">
                <div className="flex py-2.5 px-4 mb-1">
                    <p className="text-primary-theme font-semibold text-base">{t('project.documents.attachments')}</p>
                </div>
                <DragScrollContainer className="flex gap-x-2 mt-2">
                    {Array.isArray(project.quotes) &&
                        !project.quotes.some((q: { status: string; }) =>
                            ['draft'].includes(q.status?.toLowerCase())
                        ) ? (
                            <>
                                {project.quotes && project.quotes.length > 0 ? (
                                    project.quotes.map((quote: any) => (
                                        <>
                                            <div
                                                key={quote.uuid}
                                                className="flex bg-surface-theme rounded-lg p-2 items-center cursor-pointer text-sm"
                                                onClick={() => handleQuoteClick(quote.uuid)}
                                            >
                                                <div className="flex items-center justify-center w-12 h-12 mr-2 bg-theme rounded">
                                                    <FiFile className="text-2xl text-primary-theme"/>
                                                </div>
                                                <div className="flex flex-col">
                                        <span className="text-sm truncate w-40 text-primary-theme">
                                            {quote.number ? (quote.number) : 'Devis Brouillon'}
                                        </span>
                                        <span className="text-xs text-secondary-theme">
                                            {quote.status}
                                        </span>
                                                </div>
                                            </div>
                                        </>
                                    ))
                                ) : (
                                    <p className="text-primary-theme text-sm px-4">{t('project.documents.noAttachments')}</p>
                                )}
                            </>
                        ) : isCompanyProfile ? (
                            <>
                                {project.quotes && project.quotes.length > 0 ? (
                                    project.quotes
                                        .filter((quote: any) =>
                                            ['draft', 'validated', 'signed'].includes(quote.status?.toLowerCase())
                                        )
                                        .map((quote: any) => (
                                            <div
                                                key={quote.uuid}
                                                className="flex bg-surface-theme rounded-lg p-2 items-center cursor-pointer text-sm"
                                                onClick={() => handleQuoteClick(quote.uuid)}
                                            >
                                                <div className="flex items-center justify-center w-12 h-12 mr-2 bg-theme rounded">
                                                    <FiFile className="text-2xl text-primary-theme" />
                                                </div>
                                                <div className="flex flex-col">
                                                    <span className="text-sm truncate w-40 text-primary-theme">
                                                        {quote.number ? (quote.number) : 'Devis Brouillon'}
                                                    </span>
                                                    <span className="text-xs text-secondary-theme">
                                                        {quote.status}
                                                    </span>
                                                </div>
                                            </div>
                                        ))
                                ) : (
                                    <p className="text-primary-theme text-sm px-4">
                                        {t('project.documents.noAttachments')}
                                    </p>
                                )}

                            </>
                    ) : (
                        <p className="text-primary-theme text-sm px-4">{t('project.documents.noAttachments')}</p>
                    )}
                </DragScrollContainer>
            </div>

            <div className="bg-theme rounded-xl p-1 mb-5 drop-shadow-[0px_5px_10px_rgba(22,_19,_35,_0.02)]">
                <div className="flex items-center justify-between py-2.5 px-4 mb-1">
                    <p className="text-primary-theme font-semibold text-base">{t('project.documents.documents')}</p>
                    {isCompanyProfile && (
                        <button
                            className="text-primary-theme hover:text-color-primary-hover cursor-pointer"
                            onClick={handleUploadClick}
                        >
                            <FiPlus/>
                        </button>
                    )}
                    <input
                        type="file"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        className="hidden"
                    />
                </div>

                {file && (
                    <div className="px-4 py-2">
                        <div className="relative inline-flex items-center p-2 mb-2 bg-surface-theme border border-theme rounded max-w-max">
                            <div className="flex items-center justify-center w-12 h-12 mr-2 bg-theme rounded">
                                {file.type.startsWith('image/') ? (
                                    <FiImage className="text-2xl text-primary-theme"/>
                                ) : (
                                    <FiFile className="text-2xl text-primary-theme"/>
                                )}
                            </div>
                            <div className="flex flex-col">
                                <span className="text-sm truncate w-40 text-primary-theme">
                                    {file.name}
                                </span>
                                <span className="text-xs text-secondary-theme">
                                    {formatFileSize(file.size)}
                                </span>
                            </div>
                            <div className="flex ml-3">
                                <button
                                    type="button"
                                    disabled={uploading}
                                    className="ml-2 p-1.5 bg-color-primary rounded-full text-white"
                                    onClick={handleUploadDocument}
                                >
                                    {uploading ? <FiLoader className="animate-spin" /> : <FiPlus />}
                                </button>
                                <button
                                    type="button"
                                    className="ml-2 p-1.5 bg-red-500 rounded-full text-white"
                                    onClick={handleRemoveFile}
                                >
                                    <FiX />
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                <div className="flex flex-col gap-y-2">
                    {project.documents && project.documents.length > 0 ? (
                        project.documents.map((document: any) => {
                                return (
                                    <div
                                        key={document.uuid}
                                        className="flex bg-surface-theme rounded-lg p-2.5 items-center cursor-pointer"
                                        onClick={() =>
                                            handleDocumentClick(
                                                document.publicUrl,
                                                document.downloadUrl,
                                                document.mimeType,
                                                document.originalName
                                            )
                                        }
                                    >
                                        <div className="text-xl mr-4 bg-theme p-3 rounded-md text-primary-theme">
                                            {document.mimeType === 'application/pdf' ? <FiFile /> : <FiImage />}
                                        </div>
                                        <div className="w-full">
                                            <p className="font-medium w-48 truncate text-primary-theme text-sm">
                                                {document.originalName}
                                            </p>
                                            <span className="text-xs text-primary-theme">{formatDate(document.createdAt)} </span>
                                            <span className="text-xs text-secondary-theme">{formatFileSize(document.size)}</span>
                                        </div>
                                    </div>
                                );
                            }
                        )
                    ) : (
                        <p className="text-primary-theme text-sm px-4">{t('project.documents.first')}</p>
                    )}
                </div>
            </div>

            <div className="bg-theme rounded-xl p-1 drop-shadow-[0px_5px_10px_rgba(22,_19,_35,_0.02)]">
                <div className="flex items-center justify-between py-2.5 px-4 mb-1">
                    <p className="text-primary-theme font-semibold text-base">{t('project.documents.archives')}</p>
                </div>
                <div className="flex flex-col gap-y-2">
                    {project.documents && project.documents.length > 0 ? (
                        project.documents.map((document: any) => {
                                return (
                                    <div
                                        key={document.uuid}
                                        className="flex bg-surface-theme rounded-lg p-2.5 items-center cursor-pointer"
                                    >
                                        <div className="text-xl mr-4 bg-theme p-3 rounded-md text-primary-theme">
                                            {document.mimeType === 'application/pdf' ? <FiFile/> : <FiImage/>}
                                        </div>
                                        <div className="w-full">
                                            <p className="font-medium w-48 truncate text-primary-theme text-sm">
                                                {document.originalName}
                                            </p>
                                            <span className="text-xs text-primary-theme">
                                                        {formatDate(document.createdAt)}{' '}
                                                    </span>
                                            <span
                                                className="text-xs text-secondary-theme">{formatFileSize(document.size)}</span>
                                        </div>
                                    </div>
                                );
                            }
                        )
                    ) : (
                        <p className="text-primary-theme text-sm px-4">{t('project.documents.noArchives')}</p>
                    )}
                </div>
            </div>

            {selectedDocument && (
                <Modal show={true} onClose={closePreview} title={selectedDocument.originalName}>
                    <div className="flex justify-end mb-4">
                        <a
                            href={selectedDocument.downloadUrl}
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-color-primary text-white text-sm px-4 py-2 rounded hover:bg-color-primary-hover transition"
                        >
                            Télécharger
                        </a>
                    </div>

                    <DocumentPreviewer
                        url={selectedDocument.publicUrl}
                        mimeType={selectedDocument.mimeType}
                    />
                </Modal>
            )}
        </div>
    );
};

export default Documents;