import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import Skeleton from '../components/Skeleton';
import { useTranslation } from 'react-i18next';
import { useCompany } from '../hooks/useCompany';
import { useProjects } from '../hooks/useProjects';
import { useCustomers } from '../hooks/useCustomers'; // Importer le hook personnalisé
import { selectLoading, selectUser } from '../features/auth/authSlice';
import { formatPrice } from '../utils/formatPrice';
import {ImageDisplay} from "../components/ImageDisplay";
import {useUserPermissions} from "../hooks/useUserPermissions";
import {useMercure} from "../hooks/useMercure";
import { FaDiscord } from "react-icons/fa";
import Modal from "../components/Modal";
import Button from "../components/UI/Button";

const DashboardPage: React.FC = () => {
    const { t } = useTranslation();
    const user = useSelector(selectUser);

    useMercure();

    const userLoading = useSelector(selectLoading);

    const { company, loading: companyLoading, error: companyError } = useCompany(user?.company?.uuid);

    const {
        projects,
        selectedProject,
        loading: projectsLoading,
        error: projectsError,
    } = useProjects();

    // Utilisation du hook `useCustomers`
    const {
        customers,
        selectedCustomer,
        loading: customersLoading,
        error: customersError,
    } = useCustomers();

    // Combiner tous les états de chargement
    const isLoading = userLoading || companyLoading || projectsLoading || customersLoading;

    // Calcul du prix total de tous les projets
    const totalProfits = projects.reduce((total, project) => total + (project.amount || 0), 0);

    const { isCompanyProfile } = useUserPermissions();

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const hasSeenModal = localStorage.getItem('hasSeenBrickslyBetaModal');
        if (!hasSeenModal) {
            setShowModal(true);
        }
    }, []);

    const handleCloseModal = () => {
        setShowModal(false);
        localStorage.setItem('hasSeenBrickslyBetaModal', 'true'); // Stocke l'info pour ne plus afficher le modal
    };

    return (
        <div className="flex mb-8 md:m-0 md:pl-6 p-4">
            <div className="flex-1">
                {isLoading ? (
                    <>

                        <h2 className="text-primary-theme text-lg md:text-xl font-medium mb-4 flex items-center ease-in-out">
                            <Skeleton variant="text" width="15%" height="28px" count={1}/>
                        </h2>
                        <h2 className="text-primary-theme text-lg md:text-xl font-medium mb-4 ease-in-out">
                            <Skeleton variant="text" width="11%" height="28px" count={1}/>
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            <div className="p-4 bg-theme border-theme border-2 border-secondaryBgColor rounded">
                                <h3 className="text-primary-theme text-base md:text-lg font-medium mb-2">
                                    <Skeleton variant="text" width="15%" height="28px" count={1}/>
                                </h3>
                                <Skeleton variant="text" width="37%" height="24px" count={1}/>
                            </div>
                            <div className="p-4 bg-theme border-theme border-2 border-secondaryBgColor rounded">
                                <h3 className="text-primary-theme text-base md:text-lg font-medium mb-2">
                                    <Skeleton variant="text" width="15%" height="28px" count={1}/>
                                </h3>
                                <Skeleton variant="text" width="46%" height="24px" count={1}/>
                            </div>
                            <div className="p-4 bg-theme border-theme border-2 border-secondaryBgColor rounded">
                                <h3 className="text-primary-theme text-base md:text-lg font-medium mb-2">
                                    <Skeleton variant="text" width="15%" height="28px" count={1}/>
                                </h3>
                                <Skeleton variant="text" width="29%" height="24px" count={1}/>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <h2 className="text-primary-theme text-lg md:text-xl font-medium mb-4 flex items-center ease-in-out">
                            {(() => {
                                const currentHour = new Date().getHours();
                                const greeting =
                                    currentHour >= 18
                                        ? t('dashboard.goodEvening')
                                        : t('dashboard.hello');
                                return `${greeting}, ${user?.firstName}`;
                            })()}
                        </h2>

                        <h2 className="text-primary-theme text-lg md:text-xl font-medium mb-4 ease-in-out">
                            {t('dashboard.statistics')}
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {isCompanyProfile && (
                                <div className="p-4 bg-theme border-theme border-2 border-secondaryBgColor rounded">
                                    <h3 className="text-primary-theme text-base md:text-lg font-medium">
                                        {t('dashboard.profits')}
                                    </h3>
                                    <p className="text-primary-theme text-sm md:text-base">
                                        € {formatPrice(totalProfits)}
                                    </p>
                                </div>
                            )}
                            <div className="p-4 bg-theme border-theme border-2 border-secondaryBgColor rounded">
                                <h3 className="text-primary-theme text-base md:text-lg font-medium">
                                    {t('dashboard.projects')}
                                </h3>
                                <p className="text-primary-theme text-sm md:text-base">
                                    {projects.length}
                                </p>
                            </div>
                            {isCompanyProfile && (
                                <div className="p-4 bg-theme border-theme border-2 border-secondaryBgColor rounded">
                                    <h3 className="text-primary-theme text-base md:text-lg font-medium">
                                        {t('dashboard.customers')}
                                    </h3>
                                    <p className="text-primary-theme text-sm md:text-base">
                                        {customers.length}
                                    </p>
                                </div>
                            )}
                        </div>

                        <h2 className="text-primary-theme text-lg md:text-xl font-medium my-4 ease-in-out">
                            {t('dashboard.discord')}
                        </h2>

                        <div className="p-4 bg-[linear-gradient(91deg,_#81A1E7_0.42%,_#7877E2_50.62%,_#364096_145.09%)] rounded text-white">
                            <h3 className="text-white text-base md:text-lg font-medium mb-2">
                                {t('dashboard.discordCommunity')}
                            </h3>
                            <p className="text-white mb-4 text-sm md:text-base">
                                {t('dashboard.discordDescription')}
                            </p>
                            <a
                                href="https://discord.gg/58NhTSUEtp"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="inline-flex items-center px-4 md:px-5 py-2 md:py-3 bg-[#5865F2] hover:bg-[#5865F2]/50 text-theme rounded transition-colors text-sm md:text-base"
                            >
                                <FaDiscord className="mr-2"/>
                                {t('dashboard.joinDiscord')}
                            </a>
                        </div>
                    </>
                )}
            </div>
            <Modal show={showModal} onClose={handleCloseModal} hideCloseButton={true}>
                <p className="text-xl font-semibold text-primary-theme text-center mt-4 mb-6">{t('dashboard.welcomeBricksly')}</p>

                <p className="mb-4 text-sm text-primary-theme">{t('dashboard.brickslyDescription')}</p>

                <p className="mb-4 text-sm text-primary-theme">{t('dashboard.betaDescription')}</p>

                <p className="mb-6 text-sm text-primary-theme">{t('dashboard.feedbackRequest')}</p>
                <div className="text-center flex flex-col items-center">
                    <div>
                        <Button
                            size="medium"
                            onClick={handleCloseModal}
                        >
                            {t('dashboard.understood')}
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default DashboardPage;