import axiosInstance from './apiService';

export interface ImageUploadResponse {
    uuid: string;
    url: string;
    createdAt: string;
}

export const uploadImage = (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    return axiosInstance.post('/v1/images', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(response => response.data)
        .catch(error => {
            console.error('Erreur lors du téléchargement de l\'image:', error);
            throw error;
        });
};

export const deleteImage = (uuid: string) => {
    return axiosInstance.delete(`/v1/images/${uuid}`)
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la suppression de l\'image:', error);
            throw error;
        });
};

export const getImage = (uuid: string) => {
    return axiosInstance.get(`/v1/images/${uuid}`)
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la récupération de l\'image:', error);
            throw error;
        });
};

export const getAllImages = () => {
    return axiosInstance.get('/v1/images')
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la récupération des images:', error);
            throw error;
        });
};

export const getImageDownloadUrl = (uuid: string) => {
    return `/v1/images/${uuid}/download`;
};