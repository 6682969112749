// hooks/useMissions.ts

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../store/hooks';
import {
    fetchMissionsByProjectId,
    updateMissionStatusThunk,
    startMissionThunk,
    finishMissionThunk,
    updateMission,
    deleteMission,
    createMissionThunk,
    selectMissionsByProjectId,
    selectMissionsLoading,
    selectMissionsError,
    selectHasFetchedMissionsForProject,
    updateMissionsPositionsThunk,
} from '../features/project/missionSlice';
import {
    Mission,
    CreateMissionDTO,
    UpdateMissionDTO,
    UpdatePositionDTO
} from '../features/project/types';
import { RootState } from '../store/store';

interface UseMissionsReturn {
    missions: Mission[];
    loading: boolean;
    error: string | null;
    createMission: (missionData: CreateMissionDTO, tempUuid?: string) => Promise<Mission>;
    updateMissionStatus: (missionUuid: string, status: string) => Promise<void>;
    startMission: (missionUuid: string) => Promise<void>;
    finishMission: (missionUuid: string) => Promise<void>;
    modifyMission: (missionUuid: string, missionData: UpdateMissionDTO) => Promise<void>;
    removeMission: (missionUuid: string) => Promise<void>;
    updateMissionsPositions: (positions: UpdatePositionDTO[]) => Promise<void>;
}


export const useMissions = (projectUuid?: string): UseMissionsReturn => {
    const dispatch = useAppDispatch();

    const missions = useSelector((state: RootState) =>
        projectUuid ? selectMissionsByProjectId(state, projectUuid) : []
    );

    const missionsLoading = useSelector(selectMissionsLoading);
    const missionsError = useSelector(selectMissionsError);
    const hasFetchedMissions = useSelector((state: RootState) =>
        projectUuid ? selectHasFetchedMissionsForProject(state, projectUuid) : false
    );

    useEffect(() => {
        if (projectUuid && !hasFetchedMissions && !missionsLoading) {
            dispatch(fetchMissionsByProjectId(projectUuid));
        }
    }, [projectUuid, hasFetchedMissions, missionsLoading, dispatch]);

    const createMission = async (missionData: CreateMissionDTO, tempUuid?: string): Promise<Mission> => {
        const uuid = tempUuid || projectUuid;
        if (!uuid) {
            throw new Error('Project UUID is required to create a mission');
        }
        try {
            const result = await dispatch(createMissionThunk({
                projectUuid: uuid,
                missionData: {
                    ...missionData,
                    position: missionData.position || 0
                }
            })).unwrap();
            return result.mission;
        } catch (error) {
            console.error('Failed to create mission:', error);
            throw error;
        }
    };

    const modifyMission = async (
        missionUuid: string,
        missionData: UpdateMissionDTO
    ): Promise<void> => {
        if (!projectUuid) {
            throw new Error('Project UUID is required to update a mission');
        }
        try {
            await dispatch(updateMission({
                projectUuid,
                missionUuid,
                missionData
            })).unwrap();
        } catch (error) {
            console.error('Failed to update mission:', error);
            throw error;
        }
    };

    const updateMissionStatus = async (missionUuid: string, status: string): Promise<void> => {
        if (!projectUuid) {
            throw new Error('Project UUID is required to update mission status');
        }
        try {
            await dispatch(updateMissionStatusThunk({
                projectUuid,
                missionUuid,
                status
            })).unwrap();
        } catch (error) {
            console.error('Failed to update mission status:', error);
            throw error;
        }
    };

    const removeMission = async (missionUuid: string): Promise<void> => {
        if (!projectUuid) {
            throw new Error('Project UUID is required to delete a mission');
        }
        try {
            await dispatch(deleteMission({
                projectUuid,
                missionUuid
            })).unwrap();
        } catch (error) {
            console.error('Failed to delete mission:', error);
            throw error;
        }
    };

    const updateMissionsPositions = async (positions: UpdatePositionDTO[]): Promise<void> => {
        if (!projectUuid) {
            throw new Error('Project UUID is required to update mission positions');
        }

        try {
            // Avant d'envoyer l'action, créez une sauvegarde des missions actuelles
            const currentMissions = [...missions];

            // Mise à jour des positions en local avant d'envoyer au serveur
            const updatedMissions = currentMissions.map(mission => {
                const positionEntry = positions.find(p => p.uuid === mission.uuid);
                if (positionEntry) {
                    return { ...mission, position: positionEntry.position };
                }
                return mission;
            });

            // Envoi au serveur pour mise à jour des positions
            await dispatch(updateMissionsPositionsThunk({
                projectUuid,
                positions
            })).unwrap();

        } catch (error) {
            console.error('Failed to update mission positions:', error);
            throw error;
        }
    };

    const startMission = async (missionUuid: string): Promise<void> => {
        if (!projectUuid) {
            throw new Error('Project UUID is required to start a mission');
        }
        try {
            await dispatch(startMissionThunk({
                projectUuid,
                missionUuid
            })).unwrap();
        } catch (error) {
            console.error('Failed to start mission:', error);
            throw error;
        }
    };

    const finishMission = async (missionUuid: string): Promise<void> => {
        if (!projectUuid) {
            throw new Error('Project UUID is required to finish a mission');
        }
        try {
            await dispatch(finishMissionThunk({
                projectUuid,
                missionUuid
            })).unwrap();
        } catch (error) {
            console.error('Failed to finish mission:', error);
            throw error;
        }
    };

    return {
        missions,
        loading: missionsLoading,
        error: missionsError,
        createMission,
        updateMissionStatus,
        startMission,
        finishMission,
        modifyMission,
        removeMission,
        updateMissionsPositions,
    };
};