import React, { useState, useRef, useEffect } from 'react';
import { FiEye, FiEyeOff, FiInfo, FiCheck, FiX } from "react-icons/fi";
import { t } from "i18next";

interface InputPasswordProps {
    label: string;
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
    errorMessage?: string;
    placeholder?: string;
    required?: boolean;
    isConfirmation?: boolean;
    passwordToMatch?: string;
    showTooltip?: boolean;
    hasRequirements?: boolean; // Nouvelle prop
}

const InputPassword: React.FC<InputPasswordProps> = ({
     label,
     name,
     value,
     onChange,
     onBlur,
     errorMessage: propErrorMessage,
     placeholder,
     required = false,
     isConfirmation = false,
     passwordToMatch,
     showTooltip = true,
     hasRequirements = false // Valeur par défaut
 }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showTooltipState, setShowTooltipState] = useState(false);
    const [hasBlurred, setHasBlurred] = useState(false);
    const [localErrorMessage, setLocalErrorMessage] = useState(propErrorMessage);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const validatePassword = (password: string) => {
        if (isConfirmation && passwordToMatch) {
            return password === passwordToMatch;
        }

        if (!hasRequirements) {
            return true;
        }

        const requirements = [
            { condition: password.length >= 8, message: "8 caractères minimum" },
            { condition: /[A-Z]/.test(password), message: "1 majuscule" },
            { condition: /[a-z]/.test(password), message: "1 minuscule" },
            { condition: /\d/.test(password), message: "1 chiffre" },
            { condition: /[!@#$%^&*(),.?":{}|<>]/.test(password), message: "1 caractère spécial" }
        ];

        const failedRequirements = requirements.filter(req => !req.condition);
        return failedRequirements.length === 0;
    };

    useEffect(() => {
        if (!value) {
            setLocalErrorMessage(undefined);
            setHasBlurred(false);
        } else if (isConfirmation && passwordToMatch && hasBlurred) {
            setLocalErrorMessage(value !== passwordToMatch ? t('inputError.password.notMatching') : undefined);
        } else if (hasBlurred && !validatePassword(value) && hasRequirements) {
            setLocalErrorMessage(t('inputError.password.require'));
        } else {
            setLocalErrorMessage(propErrorMessage);
        }
    }, [value, propErrorMessage, passwordToMatch, hasBlurred, isConfirmation, hasRequirements]);

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    const requirements = (!isConfirmation && hasRequirements) ? [
        { text: "8 caractères minimum", valid: value.length >= 8 },
        { text: "1 majuscule", valid: /[A-Z]/.test(value) },
        { text: "1 minuscule", valid: /[a-z]/.test(value) },
        { text: "1 chiffre", valid: /\d/.test(value) },
        { text: "1 caractère spécial", valid: /[!@#$%^&*(),.?":{}|<>]/.test(value) }
    ] : [];

    const allValid = requirements.length > 0 && requirements.every(req => req.valid);
    const hasInvalid = requirements.length > 0 && requirements.some(req => !req.valid);

    const shouldShowValidation = value === '' ? false : hasBlurred;
    const statusColor = !value ? 'text-theme' :
        (shouldShowValidation ? (allValid ? 'text-primary' : 'text-red-500') : 'text-theme');

    const handleMouseEnter = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        setShowTooltipState(true);
    };

    const handleMouseLeave = () => {
        timeoutRef.current = setTimeout(() => {
            setShowTooltipState(false);
        }, 300);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setHasBlurred(true);
        onBlur && onBlur();
    };

    return (
        <div>
            <div className="flex justify-between items-center mb-[5px]">
                <label className="block text-primary-theme text-xs font-normal">
                    {label}
                </label>
                <div className="flex items-center space-x-2">
                    {localErrorMessage && (
                        <p className="text-red-500 text-xs">{localErrorMessage}</p>
                    )}
                    {(!isConfirmation && showTooltip && hasRequirements) && (
                        <div className="relative inline-block">
                            <div
                                className="flex items-center space-x-2"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                <FiInfo className={`w-4 h-4 cursor-pointer ${statusColor}`} />
                            </div>

                            {showTooltipState && (
                                <div
                                    className="absolute right-0 mt-2 p-4 bg-theme rounded-lg shadow-lg border border-theme z-50 w-64"
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <div className="space-y-2">
                                        <p className="text-xs font-medium text-theme">Votre mot de passe doit comporter : </p>
                                        {requirements.map((requirement, index) => (
                                            <div
                                                key={index}
                                                className={`text-xs font-normal flex items-center space-x-2 ${
                                                    shouldShowValidation
                                                        ? (requirement.valid ? 'text-primary' : 'text-red-500')
                                                        : 'text-theme'
                                                }`}
                                            >
                                                {shouldShowValidation ? (
                                                    requirement.valid ?
                                                        <FiCheck className="w-4 h-4" /> :
                                                        <FiX className="w-4 h-4" />
                                                ) : (
                                                    <div className="w-0 h-0 flex-shrink-0" />
                                                )}
                                                <span>{requirement.text}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="relative">
                <input
                    type={showPassword ? 'text' : 'password'}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={handleBlur}
                    placeholder={placeholder}
                    className={`appearance-none text-sm block w-full border border-theme bg-theme text-primary-theme rounded-lg px-5 py-3 leading-tight focus:outline-none ${
                        localErrorMessage || (shouldShowValidation && hasInvalid)
                            ? 'border-red-500 focus:border-red-500'
                            : 'border-theme focus:ring-1 focus:ring-primary'
                    }`}
                    required={required}
                />
                <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 flex items-center px-4 text-sm text-primary-theme focus:outline-none"
                >
                    {showPassword ? <FiEyeOff /> : <FiEye />}
                </button>
            </div>
        </div>
    );
};

export default InputPassword;