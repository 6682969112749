import React, { useEffect, useState } from 'react';
import ProfileCard from "../../../components/ProfileCard";

interface ThreadMessageItemProps {
    message: any;  // Type à affiner
    user: any;     // Type à affiner
    previousMessage?: any; // Message précédent pour la comparaison
    handleDeleteMessage: (messageId: string) => void;
    isLastMessage: boolean;
}

const ThreadMessageItem: React.FC<ThreadMessageItemProps> = ({
                                                                 message,
                                                                 user,
                                                                 previousMessage,
                                                                 handleDeleteMessage,
                                                                 isLastMessage
                                                             }) => {
    const [showStatus, setShowStatus] = useState(false);

    useEffect(() => {
        if (message.status === 'pending' ||
            message.status === 'sent' ||
            message.status === 'failed'
        ) {
            setShowStatus(true);
            const timer = setTimeout(() => {
                setShowStatus(false);
            }, 10000);

            return () => clearTimeout(timer);
        }
    }, [message.status]);

    const getMessageStyle = () => {
        if (message.status === 'pending') {
            return {
                opacity: 0.5,
                transition: 'opacity 0.3s ease-in-out'
            };
        }

        if (message.isSystem) {
            return {
                opacity: 0.8,
                fontStyle: 'italic'
            };
        }

        return {
            opacity: 1,
            transition: 'opacity 0.3s ease-in-out'
        };
    };

    const getMessageContainerClass = () => {
        if (message.isDocument || message.isSystem) {
            return "text-center mx-auto my-2";
        }
        const alignment = message.sender?.uuid === user?.uuid
            ? "items-end"
            : "items-start";
        return `flex flex-col ${alignment}`;
    };

    const hasLineBreaks = (content: string) => {
        return content.includes('\n');
    };

    const renderMessageContent = (content: string) => {
        if (!content) return null;

        if (hasLineBreaks(content)) {
            return content.split('\n').map((line, index, array) => (
                <React.Fragment key={index}>
                    {line}
                    {index < array.length - 1 && <br />}
                </React.Fragment>
            ));
        }

        return content;
    };

    const renderFailedMessage = () => {
        if (message.sender?.uuid === user?.uuid && showStatus && message.status === 'failed') {
            return (
                <span
                    className="text-sm text-red-500"
                    style={{
                        position: 'absolute',
                        right: '0',
                        bottom: '-25px',
                        whiteSpace: 'nowrap',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        padding: '2px 6px',
                        borderRadius: '4px',
                        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                    }}
                >
                    Échec de l'envoi
                </span>
            );
        }
        return null;
    };

    const renderDeleteButton = () => {
        if (message.sender?.uuid === user?.uuid && message.status === 'failed') {
            return (
                <button
                    onClick={() => handleDeleteMessage(message.uuid || '')}
                    className="mt-1 text-red-500"
                >
                    Supprimer
                </button>
            );
        }
        return null;
    };

    const shouldShowSenderName = () => {
        if (!previousMessage) return true;

        const currentMessageTime = new Date(message.createdAt);
        const previousMessageTime = new Date(previousMessage.createdAt);

        // Vérifie si les messages sont du même expéditeur
        const isSameSender = message.sender?.uuid === previousMessage.sender?.uuid;

        // Vérifie si les messages sont dans la même minute
        const isSameMinute = currentMessageTime.getTime() - previousMessageTime.getTime() < 60000;

        return !isSameSender || !isSameMinute;
    };

    const getSenderName = () => {
        return message.sender?.uuid === user?.uuid
            ? "Vous"
            : `${message.sender?.firstName} ${message.sender?.lastName}`;
    };

    const content = message.content || message.body;

    return (
        <div
            className={getMessageContainerClass()}
            style={{ position: 'relative' }}
        >
            {message.isSystem ? (
                <p className="text-sm text-primary-theme/70" style={getMessageStyle()}>
                    {renderMessageContent(content)}
                </p>
            ) : !message.isDocument ? (
                <>
                    {message.sender?.uuid !== user?.uuid && shouldShowSenderName() ? (
                        <>
                            <div className="flex items-start mt-4 ">
                                <ProfileCard
                                    imageUrl=""
                                    firstName={message.sender?.firstName}
                                    lastName={message.sender?.lastName}
                                    hideText={true}
                                />
                                <div className="max-w-[70%] ml-4 inline-block overflow-hidden">
                                    {shouldShowSenderName() && (
                                        <p className="text-left mb-1 text-primary-theme text-xs">
                                            {getSenderName()}
                                        </p>
                                    )}

                                    <div
                                        className={`bubble p-2 ${
                                            message.sender?.uuid === user?.uuid
                                                ? "bg-primary text-white rounded-l-lg"
                                                : "bg-primary/10 dark:bg-primary/30 text-primary-theme rounded-r-lg"
                                        } relative inline-block ${hasLineBreaks(content) ? 'whitespace-pre-wrap' : 'word-break break-all whitespace-normal'}`}
                                        style={getMessageStyle()}
                                    >
                                        <p className="text-sm">
                                            {renderMessageContent(content)}
                                        </p>
                                        {renderFailedMessage()}
                                    </div>

                                    {renderDeleteButton()}
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className={`max-w-[70%] ${message.sender?.uuid !== user?.uuid ? 'ml-14 mt-2' : ' mt-2'} inline-block overflow-hidden`}>
                            {shouldShowSenderName() && (
                                <p className={`text-left mt-4 mb-1 text-primary-theme text-xs`}>
                                    {getSenderName()}
                                </p>
                            )}
                            <div
                                className={`bubble p-2 ${
                                    message.sender?.uuid === user?.uuid
                                        ? "bg-primary text-white rounded-l-lg"
                                        : "bg-primary/10 dark:bg-primary/30 text-primary-theme rounded-r-lg"
                                } relative inline-block ${hasLineBreaks(content) ? 'whitespace-pre-wrap' : 'word-break break-all whitespace-normal'}`}
                                style={getMessageStyle()}
                            >
                                <p className="text-sm break-keep hyphens-none">
                                    {renderMessageContent(content)}
                                </p>
                                {renderFailedMessage()}
                            </div>

                            {renderDeleteButton()}
                        </div>
                    )}
                </>
            ) : (
                <p>{message.content}</p>
            )}
        </div>
    );
};

export default ThreadMessageItem;