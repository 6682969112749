import React, { useEffect } from 'react';

interface ModalProps {
    show: boolean;
    onClose: () => void;
    title?: string; // Rend le titre optionnel
    children: React.ReactNode;
    hideCloseButton?: boolean; // Nouvelle prop optionnelle
}

export const Modal: React.FC<ModalProps> = ({ show, onClose, title, children, hideCloseButton }) => {
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                onClose();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose]);

    if (!show) return null;

    return (
        <div
            className="fixed inset-0 bg-theme/10 backdrop-blur-sm flex justify-center items-center z-50 p-4"
            onClick={onClose}
        >
            <div
                className="bg-surface-theme border-theme border-2 rounded-lg shadow-xl p-6 w-full max-w-md sm:max-w-lg md:max-w-xl lg:max-w-2xl max-h-full overflow-y-auto"
                onClick={(e) => e.stopPropagation()}
            >
                {/* Rendre le titre optionnel */}
                {(title || !hideCloseButton) && (
                    <div className="flex justify-between items-center mb-4">
                        {title && <h2 className="text-xl font-semibold text-primary-theme">{title}</h2>}
                        {!hideCloseButton && (
                            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                                &times;
                            </button>
                        )}
                    </div>
                )}
                <div>{children}</div>
            </div>
        </div>
    );
};

export default Modal;