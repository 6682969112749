import React from 'react';
import {FiArrowLeft} from "react-icons/fi";
import {useNavigate} from "react-router-dom";

const LegalNoticePage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div>
            <div className="prose max-w-4xl mx-auto p-6">
                <div className="flex items-center justify-between mb-8">
                    <button
                        onClick={() => navigate(-1)}
                        className="flex items-center gap-2 text-sm text-primary-theme py-2 rounded-lg transition"
                    >
                        <FiArrowLeft className="text-lg" />
                        Retour
                    </button>
                    <h1 className="text-center text-lg sm:text-2xl font-semibold mx-auto w-full left-0 right-0 pointer-events-none">
                        Mentions légales
                    </h1>
                </div>

                <div className="mb-6">
                    <h2 className="text-lg sm:text-xl font-semibold mb-2">1. Éditeurs du site</h2>
                    <p>Le site Bricksly est édité conjointement par deux micro-entrepreneurs :</p>
                    <ul className="list-disc list-inside">
                        <li>Damien HEBERT – SIRET : 898 661 327 00012</li>
                        <li>Matteo MICHEL – SIRET : 925 143 380 00010</li>
                    </ul>
                    <p>Ces deux éditeurs collaborent dans le cadre du développement et de la gestion de la plateforme Bricksly.</p>
                </div>

                <div className="mb-6">
                    <h2 className="text-lg sm:text-xl font-semibold mb-2">2. Hébergement</h2>
                    <p>La plateforme Bricksly est hébergée par :</p>
                    <p>Dyjix — <a href="https://dyjix.eu/" className="text-primary underline">https://dyjix.eu/</a></p>
                    <p>149 avenue du Maine 75014 Paris, FRANCE</p>
                </div>

                <div className="mb-6">
                    <h2 className="text-lg sm:text-xl font-semibold mb-2">3. Contact</h2>
                    <p>Pour toute question ou réclamation, vous pouvez nous contacter à l’adresse suivante :</p>
                    <p><a href="mailto:contact@bricksly.fr" className="text-primary underline">contact@bricksly.fr</a></p>
                </div>

                <div className="mb-6">
                    <h2 className="text-lg sm:text-xl font-semibold mb-2">4. Propriété intellectuelle</h2>
                    <p>L’ensemble des contenus présents sur la plateforme Bricksly (textes, images, graphismes, logo, code, etc.) est la propriété exclusive de leurs auteurs respectifs, sauf mention contraire. Toute reproduction, représentation ou exploitation, partielle ou totale, sans autorisation écrite est interdite.</p>
                </div>

                <div className="mb-6">
                    <h2 className="text-lg sm:text-xl font-semibold mb-2">5. Responsabilité</h2>
                    <p>Les éditeurs de Bricksly s’efforcent de fournir un service fiable, mais ne peuvent garantir l’absence totale d’erreurs ou d’indisponibilités. L’utilisation de la plateforme se fait aux risques de l’utilisateur. En cas de dysfonctionnement ou perte de données, aucune responsabilité ne pourra être engagée hors des obligations légales.</p>
                </div>
            </div>
            <div>
                <p className="text-primary text-xs text-center cursor-pointer"><a className="mr-2" onClick={() => navigate('/terms-of-service')}> Conditions Générales d’Utilisation</a><a className="mr-2" onClick={() => navigate('/privacy-policy')}>Politique de Confidentialité</a><a onClick={() => navigate('/legal-notice')}>Mentions légales</a></p>
            </div>
        </div>
    );
};

export default LegalNoticePage;