import React, {useEffect, useRef, useState} from 'react';
import { searchCompanyBySiret } from '../api/companyService';
import {useCountries} from "../hooks/useCountries";

interface CompanySearchResult {
    name: string | null;
    companyNumber: string;
    vatNumber: string;
    address: string;
    postcode: string;
    city: string;
    country: string;
}

interface SiretSearchInputProps {
    label: string;
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onCompanySelect?: (company: CompanySearchResult) => void;
    placeholder?: string;
    required?: boolean;
}

const SiretSearchInput: React.FC<SiretSearchInputProps> = ({
                                                               label,
                                                               name,
                                                               value,
                                                               onChange,
                                                               onCompanySelect,
                                                               placeholder,
                                                               required = false,
                                                           }) => {
    const [isSearching, setIsSearching] = useState(false);
    const [searchResult, setSearchResult] = useState<CompanySearchResult | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [showResults, setShowResults] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const { getCountryCodeByName } = useCountries();

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
                setShowResults(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleInputFocus = () => {
        if (searchResult) {
            setShowResults(true);
        }
    };

    const cleanSiretNumber = (siret: string): string => {
        // Supprime tous les caractères non numériques (espaces, tirets, etc.)
        return siret.replace(/[^0-9]/g, '');
    };

    const handleSearch = async (siretValue: string) => {
        const cleanedSiret = cleanSiretNumber(siretValue);

        if (!cleanedSiret || cleanedSiret.length !== 14) {
            setError('Le SIRET doit contenir 14 chiffres');
            setShowResults(false);
            return;
        }

        setIsSearching(true);
        setError(null);

        try {
            const response = await searchCompanyBySiret(cleanedSiret);
            const company = response.data;

            setSearchResult({
                name: company.name,
                companyNumber: company.companyNumber,
                vatNumber: company.vatNumber,
                address: company.address,
                postcode: company.postcode,
                city: company.city,
                country: company.country
            });
            setShowResults(true);
        } catch (error) {
            setError('Entreprise non trouvée');
            setSearchResult(null);
            setShowResults(false);
        } finally {
            setIsSearching(false);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        onChange(e);

        // Si la valeur nettoyée atteint 14 chiffres, lance la recherche
        const cleanedValue = cleanSiretNumber(newValue);
        if (cleanedValue.length === 14) {
            handleSearch(cleanedValue);
        } else if (cleanedValue.length > 14) {
            setError('Le SIRET ne peut pas dépasser 14 chiffres');
        } else {
            setError(null);
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const pastedText = e.clipboardData.getData('text');
        const cleanedPastedText = cleanSiretNumber(pastedText);

        // Empêche le collage par défaut
        e.preventDefault();

        // Simule un événement de changement avec la valeur nettoyée
        const simulatedEvent = {
            target: {
                name: name,
                value: cleanedPastedText
            }
        } as React.ChangeEvent<HTMLInputElement>;

        handleInputChange(simulatedEvent);
    };

    const handleResultClick = () => {
        if (searchResult && onCompanySelect) {
            onCompanySelect(searchResult);
            setShowResults(false);
        }
    };

    return (
        <div className="relative" ref={wrapperRef}>
            <div className="flex justify-between items-center mb-[5px]">
                <label className="block text-primary-theme text-xs font-normal">
                    {label}
                </label>
                {error && (
                    <p className="text-red-500 text-xs">{error}</p>
                )}
            </div>
            <div className="relative">
                <input
                    type="text"
                    name={name}
                    value={value}
                    onChange={handleInputChange}
                    onPaste={handlePaste}
                    onFocus={handleInputFocus}
                    placeholder={placeholder}
                    className={`appearance-none text-sm block w-full border ${error ? 'border-red-500' : 'border-theme'} bg-theme text-primary-theme rounded-lg px-5 py-3 leading-tight focus:outline-none ${!error && 'focus:ring-1 focus:ring-primary'}`}
                    required={required}
                />
            </div>

            {showResults && searchResult && (
                <div className="absolute z-10 w-full mt-1 bg-theme border border-theme rounded-lg shadow-lg">
                    <div
                        onClick={handleResultClick}
                        className="p-4 cursor-pointer transition-colors duration-150 ease-in-out rounded-lg"
                    >
                        <div className="space-y-1">
                            <h4 className="font-medium text-primary-theme">{searchResult.name}</h4>
                            <p className="text-sm text-secondary-theme">
                                {searchResult.address}
                            </p>
                            <p className="text-sm text-secondary-theme">
                                {searchResult.postcode} {searchResult.city}
                            </p>
                            <p className="text-sm text-secondary-theme">
                                {searchResult.country}
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SiretSearchInput;