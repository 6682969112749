import React from 'react';
import LoginForm from '../features/auth/LoginForm';

type LoginPageProps = {
    theme: string;
};

const LoginPage: React.FC<LoginPageProps> = ({theme}) => {
    return (
        <div>
            <LoginForm theme={theme}/>
        </div>
    );
};

export default LoginPage;