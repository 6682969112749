import React, { useEffect, useRef } from 'react';

interface QuoteData {
    project: {
        createdAt: string;
        updatedAt: string;
        uuid: string;
        statusHistories: any[];
    };
    company: {
        createdAt: string;
        updatedAt: string | null;
        uuid: string;
        commercialName?: string;
        legalName?: string;
        companyNumber?: string;
        address?: {
            street?: string;
            zipCode?: string;
            city?: string;
            country?: {
                name?: string;
            };
        };
    };
    customer: {
        createdAt: string;
        updatedAt: string | null;
        uuid: string;
        name?: string;
        companyNumber?: string;
        address?: {
            street?: string;
            zipCode?: string;
            city?: string;
            country?: {
                name?: string;
            };
        };
    };
    createdBy: {
        createdAt: string;
        updatedAt: string;
        uuid: string;
    };
    document: any | null;
    items: Array<{
        title: string;
        description: string;
        quantity: number;
        unitPrice: number;
        taxRate: number | null;
        position: number;
        createdAt: string;
        updatedAt: string | null;
        uuid: string;
        _fromLiveForm?: boolean;
    }>;
    title: string;
    description: string;
    number: string | null;
    emissionDate: string | null;
    expirationDate: string | null;
    currency: string | null;
    paymentMethod: string | null;
    validityPeriod: string | null;
    notes: string | null;
    status: string;
    latePaymentPenalty: string | null;
    recoveryFee: string | null;
    taxExemption: {
        name?: string;
    } | null;
    signRequestSendAt: string | null;
    createdAt: string;
    updatedAt: string | null;
    uuid: string;
    totalAmount: number;
    totalAmountWithTax: number;
    totalTaxAmount: number;
}

interface IsolatedQuotePreviewProps {
    html: string;
    quoteData?: QuoteData | null;
    currency?: string;
}

const IsolatedQuotePreview: React.FC<IsolatedQuotePreviewProps> = ({ html, quoteData, currency }) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const resizeTimeoutRef = useRef<number>();
    const htmlContentRef = useRef<string>('');

    // Fonction pour remplacer les variables dans le HTML
    const replaceVariables = (htmlContent: string, data: QuoteData, selectedCurrency: string = 'EUR'): string => {
        if (!data) return htmlContent;

        let updatedHtml = htmlContent;

        // Formatter les montants avec le symbole de devise
        const formatCurrency = (amount: number): string => {
            const formatter = new Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: selectedCurrency || 'EUR'
            });
            return formatter.format(amount);
        };

        // Formater les dates
        const formatDate = (dateString: string | null): string => {
            if (!dateString) return '';
            const date = new Date(dateString);
            return date.toLocaleDateString('fr-FR');
        };

        // Fonction pour remplacer une variable en tenant compte de la valeur null
        const replaceVariable = (html: string, variable: string, value: any): string => {
            if (value === null || value === undefined) {
                return html.replace(new RegExp(variable, 'g'), '');
            }
            return html.replace(new RegExp(variable, 'g'), value.toString());
        };

        // Remplacer les variables de base
        updatedHtml = replaceVariable(updatedHtml, '{{ quote.title }}', data.title);
        updatedHtml = replaceVariable(updatedHtml, '{{ quote.description }}', data.description);
        updatedHtml = replaceVariable(updatedHtml, '{{ quote.number }}', data.number);
        updatedHtml = replaceVariable(updatedHtml, '{{ quote.emissionDate }}', formatDate(data.emissionDate));
        updatedHtml = replaceVariable(updatedHtml, '{{ quote.expirationDate }}', formatDate(data.expirationDate));
        updatedHtml = replaceVariable(updatedHtml, '{{ quote.totalAmount }}', formatCurrency(data.totalAmount));
        updatedHtml = replaceVariable(updatedHtml, '{{ quote.totalAmountWithTax }}', formatCurrency(data.totalAmountWithTax));
        updatedHtml = replaceVariable(updatedHtml, '{{ quote.totalTaxAmount }}', formatCurrency(data.totalTaxAmount));
        updatedHtml = replaceVariable(updatedHtml, '{{ quote.notes }}', data.notes);
        updatedHtml = replaceVariable(updatedHtml, '{{ quote.validityPeriod }}', data.validityPeriod);
        updatedHtml = replaceVariable(updatedHtml, '{{ quote.latePaymentPenalty }}', data.latePaymentPenalty);

        // Gérer les champs taxExemption qui est un objet
        if (data.taxExemption && typeof data.taxExemption === 'object' && data.taxExemption.name) {
            updatedHtml = replaceVariable(updatedHtml, '{{ quote.taxExemption.name }}', data.taxExemption.name);
        } else {
            updatedHtml = replaceVariable(updatedHtml, '{{ quote.taxExemption.name }}', '');
        }

        // Remplacer les variables pour les items (produits)
        if (data.items && data.items.length > 0) {
            // Générer le HTML pour les items
            let itemsHtml = '';

            // Trier les éléments par position
            const sortedItems = [...data.items].sort((a, b) => a.position - b.position);

            sortedItems.forEach(item => {
                const rawUnitPrice = parseFloat(item.unitPrice as any) || 0;
                const isCentimes = item._fromLiveForm !== true;
                const displayUnitPrice = isCentimes ? rawUnitPrice / 100 : rawUnitPrice;
                const totalHT = (item.quantity || 0) * displayUnitPrice;

                itemsHtml += `
                  <tr>
                    <td class="details-col">
                      <p class="bold-text">${item.title || ''}</p>
                      <p>${item.description || ''}</p>
                    </td>
                    <td class="qty-col">${item.quantity || ''}</td>
                    <td class="unit-price-col">${formatCurrency(displayUnitPrice)}</td>
                    <td class="total-ht-col">${formatCurrency(totalHT)}</td>
                    <td class="tva-col">${item.taxRate || 0} %</td>
                    <td class="total-ttc-col">${formatCurrency(totalHT)}</td>
                  </tr>
                `;
            });

            updatedHtml = updatedHtml.replace('{{ quote.items }}', itemsHtml);
        } else {
            updatedHtml = updatedHtml.replace('{{ quote.items }}', '');
        }

        // Si les données company sont disponibles, les remplacer
        if (data.company) {
            if (typeof data.company === 'object') {
                // Remplacer les champs company
                if (data.company.commercialName) {
                    updatedHtml = replaceVariable(updatedHtml, '{{ quote.company.commercialName }}', data.company.commercialName);
                }
                if (data.company.legalName) {
                    updatedHtml = replaceVariable(updatedHtml, '{{ quote.company.legalName }}', data.company.legalName);
                }
                if (data.company.companyNumber) {
                    updatedHtml = replaceVariable(updatedHtml, '{{ quote.company.companyNumber }}', data.company.companyNumber);
                }

                // Adresse de l'entreprise
                if (data.company.address) {
                    updatedHtml = replaceVariable(updatedHtml, '{{ quote.company.address.street }}', data.company.address?.street || '');
                    updatedHtml = replaceVariable(updatedHtml, '{{ quote.company.address.zipCode }}', data.company.address?.zipCode || '');
                    updatedHtml = replaceVariable(updatedHtml, '{{ quote.company.address.city }}', data.company.address?.city || '');
                    updatedHtml = replaceVariable(updatedHtml, '{{ quote.company.address.country.name }}', data.company.address?.country?.name || '');
                }
            }
        }

        // Si les données customer sont disponibles, les remplacer
        if (data.customer) {
            if (typeof data.customer === 'object') {
                // Remplacer les champs customer
                if (data.customer.name) {
                    updatedHtml = replaceVariable(updatedHtml, '{{ quote.customer.name }}', data.customer.name);
                }
                if (data.customer.companyNumber) {
                    updatedHtml = replaceVariable(updatedHtml, '{{ quote.customer.companyNumber }}', data.customer.companyNumber);
                }

                // Adresse du client
                if (data.customer.address) {
                    updatedHtml = replaceVariable(updatedHtml, '{{ quote.customer.address.street }}', data.customer.address?.street || '');
                    updatedHtml = replaceVariable(updatedHtml, '{{ quote.customer.address.zipCode }}', data.customer.address?.zipCode || '');
                    updatedHtml = replaceVariable(updatedHtml, '{{ quote.customer.address.city }}', data.customer.address?.city || '');
                    updatedHtml = replaceVariable(updatedHtml, '{{ quote.customer.address.country.name }}', data.customer.address?.country?.name || '');
                }
            }
        }

        return updatedHtml;
    };

    // Mettre à jour le contenu de l'iframe quand les données changent
    useEffect(() => {
        try {
            // Considérer que html est directement le contenu HTML et non un JSON
            if (quoteData) {
                htmlContentRef.current = replaceVariables(html, quoteData, currency);
            } else {
                htmlContentRef.current = html;
            }

            updateIframeContent();
        } catch (e) {
            console.error("Erreur lors du traitement du HTML:", e);
            // En cas d'erreur, utiliser le HTML brut
            htmlContentRef.current = html;
            updateIframeContent();
        }
    }, [html, quoteData, currency]);

    // Fonction pour mettre à jour le contenu de l'iframe
    const updateIframeContent = () => {
        if (iframeRef.current) {
            const iframe = iframeRef.current;
            const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;

            if (iframeDocument) {
                iframeDocument.open();
                iframeDocument.write(htmlContentRef.current);
                iframeDocument.close();

                resizeIframe();
            }
        }
    };

    // Fonction pour redimensionner l'iframe
    const resizeIframe = () => {
        // Annuler le timeout précédent
        if (resizeTimeoutRef.current) {
            window.clearTimeout(resizeTimeoutRef.current);
        }

        // Créer un nouveau timeout
        resizeTimeoutRef.current = window.setTimeout(() => {
            if (iframeRef.current) {
                const iframe = iframeRef.current;
                const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;

                if (iframeDocument && iframeDocument.body) {
                    const height = iframeDocument.body.scrollHeight;
                    if (height > 0) {
                        iframe.style.height = `${height}px`;
                    }
                }
            }
        }, 100); // Délai de 100ms
    };

    // Configurer les observateurs pour le redimensionnement
    useEffect(() => {
        if (iframeRef.current) {
            const iframe = iframeRef.current;
            const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;

            if (iframeDocument) {
                // Ajouter les événements de redimensionnement
                if (iframeDocument.defaultView) {
                    iframeDocument.defaultView.addEventListener('resize', resizeIframe);
                }

                // Gérer les images et autres ressources qui pourraient charger après
                const mutationObserver = new MutationObserver(resizeIframe);

                if (iframeDocument.body) {
                    mutationObserver.observe(iframeDocument.body, {
                        attributes: true,
                        childList: true,
                        subtree: true
                    });
                }

                return () => {
                    if (resizeTimeoutRef.current) {
                        window.clearTimeout(resizeTimeoutRef.current);
                    }
                    mutationObserver.disconnect();
                    if (iframeDocument.defaultView) {
                        iframeDocument.defaultView.removeEventListener('resize', resizeIframe);
                    }
                };
            }
        }
    }, []);

    return (
        <iframe
            ref={iframeRef}
            className="w-full border-none bg-white"
            title="Quote Preview"
            style={{
                width: '210mm',
                minHeight: '297mm',
                maxWidth: '100%',
            }}
        />
    );
};

export default IsolatedQuotePreview;