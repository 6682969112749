import React, { createContext, useContext, useState, ReactNode } from 'react';

// Définir le type pour les données dynamiques
type PageContextType = {
    dynamicData: { [key: string]: string };
    setDynamicData: (data: { [key: string]: string }) => void;
    showList: boolean;
    setShowList: (show: boolean) => void;
};

const PageContext = createContext<PageContextType | undefined>(undefined);

export const PageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [dynamicData, setDynamicData] = useState<{ [key: string]: string }>({});
    const [showList, setShowList] = useState(true);

    return (
        <PageContext.Provider value={{
            dynamicData,
            setDynamicData,
            showList,
            setShowList
        }}>
            {children}
        </PageContext.Provider>
    );
};

export const usePageContext = (): PageContextType => {
    const context = useContext(PageContext);
    if (!context) {
        throw new Error('usePageContext must be used within a PageProvider');
    }
    return context;
};