import React from 'react';
import { useNavigate } from 'react-router-dom';

type ButtonProps = {
    type?: 'button' | 'submit' | 'reset';
    isLoading?: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
    background?: boolean;
    border?: boolean; // Nouvelle option pour le style avec bordure
    size?: 'small' | 'medium' | 'large';
    icon?: React.ReactNode;
    iconPosition?: 'left' | 'right';
    children: React.ReactNode;
    onClick?: () => void;
    action?: 'navigate' | 'default'; // Action type for onClick behavior
    to?: string; // Optional path for navigation
};

const Button: React.FC<ButtonProps> = ({
   type = 'button',
   isLoading = false,
   disabled = false,
   fullWidth = false,
   background = true,
   border = false, // Par défaut, pas de bordure spéciale
   size = 'medium',
   icon,
   iconPosition = 'left',
   children,
   onClick,
   action = 'default',
   to = '/',
}) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (disabled || isLoading) return;

        if (action === 'navigate' && to) {
            navigate(to);
        } else if (onClick) {
            onClick();
        }
    };

    const baseStyles =
        'group relative flex justify-center items-center font-medium rounded-md';

    const sizeStyles = {
        small: 'text-xs',
        medium: 'text-sm',
        large: 'text-lg',
    };

    const widthStyle = fullWidth ? 'w-full' : 'w-auto';

    const disabledStyles = disabled ? 'opacity-50 cursor-not-allowed' : '';

    // Gérer les différents styles de bouton
    let buttonStyle = '';

    if (border) {
        // Style avec bordure primary et fond bg-theme
        buttonStyle = 'text-[#846EE8] bg-transparent border border-[#846EE8] hover:bg-theme-hover focus:ring-1 focus:ring-primary py-3 px-4 duration-300';
    } else if (background) {
        // Style avec fond coloré (original)
        buttonStyle = 'text-white bg-[#846EE8] hover:bg-primary border border-transparent focus:ring-1 focus:ring-primary py-3 px-4 duration-300';
    } else {
        // Style transparent (original)
        buttonStyle = 'text-[#846EE8] hover:text-primary bg-transparent border border-transparent font-normal hover:bg-transparent py-0 px-0 duration-300';
    }

    return (
        <button
            type={type}
            onClick={handleClick}
            disabled={disabled || isLoading}
            className={`${baseStyles} ${sizeStyles[size]} ${widthStyle} ${buttonStyle} ${disabledStyles}`}
        >
            {isLoading ? (
                <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    ></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
            ) : (
                <>
                    {icon && iconPosition === 'left' && (
                        <span className="mr-2">{icon}</span>
                    )}
                    {children}
                    {icon && iconPosition === 'right' && (
                        <span className="ml-2">{icon}</span>
                    )}
                </>
            )}
        </button>
    );
};

export default Button;