import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CustomerList from '../features/customer/CustomerList';
import CustomerDetail from '../features/customer/CustomerDetail';

const CustomersPage: React.FC = () => {
    return (
        <div className="md:pl-6 md:pr-4 h-full p-4 mb-8 md:m-0">
            <Routes>
                <Route path="/" element={<CustomerList />} />
                <Route path=":uuid" element={<CustomerDetail />} />
            </Routes>
        </div>
    );
};

export default CustomersPage;