import React, {useEffect, useRef, useState} from 'react';
import { useParams } from 'react-router-dom';
import { getQuoteById, validateQuote, updateQuote, signQuote, sendQuote, getQuotePreview, getProjectInfo, refuseQuote  } from '../../../api/projectService';
import Skeleton from '../../../components/Skeleton';
import { useUserPermissions } from '../../../hooks/useUserPermissions';
import IsolatedQuotePreview from "../../../components/IsolatedQuotePreview";
import ProjectForm from "../form/ProjectForm";
import {CustomerData, ProjectFormData} from "../form/types";
import Button from "../../../components/UI/Button";
import PdfViewer from "../../../components/PdfViewer";
import { usePageContext } from '../../../contexts/PageContext';
import {Mission} from "../types";

interface Quote {
    uuid: string;
    status: string;
    createdAt: string;
    updatedAt: string;
    pdfUrl?: string;
    currency?: string;
    title: string;
    description: string;
    customer: CustomerData;
    number: string;
    document: {
        uuid: string;
        publicUrl: string;
    }
}

interface ApiError {
    response?: {
        data?: {
            errors?: {
                email?: string[];
            };
        };
    };
}

interface ProjectData {
    uuid: string;
    title: string;
    description: string;
    customer: CustomerData;
}

// Interface pour les données complètes du devis
interface QuoteDataComplete {
    project: {
        createdAt: string;
        updatedAt: string;
        uuid: string;
        statusHistories: any[];
    };
    company: {
        createdAt: string;
        updatedAt: string | null;
        uuid: string;
        commercialName?: string;
        legalName?: string;
        companyNumber?: string;
        address?: {
            street?: string;
            zipCode?: string;
            city?: string;
            country?: {
                name?: string;
            };
        };
    };
    customer: {
        createdAt: string;
        updatedAt: string | null;
        uuid: string;
        name?: string;
        companyNumber?: string;
        address?: {
            street?: string;
            zipCode?: string;
            city?: string;
            country?: {
                name?: string;
            };
        };
    };
    createdBy: {
        createdAt: string;
        updatedAt: string;
        uuid: string;
    };
    document: {
        uuid: string;
        downloadUrl: string;
    };
    items: Array<{
        title: string;
        description: string;
        quantity: number;
        unitPrice: number;
        taxRate: number | null;
        position: number;
        createdAt: string;
        updatedAt: string | null;
        uuid: string;
        _fromLiveForm?: boolean;
    }>;
    title: string;
    description: string;
    number: string | null;
    emissionDate: string | null;
    expirationDate: string | null;
    currency: string | null;
    paymentMethod: string | null;
    validityPeriod: string | null;
    notes: string | null;
    status: string;
    latePaymentPenalty: string | null;
    recoveryFee: string | null;
    taxExemption: {
        name?: string;
    } | null;
    signRequestSendAt: string | null;
    createdAt: string;
    updatedAt: string | null;
    uuid: string;
    totalAmount: number;
    totalAmountWithTax: number;
    totalTaxAmount: number;
}

export interface MissionWithPreview extends Mission {
    _fromLiveForm?: boolean;
}

const QuoteDetail: React.FC = () => {
    const { projectId, quoteId } = useParams<{ projectId: string, quoteId: string }>();
    const [quote, setQuote] = useState<Quote | null>(null);
    const [loading, setLoading] = useState(true);
    const [validating, setValidating] = useState(false);
    const [signing, setSigning] = useState(false);
    const [sending, setSending] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [selectedCurrency, setSelectedCurrency] = useState('EUR');
    const { isCompanyProfile } = useUserPermissions();
    const [quotePreview, setQuotePreview] = useState<string>('');
    const projectFormRef = useRef<any>(null);
    const { setDynamicData } = usePageContext();
    const [refusing, setRefusing] = useState(false);

    // Données complètes du devis pour les variables de modèle
    const [completeQuoteData, setCompleteQuoteData] = useState<QuoteDataComplete | null>(null);

    // Séparation des états pour les données du projet
    const [projectData, setProjectData] = useState<ProjectData | null>(null);
    const [formData, setFormData] = useState<ProjectFormData>({
        title: '',
        description: '',
        customer: ''
    });

    const [saving, setSaving] = useState(false);
    const [saved, setSaved] = useState(false);

    const [missionsData, setMissionsData] = useState<Mission[]>([]);

    useEffect(() => {
        const fetchQuoteData = async () => {
            try {
                if (projectId && quoteId) {
                    const [quoteData, previewHtml, projectData] = await Promise.all([
                        getQuoteById(projectId, quoteId),
                        getQuotePreview(projectId, quoteId),
                        getProjectInfo(projectId)
                    ]);

                    setQuote(quoteData);
                    setMissionsData((quoteData.items || []).map((item: any) => {
                        const raw = typeof item.unitPrice === 'string' ? parseFloat(item.unitPrice.replace(',', '.')) : item.unitPrice;
                        const unitPrice = raw > 1000 ? raw / 100 : raw;

                        return {
                            ...item,
                            unitPrice,
                            _fromLiveForm: true
                        };
                    }));

                    setQuotePreview(previewHtml);
                    setProjectData(projectData);

                    setFormData({
                        title: projectData.title,
                        description: projectData.description,
                        customer: projectData.customer.uuid
                    });

                    if (quoteData.currency) {
                        setSelectedCurrency(quoteData.currency);
                    }

                    setCompleteQuoteData({
                        ...quoteData,
                        project: {
                            createdAt: quoteData.createdAt,
                            updatedAt: quoteData.updatedAt,
                            uuid: quoteData.uuid,
                            statusHistories: []
                        },
                        company: {
                            ...quoteData.company,
                            createdAt: new Date().toISOString(),
                            updatedAt: null,
                            uuid: "company-uuid"
                        },
                        customer: {
                            ...quoteData.customer,
                            createdAt: new Date().toISOString(),
                            updatedAt: null,
                            uuid: projectData.customer.uuid
                        },
                        createdBy: {
                            createdAt: new Date().toISOString(),
                            updatedAt: new Date().toISOString(),
                            uuid: "user-uuid"
                        },
                        items: quoteData.items || [],
                        currency: selectedCurrency,
                        totalAmount: quoteData.totalAmount || 0,
                        totalAmountWithTax: quoteData.totalAmountWithTax || 0,
                        totalTaxAmount: quoteData.totalTaxAmount || 0
                    });
                }
            } catch (err) {
                setError('Erreur lors de la récupération du devis');
            } finally {
                setLoading(false);
            }
        };

        fetchQuoteData();
    }, [projectId, quoteId]);

    useEffect(() => {
        const computeSafePrice = (raw: string | number): number => {
            let val = typeof raw === 'string'
                ? parseFloat(raw.replace(',', '.'))
                : raw;

            return val > 1000 ? val / 100 : val;
        };

        const totalHT = missionsData.reduce((sum, m) => {
            const unit = computeSafePrice(m.unitPrice);
            return sum + unit * m.quantity;
        }, 0);

        const totalTVA = missionsData.reduce((sum, m) => {
            const unit = computeSafePrice(m.unitPrice);
            const rate = m.taxRate ?? 0;
            return sum + (unit * m.quantity * rate / 100);
        }, 0);

        const totalTTC = totalHT + totalTVA;

        if (completeQuoteData) {
            setCompleteQuoteData(prevData => {
                if (!prevData) return prevData;

                const updatedItems = missionsData.map(m => ({
                    title: m.title,
                    description: m.description,
                    quantity: m.quantity,
                    unitPrice: (() => {
                        const raw = m.unitPrice as string | number;
                        const num = typeof raw === 'string'
                            ? parseFloat(raw.replace(',', '.'))
                            : raw;

                        return num > 1000 ? num / 100 : num;
                    })(),
                    _fromLiveForm: true,
                    taxRate: m.taxRate ?? 0,
                    position: m.position,
                    createdAt: m.createdAt ?? new Date().toISOString(),
                    updatedAt: m.updatedAt ?? null,
                    uuid: m.uuid || crypto.randomUUID(),
                }));

                return {
                    ...prevData,
                    title: formData.title,
                    description: formData.description,
                    items: updatedItems,
                    currency: selectedCurrency,
                    totalAmount: totalHT,
                    totalTaxAmount: totalTVA,
                    totalAmountWithTax: totalTTC
                };
            });
        }
    }, [formData, missionsData, selectedCurrency]);


    useEffect(() => {
        if (projectId && quoteId && quote && projectData) {
            const quoteLabel = quote.number ? `Devis ${quote.number}` : 'Devis brouillon';

            setDynamicData({
                [projectId]: projectData.title,
                [quoteId]: quoteLabel
            });
        }
    }, [quote, projectData]);


    const handleValidateQuote = async () => {
        try {
            setValidating(true);
            if (projectId && quoteId) {
                const response = await validateQuote(projectId, quoteId);

                // Re-fetch pour récupérer le `document.publicUrl`
                const updatedQuote = await getQuoteById(projectId, quoteId);

                setQuote(updatedQuote);

                if (completeQuoteData) {
                    setCompleteQuoteData(prevData => {
                        if (!prevData) return prevData;
                        return {
                            ...prevData,
                            status: 'validated'
                        };
                    });
                }
            }
        } catch (err) {
            setError('Erreur lors de la validation du devis');
        } finally {
            setValidating(false);
        }
    };

    const handleSignQuote = async () => {
        try {
            setSigning(true);
            if (projectId && quoteId) {
                const response = await signQuote(projectId, quoteId);
                setQuote((prevQuote: Quote | null) => {
                    if (!prevQuote) return null;
                    return {
                        ...prevQuote,
                        status: 'signed',
                        ...response
                    };
                });

                // Mettre à jour l'état du devis dans les données complètes
                if (completeQuoteData) {
                    setCompleteQuoteData(prevData => {
                        if (!prevData) return prevData;
                        return {
                            ...prevData,
                            status: 'signed'
                        };
                    });
                }
            }
        } catch (err) {
            setError('Vous n\'avez pas les droits pour signer ce devis');
        } finally {
            setSigning(false);
        }
    };

    const handleSendQuote = async () => {
        try {
            const customerEmail = projectData?.customer?.email;

            if (!customerEmail) {
                setError('L\'email du client n\'est pas disponible');
                return;
            }

            setSending(true);

            if (projectId && quoteId) {
                await sendQuote(projectId, quoteId, customerEmail);

                if (completeQuoteData) {
                    setCompleteQuoteData(prevData =>
                        prevData ? { ...prevData } : prevData
                    );
                }
            }
        } catch (err) {
            setError('Erreur lors de l\'envoi du devis');
        } finally {
            setSending(false);
        }
    };

    const handleSaveQuoteData = async () => {
        if (!projectId || !quoteId) return;

        try {
            setSaving(true);
            setSaved(false);

            await updateQuote(projectId, quoteId, {
                currency: selectedCurrency,
                title: formData.title,
                description: formData.description,
                items: missionsData.map((m) => ({
                    title: m.title,
                    description: m.description,
                    quantity: m.quantity,
                    unitPrice: Math.round(Number(m.unitPrice) * 100),
                    taxRate: m.taxRate ?? 0,
                    position: m.position,
                    uuid: m.uuid,
                }))
            });

            setSaved(true);
            setTimeout(() => setSaved(false), 2000);
        } catch (error) {
            console.error('Erreur lors de la mise à jour du devis:', error);
            alert('Erreur lors de la mise à jour du devis');
        } finally {
            setSaving(false);
        }
    };

    const handleRefuseQuote = async () => {
        try {
            setRefusing(true);
            if (projectId && quoteId) {
                await refuseQuote(projectId, quoteId);

                setQuote(prev => prev ? { ...prev, status: 'refused' } : null);

                if (completeQuoteData) {
                    setCompleteQuoteData(prev => prev ? { ...prev, status: 'refused' } : null);
                }
            }
        } catch (err) {
            setError("Erreur lors du refus du devis");
        } finally {
            setRefusing(false);
        }
    };

    if (loading) {
        return (
            <div className="p-4 space-y-4">
                <Skeleton width="50%" height="30px" />
                <Skeleton width="100%" height="20px" />
            </div>
        );
    }

    if (error) {
        return <div className="text-red-500 p-4">{error}</div>;
    }

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-medium text-primary-theme">Détails du devis</h1>
                <div className="flex items-center gap-4">
                    {isCompanyProfile && quote?.status === 'draft' && (
                        <Button
                            onClick={handleSaveQuoteData}
                            border
                            size="medium"
                            disabled={saving}
                        >
                            {saving
                                ? 'Enregistrement en cours...'
                                : saved
                                    ? 'Enregistré avec succès'
                                    : 'Enregistrer'}
                        </Button>
                    )}
                    {isCompanyProfile && quote?.status === 'draft' && (
                        <>
                            <Button
                                onClick={handleValidateQuote}
                                disabled={validating}
                                size="medium"
                            >
                                {validating ? 'Validation en cours...' : 'Valider le devis'}
                            </Button>
                        </>
                    )}
                    {!isCompanyProfile && quote?.status === 'validated' && (
                        <Button onClick={handleRefuseQuote} disabled={refusing} size="medium" border>
                            {refusing ? 'Refus en cours...' : 'Refuser le devis'}
                        </Button>
                    )}
                    {isCompanyProfile && quote?.status === 'validated' && (
                        <Button onClick={handleRefuseQuote} disabled={refusing} size="medium" border>
                            {refusing ? 'Annulation en cours...' : 'Annuler le devis'}
                        </Button>
                    )}
                    {isCompanyProfile && quote?.status === 'validated' && (
                        <Button onClick={handleSendQuote} disabled={sending} size="medium">
                            {sending ? 'Envoi en cours...' : 'Envoyer le devis'}
                        </Button>
                    )}
                    {!isCompanyProfile && quote?.status === 'validated' && (
                        <Button onClick={handleSignQuote} disabled={signing} size="medium">
                            {signing ? 'Signature en cours...' : 'Signer le devis'}
                        </Button>
                    )}
                    {quote?.status === 'accepted' && (
                        <>
                            <p>Devis accepté</p>
                        </>
                    )}
                </div>
            </div>


                {quote?.status !== 'draft' && quote?.document?.publicUrl ? (
                    <>
                        <PdfViewer
                            pdfUrl={quote?.document?.publicUrl}
                        />
                    </>
                ) : (
                    <>
                        <div className="flex gap-6">
                            <div className="flex-1 bg-theme rounded-lg p-6 relative h-[calc(100vh-13rem)] overflow-auto">
                                <div className="max-w-[210mm] bg-white mx-auto">
                                    <div className="flex justify-center p-10">
                                        {/* Utiliser le composant amélioré avec les données complètes */}
                                        <IsolatedQuotePreview
                                            html={quotePreview}
                                            quoteData={completeQuoteData}
                                            currency={selectedCurrency}
                                        />
                                    </div>
                                </div>
                            </div>
                            {isCompanyProfile && quote?.status === 'draft' && (
                                <div className="w-[500px] flex-shrink-0">
                                    <div className="h-[calc(100vh-13rem)] overflow-auto">
                                        {projectData && (
                                            <>
                                                <ProjectForm
                                                    ref={projectFormRef}
                                                    onSuccess={() => {
                                                        // Vous pouvez ajouter ici une logique après la sauvegarde réussie
                                                        // Par exemple, rafraîchir les données ou afficher un message
                                                    }}
                                                    projectData={projectData}
                                                    isEditMode={true}
                                                    setFormData={(data: ProjectFormData) => setFormData(data)}
                                                    setMissionsData={(missions: Mission[]) => setMissionsData(missions)}
                                                    formData={formData}
                                                    isLoading={loading}
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}
        </div>
    );
};

export default QuoteDetail;