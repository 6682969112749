import React from 'react';
import ForgotPassword from '../features/auth/ForgotPasswordForm';

type LoginPageProps = {
    theme: string;
};

const LoginPage: React.FC<LoginPageProps> = ({theme}) => {
    return (
        <div>
            <ForgotPassword theme={theme} />
        </div>
    );
};

export default LoginPage;
