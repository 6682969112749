// src/hooks/useInvitationTokenHandler.tsx
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { associateToCustomer } from '../api/authService';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated, selectAuthChecked } from '../features/auth/authSlice';

const useInvitationTokenHandler = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const authChecked = useSelector(selectAuthChecked);

    useEffect(() => {
        if (!authChecked) return;

        const queryParams = new URLSearchParams(location.search);
        const invitationToken = queryParams.get('invitationToken');

        if (invitationToken) {
            if (isAuthenticated) {
                associateToCustomer(invitationToken)
                    .then(() => {
                        localStorage.removeItem('invitationToken');
                        navigate('/dashboard', { replace: true });
                    })
                    .catch(err => {
                        console.error("Erreur association au client :", err);
                        navigate('/dashboard', { replace: true });
                    });
            } else {
                localStorage.setItem('invitationToken', invitationToken);
                navigate('/login', { replace: true });
            }
        }
    }, [location.search, isAuthenticated, authChecked, navigate]);

};

export default useInvitationTokenHandler;
