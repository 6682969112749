import React from 'react';
import {ImageDisplay} from "./ImageDisplay";

interface ProfileCardProps {
    imageUrl?: string;
    firstName: string;
    lastName: string;
    firstHide?: string;  // Nouvelle propriété pour l'initial cachée du prénom
    lastHide?: string;   // Nouvelle propriété pour l'initial cachée du nom
    label?: string;
    size?: 'small' | 'large';
    hideText?: boolean;
    truncateFirstName?: boolean; // Nouvelle propriété pour tronquer le prénom
    maxFirstNameLength?: number; // Longueur maximale du prénom
}

const generateBackgroundColor = (firstName: string, lastName: string, firstHide?: string, lastHide?: string) => {
    // Utilise firstHide et lastHide en priorité s'ils sont disponibles
    const firstChar = firstHide ? firstHide.charAt(0) : firstName.charAt(0);
    const lastChar = lastHide ? lastHide.charAt(0) : lastName.charAt(0);
    const charCodeSum = firstChar.charCodeAt(0) + lastChar.charCodeAt(0);
    const colors = ['bg-red-500', 'bg-blue-500', 'bg-green-500', 'bg-yellow-500', 'bg-purple-500', 'bg-pink-500'];
    return colors[charCodeSum % colors.length];
};

const ProfileCard: React.FC<ProfileCardProps> = ({
     imageUrl,
     firstName,
     lastName,
     firstHide,
     lastHide,
     label,
     size = 'large',
     hideText = false,
     truncateFirstName = false,
     maxFirstNameLength = 10
 }) => {
    const isLarge = size === 'large';

    // Utilise la première lettre des valeurs cachées si elles sont fournies, sinon utilise les premières lettres des noms
    const firstInitial = firstHide ? firstHide.charAt(0) : firstName.charAt(0);
    const lastInitial = lastHide ? lastHide.charAt(0) : lastName.charAt(0);
    const initials = `${firstInitial}${lastInitial}`;

    // Tronque le prénom si l'option est activée et que le prénom dépasse la longueur maximale
    const displayFirstName = truncateFirstName && firstName.length > maxFirstNameLength
        ? `${firstName.slice(0, maxFirstNameLength)}...`
        : firstName;

    const backgroundColor = generateBackgroundColor(firstName, lastName, firstHide, lastHide);

    return (
        <div className="flex items-center">
            {imageUrl ? (
                <ImageDisplay
                    uuid={imageUrl}
                    alt="Photo de profil"
                    className={`${isLarge ? 'w-10 h-10' : 'w-6 h-6 text-xs'} ${!hideText && 'mr-2 md:mr-4'} rounded-full object-cover bg-gray-500`}
                />
            ) : (
                <div
                    className={`${backgroundColor} ${isLarge ? 'w-10 h-10' : 'w-6 h-6 text-xs'} ${!hideText && (isLarge ? 'mr-4' : 'mr-2')} rounded-full flex items-center justify-center text-white font-semibold`}
                >
                    {initials.toUpperCase()}
                </div>
            )}
            {!hideText && (
                <div className="flex-grow">
                    <p className={`${isLarge ? 'text-sm' : 'text-xs'} font-medium text-primary-theme`}>
                        {displayFirstName} {lastName}
                    </p>
                    {isLarge && label && <p className="text-xs text-primary-theme">{label}</p>}
                </div>
            )}
        </div>
    );
};

export default ProfileCard;