import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from './store/store';
import { useTheme } from './hooks/useTheme';
import {
    fetchUserInfo, selectAuthChecked,
    selectHasCompany,
    selectIsAuthenticated,
    selectLoading,
    selectUser,
    checkAuthStatus
} from './features/auth/authSlice';
import LoginPage from './views/LoginPage';
import ForgotPasswordPage from './views/ForgotPasswordPage';
import ResetPasswordPage from './views/ResetPasswordPage';
import RegisterPage from './views/RegisterPage';
import DashboardPage from './views/DashboardPage';
import Customers from './views/CustomersPage';
import ProjectsPage from './views/ProjectsPage';
import MessagingPage from './views/MessagingPage';
import SettingsPage from './views/SettingsPage';
import CreateCompanyForm from './features/company/CreateCompanyForm';
// import DocumentPage from './views/DocumentPage';
import PrivateRoute from './components/routes/PrivateRoute';
import CompanyCheckRoute from './components/routes/CompanyCheckRoute';
import NavbarTop from './components/NavbarTop';
import NavbarSide from './components/NavbarSide';
import './i18n/i18n';
import {PageProvider} from "./contexts/PageContext";
import background from './assets/images/background.svg';
import QuoteDetail from "./features/project/components/QuoteDetail";
import HeroSlider from "./components/HeroSlider";
import NavbarMobile from "./components/NavbarMobile";
import { usePageContext } from "./contexts/PageContext";
import usePreventInputZoom from "./hooks/usePreventInputZoom";
import TermsOfServicePage from "./views/TermsOfServicePage";
import PrivacyPolicyPage from "./views/PrivacyPolicyPage";
import LegalNoticePage from "./views/LegalNoticePage";
import useInvitationTokenHandler from './hooks/useInvitationTokenHandler';
import PublicRoute from "./components/routes/PublicRoute";

const App: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const hasCompany = useSelector(selectHasCompany);
    const authChecked = useSelector(selectAuthChecked);
    const location = useLocation();
    const { theme, toggleTheme } = useTheme();
    useInvitationTokenHandler();

    useEffect(() => {
        if (!authChecked) {
            dispatch(checkAuthStatus());
        }
    }, [dispatch, authChecked]);

    const hideNavbar = location.pathname === '/create-company';
    const isLoginOrSignup = location.pathname === '/login' || location.pathname === '/signup';
    const isforgotOrReset = location.pathname === '/forgot-password' || location.pathname === '/reset-password';
    const { dynamicData, showList } = usePageContext();


    const containerClassName2 = isLoginOrSignup || isforgotOrReset
        ? 'flex-1 flex flex-col overflow-hidden'
        : 'flex-1 flex flex-col overflow-y-auto';

    const isPublicRoute = ['/login', '/signup', '/forgot-password', '/reset-password', '/terms-of-service', '/privacy-policy', '/legal-notice'].includes(location.pathname);

    // Modification des classes pour ajuster la largeur en fonction de la page
    const containerClassName = isLoginOrSignup
        ? "w-full md:w-2/5 ml-auto md:p-4 md:pl-0 flex flex-col overflow-hidden duration-300 ease-in-out"
        : `w-full md:w-5/5 ml-auto flex-1 p-0 ${location.pathname.startsWith('/messaging') ? !showList ? 'p-0' : 'pb-14' : 'pb-14'} md:p-4 ${isPublicRoute ? 'pl-4' : 'md:pl-0'} flex flex-col overflow-hidden duration-300 ease-in-out`

    usePreventInputZoom();

    return (
        <div className={`flex h-screen ${isLoginOrSignup ? ('bg-auth-theme h-64 w-full') : ('bg-theme')} overflow-hidden`}>
            {(!authChecked || isAuthenticated) && !hideNavbar && !isPublicRoute && (
                <>
                    <NavbarSide theme={theme} toggleTheme={toggleTheme} />
                    <NavbarMobile theme={theme} />
                </>
            )}

            {isLoginOrSignup && (
                <HeroSlider theme={theme} />
            )}

            <div className={containerClassName}>
                <div className="bg-surface-theme border-theme md:border-2 h-full md:rounded-lg flex flex-col md:overflow-auto overflow-hidden md:p-0">
                    <div className={containerClassName2}>
                        {(!authChecked || isAuthenticated) && !hideNavbar && !isPublicRoute && <NavbarTop />}
                        <Routes>
                            <Route path="/" element={<Navigate to={isAuthenticated ? "/dashboard" : "/login"} />} />
                            <Route element={<PublicRoute />}>
                                <Route path="/login" element={<LoginPage theme={theme} />} />
                                <Route path="/signup" element={<RegisterPage theme={theme} />} />
                                <Route path="/forgot-password" element={<ForgotPasswordPage theme={theme} />} />
                                <Route path="/reset-password" element={<ResetPasswordPage theme={theme} />} />
                                <Route path="/terms-of-service" element={<TermsOfServicePage />} />
                                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                                <Route path="/legal-notice" element={<LegalNoticePage />} />
                            </Route>
                            <Route element={<PrivateRoute />}>
                                <Route path="/create-company" element={hasCompany ? <Navigate to="/dashboard" /> : <CreateCompanyForm theme={theme} />} />
                                <Route element={<CompanyCheckRoute />}>
                                    <Route path="/dashboard" element={<DashboardPage />} />
                                    <Route path="/customers/*" element={<Customers />} />
                                    <Route path="/projects/*" element={<ProjectsPage />} />
                                    <Route path="/projects/:projectId/:quoteId" element={<QuoteDetail />} />
                                    {/*<Route path="/documents/*" element={<DocumentPage />} />*/}
                                    <Route path="/messaging/*" element={<MessagingPage />} />
                                    <Route path="/settings" element={<SettingsPage />} />
                                </Route>
                            </Route>
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    );
};

const AppWrapper: React.FC = () => (
    <Router>
        <PageProvider>
            <App />
        </PageProvider>
    </Router>
);

export default AppWrapper;