import React from 'react';
import {FiArrowLeft} from "react-icons/fi";
import {useNavigate} from "react-router-dom";

const PrivacyPolicyPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className="prose max-w-4xl mx-auto p-6">
            <div className="flex items-center justify-between mb-8">
                <button
                    onClick={() => navigate(-1)}
                    className="flex items-center gap-2 text-sm text-primary-theme py-2 rounded-lg transition"
                >
                    <FiArrowLeft className="text-lg" />
                    Retour
                </button>
                <h1 className="text-center text-lg sm:text-2xl font-semibold mx-auto w-full left-0 right-0 pointer-events-none">
                    Politique de Confidentialité
                </h1>
            </div>

            <div className="mb-6">
                <p>Version en vigueur au : 1 avril 2025</p>
            </div>

            <div className="mb-6">
                <h2 className="text-lg sm:text-xl font-semibold mb-2">1. Introduction</h2>
                <p>
                    La présente Politique de Confidentialité décrit les informations que nous collectons, utilisons et protégeons dans le cadre de l’utilisation de la plateforme Bricksly.
                </p>
            </div>

            <div className="mb-6">
                <h2 className="text-lg sm:text-xl font-semibold mb-2">2. Données collectées</h2>
                <p>a) Nom, prénom, adresse email</p>
                <p>b) Nom de l’entreprise, adresse postale, numéro SIRET</p>
                <p>c) Adresse IP, type de navigateur, appareil</p>
                <p>d) Documents, messages échangés, données de projets</p>
            </div>

            <div className="mb-6">
                <h2 className="text-lg sm:text-xl font-semibold mb-2">3. Finalités du traitement</h2>
                <p>a) Fournir et gérer l’accès aux services</p>
                <p>b) Assurer le bon fonctionnement de la Plateforme</p>
                <p>c) Améliorer la qualité du service</p>
                <p>d) Fournir une assistance technique</p>
            </div>

            <div className="mb-6">
                <h2 className="text-lg sm:text-xl font-semibold mb-2">4. Base légale du traitement</h2>
                <p>a) Le consentement</p>
                <p>b) L’exécution du contrat</p>
                <p>c) L’intérêt légitime des fondateurs</p>
            </div>

            <div className="mb-6">
                <h2 className="text-lg sm:text-xl font-semibold mb-2">5. Durée de conservation</h2>
                <p>
                    Les données sont conservées tant que le compte utilisateur est actif. Elles peuvent être supprimées à la demande de l’utilisateur ou à la suppression du compte.
                </p>
            </div>

            <div className="mb-6">
                <h2 className="text-lg sm:text-xl font-semibold mb-2">6. Sécurité</h2>
                <p>
                    Des mesures de sécurité appropriées sont mises en place pour protéger les données contre tout accès non autorisé ou illégal.
                </p>
            </div>

            <div className="mb-6">
                <h2 className="text-lg sm:text-xl font-semibold mb-2">7. Droits des utilisateurs</h2>
                <p>a) Droit d’accès</p>
                <p>b) Droit de rectification</p>
                <p>c) Droit à l’effacement</p>
                <p>d) Droit à la limitation du traitement</p>
                <p>e) Droit d’opposition</p>
                <p>
                    Pour exercer vos droits, contactez-nous à : <a href="mailto:contact@bricksly.fr" className="text-primary underline">contact@bricksly.fr</a>
                </p>
            </div>

            <div className="mb-6">
                <h2 className="text-lg sm:text-xl font-semibold mb-2">8. Hébergement</h2>
                <p>
                    Les données sont hébergées en France chez <a href="https://dyjix.eu/" className="text-primary underline">Dyjix</a>, en conformité avec le RGPD.
                </p>
            </div>

            <div className="mb-6">
                <h2 className="text-lg sm:text-xl font-semibold mb-2">9. Mise à jour</h2>
                <p>
                    La présente Politique peut faire l’objet de mises à jour. Toute modification significative sera communiquée via la Plateforme.
                </p>
            </div>
            <div>
                <p className="text-primary text-xs text-center cursor-pointer"><a className="mr-2" onClick={() => navigate('/terms-of-service')}> Conditions Générales d’Utilisation</a><a className="mr-2" onClick={() => navigate('/privacy-policy')}>Politique de Confidentialité</a><a onClick={() => navigate('/legal-notice')}>Mentions légales</a></p>
            </div>
        </div>
    );
};

export default PrivacyPolicyPage;