import React, { useState, useEffect, useRef } from 'react';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
    DroppableProvided,
    DraggableProvided,
    DraggableStateSnapshot
} from 'react-beautiful-dnd';
import { FiRotateCw, FiCheck, FiClock, FiMaximize2, FiMinimize2, FiFile, FiImage } from 'react-icons/fi';
import { formatPrice } from '../../../utils/formatPrice';
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { formatDate } from "../../../utils/dateUtils";
import { formatFileSize } from "../../../utils/fileUtils";
import DragScrollContainer from "../../../components/DragScrollContainer";
import { RiDraggable } from "react-icons/ri";
import { useMissions } from "../../../hooks/useMissions";
import { useProjects } from "../../../hooks/useProjects";
import { useUserPermissions } from '../../../hooks/useUserPermissions';
import {t} from "i18next";
import {fetchMissionsByProjectId} from "../missionSlice";

type Mission = {
    uuid: string;
    title: string;
    description: string;
    exceptedEndDate: string;
    unitPrice: number;
    position: number;
    quantity: number;
    status: string;
    documents?: Document[];
    createdAt?: string;
    updatedAt?: string;
};

type Document = {
    uuid: string;
    downloadUrl: string;
    publicUrl: string;
    mimeType: string;
    originalName: string;
    size: number;
    createdAt: string;
};

interface TimelineProps {
    missions: Mission[];
    projectUuid: string;
    projectStatus?: string;
}

const Timeline: React.FC<TimelineProps> = ({ missions, projectUuid, projectStatus }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { updateMissionsPositions } = useMissions(projectUuid);
    const [orderedMissions, setOrderedMissions] = useState<Mission[]>([]);
    const [expandedMissions, setExpandedMissions] = useState<Record<string, boolean>>({});
    const [shouldShowGradient, setShouldShowGradient] = useState<Record<string, boolean>>({});
    const descriptionRefs = useRef<Record<string, HTMLDivElement | null>>({});
    const { modifyProject } = useProjects();
    const { isCompanyProfile } = useUserPermissions();

    useEffect(() => {
        const sortedMissions = [...missions].sort((a, b) => {
            // Tri explicite par la propriété position
            return a.position - b.position;
        });
        setOrderedMissions(sortedMissions);

        // Reste du code pour l'initialisation...
        const initialExpandedState = missions.reduce((acc, mission) => ({
            ...acc,
            [mission.uuid]: false
        }), {});
        setExpandedMissions(initialExpandedState);

        const initialGradientState = missions.reduce((acc, mission) => ({
            ...acc,
            [mission.uuid]: false
        }), {});
        setShouldShowGradient(initialGradientState);
    }, [missions]);

    useEffect(() => {
        let resizeObserver: ResizeObserver;

        const checkContentHeight = () => {
            // On utilise requestAnimationFrame pour éviter les boucles infinies
            requestAnimationFrame(() => {
                orderedMissions.forEach(mission => {
                    const element = descriptionRefs.current[mission.uuid];
                    if (element) {
                        const lineHeight = parseInt(window.getComputedStyle(element).lineHeight) || 20;
                        const shouldShow = element.scrollHeight > (lineHeight * 1.2);
                        setShouldShowGradient(prev => ({
                            ...prev,
                            [mission.uuid]: shouldShow
                        }));
                    }
                });
            });
        };

        setTimeout(() => {
            checkContentHeight();

            resizeObserver = new ResizeObserver((entries) => {
                requestAnimationFrame(checkContentHeight);
            });

            orderedMissions.forEach(mission => {
                const element = descriptionRefs.current[mission.uuid];
                if (element) {
                    resizeObserver.observe(element);
                }
            });
        }, 0);

        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        };
    }, [orderedMissions]);

    const reorderMissions = (missions: Mission[], sourceIndex: number, destinationIndex: number): Mission[] => {
        const reordered = Array.from(missions);
        const [removed] = reordered.splice(sourceIndex, 1);
        reordered.splice(destinationIndex, 0, removed);

        // Conserver les positions d'origine et les réassigner dans le nouvel ordre
        const positions = missions.map(m => m.position).sort((a, b) => a - b);
        return reordered.map((mission, index) => ({
            ...mission,
            position: positions[index]
        }));
    };

    const handleDragEnd = async (result: DropResult) => {
        if (!result.destination) return;

        // Sauvegarde des missions actuelles avant modification
        const currentMissions = [...orderedMissions];

        // Calcul du nouvel ordre
        const newOrder = reorderMissions(orderedMissions, result.source.index, result.destination.index);

        // Mise à jour immédiate de l'UI pour une expérience fluide
        setOrderedMissions(newOrder);

        try {
            // Préparation du payload pour l'API
            const projectPayload = {
                missions: newOrder.map(({ createdAt, updatedAt, ...mission }) => ({
                    ...mission,
                })),
            };

            // Appel API pour mettre à jour le projet
            await modifyProject(projectUuid, projectPayload);

            // Restaurer temporairement l'état pour éviter un flash
            setOrderedMissions(currentMissions);

            // Recharger explicitement les missions complètes après la mise à jour
            const missionsData = await dispatch(fetchMissionsByProjectId(projectUuid)).unwrap();

            // Mettre à jour avec les données fraîches une fois reçues
            if (missionsData && missionsData.missions && missionsData.missions.length > 0) {
                const sortedMissions = [...missionsData.missions].sort((a, b) => a.position - b.position);
                setOrderedMissions(sortedMissions);
            }

        } catch (error) {
            // En cas d'erreur, restaurer l'état précédent
            console.error('Erreur lors de la mise à jour des positions:', error);
            setOrderedMissions(currentMissions);
        }
    };

    const toggleExpanded = (missionUuid: string) => {
        setExpandedMissions(prev => ({
            ...prev,
            [missionUuid]: !prev[missionUuid]
        }));
    };

    const { startMission, finishMission } = useMissions(projectUuid);

    const handleStatusChange = async (missionUuid: string, newStatus: string) => {
        // Sauvegarde de l'état actuel
        const currentMissions = [...orderedMissions];

        try {
            if (newStatus === 'in_progress') {
                await startMission(missionUuid);
            } else if (newStatus === 'completed') {
                await finishMission(missionUuid);
            }

            // Restaurer temporairement l'état pour éviter un flash
            setOrderedMissions(currentMissions);

            // Recharger explicitement les missions
            const missionsData = await dispatch(fetchMissionsByProjectId(projectUuid)).unwrap();

            // Mettre à jour avec les données fraîches
            if (missionsData && missionsData.missions && missionsData.missions.length > 0) {
                const sortedMissions = [...missionsData.missions].sort((a, b) => a.position - b.position);
                setOrderedMissions(sortedMissions);
            }

        } catch (error) {
            console.error('Erreur lors du changement de statut:', error);
            setOrderedMissions(currentMissions);
        }
    };

    const getStatusIcon = (status: string) => {
        switch (status) {
            case 'draft':
                return;
            case 'in_progress':
                return <FiRotateCw className="text-primary-theme" size="14" />;
            case 'completed':
                return <FiCheck className="text-color-primary" size="14" />;
            default:
                return <FiClock className="text-primary-theme" size="14" />;
        }
    };

    if (!isCompanyProfile) {
        return (
            <div>
                {orderedMissions.length > 0 && projectUuid ? (
                    <ol className="relative ml-3 border-l border-theme">
                        {orderedMissions.map((mission) => (
                            <li key={mission.uuid} className="mb-10">
                                <div className="ml-6">
                                    <span className={`absolute flex items-center justify-center w-6 h-6 border-2 bg-surface-theme rounded-full -left-3 ring-8 ring-transparent ${
                                        mission.status !== 'draft' ? "border-[#846EE8] bg-theme" : "bg-theme"
                                    }`}>
                                        {getStatusIcon(mission.status)}
                                    </span>
                                    <div>
                                        <div className="flex justify-between items-center mb-4">
                                            <p className="text-primary-theme font-medium text-sm">
                                                {mission.title}
                                                <span className="text-secondary-theme">
                                                {mission.status === 'draft' ? "" :
                                                    mission.status === 'in_progress' ? " en cours depuis le" : " terminée le "}
                                                </span>
                                                {mission.status === 'draft' ? "" :
                                                    <time>{new Date(mission.exceptedEndDate).toLocaleDateString('fr-FR')}</time>}
                                            </p>
                                        </div>
                                        <div className="bg-theme rounded-xl p-1 drop-shadow-[0px_5px_10px_rgba(22,_19,_35,_0.02)]">
                                            <div>
                                                <div className="flex justify-between items-center mb-1 py-2.5 px-4">
                                                    <div className="flex items-center">
                                                        <p className="text-primary-theme font-medium text-sm">{mission.title}</p>
                                                    </div>
                                                    <p className="text-color-primary font-medium text-base">{formatPrice(mission.unitPrice)} €</p>
                                                </div>
                                            </div>
                                            <div className="bg-surface-theme py-2.5 px-4 rounded-lg text-sm text-[#8A8991] font-normal">
                                                <div className="flex items-center justify-between mb-2.5">
                                                    <p className="text-primary-theme text-xs font-medium">{t('project.description')}</p>
                                                    <button
                                                        className="ml-2 text-primary-theme hover:text-color-primary-hover"
                                                        onClick={() => toggleExpanded(mission.uuid)}
                                                    >
                                                        {expandedMissions[mission.uuid] ?
                                                            <FiMinimize2 size="14" /> :
                                                            <FiMaximize2 size="14" />}
                                                    </button>
                                                </div>
                                                <div
                                                    className={`text-primary-theme ${
                                                        !expandedMissions[mission.uuid] ? "overflow-hidden" : ""
                                                    }`}
                                                    style={{
                                                        maxHeight: expandedMissions[mission.uuid] ? "none" : "4.5rem",
                                                        position: "relative",
                                                    }}
                                                >
                                                    <div
                                                        ref={el => descriptionRefs.current[mission.uuid] = el}
                                                        className="leading-5"
                                                    >
                                                        <p className="text-primary-theme font-normal text-sm">
                                                            {mission.description}
                                                        </p>
                                                    </div>

                                                    {!expandedMissions[mission.uuid] && shouldShowGradient[mission.uuid] && (
                                                        <div className="absolute bottom-0 left-0 w-full h-8 bg-gradient-to-t from-surface-theme to-transparent"/>
                                                    )}
                                                </div>

                                                {!expandedMissions[mission.uuid] ? (
                                                    <p className="text-primary mt-2 text-xs">{mission.documents && mission.documents.length > 0 ? mission.documents.length + " Documents joints" : ""}</p>
                                                ) : (
                                                    <DragScrollContainer className="flex gap-x-2 mt-2">
                                                        {mission.documents && mission.documents.length > 0 && (
                                                            mission.documents.map((document: any) => {
                                                                return (
                                                                    <div
                                                                        key={document.uuid}
                                                                        className="flex bg-theme rounded-lg p-2 items-center cursor-pointer"
                                                                    >
                                                                        <div className="text-xl mr-4 bg-surface-theme p-3 rounded-md text-primary-theme">
                                                                            {document.mimeType === 'application/pdf' ?
                                                                                <FiFile /> :
                                                                                <FiImage />}
                                                                        </div>
                                                                        <div className="w-full">
                                                                            <p className="text-sm font-medium w-48 truncate text-primary-theme">
                                                                                {document.originalName}
                                                                            </p>
                                                                            <span className="text-xs text-primary-theme">
                                                                {formatDate(document.createdAt)}{' '}
                                                            </span>
                                                                            <span className="text-xs text-secondary-theme">
                                                                {formatFileSize(document.size)}
                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </DragScrollContainer>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ol>
                ) : (
                    <p className="text-primary-theme">{t('project.noMissions')}</p>
                )}
            </div>
        );
    }

    return (
        <div>
            {orderedMissions.length > 0 && projectUuid ? (
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="missions">
                        {(provided: DroppableProvided) => (
                            <ol className="relative ml-3 border-l border-theme"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {orderedMissions.map((mission, index) => (
                                    <Draggable
                                        key={mission.uuid}
                                        draggableId={mission.uuid}
                                        index={index}
                                    >
                                        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                                            <li
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                className="mb-10"
                                            >
                                                <div className="ml-6">
                                                    <span
                                                        className={`absolute flex items-center justify-center w-6 h-6 border-2 bg-surface-theme rounded-full -left-3 ring-8 ring-transparent ${
                                                            mission.status !== 'draft' ? "border-[#846EE8] bg-theme" : "bg-theme"
                                                        }`}
                                                    >
                                                        {getStatusIcon(mission.status)}
                                                    </span>
                                                    <div className={`${snapshot.isDragging ? "opacity-75" : ""}`}>
                                                        <div className="flex justify-between items-center mb-4">
                                                            <p className="text-primary-theme font-medium text-sm">
                                                                {mission.title}
                                                                <span className="text-secondary-theme">
                                                                    {mission.status === 'draft' ? "" :
                                                                        mission.status === 'in_progress' ? " en cours depuis le " :
                                                                            " terminée le "}
                                                                </span>
                                                                {mission.status === 'draft' ? "" :
                                                                    <time>{new Date(mission.exceptedEndDate).toLocaleDateString('fr-FR')}</time>}
                                                            </p>
                                                            <div className="ml-4 flex items-center justify-between">
                                                                <div>
                                                                    {mission.status === 'draft' && projectStatus === 'validated' && (
                                                                        <div className="flex items-center">
                                                                            <p className="mr-2 text-secondary-theme text-sm">{t('project.mission.start.label')}</p>
                                                                            <button
                                                                                className="text-primary-theme bg-primary px-2 rounded-md text-sm text-white"
                                                                                onClick={() => handleStatusChange(mission.uuid, 'in_progress')}
                                                                            >
                                                                                {t('project.mission.start.button')}
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                    {mission.status === 'in_progress' && projectStatus === 'validated' && (
                                                                        <div className="flex items-center">
                                                                            <p className="mr-2 text-secondary-theme text-sm">{t('project.mission.end.label')}</p>
                                                                            <button
                                                                                className="bg-primary px-2 rounded-md text-sm text-white"
                                                                                onClick={() => handleStatusChange(mission.uuid, 'completed')}
                                                                            >
                                                                                {t('project.mission.end.button')}
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="bg-theme rounded-xl p-1 drop-shadow-[0px_5px_10px_rgba(22,_19,_35,_0.02)]">
                                                            <div>
                                                                <div
                                                                    {...provided.dragHandleProps}
                                                                    className="flex justify-between items-center mb-1 cursor-move hover:bg-surface-theme transition-colors py-2.5 px-4"
                                                                >
                                                                    <div className="flex items-center">
                                                                        <RiDraggable className="mr-2 text-[1.3rem]" />
                                                                        <p className="text-primary-theme font-medium text-base">{mission.title}</p>
                                                                    </div>
                                                                    <p className="text-color-primary font-medium text-base">{formatPrice(mission.unitPrice * mission.quantity)} €</p>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="bg-surface-theme py-2.5 px-4 rounded-lg text-sm text-[#8A8991] font-normal">
                                                                <div
                                                                    className="flex items-center justify-between mb-2.5">
                                                                    <p className="text-primary-theme text-xs font-medium">{t('project.description')}</p>
                                                                    <button
                                                                        className="ml-2 text-primary-theme hover:text-color-primary-hover"
                                                                        onClick={() => toggleExpanded(mission.uuid)}
                                                                    >
                                                                        {expandedMissions[mission.uuid] ?
                                                                            <FiMinimize2 size="14" /> :
                                                                            <FiMaximize2 size="14" />}
                                                                    </button>
                                                                </div>
                                                                <div
                                                                    className={`text-primary-theme ${
                                                                        !expandedMissions[mission.uuid] ? "overflow-hidden" : ""
                                                                    }`}
                                                                    style={{
                                                                        maxHeight: expandedMissions[mission.uuid] ? "none" : "4.5rem",
                                                                        position: "relative",
                                                                    }}
                                                                >
                                                                    <div
                                                                        ref={el => descriptionRefs.current[mission.uuid] = el}
                                                                        className="leading-5"
                                                                    >
                                                                        <p className="text-primary-theme text-sm">
                                                                            {mission.description}
                                                                        </p>
                                                                    </div>

                                                                    {!expandedMissions[mission.uuid] && shouldShowGradient[mission.uuid] && (
                                                                        <div
                                                                            className="absolute bottom-0 left-0 w-full h-8 bg-gradient-to-t from-surface-theme to-transparent"
                                                                        />
                                                                    )}
                                                                </div>

                                                                {!expandedMissions[mission.uuid] ? (
                                                                    <p className="text-primary mt-2 text-xs">{mission.documents && mission.documents.length > 0 ? mission.documents.length + " Documents joints" : ""}</p>
                                                                ) : (
                                                                    <DragScrollContainer className="flex gap-x-2 mt-2">
                                                                        {mission.documents && mission.documents.length > 0 && (
                                                                            mission.documents.map((document: any) => {
                                                                                return (
                                                                                    <div
                                                                                        key={document.uuid}
                                                                                        className="flex bg-theme rounded-lg p-2 items-center cursor-pointer"
                                                                                    >
                                                                                        <div
                                                                                            className="text-xl mr-4 bg-surface-theme p-3 rounded-md text-primary-theme">
                                                                                            {document.mimeType === 'application/pdf' ?
                                                                                                <FiFile /> :
                                                                                                <FiImage />}
                                                                                        </div>
                                                                                        <div className="w-full">
                                                                                            <p className="text-sm font-medium w-48 truncate text-primary-theme">
                                                                                                {document.originalName}
                                                                                            </p>
                                                                                            <span
                                                                                                className="text-xs text-primary-theme">
                                                                                                {formatDate(document.createdAt)}{' '}
                                                                                            </span>
                                                                                            <span
                                                                                                className="text-xs text-secondary-theme">{formatFileSize(document.size)}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            })
                                                                        )}
                                                                    </DragScrollContainer>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </ol>
                        )}
                    </Droppable>
                </DragDropContext>
            ) : (
                <p className="text-primary-theme">{t('project.noMissions')}</p>
            )}
        </div>
    );
};

export default Timeline;