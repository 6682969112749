import React, { useEffect, useState, useRef } from 'react';
import {useNavigate, useParams } from 'react-router-dom';
import Skeleton from '../../components/Skeleton';
import ProfileCard from '../../components/ProfileCard';
import Tabs from '../../components/Tabs';
import Timeline from "./components/Timeline";
import Overview from "./components/Overview";
import Documents from "./components/Documents";
import ProjectForm from './form/ProjectForm';
import { useProjects } from '../../hooks/useProjects';
import { useMissions } from '../../hooks/useMissions';
import { useCompany } from '../../hooks/useCompany';
import { useSelector } from 'react-redux';
import { selectLoading, selectUser } from "../auth/authSlice";
import { useCustomers } from "../../hooks/useCustomers";
import { usePageContext } from "../../contexts/PageContext";
import SidePanel from '../../components/SidePanel';
import MessageWidget from "../../components/MessageWidget";
import DropOverlay from "../messaging/components/DropOverlay";
import ThreadMessagesList from "../messaging/components/ThreadMessagesList";
import ThreadMessageInput from "../messaging/components/ThreadMessageInput";
import { useUserPermissions } from '../../hooks/useUserPermissions';
import {t} from "i18next";
import Button from "../../components/UI/Button";
import {setSelectedProject, updateProjectInList} from "./projectSlice";
import {useAppDispatch} from "../../store/hooks";
import type { Project } from './types';
import {navigate} from "@storybook/addon-links";

// Interfaces
interface Customer {
    uuid: string;
    firstName: string;
    lastName: string;
    companyName: string;
    email: string;
}

interface ProjectFormData {
    uuid: string;
    title: string;
    description: string;
    customer: Customer;
}

interface FormData {
    title: string;
    description: string;
    customer: string;
}

interface Mission {
    uuid: string;
    title: string;
    description: string;
    status: string;
    unitPrice: number;
    position: number;
    quantity: number;
    exceptedEndDate: string;
    projectUuid: string;
    createdAt: string;
    updatedAt: string;
}

interface Quotes {
    uuid: string;
}

// interface Project {
//     uuid: string;
//     title: string;
//     description: string;
//     customer?: {
//         uuid: string;
//         email: string;
//         phone: string;
//         firstName: string;
//         lastName: string;
//         companyName: string;
//     };
//     thread: {
//         uuid: string;
//     };
//     createdBy: {
//         uuid: string;
//         email: string;
//         phone: string;
//         firstName: string;
//         lastName: string;
//         company: {
//             legalName: string;
//             commercialName: string;
//             companyNumber: string;
//             logo: {
//                 uuid: string;
//             };
//         };
//     };
//     status: {
//         label: string;
//         value: string;
//     };
//     createdAt: string;
//     updatedAt: string;
//     missions?: Mission[];
//     quotes?: Quotes[];
// }

const ProjectDetail: React.FC = () => {
    const { uuid } = useParams<{ uuid: string }>();
    const [activeTab, setActiveTab] = useState<'overview' | 'timeline' | 'documents'>('overview');
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const projectFormRef = useRef<any>(null);
    const user = useSelector(selectUser);
    const userLoading = useSelector(selectLoading);
    const { isCompanyProfile } = useUserPermissions();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isCreatingQuote, setIsCreatingQuote] = useState(false);

    const {
        selectedProject: project,
        loading: projectLoading,
        createQuote,
        inviteCustomer,
    } = useProjects(uuid);

    const {
        missions,
        loading: missionsLoading,
        error: missionsError,
    } = useMissions(uuid || '');

    const { company, loading: companyLoading } = useCompany(user?.company?.uuid ?? '');

    const customerUuid = typeof project?.customer === 'string'
        ? project.customer
        : (project?.customer && 'uuid' in project.customer)
            ? (project.customer as { uuid: string }).uuid
            : undefined;

    const customerIdToFetch = isCompanyProfile ? customerUuid : undefined;
    const {
        selectedCustomer: customer,
        loading: customerLoading,
    } = useCustomers(customerIdToFetch);

    // const { selectedProject, loading: isProjectLoading } = useProjects(uuid);
    const { setDynamicData } = usePageContext();

    const [formData, setFormData] = useState<FormData>({
        title: '',
        description: '',
        customer: '',
    });

    // Formatage des données du projet pour le ProjectForm
    const getFormattedProjectData = (): ProjectFormData | null => {
        if (!project || !uuid) return null;

        const formattedData: ProjectFormData = {
            uuid: project.uuid,
            title: project.title,
            description: project.description,
            // Créer un objet customer par défaut si aucun client n'existe
            customer: customer ? {
                uuid: customer.uuid,
                firstName: customer.firstName,
                lastName: customer.lastName,
                companyName: customer.companyName,
                email: customer.email
            } : {
                uuid: '', // Chaîne vide au lieu de undefined
                firstName: '',
                lastName: '',
                companyName: '',
                email: ''
            }
        };

        return formattedData;
    };

    useEffect(() => {
        if (project && uuid) {
            setDynamicData({ [uuid]: project.title });
            setFormData({
                title: project.title,
                description: project.description,
                customer: customerUuid || '',
            });
        }
    }, [project, uuid, setDynamicData, customerUuid]);

    const handleEditClick = () => {
        setIsSidePanelOpen(true);
    };

    const handleCloseSidePanel = () => {
        setIsSidePanelOpen(false);
    };

    const handleValidate = async () => {
        setIsLoading(true);
        if (projectFormRef.current) {
            await projectFormRef.current.submitForm();
        }
        setIsLoading(false);
        setIsSidePanelOpen(false);
    };

    const handleInviteCustomer = async () => {
        try {
            if (project?.uuid) {
                await inviteCustomer(project.uuid);

                const updatedProject: Project = {
                    ...project,
                    visibility: {
                        label: 'Public',
                        value: 'public',
                    }
                };

                dispatch(setSelectedProject(updatedProject));
                dispatch(updateProjectInList({
                    uuid: updatedProject.uuid,
                    visibility: updatedProject.visibility,
                }));
            }
        } catch (error) {
            console.error('Failed to invite customer:', error);
        }
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'overview':
                return (
                    <Overview
                        project={project as Project}
                        company={company}
                        missions={missions}
                    />
                );
            case 'timeline':
                if (missionsLoading) {
                    return <div className="text-primary-theme">Chargement de la timeline...</div>;
                }
                if (missionsError) {
                    return <div className="text-red-500">Erreur : {missionsError}</div>;
                }
                return (
                    <Timeline
                        missions={missions}
                        projectUuid={uuid || ''}
                        projectStatus={project?.status.value}
                    />
                );
            case 'documents':
                return (
                    <Documents project={project as Project} />
                );
            default:
                return null;
        }
    };

    if (projectLoading || !project || userLoading || companyLoading || missionsLoading) {
        return (
            <div className="p-4 space-y-4">
                <Skeleton width="50%" height="30px" />
                <Skeleton width="100%" height="20px" />
                <Skeleton width="100%" height="20px" />
                <Skeleton width="100%" height="20px" />
            </div>
        );
    }

    const formattedProjectData = getFormattedProjectData();

    return (
        <div className="bg-surface-theme">
            {/* En-tête avec les onglets et les boutons */}
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4 gap-4">
                <Tabs
                    tabs={[
                        { label: t('project.tab.overview'), value: 'overview' },
                        { label: t('project.tab.missions'), value: 'timeline' },
                        { label: t('project.tab.documents'), value: 'documents' },
                    ]}
                    activeTab={activeTab}
                    onSelectTab={(tab) => setActiveTab(tab as 'overview' | 'timeline' | 'documents')}
                />
                {isCompanyProfile && (
                    <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 w-full lg:whitespace-nowrap sm:w-auto">
                        {project.status.value === "draft" && (
                            <>
                                <Button
                                    border
                                    background={false}
                                    size="medium"
                                    onClick={handleEditClick}
                                >
                                    {t('project.edit')}
                                </Button>

                                {Array.isArray(project.quotes) &&
                                    !project.quotes.some((q) =>
                                        ['draft', 'validated', 'signed'].includes(q.status?.toLowerCase())
                                    ) && (
                                        <Button
                                            size="medium"
                                            disabled={isCreatingQuote}
                                            onClick={async () => {
                                                try {
                                                    if (uuid) {
                                                        setIsCreatingQuote(true);
                                                        const newQuote = await createQuote(uuid);
                                                        if (newQuote?.uuid) {
                                                            navigate(`/projects/${uuid}/${newQuote.uuid}`);
                                                        }
                                                    }
                                                } catch (error) {
                                                    console.error("Erreur création devis :", error);
                                                } finally {
                                                    setIsCreatingQuote(false);
                                                }
                                            }}
                                        >
                                            {isCreatingQuote ? 'Création du devis' : t('project.createQuote')}
                                        </Button>
                                    )}
                            </>
                        )}

                    </div>
                )}
            </div>

            {/* Contenu principal */}
            <div className="flex flex-col lg:flex-row gap-4">
                {/* Section principale */}
                <div className="w-full lg:w-2/3">
                    <div>{renderContent()}</div>
                </div>

                {/* fiche de contact */}
                <div className="w-full lg:w-1/3">
                    <div className="md:sticky top-4">
                        <div className="bg-theme rounded-xl p-1 drop-shadow-[0px_5px_10px_rgba(22,_19,_35,_0.02)]">
                            <div className="py-2.5 px-4 mb-1">
                                <p className="text-primary-theme font-semibold text-base">{t('project.contact')}</p>
                                {customer?.companyName && (
                                    <span className="text-primary-theme font-normal text-xs">{customer.companyName}</span>
                                )}
                            </div>

                            {isCompanyProfile ? (
                                <>
                                    <div className="bg-surface-theme py-2.5 px-4 rounded-lg text-sm">
                                        {customer ? (
                                            <ProfileCard
                                                imageUrl=""
                                                firstName={customer.firstName}
                                                lastName={customer.lastName}
                                                label={customer.companyName}
                                                size="large"
                                            />
                                        ) : (
                                            <>
                                                <div className="text-center flex flex-col items-center">
                                                    <p className="mb-4">Vous n'avez ajouté aucun client</p>
                                                    <div>
                                                        <Button
                                                            size="medium"
                                                            onClick={handleEditClick}
                                                        >
                                                            Ajouter un client
                                                        </Button>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <div className="bg-surface-theme py-2.5 px-4 rounded-lg text-sm">
                                    {project.createdBy ? (
                                        <ProfileCard
                                            imageUrl=""
                                            firstName={project.createdBy.firstName}
                                            lastName={project.createdBy.lastName}
                                            label={project.createdBy.company.commercialName}
                                            size="large"
                                        />
                                    ) : (
                                        <>
                                        </>
                                    )}
                                </div>
                            )}
                            {customer && isCompanyProfile ? (
                                <div className="mt-1">
                                    <MessageWidget
                                        threadId={project.thread?.uuid}
                                        projectUuid={project.uuid}
                                        showInviteOverlay={project.visibility.value === "private"}
                                        onInviteClick={handleInviteCustomer}
                                        customer={project.customer}
                                    />
                                </div>
                            ) : project.thread?.uuid && (
                                <div className="mt-1">
                                    <MessageWidget
                                        threadId={project.thread?.uuid}
                                        projectUuid={project.uuid}
                                        showInviteOverlay={project.visibility.value === "private"}
                                        onInviteClick={handleInviteCustomer}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <SidePanel
                isOpen={isSidePanelOpen}
                onClose={handleCloseSidePanel}
                currentPageIndex={0}
                setCurrentPageIndex={() => {
                }}
                onValidate={handleValidate}
                isLoading={isLoading}
            >
                <div data-page="1" data-validation-text="Modifier" data-has-navigation="false">
                    {formattedProjectData && (
                        <ProjectForm
                            ref={projectFormRef}
                            onSuccess={handleCloseSidePanel}
                            projectData={formattedProjectData}
                            isEditMode={true}
                            setFormData={setFormData}
                            formData={formData}
                            isLoading={isLoading}
                        />
                    )}
                </div>
            </SidePanel>
        </div>
    );
};

export default ProjectDetail;