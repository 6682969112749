import React, { useEffect, useRef, useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import Skeleton from '../../components/Skeleton';
import Modal from '../../components/Modal';
import ProfileCard from "../../components/ProfileCard";
import SidePanel from "../../components/SidePanel";
import CustomerForm from "./CustomerForm";
import { useCustomers } from '../../hooks/useCustomers'; // Import du hook
import { useNavigate } from 'react-router-dom';
import Button from "../../components/UI/Button";

const CustomerList: React.FC = () => {
    const {
        customers,
        selectedCustomer,
        setSelectedCustomer,
        loading,
        addCustomer,
        modifyCustomer,
        removeCustomer,
    } = useCustomers(); // Utilisation du hook

    const customerFormRef = useRef<any>(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [customerToDelete, setCustomerToDelete] = useState<any | null>(null);
    const [deleting, setDeleting] = useState(false);
    const [hoveredCustomer, setHoveredCustomer] = useState<string | null>(null);

    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [formLoading, setFormLoading] = useState(false); // État pour chargement formulaire

    const navigate = useNavigate();

    const handleViewDetails = (uuid: string) => {
        navigate(`/customers/${uuid}`);
    };

    const handleCreateCustomer = () => {
        setIsEditMode(false);
        setIsSidePanelOpen(true);
    };

    const handleEditCustomer = (customer: any) => {
        setIsEditMode(true);
        setSelectedCustomer(customer);
        setIsSidePanelOpen(true);
    };

    const closeSidePanel = (customerUuid?: string) => {
        setIsSidePanelOpen(false);
        if (customerUuid && !isEditMode) {
            navigate(`/customers/${customerUuid}`);
        }
    };

    const handleDeleteClick = (customer: any) => {
        setCustomerToDelete(customer);
        setShowConfirmModal(true);
    };

    const confirmDelete = async () => {
        if (customerToDelete) {
            setDeleting(true);
            try {
                await removeCustomer(customerToDelete.uuid);
            } finally {
                setDeleting(false);
                setShowConfirmModal(false);
                setCustomerToDelete(null);
            }
        }
    };

    const cancelDelete = () => {
        setShowConfirmModal(false);
        setCustomerToDelete(null);
    };

    const handleValidate = () => {
        if (customerFormRef.current) {
            customerFormRef.current.submitForm();
        }
    };

    return (
        <div>
            <div className="flex justify-end mb-4">
                <Button
                    size="medium"
                    onClick={handleCreateCustomer}
                >
                    Nouveau Client
                </Button>
            </div>

            {loading ? (
                <div className="space-y-4">
                    {[...Array(3)].map((_, i) => (
                        <div key={i} className="bg-theme p-4 rounded-lg border-theme border-2 cursor-pointer">
                            <Skeleton height="15px" width="70%" />
                            <Skeleton height="15px" width="50%" />
                        </div>
                    ))}
                </div>
            ) : (
                customers.length === 0 ? (
                    <p className="text-primary-theme">Aucun client trouvé.</p>
                ) : (
                    <div className="space-y-4">
                        {customers.map((customer: any) => (
                            <div
                                key={customer.uuid}
                                className="bg-theme p-4 rounded-lg border-theme border-2 flex justify-between items-center cursor-pointer"
                                onClick={() => handleViewDetails(customer.uuid)}
                            >
                                <ProfileCard
                                    imageUrl=""
                                    firstName={customer.firstName}
                                    lastName={customer.lastName}
                                    label={customer.companyName}
                                    size="large"
                                />
                                <div className="relative hover-menu">
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setHoveredCustomer(hoveredCustomer === customer.uuid ? null : customer.uuid);
                                        }}
                                        className="w-8 h-8 flex justify-center items-center border-2 border-theme rounded-full bg-surface-theme text-primary-theme hover:bg-theme-hover"
                                    >
                                        <FiMoreVertical />
                                    </button>

                                    {hoveredCustomer === customer.uuid && (
                                        <div
                                            className="absolute right-0 mt-2 w-40 bg-surface-theme border-2 border-theme rounded shadow-md z-10 hover-menu">
                                            <button
                                                className="block w-full text-left px-4 py-2 hover:bg-theme-hover rounded text-primary-theme"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleEditCustomer(customer);
                                                    setHoveredCustomer(null);
                                                }}
                                            >
                                                Modifier
                                            </button>
                                            <button
                                                className="block w-full text-left px-4 py-2 hover:bg-theme-hover rounded text-primary-theme"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDeleteClick(customer);
                                                    setHoveredCustomer(null);
                                                }}
                                            >
                                                Supprimer
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )
            )}

            <Modal show={showConfirmModal} onClose={cancelDelete} title="Confirmer la suppression">
                <p>Êtes-vous sûr de vouloir supprimer le client suivant ?</p>
                {customerToDelete && (
                    <p className="font-bold">{customerToDelete.firstName} {customerToDelete.lastName}</p>
                )}
                <div className="flex justify-end space-x-2 mt-4">
                    <button className="px-4 py-2 bg-gray-500 text-white rounded" onClick={cancelDelete}
                            disabled={deleting}>
                        Annuler
                    </button>
                    <button className="px-4 py-2 bg-red-500 text-white rounded"
                            onClick={confirmDelete} disabled={deleting}>
                        Supprimer
                    </button>
                </div>
            </Modal>

            <SidePanel
                isOpen={isSidePanelOpen}
                onClose={closeSidePanel}
                onValidate={handleValidate}
            >
                <div data-page="1">
                    <CustomerForm
                        ref={customerFormRef}
                        customerData={selectedCustomer}
                        isEditMode={isEditMode}
                        isLoading={formLoading}
                        onSuccess={closeSidePanel}
                    />
                </div>
            </SidePanel>
        </div>
    );
};

export default CustomerList;