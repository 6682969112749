import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { selectAuthChecked } from '../../features/auth/authSlice';

const CompanyCheckRoute: React.FC = () => {
    const hasCompany = useSelector((state: RootState) => state.auth.hasCompany);
    const authChecked = useSelector(selectAuthChecked);

    // Au lieu de cacher le composant, on le montre directement
    if (!authChecked) {
        return <Outlet />;
    }

    if (!hasCompany) {
        return <Navigate to="/create-company" />;
    }

    return <Outlet />;
};

export default CompanyCheckRoute;