import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated, selectAuthChecked } from '../../features/auth/authSlice';

const PublicRoute: React.FC = () => {
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const authChecked = useSelector(selectAuthChecked);
    const location = useLocation();

    if (!authChecked) {
        return null;
    }

    if (isAuthenticated) {
        return <Navigate to={`/dashboard${location.search}`} replace />;
    }

    return <Outlet />;
};

export default PublicRoute;