import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { login, getUserInfo, requestMagicLink, loginWithMagicLink } from '../../api/authService';
import { useDispatch } from 'react-redux';
import { setUser } from './authSlice';
import Button from "../../components/UI/Button";
import InputPassword from "../../components/InputPassword";
import {t} from "i18next";
import InputEmail from "../../components/InputEmail";
import Logo from "../../components/Logo";

type LoginFormProps = {
    theme: string;
};

const LoginForm: React.FC<LoginFormProps> = ({ theme }) => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showMagicLinkForm, setShowMagicLinkForm] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const username = queryParams.get('username');
        const magicAuthName = queryParams.get('magic-auth-name');
        const magicAuthToken = queryParams.get('magic-auth-token');

        if (username && magicAuthName && magicAuthToken) {
            handleMagicLinkLogin(username, magicAuthName, magicAuthToken);
        }
    }, [location]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleMagicLinkLogin = async (username: string, magicAuthName: string, magicAuthToken: string) => {
        setIsLoading(true);
        setErrorMessage('');

        try {
            // Connexion avec les paramètres du magic link
            await loginWithMagicLink(username, magicAuthName, magicAuthToken);

            // Si on arrive ici, c'est que le login a réussi
            const userInfoResponse = await getUserInfo();
            dispatch(setUser(userInfoResponse.data));

            // Redirection vers la page appropriée
            if (userInfoResponse.data.company) {
                navigate('/dashboard');
            } else {
                navigate('/create-company');
            }
        } catch (error) {
            setErrorMessage(t('login.magicLinkAuthError') || 'Erreur lors de la connexion avec le lien. Il peut avoir expiré ou être invalide.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setErrorMessage('');
        setSuccessMessage('');

        try {
            if (showMagicLinkForm) {
                // Envoi du magic link
                await requestMagicLink(formData.email);
                setSuccessMessage(t('login.magicLinkSent'));
            } else {
                // Login classique
                await login({
                    username: formData.email,
                    password: formData.password,
                });

                const userInfoResponse = await getUserInfo();
                dispatch(setUser(userInfoResponse.data));

                if (userInfoResponse.data.company) {
                    navigate('/dashboard');
                } else {
                    navigate('/create-company');
                }
            }
        } catch (error) {
            if (showMagicLinkForm) {
                setErrorMessage(t('login.magicLinkError') || 'Erreur lors de l\'envoi du lien de connexion');
            } else {
                setErrorMessage('Email ou mot de passe incorrect');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const toggleLoginMethod = () => {
        setShowMagicLinkForm(!showMagicLinkForm);
        setErrorMessage('');
        setSuccessMessage('');
    };

    return (
        <>
            <div className="min-h-screen flex items-center justify-center p-4">
                <div className="max-w-md w-full">
                    <div className="mb:space-y-[5rem] space-y-0">
                        <div
                            className="absolute top-[3.5rem] md:top-[2.125rem] left-1/2 -translate-x-1/2 md:left-auto md:right-[2.125rem] md:transform-none">
                            <Logo theme={theme}/>
                        </div>
                        <div className="space-y-[.313rem]">
                            <h2 className="text-center text-[2rem] leading-9 font-medium text-primary-theme">
                                {showMagicLinkForm ? t('login.magicLinkTitle') : t('login.title')}
                            </h2>
                            <p className="text-center text-xs leading-6 font-normal text-primary-theme">{t('login.noAccount')} <a className="text-primary cursor-pointer ml-2" onClick={() => navigate('/signup')}>{t('login.register')}</a></p>
                        </div>
                    </div>
                    <form className="mt-10 space-y-[1.25rem]" onSubmit={handleSubmit}>
                        <div className="space-y-[1.25rem]">
                            <div>
                                <InputEmail
                                    label={t('login.email.label')}
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder={t('login.email.placeholder')}
                                    required={true}
                                />
                            </div>

                            {!showMagicLinkForm && (
                                <div>
                                    <InputPassword
                                        label={t('login.password')}
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        placeholder={t('user.password')}
                                        required={true}
                                    />
                                </div>
                            )}
                        </div>

                        {errorMessage && (
                            <div className="text-center text-red-500 text-xs">
                                {errorMessage}
                            </div>
                        )}

                        {successMessage && (
                            <div className="text-center text-green-500 text-xs">
                                {successMessage}
                            </div>
                        )}

                        {!showMagicLinkForm && (
                            <div className="flex items-center justify-between">
                                <Button
                                    size="small"
                                    type="button"
                                    background={false}
                                    onClick={() => navigate('/forgot-password')}
                                >
                                    {t('login.forgotPassword')}
                                </Button>
                            </div>
                        )}

                        <div className="flex items-center justify-between">
                            <Button
                                type="submit"
                                isLoading={isLoading}
                                fullWidth
                                size="medium"
                            >
                                {showMagicLinkForm
                                    ? t('login.sendMagicLink')
                                    : t('login.submit')}
                            </Button>
                        </div>

                        <div className="flex items-center justify-center">
                            <Button
                                fullWidth
                                size="medium"
                                border
                                type="button"
                                background={false}
                                onClick={toggleLoginMethod}
                            >
                                {showMagicLinkForm
                                    ? t('login.usePassword')
                                    : t('login.useMagicLink')}
                            </Button>
                        </div>

                        <p className="text-center text-xs leading-6 font-normal text-primary-theme">En vous connectant, vous acceptez nos <a className="text-primary cursor-pointer ml-2" onClick={() => navigate('/terms-of-service')}>Conditions Générales d'Utilisation.</a></p>
                    </form>
                </div>
            </div>
        </>
    );
};

export default LoginForm;