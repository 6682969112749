import React, { useState } from 'react';
import {HiOutlineSparkles} from "react-icons/hi2";

interface InputPhoneProps {
    label: string;
    name: string;
    value: string;
    onChange: (value: string) => void; // onChange reçoit simplement la nouvelle valeur du téléphone
    placeholder?: string;
    required?: boolean;
}

const InputPhone: React.FC<InputPhoneProps> = ({ label, name, value, onChange, placeholder, required = false }) => {
    const [region, setRegion] = useState<string>('+33'); // Par défaut, France
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // Configurer les longueurs maximales par région
    const maxLengthMap: { [key: string]: number } = {
        '+33': 10, // France : +33 X XX XX XX XX (10 chiffres)
        '+44': 10, // Angleterre : +44 7XXX XXX XXX (10 chiffres)
        '+34': 9   // Espagne : +34 XXX XXX XXX (9 chiffres)
    };

    // Regex pour valider les numéros par région
    const phoneRegexMap: { [key: string]: RegExp } = {
        '+33': /^(\+33|0)[1-9](\d{2}){4}$/,  // France
        '+44': /^(\+44|0)7\d{9}$/,          // Angleterre
        '+34': /^(\+34|0)\d{9}$/            // Espagne
    };

    // Format du numéro en fonction de la région avec espaces aux bons endroits
    const formatPhoneNumber = (phone: string) => {
        switch (region) {
            case '+33': // France: +33 X XX XX XX XX
                return phone.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
            case '+44': // Angleterre: +44 7XXX XXX XXX
                return phone.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3');
            case '+34': // Espagne: +34 XXX XXX XXX
                return phone.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
            default:
                return phone;
        }
    };

    const validatePhone = (phone: string) => {
        return phoneRegexMap[region]?.test(phone) || false;
    };

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let input = e.target.value.replace(/\D/g, ''); // Supprime tout caractère non numérique

        // Limiter la longueur du numéro en fonction de la région
        const maxLength = maxLengthMap[region];
        if (input.length > maxLength) {
            input = input.slice(0, maxLength);
        }

        // Formater pendant la saisie
        const formattedPhone = formatPhoneNumber(input);
        onChange(formattedPhone); // Propager la valeur formatée
        setErrorMessage(null);
    };

    const handlePhoneBlur = () => {
        const phone = value.replace(/\s+/g, ''); // Supprimer les espaces avant validation et envoi à l'API

        if (!validatePhone(phone)) {
            setErrorMessage(`Format du numéro de téléphone invalide pour la région ${region}`);
        } else {
            setErrorMessage(null);
            onChange(phone); // Envoyer la valeur sans espaces à l'API
        }
    };

    const handleRegionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setRegion(e.target.value); // Mettre à jour la région
        setErrorMessage(null); // Réinitialiser l'erreur lors du changement de région
    };

    return (
        <div className="mb-4">
            <div className="flex justify-between items-center mb-[5px]">
                <label className="block text-primary-theme text-xs font-normal">
                    {label}
                </label>
            </div>
            <div className="flex">
                <select
                    value={region}
                    onChange={handleRegionChange}
                    className="block w-1/4 appearance-none text-sm border border-theme bg-theme text-primary-theme rounded-lg rounded-tr-none rounded-br-none px-5 py-3 leading-tight focus:outline-none focus:ring-1 focus:ring-primary"
                >
                    <option value="+33">+33 (FRA)</option>
                    <option value="+44">+44 (GBR)</option>
                    <option value="+34">+34 (ESP)</option>
                </select>
                <input
                    type="tel"
                    name={name}
                    value={value}
                    onChange={handlePhoneChange}
                    onBlur={handlePhoneBlur} // Validation et suppression des espaces à la perte de focus
                    placeholder={placeholder}
                    className={`block w-3/4 appearance-none text-sm border border-theme bg-theme text-primary-theme rounded-lg rounded-tl-none rounded-bl-none px-5 py-3 leading-tight focus:outline-none ${errorMessage ? 'border-red-500 focus:border-red-500' : 'focus:ring-1 focus:ring-primary'}`}
                    required={required}
                />
            </div>
            {errorMessage && (
                <p className="text-red-500 text-xs mt-2">{errorMessage}</p>
            )}
        </div>
    );
};

export default InputPhone;