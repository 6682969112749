import React, { useState, useEffect } from 'react';
import { t } from "i18next";

interface PdfViewerProps {
    pdfUrl: string;
    completionPercentages?: number;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ pdfUrl }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [blobUrl, setBlobUrl] = useState<string | null>(null);

    useEffect(() => {
        const fetchPdf = async () => {
            try {
                setLoading(true);

                // Télécharger le PDF comme un blob
                const response = await fetch(pdfUrl);

                if (!response.ok) {
                    throw new Error(`Erreur HTTP: ${response.status}`);
                }

                const pdfBlob = await response.blob();

                // Créer une URL pour le blob
                const url = URL.createObjectURL(pdfBlob);
                setBlobUrl(url);
                setLoading(false);
            } catch (err) {
                console.error("Erreur lors du chargement du PDF:", err);
                setError("Impossible de charger le PDF");
                setLoading(false);
            }
        };

        fetchPdf();

        // Nettoyer l'URL du blob lors du démontage du composant
        return () => {
            if (blobUrl) {
                URL.revokeObjectURL(blobUrl);
            }
        };
    }, [pdfUrl]);

    return (
        <div className="mt-4">
            {loading && (
                <div className="text-center py-4 text-xs">
                    {t('project.quoteViewerLoading')}...
                </div>
            )}

            {error && (
                <div className="text-red-500 text-center py-4">
                    {error}
                </div>
            )}

            {blobUrl && !loading && !error && (
                <object
                    data={blobUrl}
                    type="application/pdf"
                    width="100%"
                    height="800"
                    className="border rounded-lg"
                >
                    <p>
                        {t('project.pdfNotSupported')}
                        <a href={blobUrl} download="document.pdf">{t('common.download')}</a>
                    </p>
                </object>
            )}
        </div>
    );
};

export default PdfViewer;