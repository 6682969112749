import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../store/hooks';
import {
    addProjectDocument,
    addMissionDocument,
    removeProjectDocument,
    removeMissionDocument,
    selectDocuments,
    selectDocumentsLoading,
    selectDocumentsError
} from '../features/document/documentSlice';
import { RootState } from '../store/store';

interface UseDocumentsProps {
    projectUuid: string;
    missionUuid?: string;
}

export const useDocuments = ({ projectUuid, missionUuid }: UseDocumentsProps) => {
    const dispatch = useAppDispatch();
    const [uploadProgress, setUploadProgress] = useState<number>(0);

    const documents = useSelector(selectDocuments);
    const loading = useSelector(selectDocumentsLoading);
    const error = useSelector(selectDocumentsError);

    const uploadDocument = async (file: File): Promise<string> => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            let response;
            if (missionUuid) {
                response = await dispatch(addMissionDocument({
                    formData,
                    projectUuid,
                    missionUuid
                })).unwrap();
            } else {
                response = await dispatch(addProjectDocument({
                    formData,
                    projectUuid
                })).unwrap();
            }

            return response.document.uuid;
        } catch (error) {
            console.error('Error uploading document:', error);
            throw error;
        }
    };

    const removeDocument = async (documentUuid: string) => {
        try {
            if (missionUuid) {
                await dispatch(removeMissionDocument({
                    projectUuid,
                    missionUuid,
                    documentUuid
                })).unwrap();
            } else {
                await dispatch(removeProjectDocument({
                    projectUuid,
                    documentUuid
                })).unwrap();
            }
        } catch (error) {
            console.error('Error removing document:', error);
            throw error;
        }
    };

    return {
        documents,
        loading,
        error,
        uploadDocument,
        removeDocument,
        uploadProgress
    };
};