import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../store/hooks';
import { useProjects } from '../../../hooks/useProjects';
import { useMissions } from '../../../hooks/useMissions';
import { selectCustomers } from '../../customer/customerSlice';
import {
    addMissionDocument,
    removeMissionDocument,
    selectDocumentsLoading
} from "../../document/documentSlice";
import {Mission, Project} from '../types';
import {
    ProjectFormProps,
    ProjectFormRef,
    ProjectFormData,
    TrackedChanges,
    CustomerData
} from './types';
import {
    updateLocalMission,
    trackMissionChanges,
    trackDocumentAddition,
    trackDocumentRemoval,
    updateExpandedStatesForMove,
    handleEditMode,
    handleCreateMode
} from './helpers';
import CustomerSection from './CustomerSection';
import InputText from '../../../components/InputText';
import TextArea from '../../../components/TextArea';
import MissionItem from './MissionItem';
import { saveFormState, getFormState, clearFormState } from '../../../helpers/formPersistence';
import { fetchMissionsByProjectId } from '../missionSlice';

const ProjectForm = forwardRef<ProjectFormRef, ProjectFormProps>((props, ref) => {
    const { onSuccess, projectData, isEditMode = false, onNext, isLoading } = props;
    const dispatch = useAppDispatch();
    const { addProject, modifyProject } = useProjects();

    const [currentProjectUuid, setCurrentProjectUuid] = useState<string | undefined>(projectData?.uuid);
    const {
        missions,
        loading: missionsLoading,
        error: missionsError,
    } = useMissions(currentProjectUuid);

    const documentsLoading = useSelector(selectDocumentsLoading);
    const customers = useSelector(selectCustomers);

    const [selectedCustomer, setSelectedCustomer] = useState<CustomerData | null>(null);
    const [localMissions, setLocalMissions] = useState<Mission[]>([getEmptyMission()]);
    const [expandedMissions, setExpandedMissions] = useState<boolean[]>([true]);
    const [message, setMessage] = useState<string | null>(null);
    const [trackedChanges, setTrackedChanges] = useState<TrackedChanges>(getInitialTrackedChanges());
    const [formData, setFormData] = useState<ProjectFormData>(getInitialFormData(projectData));
    const [modifiedMissionPrices, setModifiedMissionPrices] = useState<Record<number, boolean>>({});
    const [modifiedMissions, setModifiedMissions] = useState<Record<number, boolean>>({});
    const localMissionsLoaded = useRef(false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (!isSaving && missions.length > 0 && !localMissionsLoaded.current) {
            const sortedMissions = [...missions].sort((a, b) => a.position - b.position);
            setLocalMissions(sortedMissions);
            setExpandedMissions(new Array(sortedMissions.length).fill(false));
            localMissionsLoaded.current = true;
        }
    }, [missions, isSaving]);

    useEffect(() => {
        return () => {
            localMissionsLoaded.current = false;
        };
    }, []);

    const PROJECT_FORM_KEY = 'projectFormData';
    const MISSIONS_FORM_KEY = 'missionsFormData';

    // Function to reset the form
    const resetForm = (): void => {
        setFormData(getInitialFormData());
        setSelectedCustomer(null);
        setLocalMissions([getEmptyMission()]);
        setExpandedMissions([true]);
        clearFormState(PROJECT_FORM_KEY);
        clearFormState(MISSIONS_FORM_KEY);
    };

    // Define ref methods
    useImperativeHandle(ref, (): ProjectFormRef => ({
        submitForm: handleSubmit,
        resetForm,
        setCustomer: (uuid: string) => {
            // Cette méthode est appelée depuis ProjectList après la création d'un client

            // Toujours mettre à jour avec le nouveau client
            const updatedFormData = { ...formData, customer: uuid };
            setFormData(updatedFormData);

            // Recherche du client dans la liste des clients
            if (customers.length > 0 && uuid) {
                const customer = customers.find(c => c.uuid === uuid);
                if (customer) {
                    setSelectedCustomer(customer);
                } else {
                }
            }

            // Sauvegarder dans localStorage si nous ne sommes pas en mode édition
            if (!isEditMode) {
                saveFormState<ProjectFormData>(PROJECT_FORM_KEY, updatedFormData);
            }
        },
        // Nouvelle méthode qui accepte à la fois l'UUID et les données complètes du client
        setCustomerWithData: (uuid: string, customerData: any) => {

            // Mettre à jour formData avec l'UUID du client
            const updatedFormData = { ...formData, customer: uuid };
            setFormData(updatedFormData);

            // Définir directement selectedCustomer avec les données complètes du client
            // sans avoir à le chercher dans la liste des clients
            setSelectedCustomer(customerData);

            // Sauvegarder dans localStorage si nous ne sommes pas en mode édition
            if (!isEditMode) {
                saveFormState<ProjectFormData>(PROJECT_FORM_KEY, updatedFormData);
            }
        }
    }));

    const didInitRef = useRef(false);

    useEffect(() => {
        if (!didInitRef.current && projectData) {
            setFormData({
                title: projectData.title || '',
                description: projectData.description || '',
                customer: projectData.customer?.uuid || ''
            });
            setCurrentProjectUuid(projectData.uuid);
            if (customers.length > 0) {
                const customer = customers.find(c => c.uuid === projectData.customer?.uuid);
                if (customer) setSelectedCustomer(customer);
            }
            didInitRef.current = true;
        }
    }, [projectData, customers]);

    // Effect for edit mode - Load missions
    useEffect(() => {
        if (missions.length > 0 && !localMissionsLoaded.current) {
            const sortedMissions = [...missions].sort((a, b) => a.position - b.position);
            setLocalMissions(sortedMissions);
            setExpandedMissions(new Array(sortedMissions.length).fill(false));

            // Marquer que les missions locales ont été chargées pour éviter de les réinitialiser
            localMissionsLoaded.current = true;
        }
    }, [missions]);

    // Effect for create mode - Load from localStorage
    useEffect(() => {
        // Uniquement exécuté en mode création et quand projectData n'est pas défini
        if (!isEditMode && !projectData) {
            const savedFormData = getFormState<ProjectFormData>(PROJECT_FORM_KEY);
            if (savedFormData) {
                setFormData(savedFormData);

                // Mise à jour du selectedCustomer basée sur l'ID client sauvegardé
                if (savedFormData.customer && customers.length > 0) {
                    const customer = customers.find(c => c.uuid === savedFormData.customer);
                    if (customer) {
                        setSelectedCustomer(customer);
                    } else {
                        // Le client existe dans les données du formulaire mais pas encore dans la liste des clients
                        // Cela peut arriver si le client vient d'être créé et que Redux n'a pas encore été mis à jour
                    }
                }
            }

            const savedMissions = getFormState<Mission[]>(MISSIONS_FORM_KEY);
            if (savedMissions && savedMissions.length > 0) {
                setLocalMissions(savedMissions);
                setExpandedMissions(new Array(savedMissions.length).fill(false));
            }
        }
    }, [isEditMode, projectData, customers]);

    // Ajouter un second effet qui s'exécute uniquement quand la liste des clients change
    // Cela permet de récupérer le client sélectionné même si l'effet précédent s'est exécuté
    // avant que la liste des clients ne soit chargée
    useEffect(() => {
        if (!isEditMode && !projectData && customers.length > 0) {
            const savedFormData = getFormState<ProjectFormData>(PROJECT_FORM_KEY);
            if (savedFormData && savedFormData.customer && !selectedCustomer) {
                const customer = customers.find(c => c.uuid === savedFormData.customer);
                if (customer) {
                    setSelectedCustomer(customer);
                }
            }
        }
    }, [customers, isEditMode, projectData, selectedCustomer]);

    // Handler for project fields changes
    const handleProjectChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void => {
        const { name, value } = e.target;
        const updatedFormData = { ...formData, [name]: value };
        setFormData(updatedFormData);
        props.setFormData?.(updatedFormData);

        // Sauvegarder dans localStorage si nous ne sommes pas en mode édition
        if (!isEditMode) {
            saveFormState<ProjectFormData>(PROJECT_FORM_KEY, updatedFormData);
        }

        if (projectData) {
            setTrackedChanges(prev => ({ ...prev, [name]: value }));
        }
    };

    // Handler specific for customer changes
    const handleCustomerChange = (value: string): void => {

        // Mettre à jour formData avec le nouveau client ou une chaîne vide pour désélectionner
        const updatedFormData = { ...formData, customer: value };
        setFormData(updatedFormData);

        // Si la valeur est vide, réinitialiser le client sélectionné
        if (!value) {
            setSelectedCustomer(null);
        }
        // Sinon trouver et définir le selectedCustomer correspondant
        else if (customers.length > 0) {
            const customer = customers.find(c => c.uuid === value);
            if (customer) {
                setSelectedCustomer(customer);
            }
        }

        // Sauvegarder dans localStorage si nous ne sommes pas en mode édition
        if (!isEditMode) {
            saveFormState<ProjectFormData>(PROJECT_FORM_KEY, updatedFormData);
        }
    };



    const handleMissionChange = (index: number, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void => {
        const { name, value } = e.target;
        const mission = localMissions[index];

        // Marquer cette mission comme modifiée, quel que soit le champ
        setModifiedMissions(prev => ({
            ...prev,
            [index]: true
        }));

        // Si le champ modifié est le prix unitaire, marquez également dans modifiedMissionPrices
        if (name === 'unitPrice') {
            setModifiedMissionPrices(prev => ({
                ...prev,
                [index]: true
            }));
        }

        // Mettre à jour les missions locales
        const updatedMissions = [...localMissions];
        updatedMissions[index] = {
            ...updatedMissions[index],
            [name]: value
        };

        setLocalMissions(updatedMissions);
        props.setMissionsData?.(updatedMissions);

        // Sauvegarder les missions mises à jour si nous ne sommes pas en mode édition
        if (!isEditMode) {
            setTimeout(() => {
                saveFormState<Mission[]>(MISSIONS_FORM_KEY, updatedMissions);
            }, 0);
        }

        trackMissionChanges(mission, index, name, value, missions, setTrackedChanges);
    };

    // Handler for file uploads
    const handleFileUpload = async (missionIndex: number, files: FileList) => {
        const mission = localMissions[missionIndex];
        if (!mission.uuid || !projectData?.uuid) return;

        try {
            for (const file of Array.from(files)) {
                const formData = new FormData();
                formData.append('file', file);
                await dispatch(addMissionDocument({
                    formData,
                    projectUuid: projectData.uuid,
                    missionUuid: mission.uuid
                })).unwrap();
                trackDocumentAddition(mission.uuid, file, setTrackedChanges);
            }
            setMessage('Documents ajoutés avec succès');
        } catch (error) {
            setMessage('Erreur lors du téléchargement des fichiers');
        }
    };

    // Handler for document removal
    const handleRemoveDocument = async (missionIndex: number, documentUuid: string) => {
        const mission = localMissions[missionIndex];
        if (!mission.uuid || !projectData?.uuid) return;

        try {
            await dispatch(removeMissionDocument({
                projectUuid: projectData.uuid,
                missionUuid: mission.uuid,
                documentUuid
            })).unwrap();
            trackDocumentRemoval(mission.uuid, documentUuid, setTrackedChanges);
            setMessage('Document supprimé avec succès');
        } catch (error) {
            setMessage('Erreur lors de la suppression du document');
        }
    };

    // Toggle mission expansion
    const toggleMission = (index: number) => {
        setExpandedMissions(prev => {
            const newState = [...prev];
            newState[index] = !newState[index];
            return newState;
        });
    };

    // Add a new mission
    const addMission = (): void => {
        const maxPosition = Math.max(...localMissions.map(m => m.position), -1);
        const newMission = getEmptyMission(maxPosition + 1);
        const updatedMissions = [...localMissions, newMission];

        setLocalMissions(updatedMissions);
        setExpandedMissions(prev => [...prev, true]);

        // Sauvegarder les missions mises à jour si nous ne sommes pas en mode édition
        if (!isEditMode) {
            saveFormState<Mission[]>(MISSIONS_FORM_KEY, updatedMissions);
        }

        setTrackedChanges(prev => ({
            ...prev,
            newMissions: [...prev.newMissions, newMission]
        }));
    };

    // Remove a mission
    const removeMissionHandler = (index: number): void => {
        const updatedMissions = localMissions.filter((_, i) => i !== index);
        const missionsWithUpdatedPositions = updatedMissions.map((mission, newIndex) => ({
            ...mission,
            position: newIndex
        }));

        setLocalMissions(missionsWithUpdatedPositions);
        setExpandedMissions(prev => prev.filter((_, i) => i !== index));

        // Sauvegarder les missions mises à jour si nous ne sommes pas en mode édition
        if (!isEditMode) {
            saveFormState<Mission[]>(MISSIONS_FORM_KEY, missionsWithUpdatedPositions);
        }
    };

    const cleanMission = (mission: Mission, index: number) => {
        const { createdAt, updatedAt, ...cleanedMission } = mission;

        // Si le prix de cette mission a été modifié par l'utilisateur, alors convertir en centimes
        if (modifiedMissionPrices[index]) {
            return {
                ...cleanedMission,
                unitPrice: Math.round(cleanedMission.unitPrice * 100)
            };
        }

        // Si la mission a un UUID (mission existante) et que son prix n'a pas été modifié,
        // ne pas toucher au prix car il est déjà en centimes
        if (mission.uuid) {
            return cleanedMission;
        }

        // Pour les nouvelles missions, même sans modification du prix,
        // il faut convertir en centimes
        return {
            ...cleanedMission,
            unitPrice: Math.round(cleanedMission.unitPrice * 100)
        };
    };

    const cleanMissions = (missions: Mission[]) =>
        missions.map((mission, index) => cleanMission(mission, index));

// Dans ProjectForm.tsx, modifiez la fonction handleSubmit pour éviter un double appel à modifyProject
// et pour gérer correctement les conversions de prix

// Dans ProjectForm.tsx, simplifiez la fonction handleSubmit

    const handleSubmit = async (): Promise<Project | void> => {
        setMessage(null);

        // Capture l'état actuel
        const currentFormData = { ...formData };
        const currentMissions = [...localMissions];

        try {
            const cleanedMissions = cleanMissions(localMissions);

            if (isEditMode && currentProjectUuid) {
                const projectPayload: Partial<Omit<Project, "uuid">> = {
                    title: formData.title,
                    description: formData.description,
                    customer: formData.customer as any,
                    ...(Object.keys(modifiedMissions).length > 0 ? { missions: cleanedMissions } : {})
                };

                setMessage('Enregistrement en cours...');

                try {
                    // 1. Appel API pour la mise à jour du projet
                    await modifyProject(currentProjectUuid, projectPayload);

                    // 2. Restaurer immédiatement les valeurs actuelles pour éviter un flash
                    setFormData(currentFormData);
                    setLocalMissions(currentMissions);

                    // 3. Recharger explicitement les missions dans tous les cas
                    const missionsData = await dispatch(fetchMissionsByProjectId(currentProjectUuid)).unwrap();

                    // Mettre à jour localMissions seulement après avoir reçu les données complètes
                    if (missionsData && missionsData.missions && missionsData.missions.length > 0) {
                        const sortedMissions = [...missionsData.missions].sort((a, b) => a.position - b.position);
                        setLocalMissions(sortedMissions);
                    }

                    // Réinitialiser les états de suivi
                    setModifiedMissionPrices({});
                    setModifiedMissions({});

                    setMessage('Projet mis à jour avec succès');
                } catch (apiError) {
                    // Restaurer les valeurs en cas d'erreur
                    setFormData(currentFormData);
                    setLocalMissions(currentMissions);

                    setMessage(`Erreur lors de la mise à jour : ${apiError instanceof Error ? apiError.message : 'Erreur inconnue'}`);
                    console.error('API Error:', apiError);
                }
            } else {
                // Code pour la création
                const projectPayload = {
                    ...formData,
                    missions: cleanedMissions,
                };

                setMessage('Création en cours...');

                try {
                    const newProject = await addProject(projectPayload);
                    if (newProject && newProject.uuid) {
                        await dispatch(fetchMissionsByProjectId(newProject.uuid));
                        setMessage('Projet créé avec succès');

                        clearFormState(PROJECT_FORM_KEY);
                        clearFormState(MISSIONS_FORM_KEY);
                        resetForm();

                        // ✅ Retourner le projet créé
                        return newProject;
                    }
                } catch (apiError) {
                    setMessage(`Erreur lors de la création : ${apiError instanceof Error ? apiError.message : 'Erreur inconnue'}`);
                }
            }

            onSuccess();
        } catch (error) {
            // Restaurer également en cas d'erreur générale
            setFormData(currentFormData);
            setLocalMissions(currentMissions);

            const errorMessage = error instanceof Error ? error.message : 'Erreur inconnue';
            setMessage(errorMessage);
            console.error('Détail de l\'erreur :', error);
        }

        return;
    };

    // Move mission up
// Dans ProjectForm.tsx, modifiez les fonctions moveMissionUp et moveMissionDown
// pour mettre à jour correctement le store Redux

    const moveMissionUp = async (index: number) => {
        if (index > 0) {
            // Sauvegarde de l'état actuel
            const currentMissions = [...localMissions];

            // Logique de déplacement de mission
            const newMissions = [...localMissions];
            [newMissions[index - 1], newMissions[index]] = [newMissions[index], newMissions[index - 1]];

            const updatedMissions = newMissions.map((mission, i) => ({
                ...mission,
                position: i,
            }));

            setLocalMissions(updatedMissions);

            // Marquer les deux missions comme modifiées
            setModifiedMissions(prev => ({
                ...prev,
                [index]: true,
                [index - 1]: true
            }));

            if (currentProjectUuid) {
                try {
                    const cleanedMissionsForBackend = cleanMissions(updatedMissions);
                    await modifyProject(currentProjectUuid, { missions: cleanedMissionsForBackend });

                    // Restaurer temporairement l'état pour éviter un flash
                    setLocalMissions(currentMissions);

                    // Recharger explicitement les missions
                    const missionsData = await dispatch(fetchMissionsByProjectId(currentProjectUuid)).unwrap();

                    // Mettre à jour avec les données complètes une fois reçues
                    if (missionsData && missionsData.missions && missionsData.missions.length > 0) {
                        const sortedMissions = [...missionsData.missions].sort((a, b) => a.position - b.position);
                        setLocalMissions(sortedMissions);
                    }
                } catch (error) {
                    // Restaurer l'état en cas d'erreur
                    setLocalMissions(currentMissions);
                    console.error("Erreur lors du déplacement de la mission:", error);
                    setMessage("Erreur lors du déplacement de la mission");
                }
            }

            if (!isEditMode) {
                saveFormState<Mission[]>(MISSIONS_FORM_KEY, updatedMissions);
            }
        }
    };

    const moveMissionDown = async (index: number) => {
        if (index < localMissions.length - 1) {
            // Sauvegarde de l'état actuel
            const currentMissions = [...localMissions];

            // Logique de déplacement de mission
            const newMissions = [...localMissions];
            [newMissions[index], newMissions[index + 1]] = [newMissions[index + 1], newMissions[index]];

            const updatedMissions = newMissions.map((mission, i) => ({
                ...mission,
                position: i,
            }));

            setLocalMissions(updatedMissions);

            // Marquer les deux missions comme modifiées
            setModifiedMissions(prev => ({
                ...prev,
                [index]: true,
                [index + 1]: true
            }));

            if (currentProjectUuid) {
                try {
                    const cleanedMissionsForBackend = cleanMissions(updatedMissions);
                    await modifyProject(currentProjectUuid, { missions: cleanedMissionsForBackend });

                    // Restaurer temporairement l'état pour éviter un flash
                    setLocalMissions(currentMissions);

                    // Recharger explicitement les missions
                    const missionsData = await dispatch(fetchMissionsByProjectId(currentProjectUuid)).unwrap();

                    // Mettre à jour avec les données complètes une fois reçues
                    if (missionsData && missionsData.missions && missionsData.missions.length > 0) {
                        const sortedMissions = [...missionsData.missions].sort((a, b) => a.position - b.position);
                        setLocalMissions(sortedMissions);
                    }
                } catch (error) {
                    // Restaurer l'état en cas d'erreur
                    setLocalMissions(currentMissions);
                    console.error("Erreur lors du déplacement de la mission:", error);
                    setMessage("Erreur lors du déplacement de la mission");
                }
            }

            if (!isEditMode) {
                saveFormState<Mission[]>(MISSIONS_FORM_KEY, updatedMissions);
            }
        }
    };

    if (missionsLoading && !isEditMode) return <div>Chargement des missions...</div>;
    if (missionsError && !isEditMode) return <div>Erreur lors du chargement des missions</div>;

    return (
        <div className="flex items-center justify-center">
            <div className="max-w-md w-full space-y-8">
                <h2 className="text-xl font-semibold text-primary-theme">
                    {isEditMode ? 'Modifier le projet' : 'Ajouter un projet'}
                </h2>
                <form className="space-y-6" onSubmit={(e) => e.preventDefault()}>
                    <div className="rounded-md space-y-4">
                        <InputText
                            label="Titre du projet"
                            name="title"
                            value={formData.title}
                            onChange={handleProjectChange}
                            placeholder="Entrez le titre du projet"
                        />
                        <TextArea
                            label="Description du projet"
                            name="description"
                            value={formData.description}
                            onChange={handleProjectChange}
                            placeholder="Entrez la description du projet"
                        />
                        <CustomerSection
                            isEditMode={isEditMode}
                            projectData={projectData}
                            formData={formData}
                            customers={customers}
                            onCustomerChange={handleCustomerChange}
                            onNext={onNext}
                            selectedCustomer={selectedCustomer}
                        />
                    </div>
                    <div className="space-y-4">
                        {[...localMissions]
                            .sort((a, b) => a.position - b.position)
                            .map((mission, index) => (
                                <MissionItem
                                    key={mission.uuid || index}
                                    mission={mission}
                                    index={index}
                                    isExpanded={expandedMissions[index]}
                                    isFirst={index === 0}
                                    isLast={index === localMissions.length - 1}
                                    onToggle={() => toggleMission(index)}
                                    onChange={(e) => handleMissionChange(index, e)}
                                    onMissionModified={() => setModifiedMissions(prev => ({...prev, [index]: true}))}
                                    onMoveUp={() => moveMissionUp(index)}
                                    onMoveDown={() => moveMissionDown(index)}
                                    onRemove={() => removeMissionHandler(index)}
                                    onUploadDocument={
                                        mission.uuid ?
                                            (files) => handleFileUpload(index, files) :
                                            undefined
                                    }
                                    onRemoveDocument={
                                        mission.uuid ?
                                            (docUuid) => handleRemoveDocument(index, docUuid) :
                                            undefined
                                    }
                                    documentsLoading={documentsLoading}
                                    isEditMode={isEditMode}
                                    onPriceModified={(idx) => {
                                        setModifiedMissionPrices(prev => ({
                                            ...prev,
                                            [idx]: true
                                        }));
                                    }}
                                />
                            ))}
                        <button
                            type="button"
                            onClick={addMission}
                            className="text-primary"
                        >
                            Ajouter une mission
                        </button>
                    </div>
                    {message && (
                        <div className="text-center mt-4 text-primary">
                            {message}
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
});

function getEmptyMission(position = 0): Mission {
    return {
        uuid: '',
        title: '',
        description: '',
        exceptedEndDate: '',
        unitPrice: 0,
        position,
        quantity: 1,
        status: 'draft'
    };
}

function getInitialTrackedChanges(): TrackedChanges {
    return {
        modifiedMissions: {},
        newMissions: [],
        deletedMissions: [],
        documentChanges: {}
    };
}

function getInitialFormData(projectData?: { title: string; description: string; customer: { uuid: string } } | null): ProjectFormData {
    return {
        title: projectData?.title || '',
        description: projectData?.description || '',
        customer: projectData?.customer?.uuid || ''
    };
}

ProjectForm.displayName = 'ProjectForm';

export default ProjectForm;