import React from 'react';
import { Link } from 'react-router-dom';
import { HiOutlineBuildingLibrary } from 'react-icons/hi2';
import {FiFile, FiImage, FiMail, FiPhone} from 'react-icons/fi';
import ProfileCard from "./ProfileCard";
import documents from "../features/project/components/Documents";
import {formatDate} from "@storybook/blocks";
import {formatFileSize} from "../utils/fileUtils";

interface Project {
    uuid: string;
    title: string;
}

interface Document {
    uuid: string;
    originalName?: string;
    mimeType?: string;
    createdAt?: string;
    size?: string;
}

interface ContactCardProps {
    firstName?: string;
    lastName?: string;
    companyName?: string;
    email?: string;
    phone?: string;
    imageUrl?: string;
    customerProjects?: Project[];
    className?: string;
    documents?: Document[];
}

export const ContactCard: React.FC<ContactCardProps> = ({
    firstName,
    lastName,
    companyName,
    email,
    phone,
    imageUrl = "",
    customerProjects,
    className = '',
    documents,
}) => {
    const hasProfileInfo = firstName || lastName;
    const hasContactDetails = email || phone || companyName;

    return (
        <>
            <div className={`bg-theme rounded-xl p-1 drop-shadow-[0px_5px_10px_rgba(22,_19,_35,_0.02)] ${className}`}>
                <div className="py-2.5 px-4 mb-1">
                    <p className="text-primary-theme font-semibold text-base">Fiche de contact</p>
                    {companyName && (
                        <span className="text-primary-theme font-normal text-xs">{companyName}</span>
                    )}
                </div>

                {hasProfileInfo && (
                    <div className="bg-surface-theme mt-1 py-2.5 px-4 rounded-lg text-sm">
                        <ProfileCard
                            imageUrl={imageUrl}
                            firstName={firstName || ""}
                            lastName={lastName || ""}
                            size="large"
                        />
                    </div>
                )}

                {hasContactDetails && (
                    <div className="bg-surface-theme mt-1 py-2.5 px-4 rounded-lg text-sm">
                        <p className="text-primary-theme text-xs font-medium mb-2.5">Détails du client</p>
                        <div className="flex flex-col mt-2 gap-y-3.5">
                            {companyName && (
                                <div className="grid grid-cols-[160px_1fr] items-center">
                                    <div className="flex items-center text-secondary-theme gap-2">
                                        <HiOutlineBuildingLibrary/>
                                        <p className="text-secondary-theme text-xs">Entreprise</p>
                                    </div>
                                    <div>
                                        <p className="text-primary-theme text-xs">{companyName}</p>
                                    </div>
                                </div>
                            )}

                            {email && (
                                <div className="grid grid-cols-[160px_1fr] items-center">
                                    <div className="flex items-center text-secondary-theme gap-2">
                                        <FiMail/>
                                        <p className="text-secondary-theme text-xs">Email</p>
                                    </div>
                                    <div>
                                        <p className="text-primary-theme text-xs">{email}</p>
                                    </div>
                                </div>
                            )}

                            {phone && (
                                <div className="grid grid-cols-[160px_1fr] items-center">
                                    <div className="flex items-center text-secondary-theme gap-2">
                                        <FiPhone/>
                                        <p className="text-secondary-theme text-xs">Téléphone</p>
                                    </div>
                                    <div>
                                        <p className="text-primary-theme text-xs">{phone}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {customerProjects && customerProjects.length > 0 && (
                    <div className="bg-surface-theme mt-1 py-2.5 px-4 rounded-lg text-sm">
                        <p className="text-primary-theme text-xs font-medium mb-2.5">Projets associés</p>
                        <ul className="text-primary-theme list-disc pl-5 mt-2">
                            {customerProjects.map(project => (
                                <li key={project.uuid}>
                                    <Link
                                        to={`/projects/${project.uuid}`}
                                        className="text-primary-theme hover:text-primary"
                                    >
                                        {project.title}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
            {documents && documents.length > 0 && (
                <div className={`bg-theme rounded-xl p-1 mt-5 drop-shadow-[0px_5px_10px_rgba(22,_19,_35,_0.02)] ${className}`}>
                    <div className="flex items-center justify-between py-2.5 px-4 mb-1">
                        <p className="text-primary-theme font-semibold text-base">Documents</p>
                    </div>
                    <div className="flex flex-col gap-y-2">
                        {documents && documents.length > 0 ? (
                            documents.map((document: any) => {
                                    return (
                                        <div
                                            key={document.uuid}
                                            className="flex bg-surface-theme rounded-lg p-2.5 items-center cursor-pointer"
                                        >
                                            <div className="text-xl mr-4 bg-theme p-3 rounded-md text-primary-theme">
                                                {document.mimeType === 'application/pdf' ? <FiFile/> : <FiImage/>}
                                            </div>
                                            <div className="w-full">
                                                <p className="font-medium w-48 truncate text-primary-theme text-sm">
                                                    {document.originalName}
                                                </p>
                                                <span className="text-xs text-primary-theme">
                                                            {formatDate(document.createdAt)}{' '}
                                                        </span>
                                                <span
                                                    className="text-xs text-secondary-theme">{formatFileSize(document.size)}</span>
                                            </div>
                                        </div>
                                    );
                                }
                            )
                        ) : (
                            <p className="text-primary-theme text-sm px-4">Envoyez votre premier document.</p>
                        )}
                    </div>
                </div>
            )}
        </>
    )
        ;
};

export default ContactCard;