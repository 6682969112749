import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { register, getUserInfo, updateUserInfo, associateToCustomer } from '../../api/authService';
import { useDispatch } from 'react-redux';
import { setUser, setLoading } from './authSlice';
import { setCompany } from '../company/companySlice';
import Button from "../../components/UI/Button";
import { t } from "i18next";
import InputEmail from "../../components/InputEmail";
import InputText from "../../components/InputText";
import InputPassword from "../../components/InputPassword";
import Logo from "../../components/Logo";

type RegisterFormProps = {
    theme: string;
};

const RegisterForm: React.FC<RegisterFormProps> = ({ theme }) => {
    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        plainPassword: '',
        confirmPassword: ''
    });
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const invitationToken = searchParams.get('invitationToken');
    const profileSelected = searchParams.get('profileSelected');
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setIsLoading(true);
        dispatch(setLoading(true));

        try {
            const { email, firstName, lastName, plainPassword } = formData;
            await register({ email, firstName, lastName, plainPassword });

            const userInfoResponse = await getUserInfo();
            if (userInfoResponse.data) {
                dispatch(setUser(userInfoResponse.data));
                if (userInfoResponse.data.company) {
                    dispatch(setCompany(userInfoResponse.data.company));
                    navigate('/dashboard');
                } else {
                    dispatch(setCompany(null));
                    navigate('/create-company');
                }

                if (profileSelected && profileSelected === 'customer') {
                    await handleProfileChange('customer');
                }

                if (invitationToken) {
                    await handleAssociateToCustomer(invitationToken);
                }
            }
        } catch (error) {
            setMessage('Erreur lors de la création du compte');
        } finally {
            setIsLoading(false);
            dispatch(setLoading(false));
        }
    };

    const handleProfileChange = async (profile: 'company' | 'customer') => {
        try {
            const updatedUser = {
                ...formData,
                profileSelected: profile,
            };
            const response = await updateUserInfo(updatedUser);
            dispatch(setUser(response.data));
        } catch (error) {
            console.error("Erreur lors de la mise à jour du profil:", error);
        }
    };

    const handleAssociateToCustomer = async (token: string) => {
        try {
            const response = await associateToCustomer(token);
        } catch (error) {
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center  p-4">
            <div className="max-w-md w-full">
                <div className="mb:space-y-[5rem] space-y-0">
                    <div
                        className="absolute top-[3.5rem] md:top-[2.125rem] left-1/2 -translate-x-1/2 md:left-auto md:right-[2.125rem] md:transform-none">
                        <Logo theme={theme}/>
                    </div>
                    <div className="space-y-[.313rem]">
                        <h2 className="text-center text-[2rem] leading-9 font-medium text-primary-theme">
                            {t('signup.title')}
                        </h2>
                        <p className="text-center text-xs leading-6 font-normal text-primary-theme mr-2.5">{t('signup.existingAccount')} <a className="text-primary cursor-pointer pl-2" onClick={() => navigate('/login')}>{t('signup.login')}</a></p>
                    </div>
                </div>
                <form className="mt-10 space-y-[1.25rem]" onSubmit={handleSubmit}>
                    <div className="space-y-[1.25rem]">
                        <InputEmail
                            label={t('signup.email.label')}
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder={t('signup.email.placeholder')}
                            required
                        />
                        <InputText
                            label={t('signup.firstname.label')}
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            placeholder={t('signup.firstname.placeholder')}
                        />
                        <InputText
                            label={t('signup.lastname.label')}
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            placeholder={t('signup.lastname.placeholder')}
                        />
                        <InputPassword
                            label={t('signup.plainPassword')}
                            name="plainPassword"
                            value={formData.plainPassword}
                            onChange={handleChange}
                            placeholder={t('user.password')}
                            required
                            hasRequirements={true}
                        />
                    </div>

                    {message && (
                        <div className="text-center text-red-500 text-xs">
                            {message}
                        </div>
                    )}

                    <div className="flex items-center justify-between">
                        <Button
                            size="medium"
                            type="submit"
                            isLoading={isLoading}
                            fullWidth
                        >
                            {t('signup.submit')}
                        </Button>
                    </div>

                    <p className="text-center text-xs leading-6 font-normal text-primary-theme mr-2.5">En vous inscrivant, vous acceptez nos <a className="text-primary cursor-pointer pl-2" onClick={() => navigate('/terms-of-service')}>Conditions Générales d'Utilisation.</a></p>
                </form>
            </div>
        </div>
    );
};

export default RegisterForm;