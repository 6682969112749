import React from 'react';
import ProfileCard from "../../components/ProfileCard";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {selectProjectById} from "../project/projectSlice";
import Skeleton from "../../components/Skeleton";

interface ThreadListProps {
    threads: any[];
    loading: boolean;
    unreadMessages: Record<string, number>;
    onThreadClick: (uuid: string) => void;
    activeThreadId?: string;
}

const ThreadList: React.FC<ThreadListProps> = ({
   threads,
   loading,
   unreadMessages,
   onThreadClick,
   activeThreadId
}) => {
    const truncateMessage = (message: string, maxLength: number = 25) => {
        if (message.length <= maxLength) return message;
        return `${message.substring(0, maxLength)}...`;
    };

    return (
        <div className="md:pr-4 h-full overflow-y-auto mb-8 md:m-0">
            {loading ? (
                <div className="space-y-4">
                    <Skeleton variant="text" height="28px" count={1}/>
                    <Skeleton variant="text" height="28px" count={1}/>
                </div>
            ) : threads.length === 0 ? (
                <div className="text-center text-gray-500 py-4">
                    Aucune conversation
                </div>
            ) : (
                <div className="space-y-4">
                    {threads.map((thread: any) => {
                        const isActiveThread = activeThreadId === thread.uuid;
                        const hasUnreadMessages = unreadMessages[thread.uuid] > 0 && !isActiveThread;

                        return (
                            <div
                                key={thread.uuid}
                                className={`relative p-4 rounded-lg flex justify-between items-center cursor-pointer hover:bg-theme-hover ${
                                    activeThreadId === thread.uuid ? 'bg-theme' : ''
                                }`}
                                onClick={() => onThreadClick(thread.uuid)}
                            >
                                <ProfileCard
                                    imageUrl=""
                                    firstName={thread.subject}
                                    lastName=""
                                    firstHide={thread.project?.customer.firstName}
                                    lastHide={thread.project?.customer.lastName}
                                    label={thread.lastMessage?.body ?
                                        truncateMessage(`${thread.lastMessage?.sender?.firstName} ${thread.lastMessage?.sender?.lastName} : ${thread.lastMessage?.body}`) :
                                        'Pas de message'}
                                    size="large"
                                    truncateFirstName
                                    maxFirstNameLength={20}
                                />

                                {hasUnreadMessages && (
                                    <span className="absolute top-2 right-2 bg-red-500 text-white text-xs rounded-full w-3 h-3"></span>
                                )}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default ThreadList;