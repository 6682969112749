import React from 'react';
import { useTheme } from '../../../hooks/useTheme';
import { useTranslation } from 'react-i18next';
import RadioButtonGroup from "../../../components/RadioButtonGroup";

const ThemeSelector: React.FC = () => {
    const { t } = useTranslation();
    const { theme, toggleTheme } = useTheme();

    const themeOptions = [
        { value: 'light', label: t('settings.light') },
        { value: 'dark', label: t('settings.dark') },
        { value: 'system', label: t('settings.system') }
    ];

    return (
        <>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {t('settings.theme.title')}
            </label>
            <div className="max-w-sm">
                <RadioButtonGroup
                    options={themeOptions}
                    value={theme}
                    onChange={toggleTheme}
                    name="theme"
                    label={t('settings.theme.label')}
                />
            </div>
        </>
    );
};

export default ThemeSelector;