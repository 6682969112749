// CustomerSection.tsx simplifié
import React from 'react';
import SearchableSelect from '../../../components/SearchableSelect';

interface CustomerData {
    uuid: string;
    companyName: string;
    firstName: string;
    lastName: string;
    email?: string;
    phone?: string;
}

interface CustomerSectionProps {
    isEditMode: boolean;
    projectData: any;
    formData: { customer: string };
    customers: CustomerData[];
    selectedCustomer: CustomerData | null;
    onCustomerChange: (value: string) => void;
    onNext: (() => void) | undefined;
}

const CustomerSection: React.FC<CustomerSectionProps> = ({
     isEditMode,
     projectData,
     formData,
     customers,
     selectedCustomer,
     onCustomerChange,
     onNext
 }) => {
    return (
        <div>
            <SearchableSelect
                label="Client"
                name="customer"
                value={formData.customer}
                options={customers.map(customer => ({
                    value: customer.uuid,
                    label: customer.companyName
                        ? customer.companyName
                        : `${customer.firstName} ${customer.lastName}`
                }))}
                onChange={onCustomerChange}
                onButtonClick={onNext || (() => {})}
                buttonLabel="Nouveau"
            />
        </div>
    );
};

export default CustomerSection;