import React from 'react';

interface TextAreaProps {
    label: string;
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    placeholder?: string;
}

const TextArea: React.FC<TextAreaProps> = ({ label, name, value, onChange, placeholder }) => {
    return (
        <div className="mb-4">
            <div className="flex justify-between items-center mb-[5px]">
                <label className="block text-primary-theme text-xs font-normal">
                    {label}
                </label>
            </div>
            <textarea
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                className="appearance-none text-sm block w-full border border-theme bg-theme text-primary-theme rounded-lg px-5 py-3 leading-tight focus:outline-none focus:ring-1 focus:ring-primary"
                rows={4}
            />
        </div>
    );
};

export default TextArea;