import React, {useState, useRef, useCallback, useEffect} from 'react';
import ReactCrop, { Crop, PixelCrop, centerCrop, makeAspectCrop } from 'react-image-crop';
import { FiUpload, FiX } from 'react-icons/fi';
import 'react-image-crop/dist/ReactCrop.css';
import Button from "./UI/Button";
import {t} from "i18next";

interface ImageCropperProps {
    label: string;
    name: string;
    onImageCropped: (croppedImage: string) => void;
    required?: boolean;
    maxSize?: number;
    modalTitle?: string;
    maxWidth?: number;
    maxHeight?: number;
    initialImage?: string | null;
}


const ImageCropper: React.FC<ImageCropperProps> = ({
   label,
   name,
   onImageCropped,
   required = false,
   maxSize = 5,
   modalTitle = t('cropper.title'),
   maxWidth = 1600,
   maxHeight = 1600,
   initialImage = null,
}) => {
    const [imgSrc, setImgSrc] = useState<string>('');
    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const [showCropModal, setShowCropModal] = useState(false);
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [error, setError] = useState<string | null>(null);
    const [imgDimensions, setImgDimensions] = useState<{ width: number; height: number } | null>(null);
    const imgRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        setPreviewImage(initialImage);
    }, [initialImage]);

    const onSelectFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];

            const validTypes = ['image/png', 'image/jpeg'];

            if (!validTypes.includes(file.type)) {
                setError('Seuls les fichiers PNG et JPG sont autorisés.');
                return;
            }

            if (file.size > maxSize * 1024 * 1024) {
                setError(`L'image ne doit pas dépasser ${maxSize}MB`);
                return;
            }

            setError(null);
            e.target.value = '';

            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setImgSrc(reader.result?.toString() || '');
                setCrop(undefined);
                setCompletedCrop(undefined);
                setShowCropModal(true);
            });
            reader.readAsDataURL(file);
        }
    }, [maxSize]);

    const centerAspectCrop = useCallback((mediaWidth: number, mediaHeight: number) => {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 50,
                    height: 50,
                },
                1,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        );
    }, []);

    const onImageLoad = useCallback((e: React.SyntheticEvent<HTMLImageElement>) => {
        const { width, height, naturalWidth, naturalHeight } = e.currentTarget;
        setImgDimensions({ width: naturalWidth, height: naturalHeight });
        const newCrop = centerAspectCrop(width, height);
        setCrop(newCrop);
        setCompletedCrop({
            unit: 'px',
            x: newCrop.x,
            y: newCrop.y,
            width: newCrop.width,
            height: newCrop.height
        });
    }, [centerAspectCrop]);

    const handleCancel = () => {
        setShowCropModal(false);
        if (!previewImage) {
            setImgSrc('');
        }
    };

    const handleCrop = () => {
        if (!imgRef.current || !completedCrop) return;

        const canvas = document.createElement('canvas');
        canvas.width = completedCrop.width;
        canvas.height = completedCrop.height;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const image = imgRef.current;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        ctx.drawImage(
            image,
            completedCrop.x * scaleX,
            completedCrop.y * scaleY,
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
            0,
            0,
            completedCrop.width,
            completedCrop.height,
        );

        const base64Image = canvas.toDataURL('image/png');
        setPreviewImage(base64Image);
        onImageCropped(base64Image);
        handleCancel();
    };

    const handleRemoveImage = () => {
        setPreviewImage(null);
        setImgSrc('');
        setCrop(undefined);
        setCompletedCrop(undefined);
        setError(null);
    };

    return (
        <div className="mb-4">
            <div className="flex justify-between items-center mb-[5px]">
                <label className="block text-xs font-normal text-primary-theme">
                    {label}
                </label>
            </div>

            {!previewImage ? (
                <div>
                <label
                        className="flex flex-col items-center justify-center w-full h-40 border-2 border-dashed border-theme rounded-lg cursor-pointer bg-theme">
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <FiUpload className="w-8 h-8 mb-4 text-primary"/>
                            <p className="mb-2 text-xs text-center text-primary-theme">
                                {t('cropper.drop')} <span className="text-primary">{t('cropper.browse')}</span>
                            </p>
                            <p className="text-[10px] text-secondary-theme">
                                PNG, JPG (max. {maxWidth}x{maxHeight}px, {maxSize}MB)
                            </p>
                        </div>
                        <input
                            type="file"
                            className="hidden"
                            onChange={onSelectFile}
                            accept="image/png, image/jpeg"
                            name={name}
                            required={required && !previewImage}
                        />
                    </label>
                </div>
            ) : (
                <div className="relative w-40 group">
                    <div
                        className="w-full h-40 border border-theme rounded-lg overflow-hidden bg-theme flex items-center justify-center">
                        <img
                            src={previewImage}
                            alt="Aperçu"
                            className="w-full h-full object-contain"
                        />
                        <div
                            className="absolute inset-0 flex items-center justify-center bg-black rounded-lg bg-opacity-0 group-hover:bg-opacity-50 transition-opacity">
                            <label className="w-full h-full flex items-center justify-center cursor-pointer">
                                <span className="text-white text-sm font-medium opacity-0 group-hover:opacity-100">
                                    {t('cropper.change')}
                                </span>
                                <input
                                    type="file"
                                    className="hidden"
                                    onChange={onSelectFile}
                                    accept="image/*"
                                    name={name}
                                />
                            </label>
                        </div>
                    </div>
                    <button
                        onClick={handleRemoveImage}
                        className="absolute -top-2 -right-2 p-2 bg-red-500 text-white rounded-lg hover:bg-red-600 shadow-lg"
                        type="button"
                        title="Supprimer l'image"
                    >
                        <FiX className="w-4 h-4"/>
                    </button>
                </div>
            )}

            {error && (
                <p className="mt-2 text-sm text-red-500">{error}</p>
            )}

            {/* Modal de crop */}
            {showCropModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center">
                    <div className="fixed inset-0 bg-theme/10 backdrop-blur-sm" onClick={handleCancel}/>

                    <div
                        className="relative bg-theme rounded-lg shadow-xl w-[95vw] max-w-4xl m-4 z-50 max-h-[90vh] flex flex-col">
                        <div className="flex items-center justify-between p-4">
                            <div>
                                <h3 className="text-ms font-medium text-primary-theme">{modalTitle}</h3>
                                {imgDimensions && (
                                    <p className="text-xs text-secondary-theme">
                                        {t('cropper.label')} : {imgDimensions.width}x{imgDimensions.height}px
                                    </p>
                                )}
                            </div>
                            <button
                                onClick={handleCancel}
                                className="text-gray-400 hover:text-gray-500"
                            >
                                <FiX className="w-6 h-6"/>
                            </button>
                        </div>

                        <div className="px-4 overflow-hidden flex-grow flex flex-col">
                            <div
                                className="bg-surface-theme flex-grow rounded-lg overflow-hidden flex items-center justify-center relative">
                                <ReactCrop
                                    crop={crop}
                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                    aspect={1}
                                    className="max-w-full max-h-full"
                                >
                                    <img
                                        ref={imgRef}
                                        alt="Crop me"
                                        src={imgSrc}
                                        onLoad={onImageLoad}
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: '60vh',
                                            objectFit: 'contain'
                                        }}
                                    />
                                </ReactCrop>
                            </div>
                        </div>

                        <div className="flex justify-end bg-theme space-x-8 p-4 rounded-lg">
                            <Button
                                size="medium"
                                type="button"
                                background={false}
                                onClick={handleCancel}
                            >
                                {t('cropper.back')}
                            </Button>
                            <Button
                                size="medium"
                                type="button"
                                disabled={!completedCrop?.width || !completedCrop?.height}
                                onClick={handleCrop}
                            >
                                {t('cropper.submit')}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImageCropper;