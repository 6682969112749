import React, { useState } from 'react';
import { HiOutlineSparkles } from "react-icons/hi2";
import { t } from "i18next";

interface SiretInputProps {
    label: string;
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    autoFilled?: boolean;
}

const SiretInput: React.FC<SiretInputProps> = ({
   label,
   name,
   value,
   onChange,
   placeholder,
   required = false,
   disabled = false,
   autoFilled = false
}) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [wasBlurred, setWasBlurred] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const onlyNumbers = e.target.value.replace(/[^0-9]/g, '').slice(0, 14);

        if (!onlyNumbers) {
            setErrorMessage(null);
            setWasBlurred(false);
        } else if (wasBlurred && onlyNumbers.length !== 14) {
            setErrorMessage(t('inputError.siret.length'));
        } else {
            setErrorMessage(null);
        }

        onChange({ ...e, target: { ...e.target, name: e.target.name, value: onlyNumbers } });
    };

    const handleBlur = () => {
        setWasBlurred(true);
        if (value && value.length !== 14) {
            setErrorMessage(t('inputError.siret.length'));
        } else {
            setErrorMessage(null);
        }
    };

    return (
        <div>
            <div className="flex justify-between items-center mb-[5px]">
                <label className="block text-primary-theme text-xs font-normal">
                    {label}
                </label>
                {autoFilled && (
                    <div className="flex items-center gap-1 text-primary">
                        <HiOutlineSparkles className="w-3 h-3" />
                        <span className="text-[10px]">Renseignée automatiquement</span>
                    </div>
                )}
                {errorMessage && (
                    <p className="text-red-500 text-xs">{errorMessage}</p>
                )}
            </div>
            <input
                type="text"
                name={name}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={placeholder}
                disabled={disabled}
                className={`appearance-none text-sm block w-full border bg-theme text-primary-theme rounded-lg px-5 py-3 leading-tight focus:outline-none ${
                    errorMessage ? 'border-red-500 focus:border-red-500' : 'border-theme focus:ring-1 focus:ring-primary'
                } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                required={required}
            />
        </div>
    );
};

export default SiretInput;