import axiosInstance from './apiService';
import { AxiosError } from 'axios';
import { EventSourcePolyfill } from 'event-source-polyfill';

// Récupère la liste des topics en utilisant le token utilisateur
export const discoverMercure = async () => {
    try {
        const response = await axiosInstance.get('/v1/mercure/discover');
        const topics = response.data.map((item: any) => item.topic); // Extrait les topics

        // Le token est dans le cookie HttpOnly, vous n'avez pas besoin de le manipuler ici.

        return { topics };
    } catch (error) {
        if (error instanceof AxiosError) {
            console.error('Erreur lors de la découverte de Mercure:', error.response?.data || error.message);
        } else {
            console.error('Erreur lors de la découverte de Mercure:', error);
        }
        throw error;
    }
};

// Connecte au hub Mercure pour écouter les événements en temps réel
export const connectToMercure = (topics: string[], onMessage: (event: any) => void) => {
    const baseURL = axiosInstance.defaults.baseURL;
    if (!baseURL) {
        console.error('Base URL is not set in axiosInstance.');
        throw new Error('Base URL is not set in axiosInstance.');
    }

    // Créez l'URL pour la connexion à Mercure
    const url = new URL('/.well-known/mercure', baseURL);
    topics.forEach((topic) => {
        url.searchParams.append('topic', topic);
    });



    // Utilisation de EventSourcePolyfill pour la connexion (ou EventSource natif si le polyfill n'est pas nécessaire)
    const eventSource = new EventSourcePolyfill(url.toString(), { withCredentials: true });

    eventSource.onopen = () => {
    };

    // Utilisation de addEventListener pour récupérer les événements spécifiques
    eventSource.addEventListener('messages::create', (event: MessageEvent) => {

        if (event.data && event.data !== ":") {  // Assurez-vous que event.data existe et n'est pas simplement ":"
            try {
                const parsedData = JSON.parse(event.data);

                onMessage(parsedData);
            } catch (error) {
                console.error('Erreur lors du parsing de la donnée:', error);
            }
        } else {
            console.error('Aucune donnée ou donnée invalide reçue dans l\'événement.');
        }
    });

    // eventSource.onerror = (error: Event) => {
    // };

    return eventSource;
};