import React, { useState } from 'react';
import { FiChevronDown } from "react-icons/fi";

interface AccordionProps {
    title: string;
    children: React.ReactNode;
    isOpen?: boolean;
    onToggle?: (isOpen: boolean) => void;
    className?: string;
}

const Accordion: React.FC<AccordionProps> = ({
     title,
     children,
     isOpen: controlledIsOpen,
     onToggle,
     className = ''
 }) => {
    const [internalIsOpen, setInternalIsOpen] = useState(false);

    const isOpen = controlledIsOpen ?? internalIsOpen;

    const handleToggle = () => {
        const newValue = !isOpen;
        setInternalIsOpen(newValue);
        onToggle?.(newValue);
    };

    return (
        <div className={`border border-theme rounded-lg ${className}`}>
            <button
                className="w-full px-4 py-3 flex items-center justify-between bg-theme rounded-lg"
                onClick={handleToggle}
                type="button"
            >
                <span className="text-sm font-normal text-primary-theme">{title}</span>
                <FiChevronDown
                    className={`w-4 h-4 transition-transform duration-200 ${
                        isOpen ? 'transform rotate-180' : ''
                    }`}
                />
            </button>
            <div
                className={`
                    transform origin-top transition-all duration-200 ease-in-out
                    ${isOpen
                    ? 'opacity-100 scale-y-100 visible'
                    : 'opacity-0 scale-y-0 invisible h-0'
                }
                `}
            >
                <div className="px-4 py-4">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Accordion;