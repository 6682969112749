import { useState, useEffect } from 'react';

export const useIsPWA = () => {
    const [isPWA, setIsPWA] = useState(false);

    useEffect(() => {
        const isInStandaloneMode = window.matchMedia('(display-mode: standalone)').matches
            || (window.navigator as any).standalone;

        setIsPWA(isInStandaloneMode);
    }, []);

    return isPWA;
};
