import React from 'react';
import { HiOutlineSparkles } from "react-icons/hi2";

interface InputTextProps {
    label: string;
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    autoFilled?: boolean;
}

const InputText: React.FC<InputTextProps> = ({
     label,
     name,
     value,
     onChange,
     placeholder,
     required = false,
     disabled = false,
     autoFilled = false
 }) => {
    return (
        <div>
            <div className="flex justify-between items-center mb-[5px]">
                <label className="block text-primary-theme text-xs font-normal">
                    {label}
                </label>
                {autoFilled && (
                    <div className="flex items-center gap-1 text-primary">
                        <HiOutlineSparkles className="w-3 h-3" />
                        <span className="text-[10px]">Renseignée automatiquement</span>
                    </div>
                )}
            </div>
            <input
                type="text"
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                disabled={disabled}
                className={`appearance-none text-sm block w-full border border-theme bg-theme text-primary-theme rounded-lg px-5 py-3 leading-tight focus:outline-none focus:ring-1 focus:ring-primary ${
                    disabled ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                required={required}
            />
        </div>
    );
};

export default InputText;