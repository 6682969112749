// NavbarTop.tsx
import React, {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumb from "./Breadcrumb";
import { usePageContext } from "../contexts/PageContext";
import SearchBar, { SearchResult } from "./SearchBar";
import { useCustomers } from '../hooks/useCustomers';
import { useProjects } from '../hooks/useProjects';
import { useMissions } from '../hooks/useMissions';
import ProfileCard from "./ProfileCard";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store/store";
import {logoutUser, setUser} from "../features/auth/authSlice";
import {updateUserInfo} from "../api/authService";

const NavbarTop: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { dynamicData, showList } = usePageContext();
    const user = useSelector((state: RootState) => state.auth.user);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch<AppDispatch>();

    // Hooks Redux
    const { customers } = useCustomers();
    const { projects } = useProjects();
    const { missions } = useMissions();

    const hiddenBreadcrumbPaths = ['/dashboard', '/projects', '/customers', '/messaging', '/documents', '/settings'];
    const showBreadcrumb = !hiddenBreadcrumbPaths.includes(location.pathname);

    const truncateText = (text: string | undefined, limit: number = 50): string => {
        if (!text) return '';
        return text.length > limit ? `${text.substring(0, limit)}...` : text;
    };

    const handleSearch = (query: string): SearchResult[] => {
        const results: SearchResult[] = [];
        const searchTerm = query.toLowerCase();

        // Recherche dans les clients
        if (customers) {
            customers.forEach(customer => {
                const fullName = `${customer.firstName} ${customer.lastName}`.toLowerCase();
                if (
                    fullName.includes(searchTerm) ||
                    customer.companyName.toLowerCase().includes(searchTerm) ||
                    customer.email.toLowerCase().includes(searchTerm)
                ) {
                    results.push({
                        type: 'customer',
                        title: `${customer.firstName} ${customer.lastName}`,
                        subtitle: truncateText(customer.companyName),
                        uuid: customer.uuid
                    });
                }
            });
        }

        // Recherche dans les projets
        if (projects) {
            projects.forEach(project => {
                if (
                    project.title.toLowerCase().includes(searchTerm) ||
                    project.description.toLowerCase().includes(searchTerm)
                ) {
                    results.push({
                        type: 'project',
                        title: project.title,
                        subtitle: truncateText(project.description),
                        uuid: project.uuid
                    });
                }
            });
        }

        // Recherche dans les missions
        if (missions) {
            missions.forEach(mission => {
                if (
                    mission.title.toLowerCase().includes(searchTerm) ||
                    mission.description.toLowerCase().includes(searchTerm)
                ) {
                    results.push({
                        type: 'mission',
                        title: mission.title,
                        subtitle: truncateText(mission.status),
                        uuid: mission.uuid,
                        projectUuid: mission.uuid
                    });
                }
            });
        }

        // Options de création
        if (searchTerm.startsWith('new') || searchTerm.startsWith('créer')) {
            results.unshift(
                {
                    type: 'action',
                    title: 'Créer un nouveau client',
                    actionType: 'new_customer'
                },
                {
                    type: 'action',
                    title: 'Créer un nouveau projet',
                    actionType: 'new_project'
                }
            );
        }

        return results;
    };

    const handleResultSelect = (result: SearchResult) => {
        switch (result.type) {
            case 'customer':
                if (result.uuid) {
                    navigate(`/customers/${result.uuid}`);
                }
                break;
            case 'project':
                if (result.uuid) {
                    navigate(`/projects/${result.uuid}`);
                }
                break;
            case 'mission':
                if (result.projectUuid) {
                    navigate(`/projects/${result.projectUuid}`);
                }
                break;
            case 'action':
                if (result.actionType === 'new_customer') {
                    navigate('/customers/new');
                } else if (result.actionType === 'new_project') {
                    navigate('/projects/new');
                }
                break;
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (isModalOpen &&
                modalRef.current &&
                buttonRef.current &&
                !modalRef.current.contains(event.target as Node) &&
                !buttonRef.current.contains(event.target as Node)) {
                setIsModalOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isModalOpen]);


    const handleLogout = async () => {
        setIsLoading(true);

        try {
            await dispatch(logoutUser()).unwrap();
            navigate('/login');
        } catch (error) {
            console.error("Erreur lors de la déconnexion:", error);
        } finally {
            setIsLoading(false);
            setIsModalOpen(false);
        }
    };

    const handleProfileChange = async (profile: 'company' | 'customer') => {
        // setIsModalOpen(false);

        if (user) {
            try {
                const updatedUser = {
                    ...user,
                    profileSelected: profile,
                };
                const response = await updateUserInfo(updatedUser);
                dispatch(setUser(response.data));
                setIsModalOpen(false);
            } catch (error) {
                console.error("Erreur lors de la mise à jour du profil:", error);
            }
        }
    };

    return (
        <nav className={`flex items-center justify-between md:pl-6 md:p-4 p-4 
            ${location.pathname.startsWith('/messaging') ?
            `${!showList ? 'hidden md:flex' : 'flex'}`
            : 'flex'}`}>
            <div className="flex items-center justify-between md:block hidden">
                {showBreadcrumb && <Breadcrumb dynamicNames={dynamicData}/>}
            </div>
            <div className="md:hidden mr-4 relative">
                <button
                    ref={buttonRef}
                    onClick={() => setIsModalOpen(!isModalOpen)}
                >
                    <ProfileCard
                        imageUrl=""
                        // imageUrl={company?.logo && (company?.logo.uuid)}
                        firstName={user?.firstName || ''}
                        lastName={user?.lastName || ''}
                        label={user?.profileSelected === 'company' ? t('profil.company') : t('profil.customer')}
                        size="large"
                        hideText={true}
                    />
                </button>
                {isModalOpen && (
                    <div ref={modalRef}
                         className="absolute z-50 top-full mt-2 left-0 w-56"> {/* Changé bottom-0 en top-full et ajouté mt-2 */}
                        <div
                            className="w-full bg-surface-theme p-1 rounded-lg drop-shadow-[0px_5px_10px_rgba(22,_19,_35,_0.05)] border border-theme"> {/* Supprimé absolute et les positions */}
                            <button
                                onClick={() => handleProfileChange('company')}
                                className={`block w-full text-left p-2 hover:bg-theme-hover rounded-md ${user?.profileSelected === 'company' && 'bg-theme-focus'}`}
                            >
                                <ProfileCard
                                    firstName={t('profil.company')}
                                    lastName=""
                                    firstHide={user?.firstName || ''}
                                    lastHide={user?.lastName || ''}
                                    label="Profil"
                                    size="large"
                                />
                            </button>
                            <button
                                onClick={() => handleProfileChange('customer')}
                                className={`block w-full text-left p-2 hover:bg-theme-hover rounded-md ${user?.profileSelected === 'customer' && 'bg-theme-focus'}`}
                            >
                                <ProfileCard
                                    firstName={t('profil.customer')}
                                    lastName=""
                                    firstHide={user?.firstName || ''}
                                    lastHide={user?.lastName || ''}
                                    label="Profil"
                                    size="large"
                                />
                            </button>
                            <hr className="my-2"/>
                            <button
                                onClick={handleLogout}
                                className={`block w-full text-left p-2 hover:bg-theme-hover text-primary-theme rounded-md text-sm ${isLoading && 'flex justify-center items-center'}`}>
                                {isLoading ? (
                                    <svg
                                        className="animate-spin h-5 w-5 text-theme"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                ) : (
                                    <>
                                        {t('navbar.logout')}
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <SearchBar
                placeholder="Rechercher..."
                onSearch={handleSearch}
                onResultSelect={handleResultSelect}
            />
        </nav>
    );
};

export default NavbarTop;