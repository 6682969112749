import qs from "qs";
import axiosInstance from './apiService';

export const getAllProjects = () => {
    return axiosInstance.get('/v1/projects')
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la récupération des projets:', error);
            throw error;
        });
};

export const createProject = (projectData: any) => {
    const data = qs.stringify(projectData);
    return axiosInstance.post('/v1/projects', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.error('Erreur lors de la création du projet:', error);
        throw error;
    });
};

export const updateProject = (uuid: string, projectData: any) => {
    const data = qs.stringify(projectData);
    return axiosInstance.patch(`/v1/projects/${uuid}`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.error('Erreur lors de la mise à jour du projet:', error);
        throw error;
    });
};

export const deleteProject = (uuid: string) => {
    return axiosInstance.delete(`/v1/projects/${uuid}`)
        .then(response => {
            return response;
        }).catch(error => {
            console.error('Erreur lors de la suppression du projet:', error);
            throw error;
        });
};

export const getProjectInfo = (uuid: string) => {
    return axiosInstance.get(`/v1/projects/${uuid}`)
        .then(response => response.data)
        .catch(error => {
            console.error(`Erreur lors de la récupération du projet avec l'UUID ${uuid}:`, error);
            throw error;
        });
};

export const getMissionsByProject = (projectUuid: string) => {
    return axiosInstance.get(`/v1/projects/${projectUuid}/missions`)
        .then(response => response.data)
        .catch(error => {
            console.error(`Erreur lors de la récupération des missions pour le projet ${projectUuid}:`, error);
            throw error;
        });
};

export const createMission = (projectUuid: string, missionData: any) => {
    const data = qs.stringify(missionData);
    return axiosInstance.post(`/v1/projects/${projectUuid}/missions`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.error('Erreur lors de la création de la mission:', error);
        throw error;
    });
};

export const updateMissionStatus = (projectUuid: string, missionUuid: string, status: string) => {
    const data = qs.stringify({ status }); // On ne met à jour que le statut
    return axiosInstance.patch(`/v1/projects/${projectUuid}/missions/${missionUuid}`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.error(`Erreur lors de la mise à jour du statut de la mission ${missionUuid}:`, error);
        throw error;
    });
};

export const apiUpdateMission = (projectUuid: string, missionUuid: string, missionData: any) => {
    const data = qs.stringify(missionData);
    return axiosInstance.patch(`/v1/projects/${projectUuid}/missions/${missionUuid}`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    }).then(response => response.data)
        .catch(error => {
            console.error(`Erreur lors de la mise à jour de la mission ${missionUuid}:`, error);
            throw error;
        });
};

export const apiDeleteMission = (projectUuid: string, missionUuid: string) => {
    return axiosInstance.delete(`/v1/projects/${projectUuid}/missions/${missionUuid}`)
        .then(response => response.data)
        .catch(error => {
            console.error(`Erreur lors de la suppression de la mission ${missionUuid}:`, error);
            throw error;
        });
};

export const validateProject = (projectUuid: string) => {
    return axiosInstance.post(`/v1/projects/${projectUuid}/validate`)
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la validation du projet:', error);
            throw error;
        });
};

export const cancelProject = (projectUuid: string) => {
    return axiosInstance.post(`/v1/projects/${projectUuid}/cancel`)
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de l\'annulation du projet:', error);
            throw error;
        });
};

export const apiCreateQuote = (projectUuid: string) => {
    return axiosInstance.post(`/v1/projects/${projectUuid}/quotes`)
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la création du devis:', error);
            throw error;
        });
};

export const apiInviteCustomer = (projectUuid: string) => {
    return axiosInstance.post(`/v1/projects/${projectUuid}/send-invitation`)
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la création du devis:', error);
            throw error;
        });
};

export const updateMissionsPositions = (projectUuid: string, positionsData: Array<{uuid: string, position: number}>) => {
    const data = qs.stringify({ positions: positionsData });
    return axiosInstance.patch(`/v1/projects/${projectUuid}/missions/positions`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.error('Erreur lors de la mise à jour des positions des missions:', error);
        throw error;
    });
};

export const getQuoteById = (projectUuid: string, quoteUuid: string) => {
    return axiosInstance.get(`/v1/projects/${projectUuid}/quotes/${quoteUuid}`)
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la récupération du devis:', error);
            throw error;
        });
};

export const getQuotePreview = (projectUuid: string, quoteUuid: string) => {
    return axiosInstance.get(`/v1/projects/${projectUuid}/quotes/${quoteUuid}/preview`)
        .then(response => response.data.html)
        .catch(error => {
            console.error('Erreur lors de la récupération de la prévisualisation du devis:', error);
            throw error;
        });
};

export const validateQuote = (projectUuid: string, quoteUuid: string, currency?: string) => {
    const data = currency ? qs.stringify({ currency }) : '';

    return axiosInstance.post(`/v1/projects/${projectUuid}/quotes/${quoteUuid}/validate`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la validation du devis:', error);
            throw error;
        });
};
export const updateQuote = (
    projectUuid: string,
    quoteUuid: string,
    {
        currency,
        title,
        description,
        items
    }: {
        currency: string;
        title?: string;
        description?: string;
        items?: any[];
    }
) => {
    // On prépare le body PATCH selon ce qui est passé
    const payload: any = { currency };

    if (title !== undefined) payload.title = title;
    if (description !== undefined) payload.description = description;
    if (items !== undefined) payload.items = items;

    return axiosInstance.patch(`/v1/projects/${projectUuid}/quotes/${quoteUuid}`, payload)
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la mise à jour du devis:', error);
            throw error;
        });
};

export const signQuote = (projectUuid: string, quoteUuid: string) => {
    const screenResolution = `${window.screen.width}x${window.screen.height}`;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneOffset = new Date().getTimezoneOffset().toString();

    const data = qs.stringify({
        screenResolution,
        timezone,
        timezoneOffset
    });

    return axiosInstance.post(`/v1/projects/${projectUuid}/quotes/${quoteUuid}/sign`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la signature du devis:', error);
            throw error;
        });
};

export const sendQuote = (projectUuid: string, quoteUuid: string, email: string) => {
    const data = qs.stringify({
        email
    });

    return axiosInstance.post(`/v1/projects/${projectUuid}/quotes/${quoteUuid}/send`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de l\'envoi du devis:', error);
            throw error;
        });
};

export const startMission = (projectUuid: string, missionUuid: string) => {
    // Utilisation de PATCH au lieu de POST pour la route /start
    return axiosInstance.patch(`/v1/projects/${projectUuid}/missions/${missionUuid}/start`)
        .then(response => {
            return response.data;
        }).catch(error => {
            console.error(`Erreur lors du démarrage de la mission ${missionUuid}:`, error);
            throw error;
        });
};

export const finishMission = (projectUuid: string, missionUuid: string) => {
    // Utilisation de PATCH au lieu de POST pour la route /finish
    return axiosInstance.patch(`/v1/projects/${projectUuid}/missions/${missionUuid}/finish`)
        .then(response => {
            return response.data;
        }).catch(error => {
            console.error(`Erreur lors de la finalisation de la mission ${missionUuid}:`, error);
            throw error;
        });
};

export const refuseQuote = (projectUuid: string, quoteUuid: string) => {
    const data = qs.stringify({}); // Pas de body attendu mais on respecte le format

    return axiosInstance.post(`/v1/projects/${projectUuid}/quotes/${quoteUuid}/refuse`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors du refus du devis:', error);
            throw error;
        });
};