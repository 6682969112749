// hooks/useProjects.ts

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../store/hooks';
import {
    fetchProjects,
    fetchProjectById,
    createProject,
    updateProject,
    deleteProject,
    validateProject,
    cancelProject,
    selectProjects,
    selectProjectsLoading,
    selectProjectsError,
    selectProjectById,
    selectHasFetchedAll,
    createQuote,
    inviteCustomer,
} from '../features/project/projectSlice';
import { Project, NewProject, Quotes } from '../features/project/types';
import { RootState } from '../store/store';

interface UseProjectsReturn {
    projects: Project[];
    selectedProject: Project | null;
    loading: boolean;
    error: string | null;
    addProject: (projectData: NewProject) => Promise<Project>;
    modifyProject: (uuid: string, projectData: Partial<Omit<Project, 'uuid'>>) => Promise<Project>;  // Modifié ici
    removeProject: (uuid: string) => Promise<void>;
    validateProject: (uuid: string) => Promise<void>;
    cancelProject: (uuid: string) => Promise<void>;
    createQuote: (uuid: string) => Promise<Quotes>;
    inviteCustomer: (uuid: string) => Promise<void>;
}

export const useProjects = (projectUuid?: string): UseProjectsReturn => {
    const dispatch = useAppDispatch();

    const projects = useSelector(selectProjects);
    const projectsLoading = useSelector(selectProjectsLoading);
    const projectsError = useSelector(selectProjectsError);
    const hasFetchedAll = useSelector(selectHasFetchedAll);

    const selectedProject = useSelector((state: RootState) =>
        projectUuid ? selectProjectById(state, projectUuid) : null
    ) ?? null;

    useEffect(() => {
        if (projectUuid) {
            if (!selectedProject) {
                dispatch(fetchProjectById(projectUuid));
            }
        } else if (!hasFetchedAll) {
            dispatch(fetchProjects());
        }
    }, [projectUuid, selectedProject, hasFetchedAll, dispatch]);

    const addProject = async (projectData: NewProject): Promise<Project> => {
        try {
            const result = await dispatch(createProject(projectData)).unwrap();
            return result;
        } catch (err) {
            console.error('Failed to create project:', err);
            throw err;
        }
    };

    const modifyProject = async (
        uuid: string,
        projectData: Partial<Omit<Project, 'uuid'>>
    ): Promise<Project> => { // Retourne maintenant Project au lieu de void
        try {
            const result = await dispatch(updateProject({ uuid, projectData })).unwrap();
            return result; // Retourne le projet mis à jour
        } catch (err) {
            console.error('Failed to update project:', err);
            throw err;
        }
    };

    const removeProject = async (uuid: string): Promise<void> => {
        try {
            await dispatch(deleteProject(uuid)).unwrap();
        } catch (err) {
            console.error('Failed to delete project:', err);
            throw err;
        }
    };

    const validate = async (uuid: string): Promise<void> => {
        try {
            await dispatch(validateProject(uuid)).unwrap();
        } catch (err) {
            console.error('Failed to validate project:', err);
            throw err;
        }
    };

    const cancel = async (uuid: string): Promise<void> => {
        try {
            await dispatch(cancelProject(uuid)).unwrap();
        } catch (err) {
            console.error('Failed to cancel project:', err);
            throw err;
        }
    };

    const createProjectQuote = async (uuid: string): Promise<Quotes> => {
        try {
            const result = await dispatch(createQuote(uuid)).unwrap();
            return result.quote;
        } catch (err) {
            console.error('Failed to create quote:', err);
            throw err;
        }
    };


    const inviteProjectCustomer = async (uuid: string): Promise<void> => {
        try {
            await dispatch(inviteCustomer(uuid)).unwrap();
        } catch (err) {
            console.error('Failed to invite customer:', err);
            throw err;
        }
    };

    return {
        projects,
        selectedProject,
        loading: projectsLoading,
        error: projectsError,
        addProject,
        modifyProject,
        removeProject,
        validateProject: validate,
        cancelProject: cancel,
        createQuote: createProjectQuote,
        inviteCustomer: inviteProjectCustomer,
    };
};