import { useState, useEffect } from 'react';
import { getCountries } from '../api/companyService';

interface Country {
    value: string;
    label: string;
}

export const useCountries = () => {
    const [countries, setCountries] = useState<Country[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await getCountries();
                setCountries(response.data.items);
            } catch (error) {
                console.error('Erreur lors de la récupération des pays:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCountries();
    }, []);

    const normalizeString = (str: string): string => {
        return str
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/[^a-z0-9]/g, "");
    };

    const getCountryCodeByName = (countryName: string): string => {
        if (!countryName) return '';

        const normalizedSearchName = normalizeString(countryName);

        // Recherche exacte d'abord
        const exactMatch = countries.find(c =>
            normalizeString(c.label) === normalizedSearchName
        );
        if (exactMatch) return exactMatch.value;

        // Si pas de correspondance exacte, recherche partielle
        const partialMatch = countries.find(c =>
            normalizeString(c.label).includes(normalizedSearchName) ||
            normalizedSearchName.includes(normalizeString(c.label))
        );

        return partialMatch ? partialMatch.value : '';
    };

    return { countries, isLoading, getCountryCodeByName };
};