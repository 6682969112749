import React, { useState } from "react";
import { formatDate } from '../../../utils/dateUtils';
import ProfileCard from "../../../components/ProfileCard";
import {
    FiCalendar, FiCheck, FiEdit,
    FiLoader, FiMaximize2, FiMinimize2,
    FiUsers, FiX
} from 'react-icons/fi';
import {formatPrice} from "../../../utils/formatPrice";
import EditableText from "../../../components/EditableText";
import InputSelect, {SelectOption} from "../../../components/InputSelect";
import {useProjects} from "../../../hooks/useProjects";
import {useUserPermissions} from "../../../hooks/useUserPermissions";
import PdfViewer from "../../../components/PdfViewer";
import {t} from "i18next";
import {ImageDisplay} from "../../../components/ImageDisplay";
import type { Quotes } from '../types';

const Overview: React.FC<{ project: any; company: any; missions: any[] }> = ({ project, company, missions }) => {

    const totalMissions = missions.length;
    const completedMissions = missions.filter(mission => mission.status === 'completed').length;
    const completionPercentage = totalMissions > 0 ? (completedMissions / totalMissions) * 100 : 0;

    const { isCompanyProfile } = useUserPermissions();

    // Trouver la date la plus éloignée parmi les missions
    const mostDistantDate = missions && missions.length > 0
        ? missions
            .map(mission => new Date(mission.exceptedEndDate))
            .reduce((latest, date) => (date > latest ? date : latest), new Date(0))
        : null; // ou utilisez une valeur par défaut, par exemple new Date(0)


    const totalPrice = missions
        .map(mission => mission.unitPrice * mission.quantity)
        .reduce((total, amount) => total + amount, 0);

    const formatDateTest = (date: Date) => {
        return date.toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
    };

    const [isExpanded, setIsExpanded] = useState(false);

    const [selected, setSelected] = useState<SelectOption>();

    const { validateProject, cancelProject } = useProjects(project?.uuid);

    const options: SelectOption[] = [
        {
            id: "accepted",
            label: t('project.manualState.accepted.label'),
            description: t('project.manualState.accepted.description'),
            icon: (
                <FiCheck className="w-5 h-5 text-green-500" />
            )
        },
        {
            id: "rejected",
            label: t('project.manualState.rejected.label'),
            description: t('project.manualState.rejected.description'),
            icon: (
                <FiX className="w-5 h-5 text-red-500" />
            )
        }
    ];

    const handleSelect = async (option: SelectOption) => {
        try {
            if (option.id === 'accepted') {
                await validateProject(project.uuid);
            } else if (option.id === 'rejected') {
                await cancelProject(project.uuid);
            }
            setSelected(option);
        } catch (error) {
            console.error('Erreur lors de la validation/annulation:', error);
        }
    };

    console.log("Quotes:", project.quotes);

    const validQuote = project.quotes?.find((q: Quotes) =>
        q.status === 'validated' || q.status === 'accepted'
    );

    return (
        <div className="bg-theme rounded-xl p-1 drop-shadow-[0px_5px_10px_rgba(22,_19,_35,_0.02)]">
            <div className="flex justify-between items-center">
                <div className="flex items-center space-x-4 py-2.5 px-4 mb-1">
                    <div className="bg-white w-14 h-14 p-2 flex items-center justify-center rounded-lg">
                        <ImageDisplay uuid={project.createdBy.company?.logo && (project.createdBy.company.logo.uuid)} alt="Photo de profil" />
                    </div>
                    <div>
                        <p className="text-primary-theme font-semibold text-base">{project.title}</p>
                        <span className="text-primary-theme font-normal text-xs">{project.createdBy.company.commercialName ? project.createdBy.company.commercialName : project.createdBy.company.legalName }</span>
                    </div>
                </div>
                <div className="px-4">
                    <p className="text-color-primary font-semibold text-base">{formatPrice(totalPrice)} €</p>
                </div>
            </div>
            <div
                className="bg-surface-theme py-2.5 px-4 rounded-lg text-sm text-[text-[#8A8991] font-normal">
                <div className="flex items-center space-x-8">
                    <p className="text-xs font-normal text-secondary-theme">{t('project.createdAt')} <span className="text-primary-theme">{formatDate(project.createdAt)}</span></p>
                    <p className="text-xs font-normal text-secondary-theme">
                        {project?.updatedAt ? (
                            <>
                                {t('project.updatedAt')} <span className="text-primary-theme">{formatDate(project.updatedAt)}</span>
                            </>
                        ) : null}
                    </p>
                </div>
                <div className="flex flex-col mt-7 gap-y-3.5">
                    <div className="flex justify-start items-center">
                        <div className="flex items-center text-primary gap-2 w-40">
                            <FiUsers className="transform translate-y-[1px]"/>
                            <span className="text-xs">{t('project.assigned')}</span>
                        </div>
                        <div className="flex space-x-6">
                            <ProfileCard
                                imageUrl=""
                                firstName={project.createdBy.firstName}
                                lastName={project.createdBy.lastName}
                                size="small"
                            />
                        </div>
                    </div>
                    <div className="md:flex md:justify-between items-center">
                        <div className="flex justify-start items-center md:mb-0 mb-4">
                            <div className="flex items-center text-primary gap-2 w-40">
                                <FiLoader className="transform translate-y-[1px]"/>
                                <span className="text-xs">{t('project.status')}</span>
                            </div>
                            <div>
                            <span className="bg-[#B261E5] text-white rounded px-4 py-1 text-xs font-medium">
                                {project.status.label}
                            </span>
                            </div>
                        </div>
                        {isCompanyProfile && (
                            <div>
                                {project.status.value === "draft" && (
                                    <InputSelect
                                        options={options}
                                        selectedOption={selected}
                                        onSelect={handleSelect}
                                        placeholder={t('project.manualState.title')}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    <div className="flex justify-start items-center">
                        <div className="flex items-center text-primary gap-2 w-40">
                            <FiCalendar className="transform translate-y-[1px]"/>
                            <span className="text-xs">{t('project.endDate')}</span>
                        </div>
                        <div>
                            <span className="text-primary-theme text-xs">{mostDistantDate ? formatDateTest(mostDistantDate) : 'No date available'}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative bg-surface-theme mt-1 py-2.5 px-4 rounded-lg">
                <div className="flex items-center justify-between mb-2.5">
                    <p className="text-primary-theme text-xs font-medium">{t('project.description')}</p>
                    <button
                        className="text-primary-theme hover:text-color-primary-hover text-sm font-bold cursor-pointer"
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        {isExpanded ? <FiMinimize2 /> : <FiMaximize2 />}
                    </button>
                </div>
                <div
                    className={`text-primary-theme text-normal ${
                        !isExpanded ? "overflow-hidden" : ""
                    }`}
                    style={{
                        maxHeight: isExpanded ? "none" : "4.5rem",
                        position: "relative",
                    }}
                >
                    <p className="text-primary-theme text-sm">{project.description}</p>

                    {!isExpanded && (
                        <div
                            className="absolute bottom-0 left-0 w-full h-8 bg-gradient-to-t from-surface-theme to-transparent z-10"
                        />
                    )}
                </div>
            </div>
            <div className="bg-surface-theme mt-1 py-2.5 px-4 rounded-lg">
                <p className="text-primary-theme text-xs font-medium mb-2.5">{t('project.progress')}</p>
                <span className="text-primary-theme text-sm text-primary-theme font-medium">{project.title} - {completionPercentage.toFixed(0)}% sur 100%</span>
                <div className="w-full bg-theme h-2 rounded-full mt-2.5">
                    <div className="bg-color-primary h-2 rounded-full" style={{width: `${completionPercentage.toFixed(0)}%`}}></div>
                </div>
            </div>
            {validQuote && (
                <PdfViewer pdfUrl={validQuote.document.publicUrl} />
            )}
        </div>
    );
}

export default Overview;