import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchCustomerById,
    selectCustomerById,
    deleteCustomer,
    selectCustomersLoading
} from './customerSlice';
import { fetchProjects, selectProjects } from '../project/projectSlice';
import Skeleton from '../../components/Skeleton';
import Modal from '../../components/Modal';
import { AppDispatch, RootState } from '../../store/store';
import {usePageContext} from "../../contexts/PageContext";
import ProfileCard from "../../components/ProfileCard";
import {FiMail, FiPhone, FiUsers} from "react-icons/fi";
import {HiOutlineBuildingLibrary} from "react-icons/hi2";
import ContactCard from "../../components/ContactCard";
import SidePanel from "../../components/SidePanel";
import CustomerForm from "./CustomerForm";

interface CustomerObject {
    uuid: string;
}

function isCustomerObject(customer: any): customer is CustomerObject {
    return customer && typeof customer === 'object' && 'uuid' in customer;
}

const CustomerDetail: React.FC = () => {
    const { uuid } = useParams<{ uuid: string }>();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
    const customerFormRef = React.useRef<any>(null);

    const customer = useSelector((state: RootState) =>
        uuid ? selectCustomerById(state, uuid) : undefined
    );
    const customerLoading = useSelector(selectCustomersLoading);

    const projects = useSelector(selectProjects); // Récupère tous les projets du store

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        if (uuid && !customer) {
            dispatch(fetchCustomerById(uuid));
        }
        if (projects.length === 0) {
            dispatch(fetchProjects());
        }
    }, [dispatch, uuid, customer, projects.length]);

    const handleDeleteClick = () => {
        setShowConfirmModal(true);
    };

    const confirmDelete = async () => {
        if (uuid) {
            setDeleting(true);
            await dispatch(deleteCustomer(uuid));
            setDeleting(false);
            navigate('/customers');
        }
    };

    const cancelDelete = () => {
        setShowConfirmModal(false);
    };

    const handleEdit = () => {
        setIsSidePanelOpen(true);
    };

    const closeSidePanel = (customerUuid?: string) => {
        setIsSidePanelOpen(false);
        if (customerUuid) {
            dispatch(fetchCustomerById(customerUuid)); // Pour rafraîchir les données si modifiées
        }
    };

    const { setDynamicData } = usePageContext(); // Utilisation du contexte

    useEffect(() => {
        if (uuid && !customer) {
            dispatch(fetchCustomerById(uuid));
        }
        if (projects.length === 0) {
            dispatch(fetchProjects());
        }

        // Mettre à jour le nom du client dans le contexte
        if (customer && uuid) {
            setDynamicData({ [uuid]: `${customer.firstName} ${customer.lastName}` });
        }
    }, [dispatch, uuid, customer, projects.length, setDynamicData]);

    if (customerLoading || !customer) {
        return (
            <div className="p-4">
                <Skeleton width="50%" height="30px" />
                <Skeleton width="100%" height="20px" />
                <Skeleton width="100%" height="20px" />
                <Skeleton width="100%" height="20px" />
            </div>
        );
    }

    // Filtrer les projets associés au client
    const customerProjects = projects.filter(project =>
        isCustomerObject(project.customer) && project.customer.uuid === customer.uuid
    );

    return (
        <>
            <ContactCard
                firstName={customer.firstName}
                lastName={customer.lastName}
                companyName={customer.companyName}
                email={customer.email}
                phone={customer.phone}
                imageUrl=""
                customerProjects={customerProjects}
            />
            <div className="mt-4 flex space-x-2">
                <button
                    className="px-4 py-2 bg-blue-500 text-white rounded"
                    onClick={handleEdit}
                >
                    Modifier
                </button>
                <button
                    className="px-4 py-2 bg-red-500 text-white rounded"
                    onClick={handleDeleteClick}
                >
                    Supprimer
                </button>
            </div>

            {/* Modal de confirmation de suppression */}
            <Modal show={showConfirmModal} onClose={cancelDelete} title="Confirmer la suppression">
                <p>Êtes-vous sûr de vouloir supprimer le client suivant ?</p>
                <p className="font-bold">{customer.firstName} {customer.lastName}</p>
                <div className="flex justify-end space-x-2 mt-4">
                    <button className="px-4 py-2 bg-gray-500 text-white rounded" onClick={cancelDelete}
                            disabled={deleting}>
                        Annuler
                    </button>
                    <button className="px-4 py-2 bg-red-500 text-white rounded flex items-center"
                            onClick={confirmDelete} disabled={deleting}>
                        {deleting ? (
                            <svg
                                className="animate-spin h-5 w-5 text-white mr-2"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                        ) : 'Supprimer'}
                    </button>
                </div>
            </Modal>
            <SidePanel
                isOpen={isSidePanelOpen}
                onClose={() => setIsSidePanelOpen(false)}
                onValidate={() => {
                    if (customerFormRef.current) {
                        customerFormRef.current.submitForm();
                    }
                }}
            >
                <div data-page="1">
                    {customer && (
                        <CustomerForm
                            ref={customerFormRef}
                            customerData={customer}
                            isEditMode={true}
                            isLoading={false}
                            onSuccess={closeSidePanel}
                        />
                    )}
                </div>
            </SidePanel>
        </>
    )
    ;
};

export default CustomerDetail;