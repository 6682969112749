import React from 'react';
import { useTranslation } from 'react-i18next';
import InputSelect, {SelectOption} from "../../../components/InputSelect";

const LanguageSelector: React.FC = () => {
    const { t, i18n } = useTranslation();

    const languageOptions: SelectOption[] = [
        {
            id: 'en',
            label: t('languages.english'),
        },
        {
            id: 'fr',
            label: t('languages.french'),
        }
    ];

    const selectedLanguage = languageOptions.find(
        option => option.id === i18n.language
    );

    const handleLanguageChange = (option: SelectOption) => {
        i18n.changeLanguage(option.id);
        localStorage.setItem('language', option.id);
    };

    return (
        <div className="max-w-sm ml-0 mt-4">
            <label
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
                {t('settings.language')}
            </label>
            <InputSelect
                options={languageOptions}
                selectedOption={selectedLanguage}
                onSelect={handleLanguageChange}
                placeholder={t('settings.selectLanguage')}
            />
        </div>
    );
};

export default LanguageSelector;