import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../store/hooks';
import {
    fetchImages,
    uploadImage,
    deleteImage,
    selectImages,
    selectImagesLoading,
    selectImagesError,
    selectImageById,
    Image
} from '../features/image/imageSlice';
import { RootState } from '../store/store';

interface UseImagesReturn {
    images: Image[];
    selectedImage: Image | null;
    loading: boolean;
    error: string | null;
    uploadImage: (file: File) => Promise<Image>;
    removeImage: (uuid: string) => Promise<void>;
}

export const useImages = (imageUuid?: string): UseImagesReturn => {
    const dispatch = useAppDispatch();

    const images = useSelector(selectImages);
    const loading = useSelector(selectImagesLoading);
    const error = useSelector(selectImagesError);
    const selectedImage = useSelector((state: RootState) =>
        imageUuid ? selectImageById(state, imageUuid) : null
    ) ?? null; // Utilisation de l'opérateur de coalescence nulle pour convertir undefined en null

    useEffect(() => {
        dispatch(fetchImages());
    }, [dispatch]);

    const handleImageUpload = async (file: File): Promise<Image> => {
        try {
            const resultAction = await dispatch(uploadImage(file));
            if (uploadImage.fulfilled.match(resultAction)) {
                return resultAction.payload;
            }
            throw new Error('Échec du téléchargement de l\'image');
        } catch (err) {
            console.error('Failed to upload image:', err);
            throw err;
        }
    };

    const removeImage = async (uuid: string): Promise<void> => {
        try {
            const resultAction = await dispatch(deleteImage(uuid));
            if (!deleteImage.fulfilled.match(resultAction)) {
                throw new Error('Échec de la suppression de l\'image');
            }
        } catch (err) {
            console.error('Failed to delete image:', err);
            throw err;
        }
    };

    return {
        images,
        selectedImage,
        loading,
        error,
        uploadImage: handleImageUpload,
        removeImage
    };
};