import React, { useState, useEffect } from 'react';
import { HiOutlineSparkles } from "react-icons/hi2";
import {FiChevronDown, FiChevronUp} from "react-icons/fi";

interface InputNumberProps {
    label: string;
    name: string;
    value: string | number;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    autoFilled?: boolean;
    min?: number;
    max?: number;
    step?: number;
    allowNegative?: boolean;
}

const InputNumber: React.FC<InputNumberProps> = ({
     label,
     name,
     value,
     onChange,
     placeholder,
     required = false,
     disabled = false,
     autoFilled = false,
     min = 0,
     max,
     step = 0.01,
     allowNegative = false
 }) => {
    // Si allowNegative est true, on met à jour min
    const effectiveMin = allowNegative ? Number.MIN_SAFE_INTEGER : min;

    // État pour stocker si l'utilisateur préfère la virgule ou le point
    const [useComma, setUseComma] = useState<boolean>(true);
    const [inputValue, setInputValue] = useState<string>(
        value ? formatForDisplay(value.toString(), true) : '0'
    );

    useEffect(() => {
        // Mettre à jour la valeur affichée quand la prop value change
        if (value !== undefined) {
            setInputValue(formatForDisplay(value.toString(), useComma));
        }
    }, [value, useComma]);

    // Crée un événement de type ChangeEvent<HTMLInputElement> compatible
    const createSyntheticEvent = (value: string): React.ChangeEvent<HTMLInputElement> => {
        // Création d'un élément input fictif
        const input = document.createElement('input');
        input.type = 'text';
        input.name = name;
        input.value = value;

        // Construction de l'événement synthétique
        return {
            target: input,
            currentTarget: input,
            bubbles: true,
            cancelable: true,
            defaultPrevented: false,
            eventPhase: 0,
            isTrusted: true,
            preventDefault: () => {},
            isDefaultPrevented: () => false,
            stopPropagation: () => {},
            isPropagationStopped: () => false,
            stopImmediatePropagation: () => {},
            isImmediatePropagationStopped: () => false,
            persist: () => {},
            timeStamp: Date.now(),
            type: 'change',
            nativeEvent: new Event('change')
        } as unknown as React.ChangeEvent<HTMLInputElement>;
    };

    // Fonction pour normaliser la valeur (remplacer la virgule par un point)
    const normalizeValue = (val: string): string => {
        return val.replace(',', '.');
    };

    // Fonction pour convertir un nombre en format d'affichage (respecte la préférence de l'utilisateur)
    function formatForDisplay(val: string, useCommaFormat: boolean): string {
        if (useCommaFormat && val.includes('.')) {
            return val.replace('.', ',');
        } else if (!useCommaFormat && val.includes(',')) {
            return val.replace(',', '.');
        }
        return val;
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;

        // Permet de saisir une valeur vide temporairement pour faciliter l'édition
        if (newValue === '') {
            setInputValue('');
            return; // Ne déclenche pas onChange sur un champ vide temporaire
        }

        // Cas spécial : juste un signe moins (pour commencer à taper une valeur négative)
        if (newValue === '-' && allowNegative) {
            setInputValue('-');
            return; // Ne déclenche pas onChange sur un signe moins seul
        }

        // Détecte la préférence de l'utilisateur pour le séparateur décimal
        if (newValue.includes(',')) {
            setUseComma(true);
        } else if (newValue.includes('.')) {
            setUseComma(false);
        }

        // Autoriser les virgules, les points comme séparateurs décimaux et le signe négatif au début
        const isValidFormat = /^-?[0-9]*[.,]?[0-9]*$/.test(newValue);

        if (isValidFormat) {
            // Si les nombres négatifs ne sont pas autorisés et que la valeur est négative, on refuse
            if (!allowNegative && newValue.startsWith('-')) {
                return;
            }

            // On affiche la valeur exactement comme l'utilisateur l'a saisie
            setInputValue(newValue);

            // On normalise la valeur (remplace la virgule par un point) avant de la passer au onChange
            const normalizedValue = normalizeValue(newValue);

            // On ne déclenche l'événement onChange que si c'est un nombre valide ou un "-" seul
            if (normalizedValue !== '-' && !isNaN(parseFloat(normalizedValue))) {
                const syntheticEvent = createSyntheticEvent(normalizedValue);
                onChange(syntheticEvent);
            }
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        // Si le champ est vide ou juste un signe moins, on le réinitialise à 0
        if (inputValue === '' || inputValue === '-' || isNaN(parseFloat(normalizeValue(inputValue)))) {
            setInputValue('0');
            const syntheticEvent = createSyntheticEvent('0');
            onChange(syntheticEvent);
            return;
        }

        // Normaliser la valeur (remplacer virgule par point)
        const normalizedValue = normalizeValue(inputValue);

        // Vérifie les limites min/max
        let numValue = parseFloat(normalizedValue);
        if (numValue < effectiveMin) numValue = effectiveMin;
        if (max !== undefined && numValue > max) numValue = max;

        // Pour les pas décimaux (comme 0.01), garde la même précision
        const stepStr = step.toString();
        const decimalPlaces = stepStr.includes('.') ? stepStr.split('.')[1].length : 0;

        // Formatage de la valeur
        let formattedWithDot: string;

        // Si la valeur entrée contient des décimales ou si le pas a des décimales
        if (normalizedValue.includes('.') || decimalPlaces > 0) {
            // Déterminer le nombre de décimales nécessaires
            const inputDecimals = normalizedValue.includes('.') ?
                normalizedValue.split('.')[1]?.length || 0 : 0;
            const requiredDecimals = Math.max(inputDecimals, decimalPlaces);

            // Formater avec le bon nombre de décimales
            formattedWithDot = numValue.toFixed(requiredDecimals);

            // Supprimer les zéros finaux inutiles
            formattedWithDot = formattedWithDot.replace(/\.?0+$/, '');

            // Si ça se termine par un point, ajouter un 0
            if (formattedWithDot.endsWith('.')) {
                formattedWithDot += '0';
            }
        } else {
            // Pour les nombres entiers
            formattedWithDot = numValue.toString();
        }

        // Convertir en format d'affichage selon la préférence de l'utilisateur
        const formattedDisplay = formatForDisplay(formattedWithDot, useComma);

        // Mettre à jour l'affichage avec la valeur formatée
        setInputValue(formattedDisplay);

        // Mais envoyer la valeur normalisée (avec point)
        if (formattedWithDot !== normalizedValue) {
            const syntheticEvent = createSyntheticEvent(formattedWithDot);
            onChange(syntheticEvent);
        }
    };

    const increment = () => {
        if (disabled) return;

        // Normaliser la valeur avant de la traiter comme un nombre
        const normalizedValue = normalizeValue(inputValue);
        const currentValue = normalizedValue === '' || normalizedValue === '-' ? 0 : parseFloat(normalizedValue);
        if (isNaN(currentValue)) return;

        let newValue = currentValue + step;
        if (max !== undefined && newValue > max) newValue = max;

        // Pour les pas décimaux (comme 0.01), garde la même précision
        const stepStr = step.toString();
        const decimalPlaces = stepStr.includes('.') ? stepStr.split('.')[1].length : 0;

        // Formate la valeur avec le bon nombre de décimales si nécessaire
        let formattedWithDot: string;
        if (decimalPlaces > 0) {
            formattedWithDot = newValue.toFixed(decimalPlaces);
        } else {
            formattedWithDot = newValue.toString();
        }

        // Afficher selon la préférence de l'utilisateur (virgule ou point)
        const formattedDisplay = formatForDisplay(formattedWithDot, useComma);
        setInputValue(formattedDisplay);

        // Mais envoyer la valeur avec point pour le traitement
        const syntheticEvent = createSyntheticEvent(formattedWithDot);
        onChange(syntheticEvent);
    };

    const decrement = () => {
        if (disabled) return;

        // Normaliser la valeur avant de la traiter comme un nombre
        const normalizedValue = normalizeValue(inputValue);
        const currentValue = normalizedValue === '' || normalizedValue === '-' ? 0 : parseFloat(normalizedValue);
        if (isNaN(currentValue)) return;

        let newValue = currentValue - step;
        if (newValue < effectiveMin) newValue = effectiveMin;

        // Pour les pas décimaux (comme 0.01), garde la même précision
        const stepStr = step.toString();
        const decimalPlaces = stepStr.includes('.') ? stepStr.split('.')[1].length : 0;

        // Formate la valeur avec le bon nombre de décimales si nécessaire
        let formattedWithDot: string;
        if (decimalPlaces > 0) {
            formattedWithDot = newValue.toFixed(decimalPlaces);
        } else {
            formattedWithDot = newValue.toString();
        }

        // Afficher selon la préférence de l'utilisateur (virgule ou point)
        const formattedDisplay = formatForDisplay(formattedWithDot, useComma);
        setInputValue(formattedDisplay);

        // Mais envoyer la valeur avec point pour le traitement
        const syntheticEvent = createSyntheticEvent(formattedWithDot);
        onChange(syntheticEvent);
    };

    return (
        <div>
            <div className="flex justify-between items-center mb-[5px]">
                <label className="block text-primary-theme text-xs font-normal">
                    {label}
                </label>
                {autoFilled && (
                    <div className="flex items-center gap-1 text-primary">
                        <HiOutlineSparkles className="w-3 h-3" />
                        <span className="text-[10px]">Renseignée automatiquement</span>
                    </div>
                )}
            </div>
            <div className="relative">
                <input
                    type="text"
                    inputMode="decimal"
                    name={name}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    placeholder={placeholder}
                    disabled={disabled}
                    className={`appearance-none text-sm block w-full border border-theme bg-theme text-primary-theme rounded-lg px-5 py-3 leading-tight focus:outline-none focus:ring-1 focus:ring-primary ${
                        disabled ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    required={required}
                />
                <div className="absolute inset-y-0 right-0 flex flex-col pr-3 py-1.5">
                    <button
                        type="button"
                        onClick={increment}
                        disabled={disabled || (max !== undefined && parseFloat(normalizeValue(inputValue) || '0') >= max)}
                        className={`h-1/2 flex items-center justify-center text-primary-theme focus:outline-none ${
                            disabled ? 'opacity-50 cursor-not-allowed' : 'hover:text-primary'
                        }`}
                    >
                        <FiChevronUp className="w-4 h-4" />
                    </button>
                    <button
                        type="button"
                        onClick={decrement}
                        disabled={disabled || parseFloat(normalizeValue(inputValue) || '0') <= effectiveMin}
                        className={`h-1/2 flex items-center justify-center text-primary-theme focus:outline-none ${
                            disabled ? 'opacity-50 cursor-not-allowed' : 'hover:text-primary'
                        }`}
                    >
                        <FiChevronDown className="w-4 h-4" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default InputNumber;