import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { store } from '../store/store';
import { logout } from '../features/auth/authSlice';
import { resetStore } from '../store/resetActions';

const API_URL = process.env.REACT_APP_API_URL;

interface QueueItem {
    resolve: (value: unknown) => void;
    reject: (reason?: unknown) => void;
}

let isRefreshing = false;
let failedQueue: QueueItem[] = [];

const processQueue = (error: unknown = null) => {
    failedQueue.forEach(({ resolve, reject }) => {
        if (error) {
            reject(error);
        } else {
            resolve(undefined);
        }
    });

    failedQueue = [];
};

// Fonction pour gérer la déconnexion complète et le reset du store
const handleLogout = () => {
    store.dispatch(resetStore());
    store.dispatch(logout());
};

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    withCredentials: true
});

interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
    _retry?: boolean;
    url?: string;
}

axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
        const originalRequest = error.config as ExtendedAxiosRequestConfig;

        if (
            error.response?.status === 401 &&
            !originalRequest._retry &&
            originalRequest.url !== '/v1/login'
        ) {
            if (originalRequest.url === '/v1/token/refresh') {
                handleLogout();
                return Promise.reject(error);
            }

            originalRequest._retry = true;

            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                })
                    .then(() => axiosInstance(originalRequest))
                    .catch(err => Promise.reject(err));
            }

            isRefreshing = true;

            try {
                await axios.post(
                    `${API_URL}/v1/token/refresh`,
                    {},
                    { withCredentials: true }
                );

                processQueue();
                return axiosInstance(originalRequest);
            } catch (refreshError) {
                processQueue(refreshError);
                handleLogout();
                return Promise.reject(refreshError);
            } finally {
                isRefreshing = false;
            }
        }

        // Correction précise ici :
        if (error.response?.status === 403) {
            console.warn("Accès refusé : Vous n'avez pas les droits pour cette action.");
            // Tu peux ici propager explicitement l'erreur sans logout
            return Promise.reject(error);
        }

        if (error.code === 'ECONNABORTED' || !error.response) {
            // erreur réseau ou timeout (tu peux adapter cela si nécessaire)
            handleLogout();
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;