/**
 * Formate un prix stocké en centimes en une chaîne de caractères lisible en euros
 * @param priceInCents Le prix en centimes comme retourné par l'API
 * @returns Une chaîne formatée avec le prix en euros (ex: "12,34" ou "1 234,56")
 */
export function formatPrice(priceInCents: number): string {
    // Convertir les centimes en euros (division par 100)
    const priceInEuros = priceInCents / 100;

    return priceInEuros
        .toFixed(2) // Toujours garder deux chiffres après la virgule
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') // Ajouter les espaces pour chaque unité (milliers, millions, etc.)
        .replace('.', ','); // Remplacer le point par une virgule
}