import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store';
import { updateCompanyInfo } from '../../../api/companyService';
import { setCompany, selectCompanyLoading } from '../../company/companySlice';
import Skeleton from '../../../components/Skeleton';
import { useTranslation } from 'react-i18next';
import InputText from '../../../components/InputText';
import {useCompany} from "../../../hooks/useCompany";
import {selectLoading, selectUser} from "../../auth/authSlice";
import {t} from "i18next";
import Button from "../../../components/UI/Button"; // Importation du composant InputText

const CompanyInformationForm: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const user = useSelector(selectUser);
    const userLoading = useSelector(selectLoading);

    const { company, loading: companyLoading, error: companyError , modifyCompany} = useCompany(user?.company?.uuid);

    const isLoading = userLoading || companyLoading;

    const [formData, setFormData] = useState({
        legalName: '',
        commercialName: '',
        companyNumber: '',
    });

    const [message, setMessage] = useState<string>('');

    // Effet pour charger les données de la société
    useEffect(() => {
        if (company) {
            setFormData({
                legalName: company.legalName || '',
                commercialName: company.commercialName || '',
                companyNumber: company.companyNumber || '',
            });
        }
    }, [company]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            await modifyCompany(formData);
            setMessage(t('settings.updateSuccess'));
        } catch (error) {
            console.error(t('settings.updateError'), error);
            setMessage(t('settings.updateError'));
        }
    };

    return (
        <form onSubmit={handleSubmit} className="max-w-sm">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {t('settings.companyInformation')}
            </label>
            <div className="space-y-6">
                {isLoading ? (
                    <>
                        <Skeleton height="40px" />
                        <Skeleton height="40px" />
                        <Skeleton height="40px" />
                    </>
                ) : (
                    <>
                        <InputText
                            label={t('company.legalName')}
                            name="legalName"
                            value={formData.legalName}
                            onChange={handleChange}
                            placeholder={t('company.legalName')}
                            required={true}
                        />
                        <InputText
                            label={t('company.commercialName.label')}
                            name="commercialName"
                            value={formData.commercialName}
                            onChange={handleChange}
                            placeholder={t('company.commercialName.placeholder')}
                            required={true}
                        />
                        <InputText
                            label={t('company.siret.label')}
                            name="companyNumber"
                            value={formData.companyNumber}
                            onChange={handleChange}
                            placeholder={t('company.siret.placeholder')}
                            required={true}
                        />
                    </>
                )}
                <Button
                    type="submit"
                    isLoading={isLoading}
                    fullWidth
                    size="medium"
                >
                    {t('settings.update')}
                </Button>
                {message && <div className="text-center mt-4 text-green-500">{message}</div>}
            </div>
        </form>
    );
};

export default CompanyInformationForm;