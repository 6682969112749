import React, { useEffect, useState } from 'react';
import ThreadList from '../features/messaging/ThreadList';
import ThreadContainer from '../features/messaging/ThreadContainer';
import { Route, Routes, useParams, useNavigate, useLocation, useMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { useMercure } from '../hooks/useMercure';
import { fetchThreadsWithLastMessage, markAsRead } from '../features/messaging/messagingSlice';
import { useAppDispatch } from '../store/hooks';
import { selectProjectById } from "../features/project/projectSlice";
import CustomerSidebar from "../features/messaging/components/CustomerSidebar";
import { useUserPermissions } from "../hooks/useUserPermissions";
import { usePageContext } from '../contexts/PageContext';
import {FiArrowLeft} from "react-icons/fi";
import Button from "../components/UI/Button";
import {useProjects} from "../hooks/useProjects";
import { setSelectedProject, updateProjectInList } from '../features/project/projectSlice';

interface ThreadWrapperProps {
    onMoreClick: () => void;
    onBackClick: () => void;
}

const ThreadWrapper: React.FC<ThreadWrapperProps> = ({ onMoreClick, onBackClick }) => {
    const { uuid } = useParams();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return uuid ? (
        <ThreadContainer
            threadId={uuid}
            onMoreClick={onMoreClick}
            onBackClick={onBackClick}
            showBackButton={isMobile}
        />
    ) : null;
};

const MessagingPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const match = useMatch('/messaging/:uuid');
    const currentThreadId = match?.params?.uuid;
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const { isCompanyProfile } = useUserPermissions();
    const { showList, setShowList } = usePageContext();

    const threads = useSelector((state: RootState) => {
        const threadsArray = state.messaging.threads.map(thread => {
            const project = thread.project?.uuid
                ? selectProjectById(state, thread.project.uuid)
                : null;
            return {
                ...thread,
                project
            };
        });
        return [...threadsArray].sort((a, b) => {
            const dateA = a.lastMessage?.createdAt ? new Date(a.lastMessage.createdAt) : new Date(0);
            const dateB = b.lastMessage?.createdAt ? new Date(b.lastMessage.createdAt) : new Date(0);
            return dateB.getTime() - dateA.getTime();
        });
    });

    const loading = useSelector((state: RootState) => state.messaging.loading);
    const unreadMessages = useSelector((state: RootState) => state.messaging.unreadMessages);
    const hasFetchedThreads = useSelector((state: RootState) => state.messaging.hasFetchedThreads);

    const currentThread = useSelector((state: RootState) =>
        currentThreadId ? state.messaging.threads.find(t => t.uuid === currentThreadId) : null
    );

    const currentProject = useSelector((state: RootState) =>
        currentThread?.project?.uuid ? selectProjectById(state, currentThread.project.uuid) : null
    );

    const { inviteCustomer } = useProjects(currentProject?.uuid);

    useMercure('/threads');

    useEffect(() => {
        if (!hasFetchedThreads) {
            dispatch(fetchThreadsWithLastMessage());
        }
    }, [dispatch, hasFetchedThreads]);

    useEffect(() => {
        if (
            threads.length > 0 &&
            !currentThreadId &&
            location.pathname === '/messaging'
        ) {
            const mostRecentThread = threads[0];
            navigate(`/messaging/${mostRecentThread.uuid}`, { replace: true });
        }
    }, [threads, currentThreadId, navigate, location.pathname]);

    useEffect(() => {
        if (currentThreadId) {
            dispatch(markAsRead({ threadId: currentThreadId }));
        }
    }, [currentThreadId, dispatch]);

    const handleThreadClick = (threadId: string) => {
        navigate(`/messaging/${threadId}`);
        dispatch(markAsRead({ threadId }));
        setShowList(false);
    };

    const handleBackToList = () => {
        setShowList(true);
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const customerData = currentProject && isCompanyProfile ? {
        firstName: currentProject.customer.firstName,
        lastName: currentProject.customer.lastName,
        companyName: currentProject.customer.companyName,
        email: currentProject.customer.email,
        phone: currentProject.customer.phone,
        documents: currentProject.documents,
    } : currentProject ? {
        firstName: currentProject.createdBy.firstName,
        lastName: currentProject.createdBy.lastName,
        companyName: currentProject.createdBy.company.commercialName,
        email: currentProject.createdBy.email,
        phone: currentProject.createdBy.phone,
        documents: currentProject.documents,
    } : undefined;

    const handleInviteCustomer = async () => {
        try {
            if (currentProject?.uuid) {
                await inviteCustomer(currentProject.uuid);

                dispatch(setSelectedProject({
                    ...currentProject,
                    visibility: {
                        label: 'Public',
                        value: 'public'
                    }
                }));
                dispatch(updateProjectInList({
                    uuid: currentProject.uuid,
                    visibility: { label: 'Public', value: 'public' }
                }));
            }
        } catch (error) {
            console.error("Erreur lors de l'invitation du client :", error);
        }
    };

    return (
        <div className="flex w-full h-screen md:overflow-hidden p-4 md:pr-0">
            {/* Liste des threads */}
            <div className={`${showList ? 'block' : 'hidden'} w-full md:block md:w-[300px] xl:w-[350px] md:border-r border-theme md:relative`}>
                <ThreadList
                    threads={threads}
                    loading={loading}
                    unreadMessages={unreadMessages}
                    onThreadClick={handleThreadClick}
                    activeThreadId={currentThreadId}
                />
            </div>

            {/* Container principal */}
            <div className="relative flex-1 flex">
                {/* Zone de messages */}
                <div className={`
                ${!showList ? 'block' : 'hidden'} md:block
                w-full flex-1 min-w-0
              `}>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <div className="flex items-center justify-center h-full text-primary-theme">
                                    {threads.length === 0 ? (
                                        <div className="text-gray-500">
                                            Aucune conversation disponible
                                        </div>
                                    ) : (
                                        "Sélectionnez une conversation"
                                    )}
                                </div>
                            }
                        />
                        <Route
                            path=":uuid"
                            element={
                                currentProject?.visibility?.value !== 'private' ? (
                                    <ThreadWrapper
                                        onMoreClick={toggleSidebar}
                                        onBackClick={handleBackToList}
                                    />
                                ) : (
                                    <div className="flex flex-col items-center justify-center h-full text-primary-theme gap-4 text-center">
                                        <div className="text-gray-500">
                                            Vous n'avez pas encore invité votre client
                                        </div>
                                        <Button size="medium" onClick={() => handleInviteCustomer?.()}>
                                            Inviter le client
                                        </Button>
                                    </div>
                                )
                            }
                        />
                    </Routes>

                </div>

                {/* Sidebar */}
                {isSidebarOpen && (
                    <div className="fixed md:relative inset-0 md:inset-auto z-50 md:z-auto">
                        <CustomerSidebar
                            isOpen={isSidebarOpen}
                            onClose={() => setIsSidebarOpen(false)}
                            customer={customerData}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default MessagingPage;