import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import type { AppDispatch } from '../../store/store'; // Assurez-vous que le chemin est correct
import { requestPasswordResetThunk } from './authSlice';
import Button from '../../components/UI/Button';
import {t} from "i18next";
import InputEmail from "../../components/InputEmail";
import Logo from "../../components/Logo";

type ForgotPasswordFormProps = {
    theme: string;
};

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ theme }) => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch(); // Utilisez AppDispatch pour typer dispatch

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setErrorMessage('');
        setSuccessMessage('');

        try {
            const resultAction = await dispatch(requestPasswordResetThunk(email));
            if (requestPasswordResetThunk.fulfilled.match(resultAction)) {
                setSuccessMessage('Un lien de réinitialisation a été envoyé à votre adresse e-mail.');
            } else {
                setErrorMessage('Une erreur s\'est produite. Veuillez réessayer.');
            }
        } catch (error) {
            setErrorMessage('Une erreur s\'est produite. Veuillez réessayer.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center  p-4">
            <div className="max-w-md w-full">
                <div className="mb:space-y-[5rem] space-y-0">
                    <div
                        className="absolute top-[3.5rem] md:top-[2.125rem] left-1/2 -translate-x-1/2 md:left-auto md:right-[2.125rem] md:transform-none">
                        <Logo theme={theme}/>
                    </div>
                    <div className="space-y-[.313rem]">
                        <h2 className="text-center text-[2rem] leading-9 font-medium text-primary-theme">{t('forgotPassword.title')}</h2>
                        <p className="text-center text-xs leading-normal font-normal text-primary-theme">{t('forgotPassword.label')}</p>
                    </div>
                </div>
                <form className="mt-10 space-y-[1.25rem]" onSubmit={handleSubmit}>
                    <div className="space-y-[1.25rem]">
                        <div>
                            <InputEmail
                                label={t('forgotPassword.email.label')}
                                name="email"
                                value={email}
                                onChange={handleChange}
                                placeholder={t('forgotPassword.email.placeholder')}
                                required={true}
                            />
                        </div>
                    </div>

                    {successMessage && (
                        <div className="text-center text-green-500 text-xs">
                            {successMessage}
                        </div>
                    )}

                    {errorMessage && (
                        <div className="text-center text-red-500 text-xs">
                            {errorMessage}
                        </div>
                    )}

                    <div className="flex items-center justify-between">
                        <Button
                            type="submit"
                            isLoading={isLoading}
                            fullWidth
                            size="medium"
                        >
                            {t('forgotPassword.submit')}
                        </Button>
                    </div>

                    <div className="flex items-center justify-between">
                        <Button
                            size="small"
                            type="button"
                            background={false}
                            onClick={() => navigate('/login')}
                        >
                            {t('forgotPassword.back')}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ForgotPasswordForm;
