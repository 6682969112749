import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../store/hooks';
import {
    fetchCompanyById,
    updateCompany,
    selectCompany,
    selectCompanyLoading,
    selectCompanyError,
    setCompany,
} from '../features/company/companySlice';
import { Company } from '../features/company/companySlice'; // Import du type

export const useCompany = (userCompanyUuid?: string) => {
    const dispatch = useAppDispatch(); // Utilisez le dispatch typé

    // Sélectionner les données et les états depuis Redux
    const company = useSelector(selectCompany);
    const loading = useSelector(selectCompanyLoading);
    const error = useSelector(selectCompanyError);

    // Récupérer les données de la société
    useEffect(() => {
        if (!userCompanyUuid) {
            return; // Si aucun UUID de société n'est fourni, ne rien faire
        }

        // Si les données de la société sont déjà chargées et correspondent à l'UUID, on évite de recharger
        if (company?.uuid === userCompanyUuid) {
            return;
        }

        // Dispatch du thunk pour récupérer les données
        dispatch(fetchCompanyById(userCompanyUuid));
    }, [userCompanyUuid, company, dispatch]);

    // Méthode pour mettre à jour les données de la société
    const modifyCompany = async (companyData: Partial<Company>): Promise<void> => {
        const companyUuid = companyData?.uuid || company?.uuid; // Vérifiez si le UUID est déjà dans l'état global
        if (!companyUuid) {
            throw new Error('No company UUID provided');
        }

        try {
            const updatedCompany = await dispatch(
                updateCompany({ uuid: companyUuid, companyData })
            ).unwrap();

            // Facultatif : mettez à jour Redux directement
            dispatch(setCompany(updatedCompany));
        } catch (err) {
            console.error('Failed to update company:', err);
            throw err; // Re-propagez l'erreur pour que le composant parent puisse la gérer
        }
    };


    return { company, loading, error, modifyCompany };
};
