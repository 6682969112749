import React, { useState } from 'react';

interface InputColorProps {
    label: string;
    name: string;
    value: string;
    onChange: (color: string) => void;
    required?: boolean;
}

const InputColor: React.FC<InputColorProps> = ({ label, name, value, onChange, required = false }) => {
    const [color, setColor] = useState<string>(value);

    const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newColor = e.target.type === 'color'
            ? e.target.value
            : `#${e.target.value.replace(/[^0-9A-Fa-f]/g, '').slice(0, 6)}`;

        // Vérifier si la couleur est valide (6 caractères après le #)
        if (e.target.type === 'text' && newColor.length !== 7) return;

        setColor(newColor);
        onChange(newColor);
    };

    const handleHexInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let hexValue = e.target.value.replace(/[^0-9A-Fa-f]/g, '');

        if (hexValue.length <= 6) {
            const newColor = hexValue ? `#${hexValue}` : '#';
            setColor(newColor);

            if (hexValue.length === 6) {
                onChange(newColor);
            }
        }
    };

    return (
        <div className="mb-4">
            <label className="block text-primary-theme text-xs font-normal mb-[5px]">
                {label}
            </label>
            <div className="flex items-center gap-4">
                <input
                    id="color-picker"
                    type="color"
                    name={name}
                    value={color}
                    onChange={handleColorChange}
                    className="w-14 h-[50px] border-0 cursor-pointer rounded-md bg-transparent outline-none"
                    style={{
                        WebkitAppearance: 'none',
                    }}
                />
                <style>
                    {`
                      #color-picker::-webkit-color-swatch {
                        border-radius: 8px;
                        border: none;
                        height: 100%;
                      }
                    `}
                </style>
                <div className="relative flex items-center w-full">
                    <span className="absolute left-3 text-gray-500">#</span>
                    <input
                        type="text"
                        value={color.replace('#', '')}
                        onChange={handleHexInputChange}
                        maxLength={6}
                        className="appearance-none text-sm block w-full border border-theme bg-theme text-primary-theme rounded-lg pl-7 pr-3 py-3 leading-tight focus:outline-none focus:ring-1 focus:ring-primary"
                    />
                </div>
            </div>
        </div>
    );
};

export default InputColor;