import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store';
import { changePassword } from '../../../api/authService';
import { setUser } from '../../auth/authSlice';
import Skeleton from '../../../components/Skeleton';
import { useTranslation } from 'react-i18next';
import { t } from "i18next";
import InputPassword from "../../../components/InputPassword";
import Button from "../../../components/UI/Button";

const SecurityForm: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.auth);

    const [formData, setFormData] = React.useState({
        currentPassword: '',
        plainPassword: '',
        confirmPassword: '',
    });

    const [loading, setLoading] = React.useState<boolean>(true);
    const [message, setMessage] = React.useState<string>('');

    React.useEffect(() => {
        if (user) {
            setFormData({
                currentPassword: '',
                plainPassword: '',
                confirmPassword: '',
            });
            setLoading(false);
        }
    }, [user]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const response = await changePassword({
                currentPassword: formData.currentPassword,
                newPassword: formData.plainPassword,
                confirmPassword: formData.confirmPassword,
            });

            dispatch(setUser(response.data));
            setMessage(t('security.updateSuccess'));
        } catch (error) {
            console.error(t('security.updateError'), error);
            setMessage(t('security.updateError'));
        }
    };

    return (
        <form onSubmit={handleSubmit} className="max-w-sm">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {t('security.changePassword')}
            </label>
            <div className="space-y-6">
                {loading ? (
                    <>
                        <Skeleton height="40px" />
                        <Skeleton height="40px" />
                    </>
                ) : (
                    <>
                        <div>
                            <InputPassword
                                label={t('security.currentPassword')}
                                name="currentPassword"
                                value={formData.currentPassword}
                                onChange={handleChange}
                                placeholder={t('security.currentPassword')}
                                required={true}
                            />
                        </div>

                        <div>
                            <InputPassword
                                label={t('security.plainPassword')}
                                name="plainPassword"
                                value={formData.plainPassword}
                                onChange={handleChange}
                                placeholder={t('security.plainPassword')}
                                required={true}
                                hasRequirements={true}
                            />
                        </div>

                        <div>
                            <InputPassword
                                label={t('security.confirmPassword')}
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                placeholder={t('security.confirmPassword')}
                                required={true}
                                isConfirmation={true}
                                passwordToMatch={formData.plainPassword}
                            />
                        </div>
                    </>
                )}
                <Button
                    type="submit"
                    // isLoading={isLoading}
                    fullWidth
                    size="medium"
                >
                    {t('settings.update')}
                </Button>
                {message && <div className="text-center mt-4 text-green-500">{message}</div>}
            </div>
        </form>
    );
};

export default SecurityForm;