// src/components/company/StepOne.tsx
import React, {useEffect, useState} from 'react';
import { t } from 'i18next';
import { CompanySearchResult, CompanyFormData } from './types';
import SiretSearchInput from "../../components/SiretSearchInput";
import InputText from "../../components/InputText";
import CountrySelect from "../../components/CountrySelect";
import Accordion from "../../components/Accordion";
import SiretInput from "../../components/SiretInput";
import InputZipcode from "../../components/InputZipcode";

interface StepOneProps {
    formData: CompanyFormData;
    autoFilledFields: Record<string, boolean>;
    onCompanySelect: (company: CompanySearchResult) => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

const StepOne: React.FC<StepOneProps> = ({
     formData,
     autoFilledFields,
     onCompanySelect,
     onChange
 }) => {

    const [isManualInputOpen, setIsManualInputOpen] = useState(false);

    useEffect(() => {
        const hasAutoFilledFields = Object.values(autoFilledFields).some(value => value);
        if (hasAutoFilledFields) {
            setIsManualInputOpen(true);
        }
    }, [autoFilledFields]);

    return (
        <div className="space-y-[3rem]">
            <div>
                <SiretSearchInput
                    label={t('company.numberSearch.label')}
                    name="companyNumberSearch"
                    value={formData.companyNumberSearch || ''}
                    onChange={(e) => onChange(e)}
                    placeholder={t('company.numberSearch.placeholder')}
                    onCompanySelect={onCompanySelect}
                />
                <p className="text-[10px] mt-[5px]">{t('company.numberSearch.help')}</p>
            </div>
            <Accordion
                title={t('company.manualInput')}
                isOpen={isManualInputOpen}
                onToggle={setIsManualInputOpen}
            >
                <div className="space-y-[1.25rem]">
                    <SiretInput
                        label={t('company.siret.label')}
                        name="companyNumber"
                        value={formData.companyNumber}
                        onChange={onChange}
                        placeholder={t('company.siret.placeholder')}
                        autoFilled={autoFilledFields['companyNumber']}
                        required
                    />
                    <InputText
                        label={t('company.legalName')}
                        name="legalName"
                        value={formData.legalName}
                        onChange={onChange}
                        placeholder={t('company.legalName')}
                        autoFilled={autoFilledFields['legalName']}
                    />
                    <InputText
                        label={t('company.commercialName.label')}
                        name="commercialName"
                        value={formData.commercialName}
                        onChange={onChange}
                        placeholder={t('company.commercialName.placeholder')}
                        autoFilled={autoFilledFields['commercialName']}
                    />
                    <InputText
                        label={t('company.address.street.label')}
                        name="address.street"
                        value={formData.address.street}
                        onChange={onChange}
                        placeholder={t('company.address.street.placeholder')}
                        autoFilled={autoFilledFields['address.street']}
                    />
                    <InputText
                        label={t('company.address.streetComplement.label')}
                        name="address.streetComplement"
                        value={formData.address.streetComplement}
                        onChange={onChange}
                        placeholder={t('company.address.streetComplement.placeholder')}
                        autoFilled={autoFilledFields['address.streetComplement']}
                    />
                    <InputText
                        label={t('company.address.city.label')}
                        name="address.city"
                        value={formData.address.city}
                        onChange={onChange}
                        placeholder={t('company.address.city.placeholder')}
                        autoFilled={autoFilledFields['address.city']}
                    />
                    <InputZipcode
                        label={t('company.address.zipCode.label')}
                        name="address.zipCode"
                        value={formData.address.zipCode}
                        onChange={onChange}
                        placeholder={t('company.address.zipCode.placeholder')}
                        autoFilled={autoFilledFields['address.zipCode']}
                    />
                    <CountrySelect
                        label={t('company.address.country.label')}
                        name="address.country"
                        value={formData.address.country}
                        onChange={onChange}
                        required
                        autoFilled={autoFilledFields['address.country']}
                    />
                    <InputText
                        label={t('company.vatNumber.label')}
                        name="vatNumber"
                        value={formData.vatNumber}
                        onChange={onChange}
                        placeholder={t('company.vatNumber.placeholder')}
                        autoFilled={autoFilledFields['legalName']}
                    />
                </div>
            </Accordion>
        </div>
    );
};

export default StepOne;