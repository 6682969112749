import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
    getProjectDocument,
    getMissionDocument,
    uploadProjectDocument,
    uploadMissionDocument,
    deleteProjectDocument,
    deleteMissionDocument
} from '../../api/documentService';

interface DocumentState {
    documents: any[];
    selectedDocument: any | null;
    loading: boolean;
    error: string | null;
}

const initialState: DocumentState = {
    documents: [],
    selectedDocument: null,
    loading: false,
    error: null,
};

// Thunks pour les documents de projet
export const fetchProjectDocument = createAsyncThunk(
    'documents/fetchProjectDocument',
    async ({ projectUuid, documentUuid }: { projectUuid: string; documentUuid: string }, { rejectWithValue }) => {
        try {
            const response = await getProjectDocument(projectUuid, documentUuid);
            return response;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const removeProjectDocument = createAsyncThunk(
    'documents/deleteProjectDocument',
    async ({ projectUuid, documentUuid }: { projectUuid: string; documentUuid: string }, { rejectWithValue }) => {
        try {
            await deleteProjectDocument(projectUuid, documentUuid);
            return documentUuid;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const addProjectDocument = createAsyncThunk(
    'documents/uploadProjectDocument',
    async (
        { formData, projectUuid }: { formData: FormData; projectUuid: string },
        { rejectWithValue }
    ) => {
        try {
            const response = await uploadProjectDocument(formData, projectUuid);
            return { document: response.data, projectUuid };
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

// Thunks pour les documents de mission
export const fetchMissionDocument = createAsyncThunk(
    'documents/fetchMissionDocument',
    async ({ projectUuid, missionUuid, documentUuid }:
               { projectUuid: string; missionUuid: string; documentUuid: string },
           { rejectWithValue }
    ) => {
        try {
            const response = await getMissionDocument(projectUuid, missionUuid, documentUuid);
            return response;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const removeMissionDocument = createAsyncThunk(
    'documents/deleteMissionDocument',
    async ({ projectUuid, missionUuid, documentUuid }:
               { projectUuid: string; missionUuid: string; documentUuid: string },
           { rejectWithValue }
    ) => {
        try {
            await deleteMissionDocument(projectUuid, missionUuid, documentUuid);
            return documentUuid;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const addMissionDocument = createAsyncThunk(
    'documents/uploadMissionDocument',
    async (
        {
            formData,
            projectUuid,
            missionUuid,
        }: { formData: FormData; projectUuid: string; missionUuid: string },
        { rejectWithValue }
    ) => {
        try {
            const response = await uploadMissionDocument(formData, projectUuid, missionUuid);
            return {
                document: response.data,
                projectUuid,
                missionUuid,
            };
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

const documentSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        clearSelectedDocument: (state) => {
            state.selectedDocument = null;
        }
    },
    extraReducers: (builder) => {
        builder
            // Project document cases
            .addCase(fetchProjectDocument.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProjectDocument.fulfilled, (state, action) => {
                state.selectedDocument = action.payload;
                state.loading = false;
            })
            .addCase(fetchProjectDocument.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(removeProjectDocument.fulfilled, (state, action) => {
                state.documents = state.documents.filter(doc => doc.uuid !== action.payload);
                state.loading = false;
            })
            // Mission document cases
            .addCase(fetchMissionDocument.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMissionDocument.fulfilled, (state, action) => {
                state.selectedDocument = action.payload;
                state.loading = false;
            })
            .addCase(fetchMissionDocument.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(removeMissionDocument.fulfilled, (state, action) => {
                state.documents = state.documents.filter(doc => doc.uuid !== action.payload);
                state.loading = false;
            });
    },
});

export const { clearSelectedDocument } = documentSlice.actions;
export default documentSlice.reducer;

// Sélecteurs
export const selectDocuments = (state: any) => state.documents.documents;
export const selectDocumentsLoading = (state: any) => state.documents.loading;
export const selectDocumentsError = (state: any) => state.documents.error;
export const selectSelectedDocument = (state: any) => state.documents.selectedDocument;