import React, { useState, useEffect } from 'react';
import { Mission } from '../types';
import InputText from '../../../components/InputText';
import DateInput from '../../../components/DateInput';
import TextArea from '../../../components/TextArea';
import { FiArrowUp, FiArrowDown, FiChevronUp, FiChevronDown } from "react-icons/fi";
import DocumentSection from './DocumentSection';
import InputNumber from "../../../components/InputNumber";
import {formatPrice} from "../../../utils/formatPrice";

interface MissionItemProps {
    mission: Mission;
    index: number;
    isExpanded: boolean;
    isFirst: boolean;
    isLast: boolean;
    onToggle: () => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    onMoveUp: () => void;
    onMoveDown: () => void;
    onRemove: () => void;
    onUploadDocument?: (files: FileList) => void;
    onRemoveDocument?: (documentUuid: string) => void;
    documentsLoading?: boolean;
    isEditMode?: boolean;
    onPriceModified?: (index: number) => void;
    onMissionModified?: (index: number) => void;
}

const MissionItem: React.FC<MissionItemProps> = ({
     mission,
     index,
     isExpanded,
     isFirst,
     isLast,
     onToggle,
     onChange,
     onMoveUp,
     onMoveDown,
     onRemove,
     onUploadDocument,
     onRemoveDocument,
     documentsLoading,
     isEditMode = false,
     onPriceModified,
     onMissionModified
 }) => {
    // Détecter si la mission a un UUID - une mission existante indique mode édition
    const isExistingMission = !!mission.uuid;

    // État local pour stocker le prix en euros pour l'affichage ET le calcul
    const [displayPrice, setDisplayPrice] = useState<string>("");

    // État pour suivre si l'utilisateur a modifié le prix
    const [userModified, setUserModified] = useState<boolean>(false);

    // Initialiser le prix au montage du composant

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Ne mettre à jour que si displayPrice n'est pas déjà défini ou si mission.unitPrice a changé
        // Si mission.unitPrice est undefined, utiliser 0 comme valeur par défaut
        const missionPrice = mission.unitPrice !== undefined ? mission.unitPrice : 0;

        if (!displayPrice || String(missionPrice) !== displayPrice) {
            // Pour les missions existantes en mode édition, on convertit les centimes en euros
            if (isEditMode && isExistingMission) {
                // Convertir des centimes en euros
                const rawValue = typeof missionPrice === 'number'
                    ? missionPrice
                    : parseFloat(String(missionPrice) || '0');

                const priceInEuros = rawValue / 100;
                setDisplayPrice(priceInEuros.toString());

            } else {
                // Pour les nouvelles missions, utiliser la valeur telle quelle
                setDisplayPrice(String(missionPrice));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mission.unitPrice]);

    // Calculer le prix unitaire à partir de la valeur affichée
    const unitPrice = parseFloat(displayPrice || '0');

    const quantity = mission.quantity !== undefined
        ? (typeof mission.quantity === 'number'
            ? mission.quantity
            : parseFloat(String(mission.quantity) || '0'))
        : 0;

    // Calculer le total
    const totalAmount = parseFloat((unitPrice * quantity).toFixed(2));

    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const newValue = e.target.value;

        // Mettre à jour notre état local
        setDisplayPrice(newValue);
        setUserModified(true);

        // Informer le parent que le prix a été modifié
        if (onPriceModified) {
            onPriceModified(index);
        }

        // Informer le parent que la mission a été modifiée (général)
        if (onMissionModified) {
            onMissionModified(index);
        }

        // Créer un événement synthétique pour transmettre la valeur au parent
        const syntheticEvent = {
            ...e,
            target: {
                ...e.target,
                name: e.target.name,
                value: newValue
            }
        } as React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;

        // Transmettre l'événement au parent
        onChange(syntheticEvent);
    };

    const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        // Informer le parent que la mission a été modifiée (général)
        if (onMissionModified) {
            onMissionModified(index);
        }

        // Transmettre l'événement au parent
        onChange(e);
    };

    // Fonction pour formater les montants avec toujours 2 décimales
    const formatAmount = (amount: number): string => {
        return amount.toLocaleString('fr-FR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    };

    // Fonction pour formater la date
    const formatDate = (dateString: string): string => {
        if (!dateString) return '';

        // Vérifier si la date est au format ISO complet
        if (dateString.includes('T')) {
            // Extraire seulement la partie YYYY-MM-DD
            return dateString.split('T')[0];
        }

        return dateString;
    };

    return (
        <div className="rounded-md border border-theme p-4">
            <div className="flex justify-between items-center">
                <div className="flex items-center gap-2 cursor-pointer w-full" onClick={onToggle}>
                    <span className="text-primary-theme">
                        {isExpanded ? <FiChevronUp/> : <FiChevronDown/>}
                    </span>
                    <span className="text-primary-theme">
                        {(mission.title || `Mission ${index + 1}`)}
                    </span>
                </div>
                <div className="flex items-center gap-4">
                    <span className="text-sm text-primary-theme cursor-pointer w-full whitespace-nowrap" onClick={onToggle}>
                        {formatAmount(totalAmount)} €
                    </span>
                    <div className="flex flex-col">
                        <button
                            type="button"
                            onClick={onMoveUp}
                            disabled={isFirst}
                            className={`p-1 rounded ${isFirst ? 'text-secondary-theme hover:bg-transparent' : 'text-primary-theme hover:bg-theme-hover'}`}
                        >
                            <FiArrowUp/>
                        </button>
                        <button
                            type="button"
                            onClick={onMoveDown}
                            disabled={isLast}
                            className={`p-1 rounded ${isLast ? 'text-secondary-theme hover:bg-transparent ' : 'text-primary-theme hover:bg-theme-hover'}`}
                        >
                            <FiArrowDown/>
                        </button>
                    </div>
                </div>
            </div>

            {/* Conteneur avec animation CSS */}
            <div className={`
                mt-0 overflow-hidden transition-all duration-300 ease-in-out origin-top
                ${isExpanded ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'}
            `}>
                <div className="mt-4 space-y-6">
                    <div className="space-y-6">
                        <InputText
                            label="Titre de la mission"
                            name="title"
                            value={mission.title || ''}
                            onChange={handleFieldChange}
                            placeholder="Entrez le titre de la mission"
                        />
                        <TextArea
                            label="Description de la mission"
                            name="description"
                            value={mission.description || ''}
                            onChange={handleFieldChange}
                            placeholder="Entrez la description de la mission"
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <DateInput
                                label="Date de fin prévue"
                                name="exceptedEndDate"
                                value={formatDate(mission.exceptedEndDate)}
                                onChange={handleFieldChange}
                            />
                        </div>
                        <div>
                            <InputNumber
                                label="Prix unitaire (€)"
                                name="unitPrice"
                                value={displayPrice}
                                onChange={handlePriceChange}
                                placeholder="Entrez le prix unitaire en euros"
                                step={0.01}
                                allowNegative={true}
                            />
                        </div>
                        <div>
                            <InputNumber
                                label="Quantité"
                                name="quantity"
                                value={mission.quantity !== undefined ? mission.quantity.toString() : '0'}
                                onChange={handleFieldChange}
                                placeholder="Entrez la quantité"
                                min={0}
                                step={1}
                            />
                        </div>
                        <div>
                            <div className="flex flex-col h-full justify-center bg-surface-theme rounded-lg px-4">
                                <span className="text-sm text-secondary-theme">Montant total</span>
                                <span className="text-primary-theme font-medium">
                                    {formatAmount(totalAmount)} €
                                </span>
                            </div>
                        </div>
                    </div>

                    {mission.uuid && onUploadDocument && onRemoveDocument && (
                        <DocumentSection
                            documents={mission.documents}
                            onUpload={onUploadDocument}
                            onRemove={onRemoveDocument}
                            isLoading={documentsLoading}
                        />
                    )}

                    <button
                        type="button"
                        onClick={onRemove}
                        className="text-red-500 mt-2"
                    >
                        Supprimer
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MissionItem;