import React from 'react';

interface Tab<T extends string> {
    label: string;
    value: T;
}

interface TabsProps<T extends string> {
    tabs: { label: string; value: T }[];
    activeTab: T;
    onSelectTab: (tab: T) => void;
}

const Tabs = <T extends string>({ tabs, activeTab, onSelectTab }: TabsProps<T>) => {
    return (
        <div className="relative w-full">
            <div className="overflow-x-auto scrollbar-hide">
                <div className="flex space-x-4 py-2 min-w-min">
                    {tabs.map((tab) => (
                        <button
                            key={tab.value}
                            className={`whitespace-nowrap py-2 px-4 rounded-md text-sm font-medium flex-shrink-0 ${
                                activeTab === tab.value
                                    ? 'bg-theme text-primary-theme'
                                    : 'text-secondary-theme hover:bg-theme-hover'
                            }`}
                            onClick={() => onSelectTab(tab.value)}
                        >
                            {tab.label}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Tabs;
