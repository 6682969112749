import React from 'react';
import ResetPassword from "../features/auth/ResetPasswordForm";

type LoginPageProps = {
    theme: string;
};

const LoginPage: React.FC<LoginPageProps> = ({ theme }) => {
    return (
        <div>
            <ResetPassword theme={theme} />
        </div>
    );
};

export default LoginPage;
