import React from 'react';

interface ImageDisplayProps {
    uuid: string;
    alt?: string;
    className?: string;
    baseUrl?: string;
}

export const ImageDisplay: React.FC<ImageDisplayProps> = ({
  uuid,
  alt = '',
  className = '',
  baseUrl
}) => {
    if (!uuid) return null;

    // Utiliser une variable d'environnement ou un paramètre pour l'URL de base
    const imageBaseUrl = baseUrl || process.env.REACT_APP_API_URL || window.location.origin;
    return (
        <img
            src={`${imageBaseUrl}/v1/images/${uuid}/download`}
            alt={alt}
            className={`max-w-full h-auto ${className}`}
            onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.style.display = 'none';
            }}
        />
    );
};