import axiosInstance from './apiService';
import qs from 'qs';

// Interface pour les données d'inscription
interface RegisterData {
    email: string;
    firstName: string;
    lastName: string;
    plainPassword: string;
}

// Interface pour les données de connexion
interface LoginData {
    username: string;
    password: string;
}

// Fonction d'inscription
export const register = (userData: RegisterData) => {
    const data = qs.stringify(userData);
    return axiosInstance.post('/v1/register', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
};

// Fonction de connexion
export const login = (userData: LoginData) => {
    const data = qs.stringify(userData);
    return axiosInstance.post('/v1/login', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
};

export const getUserInfo = () => {
    return axiosInstance.get('/v1/users/me');
};

export const updateUserInfo = (userData: any) => {
    const data = qs.stringify(userData);
    return axiosInstance.patch('/v1/users/me', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
};

// Fonction pour associer un utilisateur à un client via un token d'invitation
export const associateToCustomer = (invitationToken: string) => {
    const data = new URLSearchParams();
    data.append('invitationToken', invitationToken);

    return axiosInstance.post('/v1/users/me/associate-to-customer', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
};

// Fonction de déconnexion
export const logout = () => {
    return axiosInstance.post('/v1/logout', {}, {
        withCredentials: true
    });
};

export const changePassword = (passwordData: { currentPassword: string; newPassword: string; confirmPassword: string }) => {
    const data = qs.stringify({
        oldPassword: passwordData.currentPassword,
        password: {
            first: passwordData.newPassword,
            second: passwordData.confirmPassword
        }
    });

    return axiosInstance.post('/v1/users/me/change-password', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
};

export const requestPasswordReset = (email: string) => {
    const data = qs.stringify({ email });
    return axiosInstance.post('/v1/users/request-reset-password', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
};

export const resetPassword = (token: string, passwordData: { newPassword: string; confirmPassword: string }) => {
    const data = {
        password: {
            first: passwordData.newPassword,
            second: passwordData.confirmPassword,
        },
    };

    return axiosInstance.post(`/v1/users/reset-password?passwordResetToken=${token}`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
}

export const requestMagicLink = (email: string) => {
    const data = qs.stringify({ username: email });
    return axiosInstance.post('/v1/users/send-magic-auth', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
};

export const loginWithMagicLink = (username: string, magicAuthName: string, magicAuthToken: string) => {
    const data = qs.stringify({
        "username": username,
        "magic-auth-token": magicAuthToken,
        "magic-auth-name": magicAuthName
    });
    return axiosInstance.post('/v1/login-magic-link', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
};