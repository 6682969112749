import { Mission, CreateMissionDTO } from '../types';
import { TrackedChanges } from './types';

export const updateLocalMission = (
    localMissions: Mission[],
    setLocalMissions: React.Dispatch<React.SetStateAction<Mission[]>>,
    index: number,
    name: string,
    value: string | number
): void => {
    const updatedMissions = [...localMissions];
    updatedMissions[index] = {
        ...updatedMissions[index],
        [name]: value
    };
    setLocalMissions(updatedMissions);
};

export const trackMissionChanges = (
    mission: Mission,
    index: number,
    name: string,
    value: string | number,
    missions: Mission[],
    setTrackedChanges: React.Dispatch<React.SetStateAction<TrackedChanges>>
): void => {
    if (mission.uuid) {
        setTrackedChanges(prev => ({
            ...prev,
            modifiedMissions: {
                ...prev.modifiedMissions,
                [mission.uuid]: {
                    ...prev.modifiedMissions[mission.uuid],
                    uuid: mission.uuid,
                    [name]: value
                }
            }
        }));
    } else {
        const newMissionData: Partial<CreateMissionDTO> = {
            ...missions[index],
            [name]: value
        };
        setTrackedChanges(prev => ({
            ...prev,
            newMissions: prev.newMissions.map((m, i) =>
                i === index - missions.length ? newMissionData as CreateMissionDTO : m
            )
        }));
    }
};

export const trackDocumentAddition = (
    missionUuid: string,
    file: File,
    setTrackedChanges: React.Dispatch<React.SetStateAction<TrackedChanges>>
) => {
    setTrackedChanges(prev => ({
        ...prev,
        documentChanges: {
            ...prev.documentChanges,
            [missionUuid]: {
                added: [...(prev.documentChanges[missionUuid]?.added || []), file],
                removed: prev.documentChanges[missionUuid]?.removed || []
            }
        }
    }));
};

export const trackDocumentRemoval = (
    missionUuid: string,
    documentUuid: string,
    setTrackedChanges: React.Dispatch<React.SetStateAction<TrackedChanges>>
) => {
    setTrackedChanges(prev => ({
        ...prev,
        documentChanges: {
            ...prev.documentChanges,
            [missionUuid]: {
                added: prev.documentChanges[missionUuid]?.added || [],
                removed: [...(prev.documentChanges[missionUuid]?.removed || []), documentUuid]
            }
        }
    }));
};

export const updateExpandedStatesForMove = (
    fromIndex: number,
    toIndex: number,
    expandedMissions: boolean[],
    setExpandedMissions: React.Dispatch<React.SetStateAction<boolean[]>>
) => {
    const newExpandedStates = [...expandedMissions];
    [newExpandedStates[fromIndex], newExpandedStates[toIndex]] =
        [newExpandedStates[toIndex], newExpandedStates[fromIndex]];
    setExpandedMissions(newExpandedStates);
};

export const handleEditMode = async (
    projectData: any,
    formData: any,
    modifyProject: any,
    missions: Mission[],
    localMissions: Mission[],
    setMessage: (message: string) => void
) => {
    try {
        const projectPayload = {
            ...(formData.title !== projectData.title ? { title: formData.title } : {}),
            ...(formData.description !== projectData.description ? { description: formData.description } : {}),
            missions: localMissions.map(mission => ({
                uuid: mission.uuid,
                title: mission.title,
                description: mission.description,
                exceptedEndDate: mission.exceptedEndDate,
                unitPrice: mission.unitPrice,
                position: mission.position,
                quantity: mission.quantity,
                status: mission.status
            }))
        };

        await modifyProject(projectData.uuid, projectPayload);
        setMessage('Modifications enregistrées avec succès');
    } catch (error) {
        console.error('Erreur lors de la mise à jour:', error);
        setMessage('Erreur lors de la mise à jour');
        throw error;
    }
};

export const handleCreateMode = async (
    formData: any,
    addProject: any,
    localMissions: Mission[],
    setMessage: (message: string) => void
) => {
    try {
        const projectPayload = {
            title: formData.title,
            description: formData.description,
            customer: formData.customer,
            missions: localMissions.map((mission, index) => ({
                title: mission.title,
                description: mission.description,
                exceptedEndDate: mission.exceptedEndDate,
                unitPrice: mission.unitPrice,
                position: index,
                quantity: mission.quantity,
                status: 'draft'
            }))
        };

        await addProject(projectPayload);
        setMessage('Projet créé avec succès');
    } catch (error) {
        console.error('Erreur lors de la création:', error);
        setMessage('Erreur lors de la création');
        throw error;
    }
};

export const updateMissionsPositions = async (
    missions: Mission[],
    updatePositions: (positions: { uuid: string; position: number }[]) => Promise<void>
) => {
    const positions = missions
        .filter(mission => mission.uuid)
        .map((mission, index) => ({
            uuid: mission.uuid,
            position: index
        }));

    if (positions.length > 0) {
        await updatePositions(positions);
    }
};