import React, { useEffect, useState, useRef } from 'react';

interface DropOverlayProps {
    setFile: (file: File | null) => void;
}

const DropOverlay: React.FC<DropOverlayProps> = ({ setFile }) => {
    const [isDragging, setIsDragging] = useState(false);
    const dragCounter = useRef(0);

    useEffect(() => {
        const handleDragEnter = (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();

            // Vérifier si l'élément traîné contient des fichiers
            const hasFiles = Array.from(e.dataTransfer?.items || []).some(
                item => item.kind === 'file'
            );

            if (!hasFiles) {
                return; // Ne pas activer l'overlay si pas de fichiers
            }

            dragCounter.current += 1;
            if (dragCounter.current === 1) {
                setIsDragging(true);
            }
        };

        const handleDragOver = (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
        };

        const handleDragLeave = (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();

            // Vérifier si l'élément traîné contient des fichiers
            const hasFiles = Array.from(e.dataTransfer?.items || []).some(
                item => item.kind === 'file'
            );

            if (!hasFiles) {
                return; // Ne pas modifier l'état si pas de fichiers
            }

            dragCounter.current -= 1;
            if (dragCounter.current === 0) {
                setIsDragging(false);
            }
        };

        const handleDrop = (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDragging(false);
            dragCounter.current = 0;

            if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
                setFile(e.dataTransfer.files[0]);
                e.dataTransfer.clearData();
            }
        };

        window.addEventListener('dragenter', handleDragEnter);
        window.addEventListener('dragover', handleDragOver);
        window.addEventListener('dragleave', handleDragLeave);
        window.addEventListener('drop', handleDrop);

        return () => {
            window.removeEventListener('dragenter', handleDragEnter);
            window.removeEventListener('dragover', handleDragOver);
            window.removeEventListener('dragleave', handleDragLeave);
            window.removeEventListener('drop', handleDrop);
        };
    }, [setFile]);

    return (
        <>
            {isDragging && (
                <div className="fixed inset-0 bg-theme/10 backdrop-blur-sm flex items-center justify-center z-50">
                    <p className="text-lg font-normal text-theme">Déposez le document ici</p>
                </div>
            )}
        </>
    );
};

export default DropOverlay;