import React from 'react';
import { useLogo } from '../hooks/useLogo';

type LogoProps = {
    theme: string;
};

const Logo: React.FC<LogoProps> = ({ theme}) => {
    const logo = useLogo(theme);

    return <img src={logo} alt="Logo" className="whitespace-nowrap leading-none h-8" />;
};

export default Logo;